import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { COBAProgram } from '../../../../models/Enums';
import { CobaDisclosurePageProps } from './cobaDisclosurePageProps';
import usePageSignature from '../../../../hooks/pageSignature';
import { COBAFormsPage } from '../../../../models/Forms.enum';

const DisclosurePage1 = forwardRef(({ visible, data: viewController }: CobaDisclosurePageProps, ref) => {
  const { isFormValid, validateForm, saveForm, getSignature, getAcknowledge, getInitials } = usePageSignature(viewController);

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(COBAFormsPage.Disclosure1);
    },
  }));

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        validateForm();
      }, 500);
    }
  }, [visible]);

  const getProgramsHours = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER:
        return '1000-Clock Hr. Program';
      case COBAProgram.BARBER_HYBRID:
        return '1000-Clock Hr. Program';
      case COBAProgram.COSMETOLOGY:
        return '1000-Clock Hr. Program';
      case COBAProgram.ESTHETICIAN:
        return '600-Clock Hr. Program';
      default:
        return '';
    }
  };

  const getMainHoursTitle = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER:
        return 'Barbering - 50 hours';
      case COBAProgram.BARBER_HYBRID:
        return 'Barbering - 50 hours';
      case COBAProgram.COSMETOLOGY:
        return 'Cosmetology - 50 hours';
      case COBAProgram.ESTHETICIAN:
        return 'Esthetics - 30 hours';
      default:
        return '';
    }
  };

  const getTransferHourstTitle = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER:
        return 'Barbering - 25 hours';
      case COBAProgram.BARBER_HYBRID:
          return 'Barbering - 25 hours';
      case COBAProgram.COSMETOLOGY:
        return 'Cosmetology - 25 hours';
      case COBAProgram.ESTHETICIAN:
        return 'Esthetics - 15 hours';
      default:
        return '';
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="doc-content">
      <h3>CONSUMER INFORMATION DISCLOSURES</h3>
      <p>
        The Higher Education Opportunity Act of 2008 (HEOA) requires that postsecondary institutions participating in federal student aid
        programs make certain disclosures to enrolled and prospective students, parents, employees, and the public. The following
        information is disclosed to you in compliance with federal law. For additional information please call or e-mail the appropriate
        office or visit the indicated websites below:
      </p>
      <ul>
        <li>
          Coba Academy School Catalog --
          <a href="https://finaid360.com/api/redirect/1/" target="_blank" rel="noreferrer">
            School Catalog
          </a>
        </li>
        <li>
          Admissions Requirements --
          <a href="https://finaid360.com/api/redirect/2/" target="_blank" rel="noreferrer">
            School Catalog
          </a>
        </li>
        <li>Coba Academy Performance Fact Sheet by Program:</li>
        <ul>
          <li>
            Barbering Program SPFS 2020-2021 --
            <a href="https://finaid360.com/api/redirect/3/" target="_blank" rel="noreferrer">
              Barbering Fact Sheet
            </a>
          </li>
          <li>
            Cosmetology Program SPFS 2020-2021 --
            <a href="https://finaid360.com/api/redirect/4/" target="_blank" rel="noreferrer">Cosmetology Fact Sheet</a>
          </li>
          <li>
            Esthetics Program SPFS 2020-2021 --
            <a href="https://finaid360.com/api/redirect/5/" target="_blank" rel="noreferrer">
              Esthetics Fact Sheet
            </a>
          </li>
        </ul>
        <li>
          Coba Academy Net Price Calculator --
          <a href="https://finaid360.com/api/redirect/6/" target="_blank" rel="noreferrer">
            Net Price Calc Link
          </a>
        </li>
        <li>
          Coba Academy Office of Financial Aid Website --
          <a href="https://finaid360.com/api/redirect/7/" target="_blank" rel="noreferrer">
            https://coba.edu/financial-aid/
          </a>
        </li>
        <li>
          Federal Financial Aid Loan History --
          <a href="https://finaid360.com/api/redirect/8/" target="_blank" rel="noreferrer">
            https://studentaid.gov/
          </a>
        </li>
        <li>
          Coba Academy Student Right to Cancel Policy --
          <a href="https://finaid360.com/api/redirect/9/" target="_blank" rel="noreferrer">
            School Catalog
          </a>
        </li>
        <li>
          Federal Cohort Default Rate Database --
          <a href="https://finaid360.com/api/redirect/10/" target="_blank" rel="noreferrer">FSA Official Cohort Default Rate</a>
        </li>
        <li>
          NACCAS Annual Report Statistics --
          <a href="https://finaid360.com/api/redirect/11/" target="_blank" rel="noreferrer">
            Coba Academy NACCAS Statistics
          </a>
        </li>
        <li>
          Coba Academy Campus Security Act Disclosure Statement --
          <a href="https://finaid360.com/api/redirect/12/" target="_blank" rel="noreferrer">
            Cleary Act Disclosures
          </a>
        </li>
        <li>
          Coba Academy Drug and Alcohol Abuse Policy Statement --
          <a href="https://finaid360.com/api/redirect/13/" target="_blank" rel="noreferrer">
            Policy Statement
          </a>
        </li>
        <li>
          Coba Academy Requirements for Withdrawal and Return to Title IV --
          <a href="https://finaid360.com/api/redirect/14/" target="_blank" rel="noreferrer">
            School Catalog
          </a>
        </li>
        <li>
          Coba Academy Satisfactory Academic Progress (SAP) Policy --
          <a href="https://finaid360.com/api/redirect/15/" target="_blank" rel="noreferrer">
            School Catalog
          </a>
        </li>
        <li>
          Federal Loans: student loans (Direct loans) parent loans (PLUS loans) --
          <a href="https://finaid360.com/api/redirect/16/" target="_blank" rel="noreferrer">FSA homepage</a>
        </li>
        <li>
          Course Outline --
          <a href="https://finaid360.com/api/redirect/17/" target="_blank" rel="noreferrer">
            School Catalog
          </a>
        </li>
        <li>
          Licensing Requirements --
          <a href="https://finaid360.com/api/redirect/18/" target="_blank" rel="noreferrer">
          www.barbercosmo.ca.gov
          </a>
        </li>
        <li>
          Preprequisites for Employment for practicing professional esthetician, cosmetologist, and barber for the state of California --
          <a href="https://finaid360.com/api/redirect/19/" target="_blank" rel="noreferrer">
            www.bls.gov
          </a>
        </li>
        <li>Tour of the Coba Academy Facility</li>
      </ul>

      {getAcknowledge('received', 'I have received the Consumer Information Disclosures.')}

      <h3>Additional Charges</h3>
      <p>
        Any student who does not complete a course within the allotted contractual enrollment period will be charged at $20.00 per hour for
        any additional time required for completion.
      </p>
      <p>
        Exceptions will be made for approved Leaves of Absence or Changes of Status. Leaves of absence or changes in status are approved
        upon written request of the school for extended illnesses of the student; illness, birth, death in the student's family or for other
        reasons deemed legitimate by the school and which can be substantiated in writing.
      </p>
      <p>
        Additional Charges Example: Student is scheduled to complete 400 hours by a certain date. Student only completes 250 hours by that
        certain date. Student is 150 hours over contract multiplied by hourly rate of $20 equals $2,250.00 additional charge.
      </p>
      <p>
        AdditionalCharges are due and payable immediately. At its discretion, the Operations Manager and/or CFO may allow for Additional
        Charges to be paid with a payment schedule to be determined by identified school official.
      </p>
      <p>
        <b>
          Coba Academy allows a grace period of missed hours depending on what program you are enrolled in as part of the expected end date.
          The Grace Periods are as follows:
        </b>
      </p>

      <table className="table text-left col-md-7 col-sm-12">
        <tbody>
          <tr>
            <td colSpan={2}>
              <b>{getProgramsHours()}</b>
            </td>
          </tr>
          <tr>
            <td>{getMainHoursTitle()}</td>
            <td>{getInitials('main')}</td>
          </tr>

          {/* <tr>
            <td colSpan={2}>
              <b>Transfer Hour Students</b>
            </td>
          </tr>
          <tr>
            <td>{getTransferHourstTitle()}</td>
            <td>{getInitials('copy1', true)}</td>
          </tr> */}
        </tbody>
      </table>

      {getAcknowledge(
        'informed',
        'I have been informed and understand that I am charged by scheduled hours of attendance, NOT by actual hours attended. Coba Academy does not recognize excused or unexcused absences.'
      )}

      <h3>FAMILY EDUCATIONAL RIGHTS AND PRIVACY ACT POLICY (FERPA)</h3>

      <p>A student must sign a new form each time giving authorization to any request made by a third party.</p>
      <p>
        I understand that I have the right to gain access to my records according to the school's Access to Files Policy. I also understand
        that I have a right to authorize certain individuals/organizations to gain access to certain information in my student files.
      </p>
      <p>
        If I am a dependent student, I am not authorized to review the financial records provided by my parents. If I am a dependent student
        and 18 years of age or older, my parent(s) do not automatically have authorization to review or discuss with school officials any
        and all information related to my schooling.
      </p>
      <p>
        All information in the student files would be available to any governing state agency, accrediting agency and federal agency during
        the process of reviewing the school's normal approvals, accreditations, eligibility, and other matters conducted by those agencies
        without the specific consent of the student and/or parents.
      </p>
      <p>
        The Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. § 1232g; 34 CFR Part 99) is a federal law that protects the privacy
        of student education records. The law applies to all schools that receive funds under an applicable program of the U.S. Department
        of Education. FERPA gives parents certain rights with respect to their children's education records. These rights transfer to the
        student when he or she reaches the age of 18 or attends a school beyond the high school level. Students to whom the rights have
        transferred are "eligible students”. Parents or eligible students have the right to inspect and review the student's education
        record maintained by the school. Schools are not required to provide copies of records unless, for reasons such as great distance,
        it is impossible for parents or eligible students to review the records. Schools may charge a fee for copies.
      </p>

      <p>
        Parents or eligible students have the right to request that a school correct record which they believe to be inaccurate or
        misleading. If the school decides not to amend the record, the parent or eligible student then has the right to a formal hearing.
        After the hearing, if the school still decides not to amend the record, the parent or eligible student has the right to place a
        statement with the record setting forth his or her view about the contested information. Generally, schools must have written
        permission from the parent or eligible student to release any information from a student's education record. However, FERPA allows
        schools to disclose those records, without consent, to the following parties or under the following conditions (34 CFR § 99.31):
      </p>
      <ol>
        <li>School officials with legitimate educational interest.</li>
        <li>Other schools to which a student is transferring.</li>
        <li>Specified officials for audit or evaluation purposes.</li>
        <li>Appropriate parties about financial aid to a student.</li>
        <li>Organizations conducting certain studies for or on behalf of the school.</li>
        <li>Accrediting organizations.</li>
        <li>To comply with a judicial order or lawfully issued subpoena.</li>
        <li>Appropriate officials in cases of health and safety emergencies.</li>
        <li>State and local authorities, within a juvenile justice system, pursuant to specific State law.</li>
      </ol>

      <p>
        Schools may disclose, without consent, "directory" information such as a student's name, address, telephone number, date and place
        of birth, honors and awards, and dates of attendance. However, schools must tell parents and eligible students about directory
        information and allow parents and eligible students a reasonable amount of time to request that the school not disclose directory
        information about them. Schools must notify parents and eligible students annually of their rights under FERPA.
      </p>
      <p>
        For additional information, you may call 1-800-USA-LEARN (1-800-872-5327) (voice). Individuals who use TDD may call 1-800-437-0833.
        Or you may contact us at the following address: Family Policy Compliance Office, U.S. Department of Education 400 Maryland Avenue,
        SW Washington, D.C. 20202-8520
      </p>

      {getAcknowledge('undestand', 'I acknowledge I have read and understand the protocol under the FERPA Act Policy.')}

      {getSignature()}
    </div>
  );
});

export default DisclosurePage1;
