import http from "./http-common";
import { User } from '../models/User.model';

class UserService {
    async getReviewers(): Promise<User[]> {
        try {
            const response = await http.get("/auth/users/reviewers/");
            return response.data;
        } catch (error) {
            http.fa360.displayError("Error loading the list of reviewers");
            return [];
        }
    }

}

export default new UserService();