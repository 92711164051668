import "../facts.scss";
import { Facts } from '../types'
import { Table } from '../table'

export const Mono = ({ title, subtitle, description, table, data, cssClass }: Facts) => {

    return (
        <div className={`monoTable mb-4 ${cssClass}`}>
            <h2 className="factsTitle">{title}</h2>
            {subtitle && <p>{subtitle}</p>}
            { description && <p><i>{description}</i></p>}
            {table && <Table {...table} rows={data} />}
        </div>
    )
}