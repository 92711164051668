import { ProgramStartDate } from '../../../models/Program.model';
import dateService from '../../../services/date.service';
import Modal from '../../modal/modal';
import { useEffect, useState } from 'react';
import './startDateModal.scss'

const StartDateModal = (props: any) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [validationError, setValidationError] = useState(false);
    const [validateDateorder, setValidateDateorder] = useState(false);

    const getModalBody = () => {
        return (
            <>
                    <div className="program-form flex-form">
                        <div className="input-row">
                            <div className="input-wrapper input-wrapper__lg d-grid">
                                <label>Start Date:</label>
                                <input
                                    className={`form-control form-control-sm ${validationError && !startDate ? 'error-input' : ''}`}
                                    type="date"
                                    placeholder="Start Date"
                                    
                                    name="name"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-wrapper input-wrapper__lg d-grid">
                                <label>End Date: </label>
                                <input
                                    type="date"
                                    placeholder="End Date"
                                    className={`form-control form-control-sm ${(validationError && !endDate) || validateDateorder ? 'error-input' : ''}`}
                                    name="shortName"
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        {validateDateorder ? (
                            <div className="text-danger py-1">The end date must be equal to or later than the start date.</div>
                            
                        ):('')}
                    </div>
            </>
        );
    };

    const saveStartDate = () => {
        if (validateDates()) {
            props.handleAddStartDate(startDate, endDate);
            clear();
            props.closeModal();
        }
    }

    const clear = () => {
        setStartDate('');
        setEndDate('');
    };

    const saveChanges = () => {
        if (validateDates()) {
            let programStartDate = { ...props.selectedStartDate } as ProgramStartDate;
            programStartDate.startDate = startDate;
            programStartDate.endDate = endDate;
            props.handleEditStartDate(programStartDate);
            clear();
            props.closeModal();
            props.setSelectedStartDate(null);
        }
    }


    const validateDates = () => {
        if (!startDate || !endDate)  {
           setValidationError(true);
            return false;
        }
        if (new Date(endDate) < new Date(startDate)) {
            setValidateDateorder(true);
            return false;
        }
    
        return true;
    }


    useEffect(() => {
        setValidationError(false);
        setValidateDateorder(false);
        setModalBody(props.modalType);
        if (props.modalType) {
        }
        else if (props.selectedStartDate?.id) {
            setStartDate(dateService.formatToYMD(props.selectedStartDate.startDate));
            setEndDate(dateService.formatToYMD(props.selectedStartDate.endDate));
        } else {
            clear();
        }
    },[props.modalType, props.selectedStartDate, setStartDate, setEndDate]);

    useEffect(() =>{
        setValidateDateorder(false);
    },[endDate])

    return (
        <Modal
            visible={props.visible}
            title={modalBody === 'add' ? 'New Start Date' : 'Edit Start Date'}
            body={getModalBody()}
            cancelButtonClass={'btn-dark'}
            cancelButtonText={'Cancel'}
            mainButtonClass={'btn-primary'}
            mainButtonText={modalBody === 'add' ? 'Save Start Date' : 'Save Changes'}
            mainButtonDisabled={startDate == null || endDate == null}
            onAccept={modalBody === 'add' ? () => {
                saveStartDate();

            } : () => {
                saveChanges();

                
            }}
            onCancel={() => {
                clear();
                props.closeModal();
                props.setSelectedStartDate(null)

            }}
            onClose={() => {
                clear();
                props.closeModal();
                props.setSelectedStartDate(null)
            }}
        ></Modal>
    );
};

export default StartDateModal;
