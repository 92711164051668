// parse the error object to react friendly error message
export const errorToHtml = (error, oldKey) => {
  if (typeof error === "object") {
    let isArray = Array.isArray(error);
    let items = Object.entries(error).map(([key, value]) => {
      if (typeof value === "object") {
        return errorToHtml(value, !isArray ? key : null);
      }

      let ky = oldKey ? oldKey : key;
      let msg = value;
      if (Array.isArray(msg)) {
        msg = msg.join(", ");
      }

      return (
        <li key={ky}>
          {ky}: {msg}
        </li>
      );
    });

    return <ul>{items}</ul>;
  }
};
