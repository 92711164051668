import { FunctionComponent, useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';

interface SignatureProps {
  data?: any;
  onChange?: (isEmpty: boolean, signature: string) => void;
}

const Signature: FunctionComponent<SignatureProps> = (props) => {
  let sigCanvas: any = useRef(null);
  const [signatureError, setSignatureError] = useState(false);
  const [signed, setSigned] = useState(false);

  const width = 500;
  const height = 200;

  useEffect(() => {
    if (props.data) {
      getSignature().clear();
      sigCanvas.current.fromDataURL(props.data, { width: width, height: height });
      setSigned(props.data !== '');
    }
  }, [props.data]);

  const getSignature = () => {
    return sigCanvas.current;
  };

  const handleSignatureClear = () => {
    getSignature().clear();
    onSignatureChange();
    setSigned(false);
  };

  const onSignatureChange = () => {
    const signatureEmpty = getSignature()?.isEmpty();
    const signature = !signatureEmpty ? getSignature()?.toDataURL() : '';
    props.onChange?.(signatureEmpty, signature);
    setSigned(signature !== '');
  };

  return (
    <div className="two-columns" style={{ position: 'relative' }}>
      <div className="form-group sig-pad">
        <label className="form-check-label">Please add your Signature</label>
        {!signed && <div className="arrow arrow--2">Sign</div>}
        <SignaturePad
          onEnd={onSignatureChange}
          ref={sigCanvas}
          canvasProps={{ width, height, className: 'sigCanvas' }}
        ></SignaturePad>
      </div>
      <div className="clear-container">
        <button type="button" className="btn btn-dark mt-4" onClick={handleSignatureClear}>
          Clear Signature
        </button>
        {signatureError ? <p className="invalid-input-error">* Please sign the application</p> : null}
      </div>
    </div>
  );
};

export default Signature;
