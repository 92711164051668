import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const SpanishAgreementPage6 = forwardRef(({ data, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials, getAcknowledge } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement6)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <ol>
                <li>La institución, un lugar de la institución o un programa educativo ofrecido por la institución fue cerrado o interrumpido, y usted no eligió participar en un plan de enseñanza aprobado por la Oficina o no completó un plan de enseñanza elegido aprobado por la Oficina.</li>

                <li>2. Estuvo matriculado en una institución o en un lugar de la institución en el periodo de 120 días anterior al cierre de la institución o del lugar de la institución, o estuvo matriculado en un programa educativo en el periodo de 120 días anterior a la interrupción del programa.</li>

                <li>Estuvo matriculado en una institución o en un lugar de la institución más de 120 días antes del cierre de la institución o del lugar de la institución, en un programa educativo ofrecido por la institución respecto al cual la Oficina determinó que se había producido un descenso significativo en la calidad o el valor del programa más de 120 días antes del cierre.</li>

                <li>La Mesa ha ordenado a la institución que pague un reembolso, pero no lo ha hecho.</li>

                <li>La institución no ha pagado o reembolsado los ingresos por préstamos en el marco de un programa federal de préstamos a estudiantes, tal y como exige la ley, o no ha pagado o reembolsado los ingresos recibidos por la institución por encima de la matrícula y otros costos.</li>

                <li>Un árbitro o un tribunal le ha concedido una restitución, un reembolso u otra compensación monetaria, basándose en una violación de este capítulo por parte de una institución o un representante de una institución, pero no ha podido cobrar la compensación de la institución.</li>

                <li>Solicitó asesoramiento jurídico que dio lugar a la cancelación de uno o varios de sus préstamos estudiantiles y dispone de una factura por los servicios prestados y de pruebas de la cancelación del préstamo o préstamos estudiantiles. Para tener derecho al reembolso del STRF, la solicitud debe recibirse en un plazo de cuatro (4) años a partir de la fecha de la acción o acontecimiento que hizo que el estudiante tuviera derecho a la recuperación del STRF.</li>
            </ol>
            <p>
                Un estudiante cuyo préstamo sea reactivado por un prestamista o un cobrador tras un periodo de no cobro puede, en cualquier momento, presentar una solicitud escrita de recuperación ante el STRF por la deuda que, de otro modo, habría sido susceptible de recuperación. Si han transcurrido más de cuatro (4) años desde la acción o el acontecimiento que hizo elegible al estudiante, éste deberá haber presentado una solicitud de recuperación por escrito dentro del periodo original de cuatro (4) años, a menos que el periodo haya sido ampliado por otra ley. Sin embargo, no se podrá pagar ninguna reclamación a ningún estudiante que no tenga un número de la seguridad social o un número de identificación fiscal.
            </p>


            <p><b>Entiendo:</b></p>
            <ul className="p-0 list-unstyled">
                <li>
                    <div className="mt-4 ps-4 position-relative">
                        {getAcknowledge('FFA', `Entiendo que San Diego Global Knowledge Univerity está aprobada para recibir ayuda financiera federal para estudiantes.`)}
                    </div>
                </li>

                <li>
                    <div className="mt-4 ps-4 position-relative">
                        {getAcknowledge('degree', `Entiendo que los programas de grado y diploma/certificado de la SDGKU están diseñados para preparar a los estudiantes para un determinado campo profesional, comercial o de carrera y, por lo tanto, sus graduados pueden tener un conocimiento básico de un salario inicial concreto o de la disponibilidad de puestos de trabajo en el mercado laboral.`)}
                    </div>
                </li>

                <li>
                    <div className="mt-4 ps-4 position-relative">
                        {getAcknowledge('agreement', `Entiendo que este acuerdo no es operativo hasta que asista a la primera clase o sesión de instrucción. Este requisito no es aplicable a los programas de enseñanza a distancia por correspondencia o en línea. Entiendo además que el Catálogo General de SDGKU y su contenido, que he recibido y revisado, son parte de este acuerdo de inscripción y que la información presentada en el mismo es vinculante para la escuela y para mí.`)}
                    </div>
                </li>

            </ul>
            <div className="position-relative">
                {getInitials('page6')}
            </div>
        </div>
    )
})