import { SignedFormStatus } from "./Forms.enum";

export const getFullAddress = (form: SignedForm) => {
    let country = form.student.addressCountry;
    let stateAbbreviation = "";

    if (country === "United States") {
        country = "USA";
        stateAbbreviation = form.student.addressState || "None";
    } else {
        stateAbbreviation = form.student.addressCity?.slice(0, 3).toUpperCase() || "None";
    }
    
    return `${form.student.addressStreet}, ${form.student.addressCity}, ${stateAbbreviation} ${form.student.addressZip}, ${country}`;
};
export interface SignedFormStudent {
    id: number;
    firstName: string;
    middleName?: string;
    lastName: string;
    ssn?: string;
    gender?: string;

    birthDate?: string;
    email: string;
    phone: string;
    workPhone?: string;

    addressStreet?: string;
    addressCity?: string;
    addressState?: string;
    addressStateFull?: string;
    addressZip?: string;
    addressCountry?: string;
}

export interface SignedFormSchool {
    id: number;
    name: string;
    shortName: string;

}

export interface SignedFormData {
    id: number;
    pageKey: string;
    key: string;
    value: string;
    image: string;
}

// Fact: SingedFomr inerface is a response of
//       TotalSignedFormSerializer class in backend
export interface SignedForm {
    id: number;
    formKey: string;
    programId: number;
    freeGroupId: number;
    programName: string;
    status: SignedFormStatus;

    student: SignedFormStudent;
    school: SignedFormSchool;

    programStart?: string;
    programEnd?: string;

    createdAt: Date;
    updatedAt: Date;

    formData: SignedFormData[];
}


export interface IpadSignedForm {
    option1: boolean;
    option2: boolean;
    
}

export interface EnrollmentStatus {
    id: number;
    SDGKUprogram: number;
    studentName: string;
    disclosure: number;
    enrollment: number;
    factSheet: number;
    trascriptsCommitment: number;
    benefitCoding: number;
    vib: number; // 2 = not applicable
    hybridDisclosure: number;
    transcriptEvaluationRequest: number
}