import "./home.scss";
import { useContext, useEffect, useState } from "react";
import { School } from "../../models/School.model";
import { SelectOption } from "../../models/common/Base.model";

import globalContext from "../../context/globalContext";
import TitleBar from "../../components/shared/titleBar/titleBar";
import Dashboard from "../../components/dashboard/dashboard";
import AsideDashboard from "../../components/dashboard/asideDashboard";
import dashboardService from "../../services/dashboard.service";
import dateService from "../../services/date.service";
import DateRangePicker from "../../components/shared/dateRangePicker/dateRangePicker";
import Select from "react-select";

import PaginatedList from "../../components/shared/paginated-list/paginatedList";
import Activities from "../../components/dashboard/activitiesComponent";
import applicationRequestService from "../../services/applicationRequest.service";

const HomePage = (props: any) => {
  const isAdmin = useContext(globalContext).user.isAdmin;
  const isReviewer = useContext(globalContext).user.isReviewer;
  const { setLoading, loaders, saveFilter } = useContext(globalContext);
  const [selectedFromDate, setSelectedFromDate] = useState() as any;
  const [selectedToDate, setSelectedToDate] = useState() as any;
  const [applicationData, setApplicationData] = useState({} as any);
  const [studentData, setStudentData] = useState({} as any);
  const [adminData, setAdminData] = useState() as any;
  const [requestData, setRequestData] = useState() as any;
  const [schools, setSchools] = useState([] as School[]);
  const [selectedSchool, setSelectedSchool] = useState({} as SelectOption);
  const [mounted, setMounted] = useState(false);

  const defaultSchoolOption = { value: 0, label: "- Any School -" };

  const user = useContext(globalContext).user;

  const loadData = async (
    fromDate: string,
    toDate: string,
    school_id: number,
    status: any
  ) => {
    if (!fromDate || !toDate) return;
    setLoading(true);

    let schoolsRes = await loaders.schools();
    setSchools(schoolsRes);

    let applicationsReq = dashboardService.getApplicationData(
      fromDate,
      toDate,
      school_id
    );
    let studentsRes = dashboardService.getStudentData(
      fromDate,
      toDate,
      school_id
    );
    let responseRes = dashboardService.getAdminData(fromDate, toDate);
    let appReqPromise =
      applicationRequestService.getFilteredApplicationRequests(
        fromDate,
        toDate,
        status
      );
    let [applications, students, response, request] = await Promise.all([
      applicationsReq,
      studentsRes,
      responseRes,
      appReqPromise,
    ]);
    setAdminData(response?.data);
    setApplicationData(applications?.data);
    setStudentData(students?.data);
    setRequestData(request);

    setLoading(false);
    setMounted(true);
  };

  const getSchoolOptions = () => {
    let options = schools?.map((school) => ({
      value: school.id,
      label: school.shortName,
    }));
    return [defaultSchoolOption, ...options] as SelectOption[];
  };

  const setInitialDates = () => {
    let today = new Date();
    let { fromDate, toDate } = dateService.getFirstLastDayOfMonthStr(today);

    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);

    loadData(fromDate, toDate, 0, 0);
  };

  const handleMonthChange = (fromDate: string, toDate: string) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);

    loadData(fromDate, toDate, selectedSchool.value as number, 0);
  };

  const selectedSchoolChanged = (option: any) => {
    saveFilter("school", option);
    setSelectedSchool(option);
    loadData(selectedFromDate, selectedToDate, option.value, 0);
  };

  useEffect(() => {
    setSelectedSchool(defaultSchoolOption);
    setInitialDates();
  }, []);

  return (
    <div className=" home-page pd-0">
      <TitleBar header="Home" subheader="Dashboard and Events">
        <div className="title row row-filters pd-l-50">
          <DateRangePicker
            fromValue={selectedFromDate}
            toValue={selectedToDate}
            sendRange={(from: string, to: string) =>
              handleMonthChange(from, to)
            }
          ></DateRangePicker>

          <div className="school col-md-4 col-lg-3 col-xl-2 ">
            School
            <Select
              placeholder="Filter by school"
              options={getSchoolOptions()}
              value={selectedSchool}
              onChange={selectedSchoolChanged}
            />
          </div>
        </div>
      </TitleBar>

      <div className="content-body">
        <div className="top-container">
          <Dashboard
            applicationData={applicationData}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
            studentData={studentData}
            adminData={adminData}
            requestData={requestData}
            mounted={mounted}
            isAdmin={isAdmin}
          />

          <AsideDashboard></AsideDashboard>
        </div>
        {schools.length ? <Activities schools={schools} /> : null}
      </div>
    </div>
  );
};

export default HomePage;
