import React, { FunctionComponent } from "react";
import "./titleBar.scss";

export interface TitleBarProps {
  header: string;
  subheader?: string;
}

const TitleBar: FunctionComponent<TitleBarProps> = ({ header = "", subheader = "", children }) => {
  const getClasses = () => {
    let baseClasses = "title-bar bd-b bg-gray-200";

    if (!subheader) baseClasses += " no-subheader";

    return baseClasses;
  };

  return (
    <div className={getClasses()}>
      <div className="d-sm-flex align-items-center justify-content-between">
        <div className="headers">
          <h4 className="mg-b-0">{header}</h4>
          <p className="mg-b-0 tx-color-02">{subheader}</p>
        </div>
        <div className="title-bar-content mg-t-20 mg-sm-t-0">{children}</div>
      </div>
    </div>
  );
};

export default TitleBar;
