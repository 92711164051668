import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { Department } from "../../../models/Event.model";
import globalContext from "../../../context/globalContext";
import calendarService from "../../../services/calendar.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { toast } from "react-toastify";
import Modal from "../../modal/modal";
import DepartmentModal from "../departmentModal/departmentModal";
import { MTableToolbar } from "material-table";
import DataTable from "../department/Table";
import "../manageDepartment/manageDepartmentModal.scss";
import { School } from '../../../models/School.model';

interface ManageDepartmentModalProps {
  departmentId?: number;
  visible: boolean;
  onClose?: () => void;
  onAccept?: () => void;
}
const ManageDepartmentModal: FunctionComponent<ManageDepartmentModalProps> = ({ visible, onClose, onAccept }) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [userSchools, setUserSchools] = useState<School[]>([]);
  const { setLoading, loaders, showAlert } = useContext(globalContext);
  const [showAddDepartmentModal, setShowAddDepartmentModal] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState<Department | null>(null);

  const loadData = async () => {
    setLoading(true);

    const schoolsResponse = await loaders.schools();
    setUserSchools(schoolsResponse);

    let response = await calendarService.getDepartments();
    setLoading(false);

    if (response?.status === HttpStatusCode.Ok) {
      const data = response.data || [];
      // set school name
      data.forEach((department: Department | any) => {
        const school = schoolsResponse.find((school: School) => school.id === department.school);
        if (school) {
          department.schoolData = school;
        }
      });
      data.sort((a: Department, b: Department) => (a.name < b.name ? -1 : 1));
      setDepartments(data);
    } else {
      showAlert("Error loading department");
    }
  };  

  useEffect(() => {
    if (!showAddDepartmentModal) {
      loadData();    
      setSelectedDepartment(null);
    }
  }, [showAddDepartmentModal]);

  const handleDeleteDepartment = (department: Department) => {
    setDepartmentToDelete(department);
    setConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (departmentToDelete && departmentToDelete.id !== undefined) {
      const response = await calendarService.removeDepartment(departmentToDelete.id);
      if (
        (response && response.status === HttpStatusCode.Ok) ||
        (response && response.status === HttpStatusCode.NoContent)
      ) {
        toast.dark(`✔️ Department Deleted`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setConfirmDelete(false);
        setDepartmentToDelete(null);
        loadData();
      } else {
        toast.dark("❌ Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  const handleEditDepartment = (department: Department) => {
    setSelectedDepartment(department);
    setShowAddDepartmentModal(true);
  };

  const handleShowAddDepartment = () => {
    setSelectedDepartment(null);
    setShowAddDepartmentModal(true);    
    setSelectedDepartment(null);
  };

  const handleCloseAddDepartmentModal = () => {
    setShowAddDepartmentModal(false);    
    setSelectedDepartment(null);
  };

  const handleModalClose = () => {
    setConfirmDelete(false);
    setDepartmentToDelete(null);
    setShowAddDepartmentModal(false);
    onClose && onClose();
  };

  const getTableCols = () => {
    const cols = [
      {
        title: "Color",
        field: "color",
        maxWidth: 70,
        render: (rowData: Department) => (
          <div className="circle-color" style={{ backgroundColor: rowData.color }} />
        ),
      },
      {
        title: "Department Name",
        field: "name",
      },
      {
        title: "Creator",
        field: "creator.fullName",
      },
    ];

    if(hasMultipleSchools()) {
      cols.splice(2,0, {title:"School", field:"schoolData.shortName"})
    }

    return cols;
  }

  const hasMultipleSchools = () => userSchools.length > 1;

  return (
    <div className="flex items-center justify-self-center gap-4 pt-6 w-full">
      <Modal
        visible={confirmDelete}
        cancelButtonText="Cancel"
        mainButtonText="Delete Department"
        mainButtonClass="btn-danger"
        title="Are you sure you want to delete this department?"
        body={`You are about to delete the department: ${departmentToDelete?.name}. This action cannot be undone.`}
        onCancel={() => {
          setConfirmDelete(false);
          setDepartmentToDelete(null);
        }}
        onClose={() => {
          setConfirmDelete(false);
          setDepartmentToDelete(null);
        }}
        onAccept={handleConfirmDelete}
      />
      {showAddDepartmentModal ? (
        <DepartmentModal
          visible={showAddDepartmentModal}
          onAccept={handleCloseAddDepartmentModal}
          onClose={handleCloseAddDepartmentModal}
          departmentId={selectedDepartment?.id}
        />
      ) : null}

      <Modal
        visible={!showAddDepartmentModal && !confirmDelete && visible}
        title="Manage Departments"
        onClose={handleModalClose}
        size="lg"
      >
        <div className="py-4">
          <DataTable
            columns={getTableCols()}
            data={departments}
            title=""
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Data",
                onClick: (event: any, rowData: any) => handleEditDepartment(rowData),
              },
              {
                icon: "delete",
                tooltip: "Delete Data",
                onClick: (event: any, rowData: any) => handleDeleteDepartment(rowData),
              },
            ]}
            options={{
              actionsColumnIndex: 4,
              padding: "dense",
              search: false,
            }}
            components={{
              Toolbar: ({ ...props }) => (
                <div>
                  <MTableToolbar {...props} />
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0px 10px",
                    }}
                  >
                    <button className="btn btn-sm btn-outline-primary" onClick={handleShowAddDepartment}>
                      New Department
                    </button>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ManageDepartmentModal;
