import { School } from "../../../models/School.model";
import Modal from "../../modal/modal";

import "./schoolPicker.scss";

const SchoolPicker = (props: any) => {
  const select = (option: any) => {
    props.onChange(option);
  };

  const body = () => {
    return (
      <div className="school-choices">
        {props.schoolChoices.map((school: School, index: any) => (
          <div
            className="btn btn-sm btn-primary custom-btn"
            key={index}
            onClick={() => {
              select(school);
            }}
          >
            {school.shortName}
          </div>
        ))}
      </div>
    );
  };

  return <Modal body={body()} title={"Please select an school"} onClose={props.setShowPicker} visible={props.showPicker} />;
};

export default SchoolPicker;
