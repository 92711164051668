import commonService from "../../../services/common.service";
import globalContext from "../../../context/globalContext";
import Modal from "../../../components/modal/modal";
import SignaturePad from "react-signature-canvas";
import applicationService from "../../../services/applicationRequest.service";
import studentService from "../../../services/student.service";
import TextField from "../../../components/shared/Input/textField";
import SelectField from "../../../components/shared/Input/selectField";
import { ApplicationForm } from "../../../models/ApplicationForm.model";
import {
  ApplicationStatus,
  PersonalReferenceType,
  SchoolID,
  StudentStatus,
} from "../../../models/Enums";
import { CheckboxList } from "../../../components/shared/Input/checkBox";
import Checkbox from "../../../components/shared/Input/checkBox";
import { State } from "../../../models/common/State.model";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./pureAesthetics.scss";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { AlertType } from "../../../models/AlertType.enum";
import { UserMessages } from "../../../components/shared/constants/constants";
import { MASKS, PATTERNS } from "../../../components/shared/Input/patterns";

import { Program } from "../../../models/Program.model";
import programsService from "../../../services/programs.service";
import {
  raceOptions,
  preferredPronounOptions,
  maritalStatusOptions,
} from "../commonOptions";
import {
  simpleHearAboutUsOptions,
  educationLevelOptions,
  gender,
  convictedOptions,
  paymentOptions,
} from "./paOptions";
import { useHistory } from "react-router-dom";
import PhoneField from "../../../components/shared/Input/phoneField/phoneField";
import { GeneralQuestionaire } from "./generalQuestionaire";
import { MassageQuestionaire } from "./massageQuestionaire";

const PureAesthetics = () => {
  let sigCanvas: any = useRef(null);
  const { showAlert, clearAlert, setLoading } = useContext(globalContext);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    control,
    watch,
  } = useForm<ApplicationForm>();

  const aboutUsReference = useRef(null);
  const [states, setStates] = useState<State[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [signatureError, setSignatureError] = useState(false);
  const [aboutUsError, setAboutUsError] = useState(false);
  const [form, setForm] = useState(new ApplicationForm());
  const [otherPronounVisible, setOtherPronounVisible] = useState(false);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [startDates, setStartDates] = useState([] as any);

  const programWatch = watch("program");
  const GEDEducationLevelWatch = watch("student.educationLevel");
  const otherHearAboutUsWatch = watch("hearAboutUs");
  const cpxAboutSocialMedia = watch("complexHearAboutUs.socialMedia");
  const cpxAboutReferral = watch("complexHearAboutUs.referral");
  const cpxAboutOther = watch("complexHearAboutUs.other");
  const checkDeclineMental = watch("student.disabilities_decline");
  const [duplicatedEmail, setDuplicatedEmail] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const isMassage = () => programWatch?.includes("Massage");
  const isGED = () => GEDEducationLevelWatch === "GED";
  const isOtherHear = () => otherHearAboutUsWatch === "Other";

  //props to questionaire page
  const [program, setProgram] = useState() as any;
  const [studentId, setStudentId] = useState() as any;
  const [token, setToken] = useState() as any;
  const [showQuestionaire, setShowQuestionaire] = useState(false as boolean);
  const [showApplication, setShowApplication] = useState(true as boolean);

  let history = useHistory();

  const handleSignatureClear = () => {
    getSignature().clear();
  };

  const handlePronounSelectionChanged = (opt: any) => {
    setOtherPronounVisible(opt.target.value === "Other");
  };

  const handleInputChange = (event: any) => {
    const copy = { ...form } as any;
    let name = event.target.name;
    if (name.includes("student.")) {
      name = name.replace("student.", "");
      copy.student[name] = event.target.value;
    } else if (name.includes("academicInfo.")) {
      name = name.replace("academicInfo.", "");
      copy.academicInfo[name] = event.target.value;
    } else {
      copy[event.target.name] = event.target.value;
    }

    setForm(copy);
  };

  const loadInitialData = async () => {
    let states = await commonService.getStates();
    let programsReq = programsService.getProgramsBySchool(SchoolID.PA);
    let startDateReq = programsService.getStartDatesBySchool(SchoolID.PA);

    setLoading(true, "Loading your data...");
    let [programsRes, startDateRes] = await Promise.all([
      programsReq,
      startDateReq,
    ]);
    setLoading(false);

    setPrograms(programsRes?.data ?? []);
    setStartDates(startDateRes ?? []);
    setStates(states);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleModalAccept = async () => {
    setModalVisible(false);
  };

  const getSignature = () => {
    return sigCanvas.current;
  };

  const durationName = (p: Program) => {
    if (p.unit === "months") {
      if (p.length === 50) {
        return "4 year 2 months";
      } else if (p.length === 18) {
        return "1 year 6 months";
      }
    }

    return `${p["length"]} ${p["unit"]}`;
  };

  const handleProgramDuration = (option: any) => {
    if (option?.value) {
      setForm((prevState) => ({ ...prevState, courseLength: option.duration }));
    } else {
      setForm((prevState) => ({ ...prevState, courseLength: "" }));
    }
  };

  const getProgramsToDisplay = () => {
    return programs.map((p) => ({
      id: p.id,
      value: p.name,
      label: p.name,
      duration: durationName(p),
    }));
  };

  const handleEmailChanged = async (e: any) => {
    let response = await studentService.validateEmail(e.target.value);
    if (response?.status !== HttpStatusCode.Ok) {
      setDuplicatedEmail(true);
      showAlert(UserMessages.errors.student_duplicated_email, AlertType.Error);
    } else {
      setDuplicatedEmail(false);
      clearAlert();
    }
  };

  const handleSave = async (data: any) => {
    clearAlert();

    let sign = getSignature();
    if (sign.isEmpty()) {
      setSignatureError(true);
      return;
    }

    setIsButtonClicked(true);

    setSignatureError(false);
    let payload = { ...data } as ApplicationForm;
    payload.student.startDate = undefined;
    payload.student.school = SchoolID.PA;
    payload.student.status = StudentStatus.APPLICATION_REQUEST;
    payload.status = ApplicationStatus.SUBMITTED;
    payload.signature = sign.toDataURL();

    if (isMassage()) {
      const abt = payload.complexHearAboutUs;
      var error =
        !abt?.website &&
        !abt?.onlineSearch &&
        !abt?.droveBy &&
        !abt?.wordOfMouth &&
        !abt?.socialMedia &&
        !abt?.referral &&
        !abt?.other;
      setAboutUsError(error);
      if (error) {
        aboutUsReference && (aboutUsReference as any).current.scrollIntoView();
      }
    }

    if (payload.student.preferredPronoun === "Other") {
      payload.student.preferredPronoun = (
        payload.student as any
      )?.preferredPronounOther;
    }

    if (payload.student.disabilities_decline) {
      payload.student.disabilities = "Decline to answer";
    }

    // delete non persisted fields as they are not expected on BE
    delete (payload.student as any)?.preferredPronounOther;
    delete (payload.student as any)?.disabilities_decline;

    if (payload.references) {
      payload.references[0].type = PersonalReferenceType.NORMAL;
      payload.references[1].type = PersonalReferenceType.NORMAL;
      payload.references[2].type = PersonalReferenceType.EMERGENCY_CONTACT;
    }

    setLoading(true, "Saving data, please wait");
    let response = await applicationService.saveApplicationRequest(payload);
    setLoading(false);

    if (response) {
      setModalVisible(true);
      sign.clear();
      setIsButtonClicked(false);
      //Add props to usestate
      setProgram(response.program);
      setStudentId(response.student.id);
      setToken(response.token);
    }
  };

  useEffect(() => {
    if (program && studentId && token) {
      setShowQuestionaire(true);
      setShowApplication(false);
    }
  }, [program, studentId, token]);

  useEffect(() => {
    setForm({ ...form, academicInfo: {} as any });
    loadInitialData();
  }, []);

  const checkKeyDown = (e: any) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const privacyCheckList = [
    {
      field: "privacyCertifyInfo",
      label:
        "I have completed this application to the best of my knowledge and believe that all of the above information is true and correct. I understand that if any of the information is found to be untrue, it is grounds for termination by the school. ",
    },
    {
      field: "privacyAgreeContact",
      label:
        "By proceeding, I hereby consent to be contacted by Pure Aesthetics Natural Skincare School and its affiliates via email and text messages. This consent includes, but is not limited to, communication for program updates, financial aid, and other relevant content. Please be aware that standard messaging rates may apply for text messages, depending on your mobile carrier. You can opt out of receiving communication at any time by contacting us at (520)-499-2927.",
    },
  ];

  const simpleAboutUs = () => (
    <div className="form-group">
      <div className="two-columns">
        <SelectField
          field="hearAboutUs"
          label="How did you hear about us?"
          errors={errors}
          control={control}
          rules={{ required: true }}
          options={simpleHearAboutUsOptions}
        />
        <TextField
          type="text"
          label="Enter the other option"
          field="hearAboutUsOther"
          maxLength="300"
          parentClass="form-group"
          errors={errors}
          rules={{ required: true }}
          visible={isOtherHear()}
          onChange={handleInputChange}
          register={register}
        />
      </div>
    </div>
  );

  const complexAboutUs = () => (
    <>
      <label className="d-block">How did you hear about us?</label>
      {aboutUsError ? (
        <p className="invalid-input-error">* Select at least one option</p>
      ) : null}
      <div className="hear-about-us-section">
        <div className="col-simple-checks">
          <Checkbox
            field="complexHearAboutUs.website"
            label="PA Website"
            register={register}
            errors={errors}
          />

          <Checkbox
            field="complexHearAboutUs.onlineSearch"
            label="Online School Search"
            register={register}
            errors={errors}
          />

          <Checkbox
            field="complexHearAboutUs.droveBy"
            label="Drove by"
            register={register}
            errors={errors}
          />

          <Checkbox
            field="complexHearAboutUs.wordOfMouth"
            label="Word of Mouth"
            register={register}
            errors={errors}
          />

          <Checkbox
            field="complexHearAboutUs.movieTheater"
            label="Movie Theater"
            register={register}
            errors={errors}
          />
        </div>

        <div className="col-detailed-checks">
          <div className="detailed-check">
            <Checkbox
              field="complexHearAboutUs.socialMedia"
              label="Social Media"
              parentClass="form-group pd-t-9 mg-r-10"
              register={register}
              errors={errors}
            />

            <TextField
              type="text"
              label="Social media name"
              field="complexHearAboutUs.socialMediaName"
              maxLength="100"
              labelVisible={false}
              visible={cpxAboutSocialMedia}
              placeholder="social media name"
              onChange={handleInputChange}
              register={register}
              errors={errors}
              rules={{ required: cpxAboutSocialMedia }}
            />
          </div>

          <div className="detailed-check">
            <Checkbox
              field="complexHearAboutUs.referral"
              label="Referral"
              parentClass="form-group pd-t-9 mg-r-10"
              register={register}
              errors={errors}
            />

            <TextField
              type="text"
              label="Referral name"
              field="complexHearAboutUs.referralName"
              maxLength="150"
              labelVisible={false}
              visible={cpxAboutReferral}
              placeholder="referral name"
              onChange={handleInputChange}
              register={register}
              errors={errors}
              rules={{ required: cpxAboutReferral }}
            />
          </div>

          <div className="detailed-check">
            <Checkbox
              field="complexHearAboutUs.other"
              label="Other"
              parentClass="form-group pd-t-9 mg-r-10"
              register={register}
              errors={errors}
            />

            <TextField
              type="text"
              label="Other Name"
              field="complexHearAboutUs.otherName"
              maxLength="150"
              labelVisible={false}
              visible={cpxAboutOther}
              placeholder="specify"
              onChange={handleInputChange}
              register={register}
              errors={errors}
              rules={{ required: cpxAboutOther }}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {showApplication && (
        <form
          method="POST"
          onKeyDown={(e) => checkKeyDown(e)}
          className="preapp-form container pa-form"
        >
          <Modal
            visible={modalVisible}
            cancelButtonText="Ok"
            cancelButtonClass="btn-primary"
            mainButtonText=""
            title="Application submitted"
            body="Thank you for your interest, an administrator will reach out to you shortly"
            onCancel={handleModalClose}
            onClose={handleModalClose}
            onAccept={handleModalAccept}
          ></Modal>

          <h2>
            <img src="/static/pureAesthetics/logo2.png" alt="PA logo"></img>
            <br></br>
            <span className=" page-title">Application for Admission</span>
          </h2>

          <h3>Program Selection</h3>
          <div className="two-columns">
            <SelectField
              parentClass="form-group span-1-3"
              field="program"
              label="Intended Program of Study"
              errors={errors}
              control={control}
              rules={{ required: true }}
              onChange={handleProgramDuration}
              options={getProgramsToDisplay()}
            />
          </div>

          <h3>Personal Information</h3>

          <div className="two-columns">
            <TextField
              parentClass="form-group"
              field="student.lastName"
              maxLength="70"
              type="text"
              label="Last Name"
              placeholder="Enter your Last Name"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              parentClass="form-group"
              field="student.firstName"
              maxLength="70"
              type="text"
              label="First Name"
              placeholder="Enter your First Name"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>
          <div className="three-columns">
            <TextField
              parentClass="form-group"
              field="student.middleName"
              maxLength="70"
              type="text"
              label="Middle Name"
              placeholder="Enter your Middle Name"
              register={register}
            />

            <SelectField
              field="student.gender"
              label="Gender"
              errors={errors}
              control={control}
              rules={{ required: true }}
              options={gender}
            />

            <div className="two-columns">
              <div className="form-group select-form-group">
                <label className="d-block">Preferred Pronouns:</label>
                <select
                  className="form-select"
                  {...register("student.preferredPronoun")}
                  onChange={handlePronounSelectionChanged}
                >
                  {preferredPronounOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <TextField
                visible={otherPronounVisible}
                parentClass="form-group"
                field="student.preferredPronounOther"
                maxLength="50"
                type="text"
                label="Other"
                placeholder="Enter your preferred name"
                register={register}
              />
            </div>
          </div>

          <TextField
            parentClass="form-group"
            field="student.certificateName"
            maxLength="200"
            type="text"
            label="Name on Certificate (if different from specified name)"
            placeholder="Enter your Name on Certificate"
            register={register}
            errors={errors}
            visible={!isMassage()}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.other_name"
            maxLength="100"
            type="text"
            label="Do you have a nickname"
            placeholder="Enter your nickname"
            visible={isMassage()}
            register={register}
            errors={errors}
          />

          <div className="two-columns">
            <TextField
              parentClass="form-group"
              field="student.ssn"
              type="text"
              label="SSN"
              mask={MASKS.ssnMask}
              register={register}
              errors={errors}
              rules={{
                required: true,
                pattern: PATTERNS.ssnRegEx,
              }}
            />
            <SelectField
              field="student.maritalStatus"
              label="Marital Status"
              errors={errors}
              control={control}
              visible={!isMassage()}
              options={maritalStatusOptions}
            />

            <TextField
              parentClass="form-group"
              field="student.email"
              maxLength="70"
              type="text"
              label="Email"
              placeholder="Enter your email"
              register={register}
              onBlur={handleEmailChanged}
              errors={errors}
              rules={{
                required: true,
                pattern: PATTERNS.emailRegEx,
              }}
            />

            <TextField
              parentClass="form-group"
              field="student.birthDate"
              type="date"
              label="Date of Birth"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>

          <div className="two-columns">
            <PhoneField
              parentClass="form-group"
              field="student.phone"
              type="text"
              label="Cell Phone"
              placeholder="Enter your cell phone"
              register={register}
              errors={errors}
              control={control}
              rules={{ required: true, minLength: 10 }}
            />

            <PhoneField
              parentClass="form-group"
              field="student.workPhone"
              type="text"
              label="Home Phone"
              placeholder="Enter your home phone"
              register={register}
              control={control}
              errors={errors}
            />
          </div>

          <div className="two-columns">
            <TextField
              parentClass="form-group"
              field="student.addressStreet"
              maxLength="100"
              type="text"
              label="Address"
              placeholder="Enter your street"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              parentClass="form-group"
              field="student.addressCity"
              maxLength="100"
              type="text"
              label="City"
              placeholder="Enter your city"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <div className="form-group select-form-group">
              <label className="d-block">State:</label>
              <select
                className="form-select"
                {...register("student.addressState")}
              >
                {states.map((state: State) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <TextField
              parentClass="form-group"
              field="student.addressZip"
              maxLength="10"
              type="text"
              label="Zip Code"
              placeholder="Enter your zip code"
              register={register}
              errors={errors}
              rules={{ required: true, pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/ }}
            />
          </div>

          <h3>
            <span className="required">*</span> Method of Payment{" "}
            <span>(Select all that apply)</span>
          </h3>

          <div className="form-group">
            <label>How do you plan to finance your education?</label>
            <div className="checks-group-payment row">
              <CheckboxList
                items={paymentOptions}
                getValues={getValues}
                register={register}
                errors={errors}
                rules={{ required: true }}
              />
            </div>
          </div>

          <h5 className="section-header">
            In accordance with federal regulation, we are required to ask the
            following questions:
          </h5>

          <div className="three-columns">
            <SelectField
              field="ethnicity"
              label="Are you Hispanic or Latino ?"
              includeColon={false}
              errors={errors}
              control={control}
              rules={{ required: true }}
              options={[
                { value: "Hispanic Latino", label: "Yes" },
                { value: "Not Hispanic", label: "No" },
                { value: "", label: "Decline to answer" },
              ]}
            />

            <div className="form-group">
              <SelectField
                field="race"
                label="What is your race?"
                errors={errors}
                control={control}
                rules={{ required: true }}
                options={raceOptions}
              />
            </div>
          </div>

          <h3 ref={aboutUsReference}>General Information</h3>

          {!isMassage() ? simpleAboutUs() : complexAboutUs()}

          <div className="two-columns mg-t-10">
            <div className="form-group">
              <SelectField
                field="student.educationLevel"
                label="Educational Background"
                errors={errors}
                control={control}
                rules={{ required: true }}
                options={educationLevelOptions}
              />
            </div>
            <TextField
              field="academicInfo.gedDate"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Date (mm/yyyy)"
              visible={isGED()}
              register={register}
            />
          </div>

          {!isGED() ? (
            <div>
              <div className="four-columns group-box  group-box__first">
                <TextField
                  field="academicInfo.highName"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Name of High School"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
                />

                <TextField
                  field="academicInfo.highAddress"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Address"
                  register={register}
                />

                <TextField
                  field="academicInfo.highYear"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Year of Graduation"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
                />
              </div>

              <div className="four-columns group-box">
                <TextField
                  field="academicInfo.collegeName"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Name of College"
                  register={register}
                />

                <TextField
                  field="academicInfo.collegeAddress"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Address"
                  register={register}
                />

                <TextField
                  field="academicInfo.collegeDate"
                  maxLength="50"
                  parentClass="form-group"
                  type="text"
                  label="Dates Attended"
                  register={register}
                />

                <TextField
                  field="academicInfo.collegeDegree"
                  maxLength="50"
                  parentClass="form-group"
                  type="text"
                  label="Degree(s) Earned"
                  register={register}
                />
              </div>

              <div className="four-columns group-box">
                <TextField
                  field="academicInfo.trainingName"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Career Training School"
                  register={register}
                />

                <TextField
                  field="academicInfo.trainingAddress"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Address"
                  register={register}
                />

                <TextField
                  field="academicInfo.trainingDate"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Date of Graduation"
                  register={register}
                />

                <TextField
                  field="academicInfo.trainingDegree"
                  maxLength="150"
                  parentClass="form-group"
                  type="text"
                  label="Certificate Earned"
                  register={register}
                />
              </div>
            </div>
          ) : null}

          <h3>Employment and Personal References</h3>

          <div className="three-columns group-box">
            <TextField
              field="employment.[0].employerName"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Current/Most Recent Employer"
              register={register}
            />

            <TextField
              field="employment.[0].employerSupervisor"
              maxLength="100"
              parentClass="form-group"
              type="text"
              label="Supervisor"
              register={register}
            />

            <TextField
              field="employment.[0].employerDates"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Dates From / To"
              register={register}
            />

            <TextField
              field="employment.[0].employerAddress"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Address"
              register={register}
            />

            <PhoneField
              field="employment.[0].employerPhone"
              parentClass="form-group"
              type="text"
              label="Phone Number"
              register={register}
              control={control}
            />

            <TextField
              field="employment.[0].employerDuties"
              maxLength="200"
              parentClass="form-group"
              type="text"
              label="Duties"
              register={register}
            />
          </div>

          <div className="three-columns">
            <TextField
              field="employment.[1].employerName"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Previous Employer"
              register={register}
            />

            <TextField
              field="employment.[1].employerSupervisor"
              maxLength="100"
              parentClass="form-group"
              type="text"
              label="Supervisor"
              register={register}
            />

            <TextField
              field="employment.[1].employerDates"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Dates From / To"
              register={register}
            />

            <TextField
              field="employment.[1].employerAddress"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Address"
              register={register}
            />

            <PhoneField
              field="employment.[1].employerPhone"
              parentClass="form-group"
              type="text"
              label="Phone Number"
              register={register}
              control={control}
            />

            <TextField
              field="employment.[1].employerDuties"
              maxLength="200"
              parentClass="form-group"
              type="text"
              label="Duties"
              register={register}
            />
          </div>

          <h5 className="section-header">
            Please list two references who are not family-related to you:
          </h5>

          <div className="three-columns">
            <TextField
              field="references.[0].name"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Name"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              field="references.[0].address"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="City / State / Zip"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <PhoneField
              field="references.[0].phone"
              parentClass="form-group"
              type="text"
              label="Phone Number"
              register={register}
              errors={errors}
              rules={{ required: true }}
              control={control}
            />
          </div>

          <div className="three-columns">
            <TextField
              field="references.[1].name"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Name"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              field="references.[1].address"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="City / State / Zip"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <PhoneField
              field="references.[1].phone"
              parentClass="form-group"
              type="text"
              label="Phone Number"
              control={control}
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>

          <h5 className="section-header">
            In case of emergency, please contact:
          </h5>

          <div className="three-columns">
            <TextField
              field="references.[2].name"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Name"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              field="references.[2].relationship"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Relationship"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <PhoneField
              field="references.[2].phone"
              parentClass="form-group"
              type="text"
              label="Phone Number"
              register={register}
              errors={errors}
              rules={{ required: true }}
              control={control}
            />

            <TextField
              field="references.[2].address"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Address"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              field="references.[2].city"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="City"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />

            <TextField
              field="references.[2].state"
              maxLength="100"
              parentClass="form-group"
              type="text"
              label="State / Zip"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>

          <h3>Motivation</h3>

          <TextField
            field="whyChooseUs"
            parentClass="form-group"
            type="text"
            multiLine={true}
            includeColon={false}
            label={
              !isMassage()
                ? "Why do you want to be an aesthetician?"
                : "Why do you want to be a massage therapist?"
            }
            register={register}
            errors={errors}
            rules={{ required: true }}
            maxLength="255"
            detailText="max 255 characters"
          />

          <TextField
            field="whyAttendSchool"
            type="text"
            multiLine={true}
            includeColon={false}
            label={
              !isMassage()
                ? "Why do you want to attend Pure Aesthetics?"
                : "Why do you want to attend our school?"
            }
            register={register}
            errors={errors}
            rules={{ required: true }}
            maxLength="255"
            detailText="max 255 characters"
          />

          <TextField
            field="afterCompletion"
            parentClass="form-group"
            type="text"
            multiLine={true}
            includeColon={false}
            label={
              !isMassage()
                ? "What are your career plans after College is completed?"
                : "What are your career plans after school is completed?"
            }
            register={register}
            errors={errors}
            rules={{ required: true }}
            maxLength="255"
            detailText="max 255 characters"
          />

          <TextField
            field="student.disabilities"
            parentClass="form-group"
            type="text"
            multiLine={true}
            includeColon={false}
            visible={isMassage()}
            disabled={checkDeclineMental}
            labelAfter={
              <Checkbox
                parentClass="decline-field"
                field="student.disabilities_decline"
                label="Decline to Answer"
                register={register}
                errors={errors}
              />
            }
            label="Please disclose any learning, mental, or physical disability such as ADHD, Dyslexia, Dysgraphia, hearing or visual impairment, etc. that may affect your education at Pure Aesthetics, and what accommodations we can provide to support you."
            register={register}
            errors={errors}
            rules={{ required: !checkDeclineMental }}
            maxLength="255"
            detailText="max 255 characters"
          />

          <SelectField
            field="student.convicted"
            label="Have you ever been convicted of a felony or misdemeanor other than traffic violations in the last 5 years?"
            errors={errors}
            control={control}
            visible={isMassage()}
            rules={{ required: true }}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
          />

          <SelectField
            field="student.convicted"
            label="Have you ever been convicted of a felony or misdemeanor other than traffic violations in the last 5 years?"
            errors={errors}
            control={control}
            visible={!isMassage()}
            rules={{ required: true }}
            options={convictedOptions}
          />

          <h6 className="label-certify mg-b-20">
            <div className="form-group">
              <label> </label>
              <div className="checks-group-payment row">
                <CheckboxList
                  items={privacyCheckList}
                  getValues={getValues}
                  register={register}
                  errors={errors}
                  isDirty={isDirty}
                  rules={{ allRequired: true }}
                />
              </div>

              <p className="notes">
                Note: Your privacy is important to us, and we will work hard to
                protect your privacy, For more details, please refer to our
                Terms of Services and Privacy Policy.
              </p>
            </div>
          </h6>

          <div className="two-columns">
            <div className="form-group sig-pad">
              <label className="form-check-label">
                {" "}
                <span className="required">*</span> Please add your Signature
              </label>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
              ></SignaturePad>
            </div>
            <div className="clear-container">
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleSignatureClear}
              >
                Clear Signature
              </button>
              {signatureError ? (
                <p className="invalid-input-error">
                  * Please sign the application
                </p>
              ) : null}
            </div>
          </div>

          <div className="save-container">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit((d) => handleSave(d))}
              disabled={duplicatedEmail || isButtonClicked}
            >
              Continue to last step
            </button>
          </div>
        </form>
      )}

      {/* If show questionaire is true show questionaire */}
      {showQuestionaire &&
        (!isMassage() ? (
          <GeneralQuestionaire
            program={program}
            studentId={studentId}
            token={token}
          />
        ) : (
          <MassageQuestionaire
            program={program}
            studentId={studentId}
            token={token}
          />
        ))}
    </div>
  );
};

export default PureAesthetics;
