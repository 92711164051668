import { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { ChevronLeft } from 'react-feather';
import './initialsComponent.scss';

interface InitialsComponentProps {
  width?: number;
  height?: number;
  copy?: boolean;

  name: string;
  masterInitials?: any;
  data?: any;
  onChange?: (initals: any, name: string, copy: boolean, clear: boolean) => void;
}

const InitialsComponent = ({
  width = 200,
  height = 70,
  copy = false,

  name = '',
  masterInitials,
  data,
  onChange,
}: InitialsComponentProps) => {
  let sigCanvas: any = useRef(null);
  const [signed, setSigned] = useState(false);

  useEffect(() => {
    if (copy) {
      clearInitials(false);
    }
  }, [masterInitials]);

  useEffect(() => {
    if (data) {
      setInitials(data);
      if(copy){
        onChange?.(data, name, copy, false);
      }
    }
  }, [data]);

  const clearInitials = (notifyClear: boolean) => {
    sigCanvas.current.clear();
    setSigned(false);
    onChange?.('', name, copy, notifyClear);
  };

  const setInitials = (source: any) => {
    sigCanvas.current.clear();
    sigCanvas.current.fromDataURL(source, { width: width, height: height });
    setSigned(source !== '');
  };

  const insertInitials = () => {
    setInitials(masterInitials);
    onChange?.(masterInitials, name, copy, false);
  };

  const saveInitials = () => {
    const initials = sigCanvas.current.toDataURL();
    onChange?.(initials, name, copy, false);
    setSigned(initials !== '');
  };

  const getButton = () => {
    if (copy) {
      return (
        <button type="button" className="btn btn-sm btn-primary" onClick={insertInitials}>
          <ChevronLeft /> Insert initials
        </button>
      );
    }
    return (
      <button type="button" className="btn btn-sm btn-dark" onClick={() => clearInitials(true)}>
        Clear initials
      </button>
    );
  };

  return (
    <>
      {!signed && <div className="arrow arrow--2">Initials</div>}
      <div className="sig-box">
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{ width: width, height: height, className: 'sigCanvas ' + (copy ? ' copy_only' : '') }}
          onEnd={saveInitials}
        ></SignaturePad>
        <div className={'btn-container ' + (signed ? 'signed' : 'not_signed')}>
          <label>{copy ? 'Click the button to' : 'Please draw your initials'}</label>
          {getButton()}
        </div>
      </div>
    </>
  );
};

export default InitialsComponent;
