import './eventComponent.scss';
import { FunctionComponent, useContext, useState } from 'react';
import moment from 'moment';
import { Event } from '../../models/Event.model';
import { useEffect } from 'react';
import globalContext from '../../context/globalContext';
import { School } from '../../models/School.model';

interface EventComponentProps {
  event: Event;
  extended?: boolean;
  onClick?: (eventId: any) => void;
}

const EventComponent: FunctionComponent<EventComponentProps> = (props) => {
  const { setLoading, loaders } = useContext(globalContext);
  const [date, setDate] = useState(moment(props.event.startDate));
  const [schools, setSchools] = useState([] as School[]);

  const getContainerStyle = () => {
    return { borderLeftColor: props.event.eventTypeData?.color };
  };

  const loadData = async () => {
    setLoading(true);
    let schoolsRes = await loaders.schools();
    setSchools(schoolsRes);
    setLoading(false);
  };

  useEffect(() => {
    if (props.extended) loadData();
  }, []);

  const getSchoolPills = () => {
    return props.event.schools?.map((schoolId) => {
      let school = schools.find((s) => s.id === schoolId);
      return (
        <div className="badge bg-info" key={schoolId}>
          {school?.shortName}
        </div>
      );
    });
  };

  return (
    <div className="event-component" style={getContainerStyle()} onClick={props.onClick}>
      <div className="basic">
        <div className="header">
          <div className="day">
            <h4>{date.format('h:mm')}</h4>
            <h6>{date.format('A')}</h6>
          </div>
          <div className="date">
            <h5>{date.format('D MMMM')}</h5>
            <p>{props.event.eventTypeData?.name}</p>
          </div>
        </div>

        <div className="info">
          <p>{props.event.name}</p>
        </div>
      </div>

      {props.extended && (
        <div className="extended">
          <div className="header">
            <p>{props.event.description}</p>
          </div>

          <div className="info">{getSchoolPills()}</div>
        </div>
      )}
    </div>
  );
};

export default EventComponent;
