import React, { useMemo, useState } from "react";
import { useTable, useSortBy, useResizeColumns, usePagination } from "react-table";
import { ChevronUp as IconUp, ChevronDown as IconDown } from "react-feather";

import "./table.scss";
import Pagination from '../pagination/pagination';

const Table = ({
  columns,
  data,
  NoRecordsMessage = 'No Records found',
  maxPages = 8,
  instanceFns = {},
  rowKey = 'id',
  getTrProps = null,
  documentPage = 0,
  setDocumentPage = (e) => {
    return e;
  },
}) => {
  const [forceRender, setForceRender] = useState(false);
  const [changes, setChanges] = useState({});

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );

  const rerender = () => {
    setForceRender(!forceRender);
  };

  const registerChange = (row, prop, value) => {
    let id = row.values[rowKey];
    let copy = { ...changes };
    copy[id] = { ...copy[id], [prop]: value, rowId: id };
    setChanges(copy);
  };

  const getChanges = (row) => {
    return changes[row.values[rowKey]] || {};
  };

  const allData = useMemo(() => data || [], [data, forceRender]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: allData,
      defaultColumn,
      initialState: { pageIndex: documentPage, pageSize: 15 },
      rerender,
      registerChange,
      getChanges,
      ...instanceFns,
    },
    useSortBy,
    useResizeColumns,
    usePagination
  );

  const getVisiblePages = () => {
    const pagingButtons = 6;
    let startPage = 0;
    let endPage = pageCount > pagingButtons ? pagingButtons : pageCount;

    const pages = [];
    for (let i = startPage; i < endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <>
      <table className="general-table table table-hover" {...getTableProps()}>
        <thead className="thead-secondary">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter((col) => !col.hidden)
                .map((column) => (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps([
                        {
                          className: column.headerClassName,
                          style: column.headerStyle,
                        },
                      ])
                    )}
                    // width={column.width}
                    // maxWidth={column.maxWidth}
                    // minWidth={column.minWidth}
                  >
                    <span>{column.isSorted ? column.isSortedDesc ? <IconDown className="sort-icon" /> : <IconUp className="sort-icon" /> : ''}</span>
                    {column.render('Header')}
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} {...getTrProps?.(row)}>
                {row.cells
                  .filter((cell) => !cell.column.hidden)
                  .map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: !row.editing ? cell.column.className : cell.column.classNameEditing || cell.column.className,
                            style: cell.column.style,
                            // set here your other custom props
                          },
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        autoHide
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalRows={data?.length || 0}
        onGotoPage={(p) => {
          gotoPage(p);
          setDocumentPage(p);
        }}
        onPreviousPage={() => {
          previousPage();
          setDocumentPage(pageIndex-1);
        }}
        onNextPage={() => {
          nextPage();
          setDocumentPage(pageIndex+1);
        }}
        onSetPageSize={(size) => setPageSize(size)}
      />

      {!page.length ? <h6 className="mg-t-20 tx-center">{NoRecordsMessage}</h6> : null}
    </>
  );
};

export default Table;
