import Select from 'react-select';
import { IAdminControlsProps } from './adminControls';
import { useContext, useEffect, useState } from 'react';
import { StudentEstimate } from '../../../models/FinancialEstimate.model';
import studentEstimateService from '../../../services/studentEstimate.service';
import useToastFromAxiosResponse from '../../../hooks/toastFromAxiosResponse';
import { useGenerateImage } from '../../../hooks/generateImage';
import globalContext from '../../../context/globalContext';
import Modal from '../../modal/modal';

const EstimateControls: React.FC<IAdminControlsProps> = ({ printComponentRef, workbookRef, sheets, setSheets, studentId = 0, onBeforePrint, onAfterPrint }) => {
  const [estimates, setEstimates] = useState<StudentEstimate[]>([]);
  const [selectedEstimate, setSelectedEstimate] = useState<StudentEstimate | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [pdfsUrls, setPdfsUrls] = useState<string[]>([]); // latest pdfs for estimate and recap

  const { setLoading } = useContext(globalContext);
  const { generateImage } = useGenerateImage();
  const showToast = useToastFromAxiosResponse();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (force = false) => {
    let allTemplates = await studentEstimateService.getStudentEstimates(studentId, force);
    let data = allTemplates?.data || [];

    // when the estimate is not saved in the user, id does not have an id,
    // assign a negative value instead
    let nextId = -1;
    for (let i = 0; i < data.length; i++) {
      if (!data[i].id) {
        data[i].id = nextId;
        nextId -= 1;
      }
    }

    setEstimates(data);


    // load pdfs urls
    let latest = await studentEstimateService.getLatestEstimatePdf(studentId);
    if (latest?.data) {
      setPdfsUrls([latest.data?.estimateUrl || '', latest.data?.recapUrl || '']);
    }
  };

  const getTemplateOptions = () => {
    return estimates?.map((estimate) => ({ value: estimate.id, label: estimate.name }));
  };

  const clearSheets = () => {
    for (let i = 0; i < sheets.length; i++) {
      workbookRef.current?.deleteSheet(sheets[i].name);
    }
  };

  const onTemplateSelected = (option: any) => {
    let estimate = estimates.find((t) => t.id === option.value);
    if (estimate) {
      clearSheets();
      setSelectedEstimate(estimate);
      // set workbook to spreadsheet
      let wb = JSON.parse(estimate.template);
      for (let i = 0; i < wb.length; i++) {
        wb[i].row = 40;
        wb[i].column = 12;
      }
      setSheets?.(wb);
      workbookRef.current?.updateSheet(wb);
    }
  };

  const setDisplayedTabSheet = (tabIndex: number) => {
    workbookRef.current?.activateSheet({ index: tabIndex });
  };

  const getSpreadSheetImage = async (tabIndex: number) => {
    setDisplayedTabSheet(tabIndex);
    await new Promise((resolve) => setTimeout(resolve, 100)); // wait for the sheet to be displayed
    const base64 = await generateImage(document.getElementsByTagName('canvas')[0] as any);
    return base64;
  };

  const handleSave = async () => {
    if (selectedEstimate) {
      try {
        setLoading(true);
        const estimateImage = await getSpreadSheetImage(0);
        const recapImage = await getSpreadSheetImage(1);

        // remove negative id as they are temporal/memory only
        let estimateToSave = { ...selectedEstimate, estimateImage, recapImage };
        if (estimateToSave.id < 0) estimateToSave.id = 0;

        estimateToSave.template = JSON.stringify(sheets);
        estimateToSave.student = studentId as any;
        const res = await studentEstimateService.createOrUpdateEstimate(estimateToSave);
        showToast(res, { successMessage: ' ✔️ Estimate saved successfully' });
        if (res?.status === 200) {
          setPdfsUrls([res.data?.estimateUrl || '', res.data?.recapUrl || '']);
          setModalVisible(true);
        }
      } finally {
        setLoading(false);
        setDisplayedTabSheet(0);
      }
    }
  };

  const handleDownloadPDF = (pdfIndex: number) => {
    // 0 - Estimate, 1 - Recap
    window.open('' + pdfsUrls[pdfIndex], 'newwindow', 'width=820px,height=950px');
    return false;
  };

  const restart = () => {
    // discard changes and reload from master template
    clearSheets();
    loadData(true);
    setConfirmDelete(false);
  }

  const isThereAUrlForPDF = (pdfIndex: number) => {
    return pdfsUrls[pdfIndex] !== '';
  };

  const handleDismissModal = () => {
    setModalVisible(false);
  };

  const getModalBody = () => {
    return (
      <>
        <p className="mb-0">A new version was generated and added to student record.</p>
        <p className="mt-0">Click the button below to download the PDF.</p>
      </>
    );
  };

  return (
    <div className="student-controls no-print">
      <Modal
        visible={modalVisible}
        title="Financial Aid Estimate"
        body={getModalBody()}
        cancelButtonText={'Dismiss'}
        mainButtonClass="btn-outline-primary"
        mainButtonText="Estimate PDF"
        onAccept={() => handleDownloadPDF(0)}
        secondaryButtonText="Recap PDF"
        secondaryButtonClass="btn-outline-primary"
        onSecondary={() => handleDownloadPDF(1)}
        onCancel={handleDismissModal}
        onClose={handleDismissModal}        
      />

      <Modal
        visible={confirmDelete}
        cancelButtonText="Cancel"
        mainButtonText="Delete Estimates"
        mainButtonClass="btn-danger"
        title="Delete saved estimates?"
        body={`This will delete all saved estimates for this student, except the PDF files in student record.`}
        onCancel={() => {
          setConfirmDelete(false);
        }}
        onClose={() => {
          setConfirmDelete(false);          
        }}
        onAccept={restart}
      />

      <div className="menu-option">
        <label className="mr-2">Type:</label>
        <Select className="template-select" options={getTemplateOptions()} onChange={onTemplateSelected} />
      </div>

      <button className="btn btn-sm btn-outline-primary mr-3" onClick={handleSave}>
        Save Changes
      </button>

      <button className="btn btn-sm btn-outline-dark mr-3" disabled={!isThereAUrlForPDF(0)} onClick={() => handleDownloadPDF(0)}>
        Print Latest Estimate
      </button>

      <button className="btn btn-sm btn-outline-dark mr-3" disabled={!isThereAUrlForPDF(1)} onClick={() => handleDownloadPDF(1)}>
        Print Latest Recap
      </button>

      <button className="btn btn-sm btn-outline-danger mr-3" onClick={() => setConfirmDelete(true)}>
        Restart
      </button>

      {/* <button className="btn btn-sm btn-outline-success mr-3" onClick={handleDownloadExcel}>
        Download Excel
      </button> */}
    </div>
  );
};

export default EstimateControls;
