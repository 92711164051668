import { forwardRef, useEffect, useImperativeHandle } from "react";
import { COBAProgram } from "../../../../models/Enums";
import { CobaDisclosurePageProps } from "./cobaDisclosurePageProps";
import usePageSignature from "../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../models/Forms.enum";

const DisclosurePage2 = forwardRef(({ visible, data: viewController }: CobaDisclosurePageProps, ref) => {
  const { isFormValid, validateForm, saveForm, getSignature, getAcknowledge, getInitials } = usePageSignature(viewController);

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(COBAFormsPage.Disclosure2);
    },
  }));

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        validateForm();
      }, 500);
    }
  }, [visible]);

  const getUniformPolicy = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER:
        return (
          <>
            <h5>BARBER STUDENTS:</h5>
            <ul className="position-relative">
              <li>Barber Smock on at ALL times with an appropriate shirt under the smock;</li>
              <li>Appropriate black or blue, pants or shorts;</li>
              <li>Comfortable, clean, closed-toe shoes;</li>
              <li>Clean hygiene;</li>
              <li>Appropriate fitted or snapback caps;</li>
              <li>Appropriate sweaters or jackets. </li>
              <div className="flex-two-cols">{getInitials("main")}</div>
            </ul>
          </>
        );
      case COBAProgram.BARBER_HYBRID:
        return (
          <>
            <h5>BARBER STUDENTS:</h5>
            <ul className="position-relative">
              <li>Barber Smock on at ALL times with an appropriate shirt under the smock;</li>
              <li>Appropriate black or blue, pants or shorts;</li>
              <li>Comfortable, clean, closed-toe shoes;</li>
              <li>Clean hygiene;</li>
              <li>Appropriate fitted or snapback caps;</li>
              <li>Appropriate sweaters or jackets. </li>
              <div className="flex-two-cols">{getInitials("main")}</div>
            </ul>
          </>
        );
      case COBAProgram.COSMETOLOGY:
        return (
          <>
            <h5>COSMETOLOGY STUDENTS: </h5>
            <ul className="position-relative">
              <li>Appropriate black top or shirt;</li>
              <li>Appropriate black pants or shorts;</li>
              <li>Comfortable, clean, closed-toe shoes;</li>
              <li>Clean hygiene;</li>
              <li>Appropriate sweaters or jackets.</li>
              <div className="flex-two-cols">{getInitials("main")}</div>
            </ul>
          </>
        );
      case COBAProgram.ESTHETICIAN:
        return (
          <>
            <h5>ESTHETICIAN STUDENTS:</h5>
            <ul className="position-relative">
              <li>Appropriate black top or shirt;</li>
              <li>Appropriate black pants or shorts;</li>
              <li>Comfortable, clean, closed-toe shoes;</li>
              <li>Clean hygiene;</li>
              <li>Appropriate sweaters or jackets. </li>
              <div className="flex-two-cols">{getInitials("main")}</div>
            </ul>
          </>
        );
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="doc-content">
      <h3>CLASSROOM EXPECTATIONS -- Student Uniform Policy </h3>

      <p>All students are required to attend school every day in appropriate attire as outlined for each program below.</p>
      <p>Failure to follow School Uniform Policy will lead to a student being clocked out and sent home. </p>

      {getUniformPolicy()}

      <h5>INAPPROPRIATE CLOTHING -- BELOW LIST IS NOT EXHAUSTIVE:</h5>

      <table>
        <tbody>
          <tr>
            <td>Crop tops</td>
            <td>Ripped pants/shorts</td>
            <td>Open back shirts</td>
          </tr>
          <tr>
            <td>Sweatpants</td>
            <td>Sandals</td>
            <td>Spaghetti straps</td>
          </tr>
          <tr>
            <td>Muscle shirts</td>
            <td>Slippers</td>
            <td>See through clothing</td>
          </tr>
          <tr>
            <td>Miniskirts/shorts</td>
            <td>Heels over 3" inches</td>
            <td>Offensive shirts </td>
          </tr>
        </tbody>
      </table>

      <h5 className='mt-5'>CLASSROOM EXPECTATIONS -- Release of Liability Waiver</h5>

      <p>
        I acknowledge that the students of Coba Academy may perform services on me in support of educational services provided. I hereby
        agree that all such Cosmetology, Esthetics, and Barbering services will be performed at my own risk, and that neither Coba Academy,
        the Board of Barbering and Cosmetology, Cosmetology, Esthetics or Barber students, all Instructors nor Employees shall be liable or
        responsible for any damage or injury that may occur to me during the performance of any service.{" "}
      </p>

      <p>
        I agree to pay the posted prices (Salon Menu Price) in whichever department the work is performed to service with associated
        approvals therein.
      </p>
      <p>
        I am aware and understand that I will be under video surveillance throughout the identified period of enrollment at Coba Academy.{" "}
      </p>
      <p>
        I agree that while attending Coba Academy, students may accompany members of Coba Academy staff to promote the school as part of
        outreach, marketing, and engagement programming. The student agrees to release Coba Academy from any liability for damages while
        they accompany members of our staff to places of promotion for the school.
      </p>
      <p>
        I agree on behalf of myself, to hold harmless and to defend Coba Academy, its officers, directors, agents, employees or
        representatives associated with any field trip from any and all liability claims, loss or damage arising from or in connection with
        any participation in the field trip.
      </p>
      <p>
        I agree that while attending Coba Academy, students may be photographed as part of our marketing program and photos may be posted on
        social media or used for print media. The student agrees to release Coba Academy from any liability for damages as a result of
        photographs taken by school staff or students and used while conducting our marketing program.
      </p>
      {getAcknowledge("Main", "I acknowledge I have read and understand the Release of Liability Waiver.")}

      {getSignature()}
    </div>
  );
});

export default DisclosurePage2;
