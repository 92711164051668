import React, { FunctionComponent, useState } from 'react';
import { getValidationErrors } from '../common';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './phoneField.scss';
import { PATTERNS } from '../patterns';
import { Controller } from 'react-hook-form';

export interface PhoneFieldProps {
  field: string;
  label?: any;
  value?: any;
  labelAfter?: any;
  register: any;
  control: any;
  rules?: any;
  placeholder?: string;
  errors?: any;
  visible?: boolean;
  labelVisible?: boolean;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  labelClass?: string;
  parentClass?: string;
  includeColon?: boolean;
  showIsrequired?: boolean;
  defaultCountry?: any;
  maxLength?: string;
  type?: string;
}

const PhoneField: FunctionComponent<PhoneFieldProps> = ({
  field,
  label,
  labelAfter = null,
  register,
  control,
  rules = {},
  placeholder = '',
  errors = null,
  showIsrequired = true,
  visible = true,
  labelVisible = true,
  disabled = false,
  labelClass = 'd-block',
  parentClass = 'form-group',
  includeColon = true,
  defaultCountry = 'US',
  type = 'tel',
}) => {
  const [value, setValue] = useState('' as any);
  const [contryCode, setContryCode] = useState('' as any);
  const validationError =
    errors && getValidationErrors(errors, field, label, rules);
  const isRequired = () => {
    return rules && rules.required;
  };

  const getRules = () => {
    if (!visible && rules) {
      rules['required'] = false;
    }

    if (defaultCountry !== 'US') {
      rules['pattern'] = PATTERNS.globalPhoneRegEx;
    }

    return rules;
  };

  return visible ? (
    <div className={parentClass}>
      {labelVisible && (
        <label className={labelClass}>
          {showIsrequired && isRequired() && (
            <span className="required">*</span>
          )}
          {label}
          {includeColon && ':'}
          {labelAfter}
        </label>
      )}

      <div
        className={
          validationError ? 'form-control  invalid-control' : 'form-control '
        }
      >
        <Controller
          control={control}
          name={field}
          rules={getRules()}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <PhoneInput
              className="custom-phone-input"
              disabled={disabled}
              placeholder={placeholder}
              defaultCountry={defaultCountry}
              value={value}
              onChange={(value) => {
                onChange(value);
                // setValue(field, value, { shouldValidate: true });
              }}
              // onCountryChange={setCountryCode}
              limitMaxLength={true}
              type={type}
              focusInputOnCountrySelection={true}
            />
          )}
        />
      </div>
      {validationError}
    </div>
  ) : null;
};

export default PhoneField;
