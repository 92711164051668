import { Table as Props } from "../types"
import classNames from './sdgku.table.module.css'

export const Table = ({ title, headers, rows, footer }: Props) => {
    return (
        <div className="table-responsive">
            {title && <h3>{title}</h3>}
            <table className={`${classNames.table} mx-auto table table-striped`}>
                <thead>
                    <tr className={classNames.headers}>
                        {headers.map((item, index) => (
                            <th scope="col" key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((row, index) => (
                        <tr key={index}>
                            {row?.map((item, index) => (
                                <td key={index}>{item}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <small>{footer}</small>
        </div >
    )
}