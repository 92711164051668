import CookieConsent from 'react-cookie-consent';

const Consent = () => {
    return ( 
        <CookieConsent disableButtonStyles={true} overlay={true} buttonText="Accept All Cookies" buttonClasses="btn btn-sm btn-primary">
            <p>
              This website uses cookies to enhance your user experience, improve the quality of our website, deliver advertising and other
              content tailored to your interests. Some jurisdictions' privacy laws offer their residents specific privacy rights, which we
              respect as described in our privacy policy. <br />
              By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site
              usage, and assist in our marketing efforts. Alliance uses cookies to enhance your user experience, improve the quality of our
              website, and deliver advertising and other content tailored to your interests. To exercise rights that you may have related to
              cookies, including opt-out of sharing information for quality purposes, select "More Info" to review our privacy policy.
              <a href="https://finaid360.com/static/PrivacyStatement.pdf" target="_blank" rel="noreferrer">
                More Info
              </a>
            </p>
          </CookieConsent>
     );
}
 
export default Consent;