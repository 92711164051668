import http from "./http-common";
import moment from 'moment-timezone';


class AuthService {
	async login(email: string, password: string) {
		const currentTimezone = moment.tz.guess();
		let payload = {
			email: email,
			password: password,
			timezone: currentTimezone
		};

		try {
			let res = await http.post("/auth/login/", payload);
			return res?.data;
		} catch (error) {
			http.fa360.displayError("Error login in");
		}
	}

	async logout() {
		try {
			await http.post("/auth/logout/");
		} catch (error) {
			http.fa360.displayError("Error logging out");
		}
	}

	async me() {
		try {
			let res = await http.get("/auth/me/");
			return res?.data;
		} catch (error) {
			http.fa360.displayError("Error pinging home");
		}
	}

	async recovery(email: string) {
		try {
			let res = await http.post("/auth/recovery/", { email });
			return res?.data;
		} catch (error) {
			http.fa360.displayError("Error sending recovery email");
		}
	}

	async generateRecoveryToken(userId: any) {
		try {
			let res = await http.post("/auth/password_token/", { user_id: userId });
			return res;
		} catch (error) {
			http.fa360.displayError("Error generating token");
		}
	}

	async validateToken(token: string) {
		try {
			let res = await http.post("/auth/validate_password_token/", { token });
			return res?.data;
		} catch (error) {
			http.fa360.displayError("Error validating token");
		}
	}

	async validateDepartmentInviteToken(token: string) {
		try {
			let res = await http.post("/auth/validate_calendar_token/", { token });
			return res?.data;
		} catch (error) {
			http.fa360.displayError("Error validating token");
		}
	}

	async changePassword(password: string, token: string) {
		try {
			let res = await http.post("/auth/change_password/", { password, token });
			return res?.data;
		} catch (error) {
			http.fa360.displayError("Error changing password");
		}
	}
}

export default new AuthService();
