import { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Step, Stepper, useStepper } from '../../../../components/shared/stepper';
import { Header_Mobile as Header } from '../header';
import classNames from './sdgku.enrollment.module.css';
import { AgreementPage1, AgreementPage2, AgreementPage3, AgreementPage4, AgreementPage5, AgreementPage6, AgreementPage7, AgreementPage8 } from './steps';
import { ProgramData } from '../types';
import { getProgramData } from '../sdgkuController';
import globalContext from '../../../../context/globalContext';
import { SignedPageData } from '../../../../viewControllers/signedPage';
import { SignedForm } from '../../../../models/Forms.model';
import { SDGKUProgram, SDGKUProgramFromName } from '../../../../models/Enums';
import { SignedFormKey } from '../../../../models/Forms.enum';
import signedFormService from '../../../../services/signedForm.service';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import { AlertType } from '../../../../models/AlertType.enum';
import { FinishPage, TokenError } from './sdgku.extrapages';

interface Props {
  location: any;
}

export const Enrollment = ({ location }: Props) => {
  const NumOfSteps = 8;
  const { setLoading, showAlert } = useContext(globalContext);
  const [_, setToken] = useState<string>(null!);
  const [tokenError, setTokenError] = useState(false);
  const [feeGroupId, setFeeGroupId] = useState<number>(0);

  const [canMoveNext, setCanMoveNext] = useState<boolean>(false);
  const [viewControllers, setViewControllers] = useState<SignedPageData[]>([]);
  const [programInfo, setProgramInfo] = useState<ProgramData>(null!);
  const { step, incrementStep, decrementStep } = useStepper(0, NumOfSteps);

  const pagesRef = useRef<any[]>([]);

  useEffect(() => {
    loadInitialData();
  }, []);

  const initializeControllers = (formData: SignedForm, program: SDGKUProgram, token: string, feeGroupId?: number) => {
    if (!formData) return;

    for (let i = 0; i < NumOfSteps; i++) {
      const page = new SignedPageData(SignedFormKey.ENROLLMENT_AGREEMENT, program, formData, token, setCanMoveNext, feeGroupId);
      setViewControllers((prev) => [...prev, page]);
    }
  };

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const sudentId = params.get('id');
    const feeGroupId = params.get('fg');

    if (token && sudentId) {
      setToken(token);
      const fgId = +(feeGroupId || 0);
      setFeeGroupId(fgId);
      loadForm(+sudentId, token, fgId);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string, feeGroupId: number) => {
    setLoading(true);
    const resp = await signedFormService.getFormData(studentId, SignedFormKey.ENROLLMENT_AGREEMENT, token, feeGroupId);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      const program = resp.data.programId;
      getProgramData(program, studentId, token, "", feeGroupId).then(setProgramInfo);
      initializeControllers(resp?.data, program, token, feeGroupId);
    }
  };

  const handleDecrementStep = () => {
    decrementStep();
  };

  const handleIncrementStep = async () => {
    if (await pagesRef.current[step].saveData()) {
      // setCanMoveNext(true)
      incrementStep();
    } else {
      //   showAlert('Error while saving. Please verify all field are completed', AlertType.Error);
    }
  };

  // Components
  const GetPage = () => (
    <>
      <AgreementPage1 visible={step === 0} program={programInfo} data={viewControllers[0]} ref={(el: any) => (pagesRef.current[0] = el)} />
      <AgreementPage2 visible={step === 1} program={programInfo} data={viewControllers[1]} ref={(el: any) => (pagesRef.current[1] = el)} />
      <AgreementPage3 visible={step === 2} program={programInfo} data={viewControllers[2]} ref={(el: any) => (pagesRef.current[2] = el)} />
      <AgreementPage4 visible={step === 3} program={programInfo} data={viewControllers[3]} ref={(el: any) => (pagesRef.current[3] = el)} />
      <AgreementPage5 visible={step === 4} program={programInfo} data={viewControllers[4]} ref={(el: any) => (pagesRef.current[4] = el)} />
      <AgreementPage6 visible={step === 5} program={programInfo} data={viewControllers[5]} ref={(el: any) => (pagesRef.current[5] = el)} />
      <AgreementPage7 visible={step === 6} program={programInfo} data={viewControllers[6]} ref={(el: any) => (pagesRef.current[6] = el)} /> 
      <AgreementPage8 visible={step === 7} program={programInfo} data={viewControllers[7]} ref={(el: any) => (pagesRef.current[7] = el)} />
    </>
  );

  const StepControls = () => (
    <div className="stepper-controls">
      <button disabled={step === 0} className="btn btn-sm btn-primary" onClick={handleDecrementStep}>
        <i className="fa fa-chevron-left"></i> Back
      </button>
      <button disabled={!canMoveNext} className="btn btn-sm btn-primary" onClick={handleIncrementStep}>
        {step !== NumOfSteps - 1 ? 'Next' : 'Complete'} <i className="fa fa-chevron-right"></i>
      </button>
    </div>
  );

  const GetContent = () => {
    if (step === NumOfSteps) return <FinishPage pageMessage="Enrollment Agreement Form" requiresApproval={true} />;

    return (
      <>
        <Stepper step={step}>
          {Array(NumOfSteps)
            .fill(1)
            .map((_, index) => (
              <Step key={index} />
            ))}
        </Stepper>
        {GetPage()}
        {StepControls()}
      </>
    );
  };

  const LoadingView = () => (
    <div className="loading">
      <h3>Loading data, please wait</h3>
    </div>
  );

  if (viewControllers.length === 0) return <LoadingView />;

  if (tokenError) return <TokenError />;

  return (
    <div className={`${classNames.factsheet} mx-auto p-3 doc-area`}>
      <Header />
      {GetContent()}
    </div>
  );
};
