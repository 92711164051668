import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AutoLogout = () => {
    //inactivityTime = min * sec * ms
    const inactivityTime = 25*60*1000; //default in 15 minutes
    let timeoutId: NodeJS.Timeout;
    const history = useHistory();

    let currentLocation = "";

    const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(handleLogout, inactivityTime);
    };

    const handleLogout = () =>{
        currentLocation = history.location.pathname;
        history.push("/public/logout", {from: currentLocation});
    };

    const handleMouseMove = () => {
        resetTimer();
    };

    const handleKeyPress = () => {
        resetTimer ();
    };

    useEffect(()=>{
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keypress', handleKeyPress);

        resetTimer();
        
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keypress', handleKeyPress);
            clearTimeout(timeoutId);
        };
    }, []);


    return <></>;
}

export default AutoLogout;