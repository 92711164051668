import * as React from 'react';
import { AlertType } from '../models/AlertType.enum';
import authService from '../services/auth.service';
import globalContext from './globalContext';
import { IGlobalAlert, LoggedInStatus, ModulesData } from './IContext';
import axiosInstance from '../services/http-common';
import { withRouter } from 'react-router-dom';
import { PermissionSet, User } from '../models/User.model';
import DataLoaders from './dataLoaders';
import { Permission } from '../models/Enums';

class GlobalState extends React.Component<any, any> {
  state = {
    // data
    isLoading: false,
    loadingMessage: '',
    isLoggedIn: LoggedInStatus.NOT_VERIFIED,
    user: {} as User,
    alert: {} as IGlobalAlert,
    cache: {} as any,
    modules: {} as ModulesData,
  };

  render() {
    return (
      <globalContext.Provider
        value={{
          isLoading: this.state.isLoading,
          loadingMessage: this.state.loadingMessage,
          isLoggedIn: this.state.isLoggedIn,
          user: this.state.user,
          alert: this.state.alert,
          cache: this.state.cache,
          loaders: new DataLoaders(this.getWithCache),
          modules: this.state.modules,

          // actions
          setLoggedInStatus: this.setLoggedInStatus,
          logOut: this.logOut,
          hasPermission: this.hasPermission,
          verifyLoggedIn: this.verifyLoggedIn,
          setLoading: this.setLoading,
          showAlert: this.showAlert,
          clearAlert: this.clearAlert,
          getWithCache: this.getWithCache,
          clearCacheKey: this.clearCacheKey,
          getFilter: this.getFilter,
          saveFilter: this.saveFilter,
        }}
      >
        {this.props.children}
      </globalContext.Provider>
    );
  }

  componentDidMount() {
    axiosInstance.fa360.showError = this.showAlert;
    axiosInstance.history = this.props.history;
    this.verifyLoggedIn();
  }

  //TOO Serg: move to separate file
  getWithCache = async <T extends unknown>(
    key: string,
    getData: () => {},
    preProcess?: (data: any) => {},
    postProcess?: (data: any) => {}
  ): Promise<T> => {
    let cached = this.state.cache[key];
    if (cached) {
      return cached;
    } else {
      let hotData = await getData();
      let cache = { ...this.state.cache };
      if (preProcess) {
        hotData = preProcess(hotData);
      }

      cache[key] = hotData;
      this.setState({ cache });

      if (postProcess) {
        hotData = postProcess(hotData);
      }

      return hotData as T;
    }
  };

  clearCacheKey = (key: string) => {
    let cache = { ...this.state.cache };
    cache[key] = null;
    this.setState({ cache });
  };

  setLoggedInStatus = (status: LoggedInStatus, user: User) => {
    this.setState({ isLoggedIn: status, user });
  };

  logOut = async () => {
    this.setState({ isLoggedIn: LoggedInStatus.NOT_LOGGED_IN, user: {} as User, cache: {} });
  };

  setLoading = (isLoading: boolean, message?: string) => {
    this.setState({ isLoading, loadingMessage: message ?? 'Loading, please wait' });
  };

  showAlert = (alertMessage: any, alertType = AlertType.Error) => {
    this.setState({
      alert: {
        alertMessage,
        alertType,
      },
    });
    window.scrollTo(0, 0);
  };

  clearAlert = () => {
    this.setState({ alert: {} as IGlobalAlert });
  };

  verifyLoggedIn = async () => {
    try {
      let me = await authService.me();
      this.setState({ isLoggedIn: me ? LoggedInStatus.LOGGED_IN : LoggedInStatus.NOT_LOGGED_IN, user: me });
    } catch {
      this.setState({ isLoggedIn: LoggedInStatus.NOT_LOGGED_IN, user: {} as User });
    }
  };

  saveFilter = (action: string, value: any) => {
    let filterCpy = { ...this.state.modules.filter } as any;
    filterCpy[action] = value;
    this.setState({
      modules: {
        filter: filterCpy,
      },
    });
  };

  getFilter = () => {
    return { ...this.state.modules.filter };
  };

  hasPermission = (permission: Permission) : boolean => {
    return this.state.user?.role?.permissions.some((p: PermissionSet) => p.name === permission);    
  }
}

export default withRouter(GlobalState);
