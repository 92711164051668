import { Activity } from "../models/Activity.model";
import http from "./http-common";

class ActivityService {
	async getActivityData() {
		try {
			let response = http.get("/activity/");
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading Activity Data");
			return undefined;
		}
	}

	async getActivityDatatest() {
		try {
			let response = http.get("/activity/get_all");
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading Activity Data");
			return undefined;
		}
	}

	async getActivityDataByDate(from_month: string, to_month: string) {
		try {
			let response = http.get(`/activity/filter/?from=${from_month}&to=${to_month}`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading Activity Data");
			return undefined;
		}
	}

	async deactivateActivity(activity: Activity) {
		try {
			let response = http.patch(`/activity/${activity.id}/deactivate/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while deleting Activity");
			return undefined;
		}
	}
}
export default new ActivityService();
