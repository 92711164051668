import { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import './thankYouPage.scss';
import { useEffect } from 'react';

const SDGKUThankYouPage = (props: any) => {
  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if(params.get("step") === "1") {
      setShowProgress(false);
    }
  },[]);

  const getBodyStep1 = () => {
   return  <>
    <h4>You will receive a link to continue with the application process.</h4>
    <p>If you do not receive a link in a few minutes, please check your spam folder.</p>
    </>
  };

  const getBodyStep2 = () => {
   return  <>
    <h4>An administrator will reach out to you shortly.</h4>

    <div className="custom-progress-wrapper col-sm-10 offset-sm-1 col-md-4 offset-md-4 preapp-form container">
      <div className="step-button bg-primary progress-bar progress-bar completed" id="step-1">
        <p>Step 1</p>
      </div>
      <div className="step-button bg-primary progress-bar progress-bar completed" id="step-2">
        <p>Step 2</p>
      </div>
      <div className="step-button bg-success progress-bar progress-bar completed" id="step-3">
        <p>Done</p>
      </div>
      <ProgressBar variant="primary" now={100} />
    </div>
    </>
  };

  return (
    <>
      <div className="thank-you-container">
        <h1>Thank you for your application</h1>
        {showProgress ? getBodyStep2() : getBodyStep1()}

      </div>
    </>
  );
};
export default SDGKUThankYouPage;
