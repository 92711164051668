import "./note.scss";

const Note = (props: any) => {
  const getFormattedDate = () => {
    return new Date(props.data.createdAt).toLocaleString();
  };

  const el = document.getElementById("notes-container"); // id of the chat container
  if (el) {
    el.scrollTop = el.scrollHeight;
  }

  return (
    <div className="card text-dark mb-3 note">
      <div className="card-body">
        <div className="note-header">
          <label>{getFormattedDate()}</label>
          <label>
            Note by: <span className="creator">{props.data.creator}</span>
          </label>
        </div>
        <div id="notes-container" className="note-body">
        <p className="card-text">{props.data.text}</p>
        </div>
      </div>
    </div>
  );
};

export default Note;
