import TextField from "../../../../../components/shared/Input/textField"
import { AgreementProps } from "../../types"
import { useForm } from "react-hook-form"
import { ApplicationForm } from "../../../../../models/ApplicationForm.model"
import classNames from '../sdgku.enrollment.module.css'
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'
import dateService from '../../../../../services/date.service'
import moment from 'moment'


interface AgreementPage1StudentForm {
    firstName: string;
    middleName: string;
    lastName: string;
    ssn: string;
    birthDate: string;
    email: string;
    phone: string;

    addressStreet: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    addressCountry: string;
}

interface AgreementPage1Form {
    student: AgreementPage1StudentForm;
    startDate: string;
    completionDate: string;
}

export const SpanishAgreementPage1 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { register, formState: { errors }, setValue } = useForm<AgreementPage1Form>();
    const { saveForm, getAcknowledge, getInitials } = usePageSignature(data, false);

    let startDate = "Loading...";
    if (data?.formData?.programStart) {
        startDate = dateService.formatYMD(data.formData.programStart);
    }

    let completionDate = "Loading...";
    if (data?.formData?.programEnd) {
        completionDate = dateService.formatYMD(data.formData.programEnd);
    }


    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement1)
        },
    }))

    useEffect(() => {
        const timezone = moment.tz.guess();
        if (data && data.formData) {
            setValue("student.firstName", data.formData.student.firstName)
            setValue("student.middleName", data.formData.student.middleName)
            setValue("student.lastName", data.formData.student.lastName)
            setValue("student.ssn", data.formData.student.ssn?.substr(data.formData.student.ssn?.length - 4))

            if(data.formData.student.birthDate)
                setValue("student.birthDate", moment.tz(data.formData.student.birthDate, timezone).format('MM/DD/YYYY'))
            setValue("student.email", data.formData.student.email)
            setValue("student.phone", data.formData.student.phone)

            setValue("student.addressStreet", data.formData.student.addressStreet)
            setValue("student.addressCity", data.formData.student.addressCity)
            setValue("student.addressState", data.formData.student.addressState)
            setValue("student.addressZip", data.formData.student.addressZip)
            setValue("student.addressCountry", data.formData.student.addressCountry)

            setValue("startDate", startDate)
            setValue("completionDate", completionDate)
        }
    }, [])

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <h3>ENROLLMENT AGREEMENT</h3>
            <h5>
                {program?.programType} <br />
                {program?.programName}
            </h5>
            <div className={`container-fluid mb-3 form-group ${classNames.group_box}`}>
                <div className="row">
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Nombre" field="student.firstName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Segundo Nombre" field="student.middleName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Apellidos" field="student.lastName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled maxLength="4" errors={errors} label="SSN (4 ultimos)" field="student.ssn" type="text" register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Fecha de Nacimiento" field="student.birthDate" type="text" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Correo Electrónico" field="student.email" type="email" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Teléfono #" field="student.phone" type="tel" register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Dirección" field="student.addressStreet" type="text" register={register} />
                    </div>
                    <div className="col-2">
                        <TextField disabled errors={errors} label="Ciudad" field="student.addressCity" type="text" register={register} />
                    </div>
                    <div className="col-2">
                        <TextField disabled errors={errors} label="Estado" field="student.addressState" type="text" register={register} />
                    </div>
                    <div className="col-2">
                        <TextField disabled errors={errors} label="Código Postal" field="student.addressZip" type="number" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="País" field="student.addressCountry" type="text" register={register} />
                    </div>
                </div>
            </div>
            <p className={`${classNames.group_box}`}>
                Este acuerdo es un instrumento legalmente vinculante cuando es firmado por el estudiante y aceptado por la escuela. Su firma en este acuerdo reconoce que se le ha concedido un tiempo razonable para leerlo y comprenderlo y que se le ha entregado: (a) una declaración escrita de la política de reembolso incluyendo ejemplos de cómo se aplica, y (b) un catálogo con una descripción del curso o servicio educativo incluyendo todos los hechos materiales relativos a la escuela y al programa del curso de instrucción que puedan afectar a su decisión de matricularse. Inmediatamente después de firmar este acuerdo, se le entregará una copia del mismo para que la conserve en sus archivos.
            </p>
            <p>
                Antes de firmar este acuerdo de inscripción, se le entregará un catálogo o folleto y una hoja informativa sobre el rendimiento escolar, que se le recomienda revisar antes de firmar este acuerdo. Estos documentos contienen políticas importantes y datos sobre el rendimiento de esta institución. Esta institución está obligada a hacerle firmar y fechar la información incluida en la Hoja informativa sobre el rendimiento de la escuela relativa a las tasas de finalización, las tasas de colocación, las tasas de aprobación del examen de licencia y los sueldos o salarios, así como la tasa de morosidad de los grupos de tres años más reciente, si procede, antes de firmar este acuerdo.
            </p>
            <div className="position-relative">
                {getInitials('page1')}
            </div>
            <div className="mt-4 ps-4 position-relative">
                {getAcknowledge('certify', `
                    Certifico que he recibido el catálogo, la hoja informativa sobre el rendimiento de la escuela y la información relativa a las tasas de finalización, las tasas de colocación, las tasas de aprobación del examen de licencia y la información sobre sueldos o salarios, así como la tasa de incumplimiento de los grupos de tres años más reciente, si procede, incluida en la hoja informativa sobre el rendimiento de la escuela, y que he firmado, rubricado y fechado la información proporcionada en la hoja informativa sobre el rendimiento de la escuela.
                `)}
            </div>
            <div className="position-relative mt-4">
                {getInitials('page1', true)}
            </div>
            <div className="mt-4 ps-4">
                <div className="row align-items-center">
                    <p className='col-5'>Período cubierto por este acuerdo de inscripción</p>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Fecha de inicio del programa" field="startDate" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Fecha prevista de finalización" field="completionDate" type="text" register={register} />
                    </div>
                </div>
            </div>
        </div>
    )
})