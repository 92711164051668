import { SDGKUProgramGroup, SDGKUProgram, SchoolID } from '../../../models/Enums';
import { SimpleFeeValue } from '../../../models/Fee.model';
import { EnrollmentAgreementFees } from '../../../models/Forms.enum';
import feeService from '../../../services/fee.service';
import { ProgramData, ProgramRequirementsFees } from './types';


const FSDI: ProgramData = {
  programName: 'Full Stack Development Immersive (FSDI)',
  programCode: 'FSDI',
  programType: 'Academic Non-Degree Program',
  programCGPA: '2.0 (C)',
  graduationRequirment1: 'Eligibility for graduation requires a minimum cumulative grade point average (CGPA) of at least 2.0 (C) for the FSDI program.',
  graduationRequirment2: 'Satisfactorily complete all the requirements of the online curriculum. This FSDI certificate program at SDGKU consists of 16 courses equivalent to 1 semester equivalent credit hour each and 4 courses equivalent to 2 semester equivalent credit hours each, for a total of 24 academic credits.',
  refunds: [],
  fees: [],
  charges: [],
};

const BSGM: ProgramData = {
  programName: 'Bachelor of Science in Global Management (BSGM)',
  programCode: 'BSGM',
  programType: 'Academic Degree Program',
  programCGPA: '2.0 (C)',
  graduationRequirment1: 'Eligibility for graduation requires a minimum cumulative grade point average (CGPA) of at least 2.0 (C) for the BSGM program.', 
  graduationRequirment2: 'Satisfactorily complete all the requirements of the online curriculum. This BSGM degree program at SDGKU consists of 42 courses equivalent to 3 semester equivalent credit hours each, for a total of 126 credit hours.',
  graduationRequirment3: '___ transfer academic credits have been accepted for the BSGM degree program, for a total of __ courses equivalent to __ academic credits to be completed by the student (per the following fees and charges). Participation (physical or virtual) in graduation activities is contingent upon completion of all graduation requirements. In exceptional cases where minimal requirements have not been completed, arrangements must be made in advance with the Chief Academic Officer (CAO).',
  refunds: [],
  fees: [],
  charges: [],
};

const MSCT: ProgramData = {
  programName: 'Master of Science in Communication and Technology (MSCT)',
  programCode: 'MSCT',
  programType: 'Academic Degree Program',
  programCGPA: '3.0 (B)',
  graduationRequirment1: 'Eligibility for graduation requires a minimum cumulative grade point average (CGPA) of at least 3.0 (B) for the MSCT program.',
  graduationRequirment2: 'Satisfactorily complete all the requirements of the online curriculum. This MSCT degree program at SDGKU consists of 15 courses equivalent to 3 academic semester equivalent credits each, for a total of 45 academic credits. ',
  refunds: [],
  fees: [],
  charges: [],
};

const MSIM: ProgramData = {
  programName: 'Master of Science in International Management (MSIM)',
  programCode: 'MSIM',
  programType: 'Academic Degree Program',
  programCGPA: '3.0 (B)',
  graduationRequirment1: 'Eligibility for graduation requires a minimum cumulative grade point average (CGPA) of at least 3.0 (B) for the MSIM program.',
  graduationRequirment2: 'Satisfactorily complete all the requirements of the online curriculum. This MSIM degree program at SDGKU consists of 15 courses equivalent to 3 academic semester equivalent credits each, for a total of 45 academic credits. ',
  refunds: [],
  fees: [],
  charges: [],
};

//  alt = alternative fee (e.g. Spanish for SDGKU BSGM)
export const getProgramData = async (program: SDGKUProgram, studentId: number, token: string, alt?: string, feeGroupId?: number) => {
  const vals = await feeRetrievalService(program, studentId, token, alt, feeGroupId);

  if (SDGKUProgramGroup.allFSDIPrograms.includes(program)) {
    return { ...FSDI, ...vals };
  } else if (SDGKUProgramGroup.allBSGMPrograms.includes(program)) {
    return { ...BSGM, ...vals };
  } else if (SDGKUProgramGroup.allMSCTPrograms.includes(program)) {
    return { ...MSCT, ...vals };
  } else if (SDGKUProgramGroup.allMSIMPrograms.includes(program)) {
    return { ...MSIM, ...vals };
  }

  return Promise.reject('Program not found');
};

const feeRetrievalService = async (program: SDGKUProgram, studentId: number, token: string, alt?: string, feeGroupId?: number): Promise<{ refunds: any[]; fees: any[]; charges: any[] }> => {
  return new Promise(async (resolve, reject) => {
   
      const keys = {
        refunds: [
          EnrollmentAgreementFees.Refund_after, 
          EnrollmentAgreementFees.Refund_7, 
          EnrollmentAgreementFees.Refund_28, 
          EnrollmentAgreementFees.Refund_56, 
          EnrollmentAgreementFees.Refund_84
        ],
        fees: [
          EnrollmentAgreementFees.Fee_App,
          EnrollmentAgreementFees.Fee_Reg,
          EnrollmentAgreementFees.Fee_Tuition,
          EnrollmentAgreementFees.Fee_Tuition_2,

          EnrollmentAgreementFees.Fee_Tuition_Total,
          EnrollmentAgreementFees.Fee_Other,
          EnrollmentAgreementFees.Fee_Graduation,
          EnrollmentAgreementFees.Fee_Tuiton_Waiver,
          EnrollmentAgreementFees.Fee_Labs,
          EnrollmentAgreementFees.Fee_Text,
          EnrollmentAgreementFees.Fee_Uniforms,
          
          EnrollmentAgreementFees.Fee_Residence,
          EnrollmentAgreementFees.Fee_Equipment,
          EnrollmentAgreementFees.Fee_Tutoring,
          EnrollmentAgreementFees.Fee_assessment,
          EnrollmentAgreementFees.Fee_Transfer,
          
          EnrollmentAgreementFees.Fee_UTranscript,
          EnrollmentAgreementFees.Fee_Transcript,
          EnrollmentAgreementFees.Fee_Diploma,
          EnrollmentAgreementFees.Fee_Check,
          EnrollmentAgreementFees.Fee_STRF,

        ],
        charges: [
          EnrollmentAgreementFees.Charge_Attendance, 
          EnrollmentAgreementFees.Charge_Program, 
          EnrollmentAgreementFees.Charge_Enrrollment
        ],
      };

      const resp = await feeService.getBatchFees(program, studentId, SchoolID.SDGKU, [...keys.refunds, ...keys.fees, ...keys.charges], token, alt, feeGroupId);
      if(resp?.status !== 200) {
        reject(resp?.data);
      }

      const refunds = resp?.data.filter((x:SimpleFeeValue) => keys.refunds.includes(x.feeKey as any)).map((refund: SimpleFeeValue) => ({
        refundName: refund.fee.name,
        refundAmount: refund.amount
      }));      
      refunds.sort((a: any, b: any) => b.refundAmount - a.refundAmount);

      const fees = resp?.data.filter((x:SimpleFeeValue) => keys.fees.includes(x.feeKey as any)).map((fee: SimpleFeeValue) => ({
        feeName: fee.fee.name,
        feeCondition: fee.description,
        feeAmount: fee.amount
      }));

      const charges = resp?.data.filter((x:SimpleFeeValue) => keys.charges.includes(x.feeKey as any)).map((charge: SimpleFeeValue) => ({
        chargeName: charge.fee.name,
        chargeAmount: charge.amount
      }));

      resolve({
        refunds,
        fees,
        charges,
      });    
  });
};

///////////////////////////////////////////////////////////////////////
//////////////     VIB GENERATION DATA    /////////////////////////////
///////////////////////////////////////////////////////////////////////

export const getVibProgramCourses = async (program: SDGKUProgram) => {    
  if (SDGKUProgramGroup.allFSDIPrograms.includes(program)) {
    const fees = [
      { feeName: 'Application', feeAmount: 0 },
      { feeName: 'Registration', feeAmount: 100 },
      { feeName: 'Tuition', feeAmount: 15120 },
      { feeName: 'Graduation', feeAmount: 200 },
      { feeName: 'STRF Student Tuition Recovery Fund Waived (Applicable only for California resident or	residency)', feeAmount: 0 },
      { feeName: 'Other fees (Online	Materials)', feeAmount: 4500 },
    ];
    return { ...VIB_FSDI_Program, ...FSDI_Couses_Data, fees };
  }

  if (SDGKUProgramGroup.allBSGMPrograms.includes(program)) {
    const fees = [
      { feeName: 'Application', feeAmount: 0 },
      { feeName: 'Registration', feeAmount: 100 },
      { feeName: 'Tuition', feeAmount: 40320 },
      { feeName: 'Graduation', feeAmount: 200 },
      { feeName: 'STRF Student	Tuition	Recovery Fund Waived (Applicable only for	California resident or residency)', feeAmount: 0 },
      { feeName: 'Other fees (Online	Materials)', feeAmount: 11025 },
    ];
    return { ...VIB_BSGM_Program, ...BSGM_Courses_Data, fees };
  }

  if (SDGKUProgramGroup.allMSCTPrograms.includes(program)) {
    const fees = [
      { feeName: 'Application', feeAmount: 0 },
      { feeName: 'Registration', feeAmount: 100 },
      { feeName: 'Tuition', feeAmount: 24300 },
      { feeName: 'Graduation', feeAmount: 200 },
      { feeName: 'STRF Student	Tuition	Recovery Fund Waived (Applicable only for	California resident or residency)', feeAmount: 0 },
      { feeName: 'Other fees (Online	Materials)', feeAmount: 3937 },
    ];
    return { ...VIB_MSCT_Program, ...MSCT_Courses_Data, fees };
  }

  if (SDGKUProgramGroup.allMSIMPrograms.includes(program)) {
    const fees = [
      { feeName: 'Application', feeAmount: 0 },
      { feeName: 'Registration', feeAmount: 100 },
      { feeName: 'Tuition', feeAmount: 24300 },
      { feeName: 'Graduation', feeAmount: 200 },
      { feeName: 'STRF Student	Tuition	Recovery Fund Waived (Applicable only for	California resident or residency)', feeAmount: 0 },
      { feeName: 'Other fees (Online	Materials)', feeAmount: 3937 },
    ];
    return { ...VIB_MSIM_Program, ...MSIM_Courses_Data, fees };
  }

  return Promise.reject('Program not found');
};

const VIB_FSDI_Program: ProgramRequirementsFees = {
  programName: 'Full Stack Development Immersive',
  programCode: 'FSDI',
  courses: [],
  courseNote: '15 clock hours = 1 semester units/credit',
  fees: [],
};

const VIB_MSCT_Program: ProgramRequirementsFees = {
  programName: 'Master	of	Science	in	Communication	and	Technology',
  programCode: 'MSCT',
  courses: [],
  courseNote: 'Note: 45 clock hours = 3 semester units/credits',
  fees: [],
};

const VIB_MSIM_Program: ProgramRequirementsFees = {
  programName: 'Master of Science in International Management',
  programCode: 'MSIM',
  courses: [],
  courseNote: 'Note: 45 clock hours = 3 semester units/credits',
  fees: [],
};

const VIB_BSGM_Program: ProgramRequirementsFees = {
  programName: 'Bachelor of Science in Global Management',
  programCode: 'BSGM',
  courses: [],
  courseNote: 'Note: 45 clock hours = 3 semester units/credits',
  fees: [],
};

const FSDI_Couses_Data = {
  courses: [
    {
      courseName: 'Introductory HTML & CSS',
      courseHours: 15,
    },
    {
      courseName: 'Intermediate HTML & CSS',
      courseHours: 15,
    },
    {
      courseName: 'Programming Fundamentals',
      courseHours: 15,
    },
    {
      courseName: 'JavaScript Fundamentals',
      courseHours: 15,
    },
    {
      courseName: 'jQuery Fundamentals',
      courseHours: 15,
    },
    {
      courseName: 'JavaScript and jQuery Solutions',
      courseHours: 15,
    },
    {
      courseName: 'Introductory React',
      courseHours: 15,
    },
    {
      courseName: 'Introductory Python',
      courseHours: 15,
    },
    {
      courseName: 'Intermediate React',
      courseHours: 15,
    },
    {
      courseName: 'Introductory Web API w/ASP.net/Flask',
      courseHours: 15,
    },
    {
      courseName: 'Intermediate Python and Flask',
      courseHours: 15,
    },
    {
      courseName: 'Introductory Django',
      courseHours: 15,
    },
    {
      courseName: 'Intermediate Django',
      courseHours: 15,
    },
    {
      courseName: 'Algorithms & Data Structures',
      courseHours: 15,
    },
    {
      courseName: 'User Experience & Responsive Design',
      courseHours: 15,
    },
    {
      courseName: 'Software Dev. Methodology Fundamentals',
      courseHours: 15,
    },
    {
      courseName: 'Agile Architecture & Software Project Management',
      courseHours: 30,
    },
    {
      courseName: 'Capstone 1: User Experience',
      courseHours: 30,
    },
    {
      courseName: 'Capstone 2: Agile Methodology & Arch.',
      courseHours: 30,
    },
    {
      courseName: 'Capstone 3: Project Management',
      courseHours: 30,
    },
  ],
};

const MSCT_Courses_Data = {
  courses: [
    {
      courseName: 'Globalization and	the	New	Technology',
      courseHours: 45,
    },
    {
      courseName: 'Distance	Activity Management',
      courseHours: 45,
    },
    {
      courseName: 'Training	and	Dev. of	Dist.	Activity	Pros.',
      courseHours: 45,
    },
    {
      courseName: 'Planning	and	Dev. Of	DAC	Networks',
      courseHours: 45,
    },
    {
      courseName: 'Strategic Vision',
      courseHours: 45,
    },
    {
      courseName: 'Telecom. and the	Global E-village 1',
      courseHours: 45,
    },
    {
      courseName: 'Telecom. and the	Global E-village	2',
      courseHours: 45,
    },
    {
      courseName: 'Risk	Management and Conflict Resolution',
      courseHours: 45,
    },
    {
      courseName: 'Knowledge Mgmt. and Intellectual Prop.',
      courseHours: 45,
    },
    {
      courseName: 'Human and Emotional	Comp. for	Net. Org.',
      courseHours: 45,
    },
    {
      courseName: 'Quality and Productivity',
      courseHours: 45,
    },
    {
      courseName: 'Client Service and Global Competitiveness',
      courseHours: 45,
    },
    {
      courseName: 'Directed Research 1',
      courseHours: 45,
    },
    {
      courseName: 'Directed Research 2',
      courseHours: 45,
    },
    {
      courseName: 'Directed Research 3',
      courseHours: 45,
    },
  ]
};

const MSIM_Courses_Data = {
  courses: [
    {
      courseName: 'Globalization and the New Economy',
      courseHours: 45,
    },
    {
      courseName: 'Workforce Dynamics and Global Comp.',
      courseHours: 45,
    },
    {
      courseName: 'Systems Approach and Methodology',
      courseHours: 45,
    },
    {
      courseName: 'Strategic Vision and Planning',
      courseHours: 45,
    },
    {
      courseName: 'Leadership and Human Competence',
      courseHours: 45,
    },
    {
      courseName: 'Responsible Mgmt. of Environ. Quality',
      courseHours: 45,
    },
    {
      courseName: 'Productivity and Health Management',
      courseHours: 45,
    },
    {
      courseName: 'Networked Org. and Distance Activity',
      courseHours: 45,
    },
    {
      courseName: 'Telecom. and the Global E-village',
      courseHours: 45,
    },
    {
      courseName: 'Benchmarking and Client Service',
      courseHours: 45,
    },
    {
      courseName: 'Managerial Excellence and Total Quality',
      courseHours: 45,
    },
    {
      courseName: 'Tech. Transfer and Knowledge Mgmt.',
      courseHours: 45,
    },
    {
      courseName: 'Directed Research 1',
      courseHours: 45,
    },
    {
      courseName: 'Directed Research 2',
      courseHours: 45,
    },
    {
      courseName: 'Directed Research 3',
      courseHours: 45,
    },
  ]
};

const BSGM_Courses_Data = {
  courses: [
    {
      courseName: 'Algebra 1',
      courseHours: 45,
    },
    {
      courseName: 'Algebra 2',
      courseHours: 45,
    },
    {
      courseName: 'Quantitative Research Methods',
      courseHours: 45,
    },
    {
      courseName: 'Introduction to Art',
      courseHours: 45,
    },
    {
      courseName: 'Intro to Philosophy and Ethics',
      courseHours: 45,
    },
    {
      courseName: 'World History',
      courseHours: 45,
    },
    {
      courseName: 'Political Science',
      courseHours: 45,
    },
    {
      courseName: 'Introduction to Public Health',
      courseHours: 45,
    },
    {
      courseName: 'Environmental Systems',
      courseHours: 45,
    },
    {
      courseName: 'English 1',
      courseHours: 45,
    },
    {
      courseName: 'English 2',
      courseHours: 45,
    },
    {
      courseName: 'Speech and Oral Communication',
      courseHours: 45,
    },
    {
      courseName: 'Intro to Global Business',
      courseHours: 45,
    },
    {
      courseName: 'Global Business Models',
      courseHours: 45,
    },
    {
      courseName: 'Legal Issues for Global Business',
      courseHours: 45,
    },
    {
      courseName: 'Business Plan Development 1',
      courseHours: 45,
    },
    {
      courseName: 'Alternative Dispute Resolution',
      courseHours: 45,
    },
    {
      courseName: 'Small Business Creation',
      courseHours: 45,
    },
    {
      courseName: 'Business Plan Development 2',
      courseHours: 45,
    },
    {
      courseName: 'Principles of Global Management',
      courseHours: 45,
    },
    {
      courseName: 'International Managerial Leadership',
      courseHours: 45,
    },
    {
      courseName: 'International Human Resource Mgmt.',
      courseHours: 45,
    },
    {
      courseName: 'Strategic Management',
      courseHours: 45,
    },
    {
      courseName: 'Risk Management and Security',
      courseHours: 45,
    },
    {
      courseName: 'Production and Operations Mgmt.',
      courseHours: 45,
    },
    {
      courseName: 'Sustainability',
      courseHours: 45,
    },
    {
      courseName: 'Accounting',
      courseHours: 45,
    },
    {
      courseName: 'Finance',
      courseHours: 45,
    },
    {
      courseName: 'Introduction to Global Systems',
      courseHours: 45,
    },
    {
      courseName: 'Visioning and Creativity',
      courseHours: 45,
    },
    {
      courseName: 'Global Demographics and Gen. Dynamics',
      courseHours: 45,
    },
    {
      courseName: 'Systems Thinking and Approach',
      courseHours: 45,
    },
    {
      courseName: 'Information Systems and Technology',
      courseHours: 45,
    },
    {
      courseName: 'Global Competitiveness',
      courseHours: 45,
    },
    {
      courseName: 'Intro to global Entrepreneurship',
      courseHours: 45,
    },
    {
      courseName: 'Global Venture Development',
      courseHours: 45,
    },
    {
      courseName: 'E-commerce and Social Media',
      courseHours: 45,
    },
    {
      courseName: 'Introduction to Marketing',
      courseHours: 45,
    },
    {
      courseName: 'Global marketing Research',
      courseHours: 45,
    },
    {
      courseName: 'Global Advertising',
      courseHours: 45,
    },
    {
      courseName: 'Practicum and Directed Research 1',
      courseHours: 45,
    },
    {
      courseName: 'Practicum and Directed Research 2',
      courseHours: 45,
    },
  ]  
};
