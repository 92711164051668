
export interface StudentProgram {
	id: number;
	program: string;
}

export interface Program {
	id?: number;
	name: string;
	shortName?: string;
	length: number;
	unit: string;
	schoolId: number | string;
	clockHours: number | string;
	partTime: boolean;
	enabled?: boolean;
}

export interface ProgramStartDate {
	id?: number;
	programId: string | number;
	startDate: any;
	endDate: any;
}

export interface PeriodSchool {
	id?: number;
	name?: string;
	startDate: any;
	endDate: any;
}

export interface ProgramShift {
	id?: number;
	name: string;
	programId: string | number;
}

export interface Fee {
	id: number;
	name: string;
	key: string;
	school: string[] | number[];
	nameSpanish:string;
}

export interface FeeValue {
	id?: number;
	feeKey?: string;
	fee: Fee;
	program: string | number;
	school: string | number;
	description: string;
	amount: number;
	regular: boolean;
	va: boolean;
	descSpanish: string;
}

export interface ScheduleDay {
	id?: number;
	schoolId: number;
	programId: number;
	active: boolean;
	day: number;
	fromTime: string;
	toTime: string;
}

// Days of the week for ScheduleDays
export enum Days {
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
	Sunday,
}
