import { useState, useCallback } from 'react';
import html2canvas from 'html2canvas';

export const useGenerateImage = () => {
  const [loading, setLoading] = useState(false);

  const generateImage = useCallback(async (content: HTMLElement): Promise<string> => {
    setLoading(true);

    const printMediaQuery = 'print';
    const originalMatchMedia = window.matchMedia;
    window.matchMedia = (query: string) => ({
      matches: query === printMediaQuery,
      media: query,
      addListener: () => {},
      removeListener: () => {},
    }) as any;

    const canvas = await html2canvas(content, { useCORS: true });
    const imgData = canvas.toDataURL('image/png');

    window.matchMedia = originalMatchMedia;
    setLoading(false);

    return imgData;
  }, []);

  return { generateImage, loading };
};
