import { useContext, useEffect, useState } from "react";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import usePageSignature from "../../../../hooks/pageSignature";
import { Header } from "../header";
import TextField from "../../../../components/shared/Input/textField";
import { ApplicationForm } from "../../../../models/ApplicationForm.model";
import { useForm } from "react-hook-form";
import { AlertType } from "../../../../models/AlertType.enum";
import globalContext from "../../../../context/globalContext";
import signedFormService from "../../../../services/signedForm.service";
import { SDGKUFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { SDGKUProgram } from "../../../../models/Enums";
import { SignedForm } from "../../../../models/Forms.model";
import { FinishPage, TokenError } from "../enrollment/sdgku.extrapages";
import "../../forms.scss";
import "../index.css"
import "../hybridDisclosure/sdgkuHybridDisclosure.scss"

const HybridDisclosure = (props: any) => {
    const [tokenError, setTokenError] = useState(false);
    const [viewController, setViewController] = useState<SignedPageData>(null!);
    const { saveForm, getSignature, getInitials } = usePageSignature(viewController);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const { setLoading, showAlert } = useContext(globalContext);

    const [fullStudentName, setFullStudentName] = useState("");

    const {
        register,
        formState: { errors },
        setValue,
    } = useForm<ApplicationForm>();

    const loadInitialData = async () => {
        const params = new URLSearchParams(props.location.search);
        const token = params.get("token");
        const studentID = params.get("id");

        if (token && studentID) {
            loadForm(+studentID, token);
        } else {
            showAlert("Invalid token", AlertType.Error);
            setTokenError(true);
        }
    };

    const loadForm = async (studentId: number, token: string) => {
        setLoading(true);
        try {
            const resp = await signedFormService.getFormData(studentId, SignedFormKey.HYBRID_STATEMENT, token);
            const program: SDGKUProgram = resp.data.programId;
            if (resp?.status === HttpStatusCode.Ok) {
                initializeViewController(resp?.data, program, token);
            } else if (resp?.status === HttpStatusCode.Forbidden) {
                showAlert("Invalid token", AlertType.Error);
                setTokenError(true);
            }
        } catch {
            showAlert("Unexpected error", AlertType.Error);
            setTokenError(true);
        } finally {
            setLoading(false);
        }
    };

    const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
        if (!formData) return;

        setValue("student.firstName", formData.student.firstName);
        setValue("student.lastName", formData.student.lastName);
        setFullStudentName(`${formData.student.firstName} ${formData.student.lastName}`);

        setViewController(new SignedPageData(SignedFormKey.HYBRID_STATEMENT, program, formData, token, setEnableSubmit));
    };

    const submitForm = async () => {
        if (await saveForm(SDGKUFormsPage.HYBRID_STATEMENT, true)) {
            setCompleted(true);
        } else {
            showAlert("Error while saving. Please verify all field are completed", AlertType.Error);
        }
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    if (tokenError) return <TokenError />;

    if (completed) return (
        <>
            <div className="doc-area">
                <Header />
                <FinishPage pageMessage="Hybrid Disclosure" />
            </div>
        </>
    );

    return (
        <div className="HybrdDisclosure mx-auto p-3 doc-area">
            <Header />
            <div className="doc-content pt-3">
                <h3 className="title">HYBRID STATEMENT OF UNDERSTANDING</h3>
                <h4 className="sub-title">HYBRID MODALITY ONLY</h4>

                <div className="text-container">
                    <div className="disclosure-paragraph">
                        <p>I acknowledge receiving the SDGKU program Veterans Information Bulletin (VIB),
                            which includes my academic plan.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-0")}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I understand that I am enrolled in a hybrid program and have received my hybrid
                            schedule for the entire program.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-1", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>

                    <div className="disclosure-paragraph">
                        <p>I am accountable for adhering to the designated hybrid schedule. Exceptions will
                            only be considered if accompanied by substantiate documentations and receive
                            written approval from the Chief Academic Officer.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-2", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I understand that if I do not attend the scheduled hybrid class, my enrollment will
                            automatically be changed from in-residence to distance education. Being
                            converted to distance education will impact my Department of Veterans Affairs
                            Educational Benefits.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-3", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I understand overpayment is my responsibility, and I will owe the Department of
                            Veterans Affairs money.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-4", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I understand that failing to report enrollment changes (e.g., stop attending a class)
                            could result in delays, overpayments, or termination of my Department of
                            Veterans Affairs Educational Benefits.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-5", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I understand that I cannot count the SELF-PACED or ONLINE REMEDIAL COURSES
                            units towards my education benefits.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-6", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I acknowledge that SDGKU has a resource for employment career services,
                            including job readiness and placement opportunities.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-7", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I acknowledge that the SDGKU grievance policy is outlined in the school's catalog,
                            and I commit to adhering to the stipulations outlined in the policy.</p>
                        <div className="initials-Pad">
                            <span className="position-relative">{getInitials("main-8", true)}</span>
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div style={{ maxWidth: "50ch" }}>
                        <TextField disabled errors={errors} label="Student Name - Print" field="student.firstName" value={fullStudentName} type="text" register={register} />
                    </div>
                    <div className="position-relative d-flex flex-row">
                        <div className="pad">{getSignature()}</div>
                    </div>

                    <div className="text-center pb-2 mb-3 mt-3">
                        <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
                            Save and Complete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HybridDisclosure;