import http from "./http-common";
import { Document } from '../models/Document.model';
import { UploadRequest } from "../models/uploadRequest.model";

export enum UploadRequestGenerationType {
    Send = 1,
    GenerateLink = 2,
}

class UploadRequestService {
    async generateUploadRequest(uploadRequest: UploadRequest, type: UploadRequestGenerationType) {
        try {
            // map UploadRequest model to backend expected format/names
            let data = {
                student_id: uploadRequest.studentId,
                email_to: uploadRequest.to,
                subject: uploadRequest.subject,
                message: uploadRequest.body,
                notify_to: uploadRequest.cc,
                request_files: uploadRequest.requestedFileTypes,
                due_date: uploadRequest.dueDate,
                reply_to: uploadRequest.replyTo,
                type: type
            };

            let response = await http.post(`/upload_requests/`, data);
            return response;

        } catch (error) {
            http.fa360.displayError("Unexpected error while creating upload request");
        }
    }

    async getUploadRequest(token: string) {
        try {
            let response = await http.get(`/upload_requests/full/?token=${token}`);
            return response;
        } catch (error: any) {
            http.fa360.displayError("Unexpected error while getting upload request"); 
            return error?.response;
        }
    }

    async getUploadRequests() {
        try {
            let response = await http.get(`/upload_requests/`);
            return response;
            
        } catch (error: any) {
            http.fa360.displayError("Unexpected error while getting upload request"); 
            return error?.response;
        }
    }

    async makeCompleted(token: string) {
        try {
            let response = await http.post(`/upload_requests/mark_completed/?token=${token}`);
            return response;
        } catch (error) {
            http.fa360.displayError("The files were saved, but the request could not be marked as completed");
        }
    }

    async uploadDocument(token: string, doc: Document) {
        try {
            let formData = new FormData();
            formData.append('file', doc.file);
            formData.append('file_name', doc.fileName);
            formData.append('file_size', doc.fileSize.toString());
            formData.append('status', doc.status.toString());
            formData.append('student_id', doc.student.toString());
            formData.append('upload_type', doc.uploadType.toString());
            doc.documentType && formData.append('document_type', doc.documentType.toString());
            doc.documentTemplate && formData.append('document_template', doc.documentTemplate.toString());

            let response = await http.post(`/upload_requests/upload_document/?token=${token}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            return response;
        }
        catch (error) {
            http.fa360.displayError("Unexpected error while uploading document");
            return undefined;
        }
    }    

}

export default new UploadRequestService();