
import http from "./http-common";
import { DocumentTemplate } from "../models/Document.model";

class UploadFilesService {
  upload(doc: DocumentTemplate, a?: any) {
    let formData = new FormData();
    formData.append("file", doc.file);
    formData.append("name", doc.name);
    if (doc.school) formData.append("school", doc.school.toString());
    if (doc.documentType) formData.append("document_type", doc.documentType.toString());

    try {
      return http.post("/documents_templates/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    } catch (e) {
      http.fa360.displayError("Unexpected error while uploading file");
    }
  }
}


export default new UploadFilesService();
