import TitleBar from "../../components/shared/titleBar/titleBar";
import DocumentChecklistViewer from "../../components/documentChecklist/checklistViewer";

const DocumentChecklistPage = () => {
    return (
        <div className="home-page pd-0">
            <TitleBar header="Manage Checklists" subheader="Presented on student documents" />

            <DocumentChecklistViewer ></DocumentChecklistViewer>
        </div>
    )
}

export default DocumentChecklistPage;