import { forwardRef, useEffect, useImperativeHandle } from "react";
import { CobaFinancialAidPageProps } from "../cobaFinancialAidPageProps";
import usePageSignature from "../../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../../models/Forms.enum";

export const FinancialAidPage5 = forwardRef(({data, visible}:CobaFinancialAidPageProps, ref) => {
    const { saveForm, getInitials, getAcknowledge } = usePageSignature(data, false);

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(COBAFormsPage.FinancialAid5)
        },
    }));

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="financialAid-page5">
            <h3 className="title">COBA ACADEMY SMS AND PORTAL POLICY</h3>

            <p className="subtitle">COBA ACADEMY TEXT MESSAGING PRIVATY POLICY (SMS)</ p>
            <p>SMS messaging, also known as text messaging, is an additional method of communication that Coba Academy will use to
                communicate with Coba Community. SMS messaging will be utilized by authorized Academy officials to relay information
                for things such as emergency notification, campus closures, account information, academic advising, registration, financial
                aid messages, and campus events that are time-sensitive in nature.</p>

            <p>You have the option to opt-out of text message participation from Coba on your mobile device, as well as by email to Coba
                regarding the Coba admission, financial aid, business office and placement processes. Subscribing or unsubscribing to this
                service does not affect the required application for admission nor does it circumvent prescribed admission requirements for
                Coba.</p>

            <p>If you consent to receive text messages from Coba, the Admissions Office may send you periodic texts regarding your
                application status, financial aid deadlines, and event details. This service is to provide you information via text messaging
                about matters that Coba deems helpful and pertinent to the admissions and financial aid process.</p>

            <p>Individuals must opt-out or otherwise acquiesce to the receipt of text messaging communication. Students’ personal details
                held by Coba are updated with any changes to their mobile phone number as instructed, directed, or otherwise informed by
                the student.</p>

            <p>All costs associated with receiving a text message are the responsibility of the individual receiving the message. Receiving
                mobile text messages from Coba is completely free of charge. However, standard text messaging rates from your wireless
                carrier still apply. Check with your mobile operator for details on receiving text messages (SMS).</p>

            <p className="subtitle">TEXT MESSAGING GUIDELINES</p>
            <p>As technology is ever changing, Coba cannot guarantee that the information visitors transmit electronically is completely
                secure. Coba makes every effort to protect the privacy of its users, however, the Academy cannot guarantee the security of
                information that users transmit, and users transmit such information at their own risk.</p>

            <p>Acceptance of this policy is a condition of the use of the text messaging services and users agree to be bound by its terms
                and conditions. The resolution of any disputes concerning this policy are subject to and governed by the laws of the State of
                California.</p>

            <p>All approved officials must abide by all other Coba policies and ensure:</p>
            <ul>
                <li>The message is valid</li>
                <li>The wording is appropriate; and</li>
                <li>The circulation list is correct</li>
            </ul>

            <p>Text messaging will be used as the sole means of communicating an essential message or announcement. The text message
                notification can be supplemented by some other means of communication as well, e.g., an email or paper notice to ensure
                that all students, including those without a mobile phone, receive the message.
            </p>

            <div className="initials">
                {getInitials('page5')}
            </div>

        </div>
    );
});