import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { COBAProgram } from '../../../../models/Enums';
import { CobaDisclosurePageProps } from './cobaDisclosurePageProps';
import usePageSignature from '../../../../hooks/pageSignature';
import { COBAFormsPage } from '../../../../models/Forms.enum';

const DisclosurePage4 = forwardRef(({ visible, data: viewController }: CobaDisclosurePageProps, ref) => {
  const { isFormValid, validateForm, saveForm, getSignature, getAcknowledge } = usePageSignature(viewController);

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(COBAFormsPage.Disclosure4);
    },
  }));

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        validateForm();
      }, 500);
    }
  }, [visible]);

  const getKit = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER_HYBRID:
        return (
          <div>
            <h5>Coba Academy Barbering Kit Disclosure 2024</h5>
            <table className="table table-bordered col-lg-8">
              <thead>
                <tr>
                  <th colSpan={3}>Kit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Item</td>
                  <td>Qty</td>
                  <td>Cost Per Unit</td>
                  <td>Total</td>
                </tr>
                <tr>
                  <td>Dylan Manikin</td>
                  <td>2</td>
                  <td> $ 73.00 </td>
                  <td> $ 146.00 </td>
                </tr>
                <tr>
                  <td>Deluxe Debra Manikin</td>
                  <td>1</td>
                  <td> $ 70.00 </td>
                  <td> $ 70.00 </td>
                </tr>
                <tr>
                  <td>Barber Case</td>
                  <td>1</td>
                  <td> $ 120.00 </td>
                  <td> $ 120.00 </td>
                </tr>
                <tr>
                  <td>30" Roller Duffle Bag</td>
                  <td>1</td>
                  <td> $ 40.00 </td>
                  <td> $ 40.00 </td>
                </tr>
                <tr>
                  <td>2800 Babyliss Blow Dryer</td>
                  <td>1</td>
                  <td> $ 90.00 </td>
                  <td> $ 90.00 </td>
                </tr>
                <tr>
                  <td>Babyliss 1" Spring Iron</td>
                  <td>1</td>
                  <td> $ 50.00 </td>
                  <td> $ 50.00 </td>
                </tr>
                <tr>
                  <td>Wahl 5 Star Senior</td>
                  <td>1</td>
                  <td> $ 130.00 </td>
                  <td> $ 130.00 </td>
                </tr>
                <tr>
                  <td>Wahl 8 pack color gaurds</td>
                  <td>1</td>
                  <td> $ 25.00 </td>
                  <td> $ 25.00 </td>
                </tr>
                <tr>
                  <td>Andis Slimline Pro Li Trimmer</td>
                  <td>1</td>
                  <td> $ 90.00 </td>
                  <td> $ 90.00 </td>
                </tr>
                <tr>
                  <td>Andis Pro Foil Shaver</td>
                  <td>1</td>
                  <td> $ 90.00 </td>
                  <td> $ 90.00 </td>
                </tr>
                <tr>
                  <td>Andis Cool Care</td>
                  <td>1</td>
                  <td> $ 15.00 </td>
                  <td> $ 15.00 </td>
                </tr>
                <tr>
                  <td>Barber Spray Bottle</td>
                  <td>1</td>
                  <td> $ 6.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>Large Mirror</td>
                  <td>1</td>
                  <td> $ 15.00 </td>
                  <td> $ 15.00 </td>
                </tr>
                <tr>
                  <td>Hair Color Kit</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>Babyliss 1" Flat Iron</td>
                  <td>2</td>
                  <td> $ 155.00 </td>
                  <td> $ 155.00 </td>
                </tr>
                <tr>
                  <td>Plastic Storage Box</td>
                  <td>1</td>
                  <td> $ 8.00 </td>
                  <td> $ 8.00 </td>
                </tr>
                <tr>
                  <td>Barber Cutting / Thinning Shear Set</td>
                  <td>1</td>
                  <td> $ 98.00 </td>
                  <td> $ 98.00 </td>
                </tr>
                <tr>
                  <td>Parker Razor</td>
                  <td>1</td>
                  <td> $ 25.00 </td>
                  <td> $ 25.00 </td>
                </tr>
                <tr>
                  <td>Derby Premium Blades</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Andis Clipper Oil</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Vent Brush</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Tapered Combs</td>
                  <td>12</td>
                  <td> $ 1.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Rat Tail Combs</td>
                  <td>12</td>
                  <td> $ 1.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Clipper Comb (1 black /1 white)</td>
                  <td>2</td>
                  <td> $ 3.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>Styptic Powder Vial</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Clipper Cleaning Brush</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Clubman Talc</td>
                  <td>1</td>
                  <td> $ 8.00 </td>
                  <td> $ 8.00 </td>
                </tr>
                <tr>
                  <td>Tint Brushes</td>
                  <td>2</td>
                  <td> $ 1.50 </td>
                  <td> $ 3.00 </td>
                </tr>
                <tr>
                  <td>Utsumi Carbon Fade Comb</td>
                  <td>1</td>
                  <td> $ 12.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Carbon Comb</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>6 pk Gator Clips</td>
                  <td>1</td>
                  <td> $ 12.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Plastic Bag</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Chemical Cape</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td>Shampoo Cape</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>Cutting Cape</td>
                  <td>1</td>
                  <td> $ 15.00 </td>
                  <td> $ 15.00 </td>
                </tr>
                <tr>
                  <td>Plastic Storage Box</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>Small Round Brush</td>
                  <td>1</td>
                  <td> $ 15.00 </td>
                  <td> $ 15.00 </td>
                </tr>
                <tr>
                  <td>Medium Round Brush</td>
                  <td>1</td>
                  <td> $ 16.00 </td>
                  <td> $ 16.00 </td>
                </tr>
                <tr>
                  <td>Barber Jacket (Coba Academy Embroidered)</td>
                  <td>1</td>
                  <td> $ 45.00 </td>
                  <td> $ 45.00 </td>
                </tr>
                <tr>
                  <td>Suavecito Pomade - Original - 4 oz</td>
                  <td>1</td>
                  <td> $ 14.00 </td>
                  <td> $ 14.00 </td>
                </tr>
                <tr>
                  <td>Suavecito Firme Hold Styling Gel - 8 fl oz</td>
                  <td>1</td>
                  <td> $ 12.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Suavecito Menthol Aftershave - 8 fl oz</td>
                  <td>1</td>
                  <td> $ 12.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Suavecito Shave Gel - 8 fl oz</td>
                  <td>1</td>
                  <td> $ 16.00 </td>
                  <td> $ 16.00 </td>
                </tr>
                <tr>
                  <td>Suavecito Apron</td>
                  <td>1</td>
                  <td> $ 40.00 </td>
                  <td> $ 40.00 </td>
                </tr>
                <tr>
                  <td>Coba Academy Tshirt</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Textbook</b>
                  </td>
                </tr>
                <tr>
                  <td>Milady&nbsp;&nbsp;&nbsp;Standard Barbering textbook, workbook, and online access bundle</td>
                  <td>1</td>
                  <td> $440.00 </td>
                  <td> $440.00 </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td> $2,000 </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case COBAProgram.BARBER:
          return (
            <div>
              <h5>Coba Academy Barbering Kit Disclosure 2024</h5>
              <table className="table table-bordered col-lg-8">
                <thead>
                  <tr>
                    <th colSpan={3}>Kit</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Item</td>
                    <td>Qty</td>
                    <td>Cost Per Unit</td>
                    <td>Total</td>
                  </tr>
                  <tr>
                    <td>Dylan Manikin</td>
                    <td>2</td>
                    <td> $ 73.00 </td>
                    <td> $ 146.00 </td>
                  </tr>
                  <tr>
                    <td>Deluxe Debra Manikin</td>
                    <td>1</td>
                    <td> $ 70.00 </td>
                    <td> $ 70.00 </td>
                  </tr>
                  <tr>
                    <td>Barber Case</td>
                    <td>1</td>
                    <td> $ 120.00 </td>
                    <td> $ 120.00 </td>
                  </tr>
                  <tr>
                    <td>30" Roller Duffle Bag</td>
                    <td>1</td>
                    <td> $ 40.00 </td>
                    <td> $ 40.00 </td>
                  </tr>
                  <tr>
                    <td>2800 Babyliss Blow Dryer</td>
                    <td>1</td>
                    <td> $ 90.00 </td>
                    <td> $ 90.00 </td>
                  </tr>
                  <tr>
                    <td>Babyliss 1" Spring Iron</td>
                    <td>1</td>
                    <td> $ 50.00 </td>
                    <td> $ 50.00 </td>
                  </tr>
                  <tr>
                    <td>Wahl 5 Star Senior</td>
                    <td>1</td>
                    <td> $ 130.00 </td>
                    <td> $ 130.00 </td>
                  </tr>
                  <tr>
                    <td>Wahl 8 Pack Color Gaurds</td>
                    <td>1</td>
                    <td> $ 25.00 </td>
                    <td> $ 25.00 </td>
                  </tr>
                  <tr>
                    <td>Andis Slimline Pro li Trimmer</td>
                    <td>1</td>
                    <td> $ 90.00 </td>
                    <td> $ 90.00 </td>
                  </tr>
                  <tr>
                    <td>Andis Pro Foil Shaver</td>
                    <td>1</td>
                    <td> $ 90.00 </td>
                    <td> $ 90.00 </td>
                  </tr>
                  <tr>
                    <td>Andis Cool Care</td>
                    <td>1</td>
                    <td> $ 15.00 </td>
                    <td> $ 15.00 </td>
                  </tr>
                  <tr>
                    <td>Barber Spray Bottle</td>
                    <td>1</td>
                    <td> $ 6.00 </td>
                    <td> $ 6.00 </td>
                  </tr>
                  <tr>
                    <td>Large Mirror</td>
                    <td>1</td>
                    <td> $ 15.00 </td>
                    <td> $ 15.00 </td>
                  </tr>
                  <tr>
                    <td>Hair Color Kit</td>
                    <td>1</td>
                    <td> $ 20.00 </td>
                    <td> $ 20.00 </td>
                  </tr>
                  <tr>
                    <td>Babyliss 1" Flat Iron</td>
                    <td>1</td>
                    <td> $ 155.00 </td>
                    <td> $ 155.00 </td>
                  </tr>
                  <tr>
                    <td>Plastic Storage Box</td>
                    <td>1</td>
                    <td> $ 8.00 </td>
                    <td> $ 8.00 </td>
                  </tr>
                  <tr>
                    <td>Barber Cutting / Thinning Shear Set</td>
                    <td>1</td>
                    <td> $ 98.00 </td>
                    <td> $ 98.00 </td>
                  </tr>
                  <tr>
                    <td>Parker Razor</td>
                    <td>1</td>
                    <td> $ 25.00 </td>
                    <td> $ 25.00 </td>
                  </tr>
                  <tr>
                    <td>Derby Premium Blades</td>
                    <td>1</td>
                    <td> $ 5.00 </td>
                    <td> $ 5.00 </td>
                  </tr>
                  <tr>
                    <td>Andis Clipper Oil</td>
                    <td>1</td>
                    <td> $ 4.00 </td>
                    <td> $ 4.00 </td>
                  </tr>
                  <tr>
                    <td>Vent Brush</td>
                    <td>1</td>
                    <td> $ 5.00 </td>
                    <td> $ 5.00 </td>
                  </tr>
                  <tr>
                    <td>Tapered Combs</td>
                    <td>12</td>
                    <td> $ 1.00 </td>
                    <td> $ 12.00 </td>
                  </tr>
                  <tr>
                    <td>Rat Tail Combs</td>
                    <td>12</td>
                    <td> $ 1.00 </td>
                    <td> $ 12.00 </td>
                  </tr>
                  <tr>
                    <td>Clipper Comb (1 black/1 white)</td>
                    <td>2</td>
                    <td> $ 3.00 </td>
                    <td> $ 6.00 </td>
                  </tr>
                  <tr>
                    <td>Styptic Powder Vial</td>
                    <td>1</td>
                    <td> $ 4.00 </td>
                    <td> $ 4.00 </td>
                  </tr>
                  <tr>
                    <td>Clipper Cleaning Brush</td>
                    <td>1</td>
                    <td> $ 4.00 </td>
                    <td> $ 4.00 </td>
                  </tr>
                  <tr>
                    <td>Clubman Talc</td>
                    <td>1</td>
                    <td> $ 8.00 </td>
                    <td> $ 8.00 </td>
                  </tr>
                  <tr>
                    <td>Tint Brushes</td>
                    <td>2</td>
                    <td> $ 1.50 </td>
                    <td> $ 3.00 </td>
                  </tr>
                  <tr>
                    <td>Utsumi Carbon Fade Comb</td>
                    <td>1</td>
                    <td> $ 12.00 </td>
                    <td> $ 12.00 </td>
                  </tr>
                  <tr>
                    <td>Carbon Comb</td>
                    <td>1</td>
                    <td> $ 10.00 </td>
                    <td> $ 10.00 </td>
                  </tr>
                  <tr>
                    <td>6 pk Gator Clips</td>
                    <td>1</td>
                    <td> $ 12.00 </td>
                    <td> $ 12.00 </td>
                  </tr>
                  <tr>
                    <td>Plastic Bag</td>
                    <td>1</td>
                    <td> $ 5.00 </td>
                    <td> $ 5.00 </td>
                  </tr>
                  <tr>
                    <td>Chemical Cape</td>
                    <td>1</td>
                    <td> $ 30.00 </td>
                    <td> $ 30.00 </td>
                  </tr>
                  <tr>
                    <td>Shampoo Cape</td>
                    <td>1</td>
                    <td> $ 10.00 </td>
                    <td> $ 10.00 </td>
                  </tr>
                  <tr>
                    <td>Cutting Cape</td>
                    <td>1</td>
                    <td> $ 15.00 </td>
                    <td> $ 15.00 </td>
                  </tr>
                  <tr>
                    <td>Plastic Storage Box</td>
                    <td>1</td>
                    <td> $ 10.00 </td>
                    <td> $ 10.00 </td>
                  </tr>
                  <tr>
                    <td>Small Round Brush</td>
                    <td>1</td>
                    <td> $ 15.00 </td>
                    <td> $ 15.00 </td>
                  </tr>
                  <tr>
                    <td>Medium Round Brush</td>
                    <td>1</td>
                    <td> $ 16.00 </td>
                    <td> $ 16.00 </td>
                  </tr>
                  <tr>
                    <td>Barber Jacket (Coba Academy Embroidered)</td>
                    <td>1</td>
                    <td> $ 45.00 </td>
                    <td> $ 45.00 </td>
                  </tr>
                  <tr>
                    <td>Suavecito Pomade - Original - 4oz</td>
                    <td>1</td>
                    <td> $ 14.00 </td>
                    <td> $ 14.00 </td>
                  </tr>
                  <tr>
                    <td>Suavecito Firme Hold Styling Gel - 8 fl oz</td>
                    <td>1</td>
                    <td> $ 12.00 </td>
                    <td> $ 12.00 </td>
                  </tr>
                  <tr>
                    <td>Suavecito Menthol Aftershave - 8 fl oz</td>
                    <td>1</td>
                    <td> $ 12.00 </td>
                    <td> $ 12.00 </td>
                  </tr>
                  <tr>
                    <td>Suavecito Shave Gel - 8 fl oz</td>
                    <td>1</td>
                    <td> $ 16.00 </td>
                    <td> $ 16.00 </td>
                  </tr>
                  <tr>
                    <td>Suavecito Apron</td>
                    <td>1</td>
                    <td> $ 40.00 </td>
                    <td> $ 40.00 </td>
                  </tr>
                  <tr>
                    <td>Coba Academy Tshirt</td>
                    <td>1</td>
                    <td> $ 30.00 </td>
                    <td> $ 30.00 </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <b>Textbook</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Milady&nbsp;&nbsp;&nbsp;Standard Barbering textbook, workbook, and online access bundle</td>
                    <td>1</td>
                    <td> $440.00 </td>
                    <td> $440.00 </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>$ 2,000 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
      case COBAProgram.COSMETOLOGY:
        return (
          <div>
            <h5>Coba Academy Cosmetology Kit Disclosure 2024</h5>
            <table className="table table-bordered col-lg-8">
              <thead>
                <tr>
                  <th colSpan={4}>Kit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Item</td>
                  <td>Qty</td>
                  <td>Cost Per Unit</td>
                  <td>Total</td>
                </tr>
                <tr>
                  <td>Deluxe Debra Manikin</td>
                  <td>2</td>
                  <td> $ 70.00 </td>
                  <td> $ 140.00 </td>
                </tr>
                <tr>
                  <td>11 Piece Makeup Brush Set</td>
                  <td>1</td>
                  <td> $ 35.00 </td>
                  <td> $ 35.00 </td>
                </tr>
                <tr>
                  <td>Chemical Proof Apron (Coba Academy&nbsp;&nbsp;&nbsp;Embroidered)</td>
                  <td>1</td>
                  <td> $ 80.00 </td>
                  <td> $ 80.00 </td>
                </tr>
                <tr>
                  <td>Coba Academy T-Shirt</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td>Large Roller Bag</td>
                  <td>1</td>
                  <td> $ 45.00 </td>
                  <td> $ 45.00 </td>
                </tr>
                <tr>
                  <td>Manikin Clamp</td>
                  <td>1</td>
                  <td> $ 9.00 </td>
                  <td> $ 9.00 </td>
                </tr>
                <tr>
                  <td>Babyliss Pro 2800 Dryer</td>
                  <td>1</td>
                  <td> $ 90.00 </td>
                  <td> $ 90.00 </td>
                </tr>
                <tr>
                  <td>Babyliss 1 1/4" Spring Iron</td>
                  <td>1</td>
                  <td> $ 60.00 </td>
                  <td> $ 60.00 </td>
                </tr>
                <tr>
                  <td>Babyliss 1" Marcel Iron</td>
                  <td>1</td>
                  <td> $ 50.00 </td>
                  <td> $ 50.00 </td>
                </tr>
                <tr>
                  <td>Babyliss Pro 1" Flat Iron</td>
                  <td>1</td>
                  <td> $ 155.00 </td>
                  <td> $ 155.00 </td>
                </tr>
                <tr>
                  <td>Wahl Clipper/Trimmer Combo</td>
                  <td>1</td>
                  <td> $ 99.00 </td>
                  <td> $ 99.00 </td>
                </tr>
                <tr>
                  <td>23 Piece Hair Color Kit</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>Pick Attachement for Dryer</td>
                  <td>1</td>
                  <td> $ 6.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>Double Duty Tint Bowl</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Spray Bottle</td>
                  <td>1</td>
                  <td> $ 2.50 </td>
                  <td> $ 2.50 </td>
                </tr>
                <tr>
                  <td>Bleach Proof Cape</td>
                  <td>1</td>
                  <td> $ 25.00 </td>
                  <td> $ 25.00 </td>
                </tr>
                <tr>
                  <td>Long Cutting Cape</td>
                  <td>1</td>
                  <td> $ 15.00 </td>
                  <td> $ 15.00 </td>
                </tr>
                <tr>
                  <td>Deluxe Bendable Practice Hand</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>Shampoo Cape</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Plastic Storage Box</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Misaki Shear Set</td>
                  <td>1</td>
                  <td> $ 70.00 </td>
                  <td> $ 70.00 </td>
                </tr>
                <tr>
                  <td>FROMM Hair Shaper</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>5 pack Shaper Blades</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Double Prong Clips</td>
                  <td>1</td>
                  <td> $ 6.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>Single Prong Clips</td>
                  <td>1</td>
                  <td> $ 6.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>Metal Duckbill Clips</td>
                  <td>2</td>
                  <td> $ 2.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Gator Clips</td>
                  <td>1</td>
                  <td> $ 6.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>3 Piece Tint Brush</td>
                  <td>1</td>
                  <td> $ 3.00 </td>
                  <td> $ 3.00 </td>
                </tr>
                <tr>
                  <td>Styling Combs</td>
                  <td>12</td>
                  <td> $ 1.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>Rat Tail Combs</td>
                  <td>12</td>
                  <td> $ 1.00 </td>
                  <td> $ 12.00 </td>
                </tr>
                <tr>
                  <td>12 Pack Large Butterfly Clamps</td>
                  <td>1</td>
                  <td> $ 3.00 </td>
                  <td> $ 3.00 </td>
                </tr>
                <tr>
                  <td>Teasing Brush</td>
                  <td>1</td>
                  <td> $ 1.50 </td>
                  <td> $ 1.50 </td>
                </tr>
                <tr>
                  <td>Black Clipper Comb</td>
                  <td>1</td>
                  <td> $ 1.00 </td>
                  <td> $ 1.00 </td>
                </tr>
                <tr>
                  <td>Plastic Storage Box</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Manicure Bowl</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Manicure Brush</td>
                  <td>1</td>
                  <td> $ 2.00 </td>
                  <td> $ 2.00 </td>
                </tr>
                <tr>
                  <td>6 Piece Manicure Kit w/Case</td>
                  <td>1</td>
                  <td> $ 14.00 </td>
                  <td> $ 14.00 </td>
                </tr>
                <tr>
                  <td>Silk Nail Wrap</td>
                  <td>2</td>
                  <td> $ 1.00 </td>
                  <td> $ 2.00 </td>
                </tr>
                <tr>
                  <td>Glass Dappen Dish</td>
                  <td>2</td>
                  <td> $ 1.00 </td>
                  <td> $ 2.00 </td>
                </tr>
                <tr>
                  <td>100 Pack Nail Tips w/Glue</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>3-Way Nail File</td>
                  <td>1</td>
                  <td> $ 1.00 </td>
                  <td> $ 1.00 </td>
                </tr>
                <tr>
                  <td>Mini Odorless Acrylic Kit</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>#4 Flat Nail Brush</td>
                  <td>1</td>
                  <td> $ 7.00 </td>
                  <td> $ 7.00 </td>
                </tr>
                <tr>
                  <td>Ziploc Bag</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>10 Piece Bone Comb Set</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>Round Roll Brush</td>
                  <td>1</td>
                  <td> $ 7.00 </td>
                  <td> $ 7.00 </td>
                </tr>
                <tr>
                  <td>Large Ceramic Brush</td>
                  <td>1</td>
                  <td> $ 8.00 </td>
                  <td> $ 8.00 </td>
                </tr>
                <tr>
                  <td>Medium Ceramic Brush</td>
                  <td>1</td>
                  <td> $ 8.00 </td>
                  <td> $ 8.00 </td>
                </tr>
                <tr>
                  <td>Small Ceramic Brush</td>
                  <td>1</td>
                  <td> $ 8.00 </td>
                  <td> $ 8.00 </td>
                </tr>
                <tr>
                  <td>Tunnel Vent Brush</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Large Paddle Brush</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Round Boar Brush</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>7 Row Styling Brush</td>
                  <td>1</td>
                  <td> $ 4.00 </td>
                  <td> $ 4.00 </td>
                </tr>
                <tr>
                  <td>Cushion Base Brush</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Colored Pencil 100&nbsp;&nbsp;&nbsp;- Whatever Black</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Colored Pencil 608&nbsp;&nbsp;&nbsp;- Limitless Brown</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever HD Skin All-in-Once Face&nbsp;&nbsp;&nbsp;Palette - Harmony -1</td>
                  <td>1</td>
                  <td> $ 85.00 </td>
                  <td> $ 85.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever HD Skin All-in-Once Face&nbsp;&nbsp;&nbsp;Palette - Harmony -2</td>
                  <td>1</td>
                  <td> $ 85.00 </td>
                  <td> $ 85.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever UHD Microsmooth Finishing&nbsp;&nbsp;&nbsp;Powder (loose)</td>
                  <td>1</td>
                  <td> $ 37.00 </td>
                  <td> $ 37.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M738</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M705</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;I648</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M546</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M608</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;ME612</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M631</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M650</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;I514</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M100</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M532</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;I514</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Refillable Metal Palette-&nbsp;&nbsp;&nbsp;Medium</td>
                  <td>1</td>
                  <td> $ 18.00 </td>
                  <td> $ 18.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Color Case - Flash&nbsp;&nbsp;&nbsp;Palette, Flash</td>
                  <td>1</td>
                  <td> $ 99.00 </td>
                  <td> $ 99.00 </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Textbook</b>
                  </td>
                </tr>
                <tr>
                  <td>Milady&nbsp;&nbsp;&nbsp;Standard Cosmetology textbook, workbook, and online access bundle </td>
                  <td>1</td>
                  <td> $440.00 </td>
                  <td> $440.00 </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td> $2,140 </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case COBAProgram.ESTHETICIAN:
        return (
          <div>
            <h5>Coba Academy Esthetics Kit Disclosure 2024</h5>
            <table className="table table-bordered col-lg-8">
              <thead>
                <tr>
                  <th colSpan={4}>Kit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Item</td>
                  <td>Qty</td>
                  <td>Cost Per Unit</td>
                  <td>Total</td>
                </tr>
                <tr>
                  <td>11 Piece Makeup Brush Set</td>
                  <td>1</td>
                  <td> $ 35.00 </td>
                  <td> $ 35.00 </td>
                </tr>
                <tr>
                  <td>Coba Academy T-Shirt</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td>Small Rolling Duffle</td>
                  <td>1</td>
                  <td> $ 35.00 </td>
                  <td> $ 35.00 </td>
                </tr>
                <tr>
                  <td>Medium Soft Mixing Bowl</td>
                  <td>2</td>
                  <td> $ 7.00 </td>
                  <td> $ 14.00 </td>
                </tr>
                <tr>
                  <td>Flat Massage Head</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td>Body Brush Set</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td>Plastic Storage Box</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Gold Stork Shear</td>
                  <td>1</td>
                  <td> $ 9.00 </td>
                  <td> $ 9.00 </td>
                </tr>
                <tr>
                  <td>Ardell Student Lash Kit</td>
                  <td>2</td>
                  <td> $ 9.00 </td>
                  <td> $ 18.00 </td>
                </tr>
                <tr>
                  <td>8 Piece Make Up Sponge</td>
                  <td>1</td>
                  <td> $ 8.00 </td>
                  <td> $ 8.00 </td>
                </tr>
                <tr>
                  <td>Eyelash Curler</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>Disposable Mascara Wands</td>
                  <td>1</td>
                  <td> $ 2.00 </td>
                  <td> $ 2.00 </td>
                </tr>
                <tr>
                  <td>DUO Lash Glue, Clear</td>
                  <td>1</td>
                  <td> $ 5.00 </td>
                  <td> $ 5.00 </td>
                </tr>
                <tr>
                  <td>Metal Mixing Palette &amp; Spatula</td>
                  <td>1</td>
                  <td> $ 10.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>Ardell Demi Whispies, Black</td>
                  <td>2</td>
                  <td> $ 12.00 </td>
                  <td> $ 24.00 </td>
                </tr>
                <tr>
                  <td>Comedone Extractor</td>
                  <td>2</td>
                  <td> $ 5.00 </td>
                  <td> $ 10.00 </td>
                </tr>
                <tr>
                  <td>White Fan Brush</td>
                  <td>2</td>
                  <td> $ 3.00 </td>
                  <td> $ 6.00 </td>
                </tr>
                <tr>
                  <td>Eyebrow Kit</td>
                  <td>1</td>
                  <td> $ 15.00 </td>
                  <td> $ 15.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Colored Pencil 100&nbsp;&nbsp;&nbsp;- Whatever Black</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Colored Pencil 608&nbsp;&nbsp;&nbsp;- Limitless Brown</td>
                  <td>1</td>
                  <td> $ 20.00 </td>
                  <td> $ 20.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever HD Skin All-in-Once Face&nbsp;&nbsp;&nbsp;Palette - Harmony -1</td>
                  <td>1</td>
                  <td> $ 85.00 </td>
                  <td> $ 85.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever HD Skin All-in-Once Face&nbsp;&nbsp;&nbsp;Palette - Harmony -2</td>
                  <td>1</td>
                  <td> $ 85.00 </td>
                  <td> $ 85.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever UHD Microsmooth Finishing&nbsp;&nbsp;&nbsp;Powder (loose)</td>
                  <td>1</td>
                  <td> $ 37.00 </td>
                  <td> $ 37.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M738</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M705</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;I648</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M546</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M608</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;ME612</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M631</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M650</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;I514</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M100</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;M532</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Artist Color Eye Shadow&nbsp;&nbsp;&nbsp;I514</td>
                  <td>1</td>
                  <td> $ 17.00 </td>
                  <td> $ 17.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Refillable Metal Palette-&nbsp;&nbsp;&nbsp;Medium</td>
                  <td>1</td>
                  <td> $ 18.00 </td>
                  <td> $ 18.00 </td>
                </tr>
                <tr>
                  <td>Make Up Forever Color Case - Flash&nbsp;&nbsp;&nbsp;Palette, Flash</td>
                  <td>1</td>
                  <td> $ 99.00 </td>
                  <td> $ 99.00 </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Dermalogica Skin Care</b>
                  </td>
                </tr>
                <tr>
                  <td>Special Cleansing Gel (16.9 oz)</td>
                  <td>1</td>
                  <td> $ 68.00 </td>
                  <td> $ 68.00 </td>
                </tr>
                <tr>
                  <td>Intensive Moisture Cleanser (5.1 oz)</td>
                  <td>1</td>
                  <td> $ 44.00 </td>
                  <td> $ 44.00 </td>
                </tr>
                <tr>
                  <td>Daily Microfoliant (2.6 oz)</td>
                  <td>1</td>
                  <td> $ 66.00 </td>
                  <td> $ 66.00 </td>
                </tr>
                <tr>
                  <td>PreCleanse (5.1 oz)</td>
                  <td>1</td>
                  <td> $ 49.00 </td>
                  <td> $ 49.00 </td>
                </tr>
                <tr>
                  <td>MultiVitamin Power Recovery Masque (2.5 oz)</td>
                  <td>1</td>
                  <td> $ 68.00 </td>
                  <td> $ 68.00 </td>
                </tr>
                <tr>
                  <td>Invisible Physical Defense SPF 30 (1.7 oz)</td>
                  <td>1</td>
                  <td> $ 49.00 </td>
                  <td> $ 49.00 </td>
                </tr>
                <tr>
                  <td>Multi-Active Toner (16 oz) </td>
                  <td>1</td>
                  <td> $ 33.00 </td>
                  <td> $ 33.00 </td>
                </tr>
                <tr>
                  <td>Active Moist (6 oz)</td>
                  <td>1</td>
                  <td> $ 63.00 </td>
                  <td> $ 63.00 </td>
                </tr>
                <tr>
                  <td>Calming Botanical Mixer (4 oz)</td>
                  <td>1</td>
                  <td> $ 40.00 </td>
                  <td> $ 40.00 </td>
                </tr>
                <tr>
                  <td>MultiVitamin Power Exfoliant (30 tubes)</td>
                  <td>1</td>
                  <td> $ 90.00 </td>
                  <td> $ 90.00 </td>
                </tr>
                <tr>
                  <td>Exfoliant Accelerator 35 (6 oz)</td>
                  <td>1</td>
                  <td> $ 95.00 </td>
                  <td> $ 95.00 </td>
                </tr>
                <tr>
                  <td>Conductive Masque Base (6 oz)</td>
                  <td>1</td>
                  <td> $ 65.00 </td>
                  <td> $ 65.00 </td>
                </tr>
                <tr>
                  <td>Massage Gel Cream (6 oz)</td>
                  <td>1</td>
                  <td> $ 44.00 </td>
                  <td> $ 44.00 </td>
                </tr>
                <tr>
                  <td>Post Extraction Solution (8 oz)</td>
                  <td>1</td>
                  <td> $ 32.00 </td>
                  <td> $ 32.00 </td>
                </tr>
                <tr>
                  <td>Multi-Active Scaling Gel (8 oz)</td>
                  <td>1</td>
                  <td> $ 55.00 </td>
                  <td> $ 55.00 </td>
                </tr>
                <tr>
                  <td>Dermalogica Apron</td>
                  <td>1</td>
                  <td> $ 25.00 </td>
                  <td> $ 25.00 </td>
                </tr>
                <tr>
                  <td>Carry Case</td>
                  <td>1</td>
                  <td> $ 30.00 </td>
                  <td> $ 30.00 </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <b>Textbook</b>
                  </td>
                </tr>
                <tr>
                  <td>Milady&nbsp;&nbsp;&nbsp;Standard Esthetics textbook, workbook, and online access bundle</td>
                  <td>1</td>
                  <td> $440.00 </td>
                  <td> $440.00 </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td> $2,230 </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="doc-content">
      <h3>STUDENT RULES, REGULATIONS AND DISCIPLINARY ACTION</h3>

      <p>
        All students must follow established rules and regulations. The instructor, office representative and receptionist should not need
        to repeatedly reprimand a student for repeatable offenses. The following actions can be taken by an instructor, office
        representative, receptionist, or school official:
      </p>

      <p>
        <b>WARNING:</b> A Verbal Warning will be given
      </p>
      <p>
        <b>WRITE UP:</b> If the same issue happens a SECOND TIME, the student will be written up and the write up will be documented in the
        student's file.
      </p>
      <p>
        <b>SUSPENSION:</b> ON THE THIRD TIME/OFFENSE, THE STUDENT WILL BE SUSPENDED, and the suspension will be documented in the student’s
        file. Please refer to the school catalog regarding suspensions.
      </p>
      <p>
        <b>TERMINATION:</b> ON THE FOURTH TIME/OFFENSE, THE STUDENT WILL BE TERMINATED FROM THE PROGRAM AND COBA ACADEMY.{' '}
      </p>

      <table className="col-lg-12">
        <tbody>
          <tr>
            <td colSpan={2}>
              <h6>Immediate Withdrawal (Termination):</h6>
            </td>
          </tr>
          <tr>
            <td>(1) Theft</td>
            <td>(3) Causing extreme or willful disruption of the school </td>
          </tr>
          <tr>
            <td>(2) Altering or forging timecards (Including Swipe Cards) </td>
            <td>(4) Use of drugs or alcohol in school</td>
          </tr>
          <tr>
            <td></td>
            <td>(5) Physical abuse of another person</td>
          </tr>

          <tr>
            <td colSpan={2}>
              <h6>The Following May Result in a Suspension (One-Day to Two-Weeks): </h6>
            </td>
          </tr>

          <tr>
            <td>(1) Leaving school without permission of instructor.</td>
            <td>(13) Failure to observe sanitary rules and regulations.</td>
          </tr>
          <tr>
            <td>(2) Failure to have proper equipment when needed.</td>
            <td>(14) Shouting.</td>
          </tr>
          <tr>
            <td>(3) Use of foul language, ethnic, racial, or sexual slurs.</td>
            <td>(15) Use of cell phone inside the school or outside without clocking out.</td>
          </tr>
          <tr>
            <td>(4) Receiving personal services without instructor's permission.</td>
            <td>(16) Eating or drinking outside of designated areas.</td>
          </tr>
          <tr>
            <td>(5) Gossiping or causing discord in the school.</td>
            <td>(17) Entering offices or being behind desk without permission.</td>
          </tr>
          <tr>
            <td>(6) Smoking outside of designated areas, Coba Academy 2022 CATALOG (April 1, 2022 - December 31, 2022)</td>
            <td>(18) Failure to clean up work area or perform assigned cleanup duties. (19) Leaving class or clinic without permission</td>
          </tr>
          <tr>
            <td>(7) Refusing to perform an assignment, patron or otherwise. Page No. 31</td>
            <td>(20) Failure to clock in or out properly.</td>
          </tr>
          <tr>
            <td>(8) Removing timecards from school premises.</td>
            <td>(21) Failure to take lunch break at proper time.</td>
          </tr>

          <tr>
            <td>(9) Using products or performing services not on patron ticket.</td>
            <td>(22) Showing discourtesy to anyone in school.</td>
          </tr>

          <tr>
            <td>(10) Modifying customer services without consulting instructor.</td>
          </tr>
          <tr>
            <td>(11) Improper uniform.</td>
          </tr>
          <tr>
            <td>(12) Dirty uniform or unsatisfactory personal cleanliness.</td>
          </tr>
        </tbody>
      </table>
      <p>
        <i>These guidelines are not all inclusive, as there may be other situations that may require action of the school.</i>
      </p>

      {getKit()}

      {getAcknowledge('main', 'I have read and acknowledge the Classroom Expectations - Rules and Regulations information.')}

      {getSignature()}
    </div>
  );
});

export default DisclosurePage4;
