import './inviteUserPage.scss';
import calendarService from "../../../services/calendar.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import { Department } from '../../../models/Event.model';
import { User as IUser } from "react-feather";
import Footer from '../../footer/footer';

interface InviteUserPageProps {
  departmentId: number;
}

const InviteUserPage: React.FC<InviteUserPageProps> = (props) => {
  const [accept, setAccept] = useState<boolean | null>(null);
  const [department, setDepartment] = useState<Department | undefined>();

  const { user, setLoading, loaders, showAlert } = useContext(globalContext);

  const loadData = async () => {
    if (props.departmentId) {
      setLoading(true);
      let response = await calendarService.getDepartment(props.departmentId);
      setLoading(false);

      if (response?.status === HttpStatusCode.Ok) {
        const data = response.data as Department;
        setDepartment(data);
      } else {
        showAlert("Error loading the department.");
      }
    }
  };

  const handleAccept = () => {
    setAccept(true);
  };

  const handleDecline = () => {
    setAccept(false);
  };

  return (
    <div className="invite-user-page">
      <div className="invite-user">
        <IUser className="iUser" />
        <h1>Hello, User!</h1>
        <h3>Welcome to the calendar.</h3>
        {accept === null ? (
          <div>
            <p>
              You've been invited to join the {department?.name} <strong>test</strong> department.
              Here, you can view the events scheduled for this department.
              To join, simply click the "Accept" button, or choose "Decline."
            </p>
            <div className="d-grid gap-2">
              <button className="accept-button btn btn-md btn-outline-primary" onClick={handleAccept}>
                Accept
              </button>
              <button className="decline-button btn btn-md btn-outline-danger" onClick={handleDecline}>
                Decline
              </button>
            </div>
          </div>
        ) : accept ? (
          <p>You have accepted the invitation to join the department. Welcome!</p>
        ) : (
          <p>You have declined the invitation to join the department. Perhaps another time.</p>
        )}
      </div>
    </div>
  
  );
};

export default InviteUserPage;