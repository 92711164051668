import { GithubPicker } from "react-color";
import Modal from "../../modal/modal";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import DataTable from "../department/Table";
import { Department, addDepartmentUsersRequest } from "../../../models/Event.model";
import globalContext from "../../../context/globalContext";
import calendarService from "../../../services/calendar.service";
import { toast } from "react-toastify";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { MTableToolbar } from "material-table";
import AddUserModal from "../addUserModal/addUserModal";
import "../departmentModal/departmentModal.scss";
import { useForm, Controller } from "react-hook-form";
import { UserOptionType } from "../addUserModal/addUserModelBody";
import { School } from "../../../models/School.model";
import Select from "react-select";
import { Plus as IAdd } from "react-feather";
import { User } from "../../../models/User.model";
import { UserDepartmentStatus } from "../../../models/Enums";

interface DepartmentModalProps {
  departmentId?: number;
  visible: boolean;
  onClose?: () => void;
  onAccept?: () => void;
  onDepartmentAdded?: (department: Department) => void;
  onDepartmentEdited?: (department: Department) => void;
  onDepartmentDeleted?: (department: Department) => void;
  selectedUsers?: UserOptionType[];
}

const DepartmentModal: FunctionComponent<DepartmentModalProps> = (props) => {
  const defaultSchoolOption = { value: 0, label: "- Any School -" };
  const defaultColor = "#B80000";
  const [department, setDepartment] = useState<Department>();
  const [users, setUsers] = useState<User[]>([]);
  const [showUserModal, setshowUserModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState(defaultColor);
  const [selectedName, setselectedName] = useState<string>("");
  const [selectedSchoolId, setSelectedSchoolId] = useState(0);
  const [userSchools, setUserSchools] = useState<School[]>([]);
  const [pickerVisible, setPickerVisible] = useState(false);

  const [status, setStatus] = useState<UserDepartmentStatus>(
    UserDepartmentStatus.PENDING)

  const { user, setLoading, loaders, showAlert } = useContext(globalContext);

  const methods = useForm<Department>();
  const { handleSubmit, control, formState, setValue } = methods;
  const { errors } = formState;

  const loadData = async () => {
    if (props.departmentId) {
      setLoading(true);
      let response = await calendarService.getDepartment(props.departmentId);
      setLoading(false);

      if (response?.status === HttpStatusCode.Ok) {
        const data = response.data as Department;
        setDepartment(data);
        setSelectedSchoolId(data.school || 0);
        setselectedName(data.name || "");
        setSelectedColor(data.color || defaultColor);
        setUsers(data.users || []);
      } else {
        showAlert("Error loading department");
      }
    }
  };

  const loadSchools = async () => {
    if (userSchools.length > 0) return;
    const schoolsResponse = await loaders.schools();
    setUserSchools(schoolsResponse);
  };

  const getSchoolOptions = () => {
    let options = userSchools.map((school) => ({
      label: school.shortName,
      value: school.id,
    }));
    options.unshift(defaultSchoolOption);
    return options;
  };

  // const getSelectedSchool = () => {
  //   if (!selectedSchoolId) return null;
  //   return getSchoolOptions().find((opt) => opt.value === user.schoolsData[0].id);
  // };

  const handleColorChange = (color: any) => {
    setSelectedColor(color.hex);
    setPickerVisible(false);
  };

  const handleResponse = (response: any, action: string) => {
    if (response?.status === HttpStatusCode.Ok || response?.status === HttpStatusCode.Created) {
      toast.dark(`✔️ Department ${action}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast.dark("❌ Something went wrong, try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const createNewDepartment = async (form: any) => {
    let tempDepartment = {
      ...form,
      id: props.departmentId,
      name: selectedName,
      color: selectedColor,
      creator: user.id,
      school: selectedSchoolId || userSchools[0].id,
      users: users,
    } as Department;

    let response = await calendarService.addDepartment(tempDepartment);
    handleResponse(response, "Created");
  };

  const saveDepartmentChanges = async () => {
    if (!props.departmentId) return;
    // save department changes
    if (
      department?.name !== selectedName ||
      department?.color !== selectedColor ||
      department?.school !== selectedSchoolId
    ) {
      let tempDepartment = {
        id: props.departmentId,
        name: selectedName,
        color: selectedColor,
        school: selectedSchoolId,
      } as Department;
      let response = await calendarService.editDepartment(tempDepartment);
      handleResponse(response, "Updated");
    }

    // save users changes
    const newUsers = users.filter((user: any) => user.unSaved);
    if (newUsers.length === 0) return;

    const request: addDepartmentUsersRequest = {
      departmentId: props.departmentId,
      users: newUsers,
      school: selectedSchoolId || userSchools[0].id,
    };

    let usersResponse = await calendarService.addUsersToDepartment(request);
    handleResponse(usersResponse, "Users Updated");
  };

  const handleSaveDepartment = async (data: Department) => {
    if (props.departmentId) {
      saveDepartmentChanges();
    } else {
      createNewDepartment(data);
    }
    if (props.onAccept) {
      props.onAccept();
    }
  };

  const handleRemoveUser = async (event: any, rowData: any) => {
    if (props.departmentId) {
      // delete from DB
      const userId = rowData.id;
      // only if you are editing the department
      if(userId !== undefined){
        const response = await calendarService.removeUserFromDepartment(props.departmentId, userId);
        processDeleteResponse(response, rowData.email);
      } else {
        deleteUserFromState(rowData.email);
      }
    } else {
      // delete from state, has not been saved yet
      deleteUserFromState(rowData.email);
    }
  };

  const processDeleteResponse = (response: any, userEmail: string) => {
    if (response?.status === HttpStatusCode.Ok || response?.status === HttpStatusCode.Created) {
      toast.dark(`✔️ User removed from department`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      deleteUserFromState(userEmail);
    } else {
      toast.dark("❌ Something went wrong, try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const deleteUserFromState = (email: string) => {
    const newUsers = users.filter((user) => user.email !== email);
    setUsers(newUsers);
  };

  const OpenAddUser = () => {
    setshowUserModal(true);
  };

  const handleOnAddUsers = (newUsers: User[]) => {
    setshowUserModal(false);
    // combine the new users with the existing ones, do not duplicate
    let allUsers = [...users];
    newUsers.forEach((user) => {
      if (!users.find((u) => u.email === user.email)) {
        allUsers.push({ ...user, unSaved: true } as any);
      }
    });

    setUsers(allUsers);
  };

  const handleonAddUsersClose = () => {
    setshowUserModal(false);
  };

  useEffect(() => {
    if (props.departmentId) {
      loadData();
      
    } else {
      setDepartment(undefined);
      setselectedName("");
      setSelectedSchoolId(defaultSchoolOption.value);
      setSelectedColor(defaultColor);
      setUsers([]);
    }
    loadSchools();
  }, [props.departmentId]);

  useEffect(() => {

    if (department) {
      setValue("name", department.name);
      setValue("school", department.school);
      setSelectedColor(department.color || defaultColor);
      setUsers(department.users || []);
    }
  }, [department, setValue]);

  return (
    <div className=" departmentModal flex items-center justify-self-center gap-4 pt-6 w-full">
      {showUserModal ? (
        <AddUserModal visible={true} onAccept={handleOnAddUsers} onClose={handleonAddUsersClose} />
      ) : null}
      <Modal
        visible={!showUserModal && props.visible}
        title={props.departmentId ? "Update Department" : "Add New Department"}
        mainButtonText={props.departmentId ? "Save Changes" : "Create Department"}
        onClose={props.onClose}
        onAccept={handleSubmit((d) => handleSaveDepartment(d))}
        size="lg"
      >
        <div className="">
          <div className="form-group">
            <label className="d-block">Department Name:</label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: "Department name is required",
                maxLength: {
                  value: 100,
                  message: "Department name should not exceed 100 characters",
                },
              }}
              render={({ field }) => (
                <div>
                  <input
                    {...field}
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setselectedName(e.target.value); 
                    }}
                  />
                  {errors.name && <span className="text-danger">{errors.name.message}</span>}
                </div>
              )}
            />
          </div>

          {userSchools.length < 2 ? (
            ""
          ) : (
            <Controller
              name="school"
              control={control}
              defaultValue={defaultSchoolOption.value}
              rules={{
                validate: (value) => value !== 0 || "Please select a valid school",
              }}
              render={({ field }) => (
                <div className="mb-3 select-container">
                  <label className="d-block">School:</label>
                  <Select
                    className="select wd-100p"
                    options={getSchoolOptions()}
                    value={getSchoolOptions().find((opt) => opt.value === field.value) || null}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption?.value || 0);
                      setSelectedSchoolId(selectedOption?.value || 0); 
                    }}
                  />
                  {errors.school && <span className="text-danger">{errors.school.message}</span>}
                </div>
              )}
            />
          )}

          <p className="picker">
            Department color:{" "}
            <span
              className="color-value"
              style={{ backgroundColor: selectedColor }}
              onClick={() => setPickerVisible((current) => !current)}
            >
              &nbsp;
            </span>
          </p>
          {pickerVisible ? (
            <GithubPicker className="color-picker" color={selectedColor} onChange={handleColorChange} />
          ) : null}

          <div className="py-4">
            <DataTable
              columns={[
                {
                  title: "Email",
                  field: "email",
                },
                {
                  title: "First Name",
                  field: "firstName",
                },
                {
                  title: "Last Name",
                  field: "lastName",
                },
                {
                  title: "Status",
                  field: "status",
                  render: (rowData : User) => (
                    <div className={`status-${rowData.departmentStatus}`}>
                    {`${rowData.departmentStatus}` === '0' ? "Pending" : 
                     `${rowData.departmentStatus}` === '1' ? "Approved" : 
                     `${rowData.departmentStatus}` === '2' ? "Rejected" : 
                      "Pending"
                    }                 
                    </div>
                  )
                },
              ]}
              data={users || []}
              title="Users in department"
              actions={[
                // will be used to modify user type (normal or department Admin)
                // {
                //   icon: 'edit',
                //   tooltip: 'Edit Data',
                //   onClick: () => {
                //     alert('You want to edit ');
                //   },
                // },
                {
                  icon: "delete",
                  tooltip: "Delete Data",
                  onClick: handleRemoveUser,
                },
              ]}
              options={{
                actionsColumnIndex: 4,
                padding: "dense",
                search: true,
              }}
              components={{
                Toolbar: ({ ...props }) => (
                  <div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "0px 10px",
                      }}
                    >
                      <button className="btn btn-sm btn-outline-primary mx-1 my-1" onClick={OpenAddUser}>
                        <IAdd />
                        Add user
                      </button>
                    </div>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DepartmentModal;
