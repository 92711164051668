import http from "./http-common";
import { DocumentType } from "../models/Document.model";
import { StudentChecklist, StudentChecklistDocument } from "../models/Checklist.model";

class ChecklistService {
    async getAllChecklists() {
		try {
			let response = await http.get(`checklists/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading checklists");
			return undefined;
		}
	}

	async addChecklist(payload: any) {
		try {
			let response = await http.post(`checklists/`, { name: payload.name, schools: payload.schools });
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while adding checklist");
			return undefined;
		}
	}

	async editChecklist(payload: any) {
		try {
			let response = await http.patch(`checklists/${payload.id}/edit_checklist/`, { name: payload.name, schools: payload.schools });
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while updating checklist");
			return undefined;
		}
	}

	async addDocType(docType: DocumentType) {
		try {
			let response = await http.post(`document_types/`, { name: docType.name });
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while adding Document Type");
			return undefined;
		}
	}

	async editDocType(docType: DocumentType) {
		try {
			let response = await http.patch(`document_types/${docType.id}/`, { name: docType.name, enabled: docType.enabled});
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while editing Document Type");
			return undefined;
		}
	}

	async addDocument(checklistId: number, docTypeId: number) {
		try {
			let response = await http.patch(`checklists/${checklistId}/add_doc/`, { doc_type_id: docTypeId });
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while updating checklist");
			return undefined;
		}
	}

	async removeDocument(checklistId: number, docId: any) {
		try {
			let response = await http.patch(`checklists/${checklistId}/remove_doc/`, { doc_id: docId });
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while updating checklist");
			return undefined;
		}
	}

	async getStudentChecklists(studentId: string | number) {
		try {
			let response = await http.get(`/checklists/student/${studentId}/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading student checklists");
			return undefined;
		}
	}
}


export default new ChecklistService();