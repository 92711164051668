import { useContext, useEffect, useMemo, useState } from 'react';
import SearchBox from '../../../components/shared/searchBox/searchBox';
import Select from 'react-select';
import Table from '../../../components/shared/table/table';
import { EnumToSelectOptions, Permission, StudentStatus, StudentStatusName } from '../../../models/Enums';
import { Link } from 'react-router-dom';
import { School } from '../../../models/School.model';
import { SelectOption } from '../../../models/common/Base.model';
import { Student } from '../../../models/Student.model';
import './studentList.scss';
import globalContext from '../../../context/globalContext';
import TitleBar from '../../../components/shared/titleBar/titleBar';

const StudentList = (props: any) => {
  const { user, setLoading, getWithCache, loaders, saveFilter, getFilter, hasPermission } = useContext(globalContext);

  const [students, setStudents] = useState([] as Student[]);
  const [statusOptions, setStatusOptions] = useState([] as SelectOption[]);
  const [schools, setSchools] = useState([] as School[]);
  const multiSchool = useMemo(() => user.isReviewer || user.isAdmin, [user]);

  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState({} as SelectOption);
  const [selectedSchool, setSelectedSchool] = useState({} as SelectOption);

  const canViewEstimator = hasPermission(Permission.VIEW_ESTIMATE_TEMPLATES);

  const defaultSchoolOption = { value: undefined, label: '- Any School -' };
  const defaultStatusOption = { value: undefined, label: '- Any Status -' };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Full Name',
        accessor: 'firstName',
        Cell: ({ cell }: { cell: any }) => cell.row.original.firstName + ' ' + cell.row.original.lastName,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 'auto',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Start date',
        accessor: 'startDate',
      },
      {
        Header: 'SSN',
        accessor: 'ssn',
      },
      {
        Header: 'School',
        accessor: 'schoolData.shortName',
        hidden: !multiSchool,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell }: { cell: any }) => StudentStatusName(cell.row.original.status),
      },
      {
        Header: 'Options',
        accessor: 'file',
        Cell: ({ cell }: { cell: any }) => (
          <>
            <Link className="btn btn-sm btn-outline-primary" to={'/student/overview/' + cell.row.original.id}>
              Overview
            </Link>

            {canViewEstimator && (
              <Link className="btn btn-sm btn-outline-success ml-1" to={'/student/estimate/' + cell.row.original.id}>
                Estimate
              </Link>
            )}
          </>
        ),
      },
    ],
    []
  );

  const loadData = async () => {
    if(students.length > 0) return;

    setLoading(true);

    // this function will retrieve data an assign the school to students and cache both sets of data
    let dataResponse = await loaders.studentsAndSchools();
    setLoading(false);    
    setStudents(dataResponse.students);
    setSchools(dataResponse.schools);
  };

  const loadFilters = () => {
    let filters = getFilter() as any;   
    setSelectedSchool(filters?.school || defaultSchoolOption);
    setSelectedStatus(filters?.status || defaultStatusOption);
  };

  const studentSearch = (student: Student) => {
    let filter = getFilter() as any;
    let pass = true;


    if (filter.search) {      
      const src = filter.search.toLowerCase();
      const studentFullName = student.firstName + student.lastName;
      pass =
        student.firstName.toLowerCase().includes(src) ||
        student.lastName.toLowerCase().includes(src) ||
        student.email.toLowerCase().includes(src) ||
        student.phone?.toLowerCase().includes(src) ||
        src.split(' ').some((el: string) => studentFullName.toLowerCase().includes(el));
    }

    if (filter.school && filter.school?.value) {
      pass = pass && student.school === +filter.school.value;
    }

    if (filter.status && filter.status.value) {
      pass = pass && student.status == filter.status.value;
    }

    return pass;
  };

  const getSchoolOptions = () => {
    let options = schools?.map((school) => ({ value: school.id, label: school.shortName }));
    return [defaultSchoolOption, ...options] as SelectOption[];
  };

  const searchTextChanged = (text: string) => {
    setSearch(text);
    saveFilter('search', text);    
  };

  const selectedSchoolChanged = (option: any) => {
    saveFilter('school', option);
    setSelectedSchool(option);
  };

  const selectedStateChanged = (option: any) => {
    saveFilter('status', option);
    setSelectedStatus(option);
  };

  useEffect(() => {
    loadData();
    let options = EnumToSelectOptions(StudentStatus, StudentStatusName);
    setStatusOptions([defaultStatusOption, ...options]);
    
    loadFilters();
  }, []);

  return (
    <div className="page page-student-list">
      <TitleBar header="List of students">
        <div className="row row-filters">
          <SearchBox className="col-md-4 col-lg-3 mx-sm-0 mx-lg-2" value={search} label="" placeholder="Search student" onChange={searchTextChanged} />

          {multiSchool && (
            <div className="col-md-4 col-lg-3 col-xl-2 mx-sm-0 mx-lg-2">
              <Select placeholder="Filter by school" options={getSchoolOptions()} value={selectedSchool} onChange={selectedSchoolChanged} />
            </div>
          )}

          <div className="col-md-4 col-lg-3 col-xl-2 mx-sm-0 mx-lg-2">
            <Select placeholder="Filter by Status" options={statusOptions} value={selectedStatus} onChange={selectedStateChanged} />
          </div>
        </div>
      </TitleBar>

      <div className="content">
        <Table columns={columns} data={students?.filter(studentSearch)}></Table>
      </div>
    </div>
  );
};

export default StudentList;
