import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Student } from "../../../../../models/Student.model";
import { AgreementProps } from "../../types";
import TextField from "../../../../../components/shared/Input/textField";
import { set, useForm } from "react-hook-form";
import usePageSignature from "../../../../../hooks/pageSignature";
import Checkbox, {
  CheckboxList,
} from "../../../../../components/shared/Input/checkBox";
import { SDGKUFormsPage } from "../../../../../models/Forms.enum";
import RadioButton from "../../../../../components/shared/Input/radioButton";
import { PeriodSchool } from "../../../../../models/Program.model";
import schoolService from "../../../../../services/school.service";

interface extraData {
  chapter30: boolean;
  chapter31: boolean;
  chapter32: boolean;
  chapter33: boolean;
  chapter33D: boolean;
  chapter35: boolean;
  studentStatus: string;
  lastTimeBenefitsUsed: string;
  vaBenefits: boolean;
  educationalPlan: boolean;
  evaluationRequest: boolean;
}

interface VaEducationPage1Form {
  student: Student;
  startDate: string;
  completionDate: string;
  termOfAgreement: string;
  programName: string;
  extraData: extraData;
}

export const VaEducationPage1 = forwardRef(
  ({ data, program, visible }: AgreementProps, ref) => {
    const {
      register,
      formState: { errors },
      setValue,
      getValues,
      watch,
      control,
    } = useForm<VaEducationPage1Form>();
    const { saveForm, validateForm } = usePageSignature(data, false);
    const [periodDates, setPeriodDates] = useState<PeriodSchool>();

    const studentStatus = watch("extraData.studentStatus");
    const isChapter35 = watch("extraData.chapter35");

    const loadData = async () => {
      try {
        const response = await schoolService.getPeriodsBySchool(
          data?.formData?.school.id
        );
        if (response?.status == 200 && response?.data) {
          const periods = response.data;
          const currentPeriod = periods?.find((period: PeriodSchool) => {
            const today = new Date();
            const startDate = new Date(period.startDate);
            const endDate = new Date(period.endDate);
            return today >= startDate && today <= endDate;
          });
          setPeriodDates(currentPeriod || periods[0]);
        }
      } catch (error) {
        console.log("Error loading data:", error);
      }
    };

    useImperativeHandle(ref, () => ({
      async saveData() {
        const extraData = control._formValues.extraData;
        return await saveForm(
          SDGKUFormsPage.Va_education1,
          false,
          extraData as extraData
        );
      },
    }));

    useEffect(() => {
      if (data) {
        setValue("programName", data.formData.programName);
        setValue(
          "termOfAgreement",
          data.formData.programName.includes("Full Stack")
            ? data.formData.programStart + " - " + data.formData.programEnd
            : periodDates?.startDate + " - " + periodDates?.endDate
        );
        setValue("student.firstName", data.formData.student.firstName);
        setValue("student.lastName", data.formData.student.lastName);
        setValue(
          "student.middleName",
          data.formData.student.middleName
            ? data.formData.student.middleName.substring(0, 1)
            : ""
        );
        setValue("student.email", data.formData.student.email);
        setValue("student.phone", data.formData.student.phone);
        setValue("student.addressStreet", data.formData.student.addressStreet);
        setValue("student.addressCity", data.formData.student.addressCity);
        setValue("student.addressState", data.formData.student.addressState);
        setValue("student.addressZip", data.formData.student.addressZip);
      }
    }, [data, periodDates]);

    useEffect(() => {
      if (visible) {
        setTimeout(() => {
          window.scrollTo(0, 0);
          validateForm();
        }, 500);
      }
    }, [visible]);

    useEffect(() => {
      loadData();
    }, [data]);

    if (!visible) return null;

    return (
      <div className="va-education-step1">
        <h2>VA Education Benefits Certification Request</h2>
        <h3>Program Information</h3>

        <div className="row">
          <div className="col-8">
            <TextField
              disabled
              errors={errors}
              label="Program Name"
              field="programName"
              register={register}
              type="text"
            />
          </div>
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="Term of Agreement"
              field="termOfAgreement"
              register={register}
              type="text"
            />
          </div>
        </div>
        <h3>Student Information</h3>
        <div className="row">
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="First Name"
              field="student.firstName"
              register={register}
              type="text"
            />
          </div>
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="Last Name"
              field="student.lastName"
              register={register}
              type="text"
            />
          </div>
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="Middle initial"
              field="student.middleName"
              register={register}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="Email"
              field="student.email"
              register={register}
              type="text"
            />
          </div>
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="Phone"
              field="student.phone"
              register={register}
              type="text"
            />
          </div>
        </div>
        <h3>Addres Information</h3>
        <div className="row">
          <div className="col-4">
            <TextField
              disabled
              errors={errors}
              label="Street"
              field="student.addressStreet"
              register={register}
              type="text"
            />
          </div>
          <div className="col-3">
            <TextField
              disabled
              errors={errors}
              label="City"
              field="student.addressCity"
              register={register}
              type="text"
            />
          </div>
          <div className="col-3">
            <TextField
              disabled
              errors={errors}
              label="State"
              field="student.addressState"
              register={register}
              type="text"
            />
          </div>
          <div className="col-2">
            <TextField
              disabled
              errors={errors}
              label="Zip"
              field="student.addressZip"
              register={register}
              type="text"
            />
          </div>
        </div>

        <h3>
          Benefits Status
          <span style={{ fontSize: "1rem" }}>
            (Please indicate the benefit you intend to utilize this semester)
          </span>
        </h3>
        <div className="chapters">
          <CheckboxList
            items={[
              {
                field: "extraData.chapter30",
                label: "Chapter 30: Montgomery GI Bill",
              },
              {
                field: "extraData.chapter31",
                label: "Chapter 31: Vocational Rehabilitation",
              },
              {
                field: "extraData.chapter32",
                label:
                  "Chapter 32: Veterans Educational Assistance Program (VEAP)",
              },
              {
                field: "extraData.chapter33",
                label: "Chapter 33: Post 9/11 GI Bill",
              },
              {
                field: "extraData.chapter33D",
                label: "Chapter 33D: Post 9/11 GI Bill",
              },
              {
                field: "extraData.chapter35",
                label: "Chapter 35: Dependent Education Assistence",
              },
            ]}
            register={register}
            getValues={getValues}
            errors={errors}
            rules={{ oneRequired: true, required: true }}
          />
        </div>
        <hr />
        {isChapter35 && (
          <div>
            <h5>Chapter 35 Only:</h5>
            <div className="row">
              <div className="col-3">
                <TextField
                  errors={errors}
                  label="File #"
                  field="extraData.file"
                  register={register}
                  type="text"
                />
              </div>
              <div className="col-3">
                <TextField
                  errors={errors}
                  label="Payee #"
                  field="extraData.payee"
                  register={register}
                  type="text"
                />
              </div>
            </div>
            <hr />
          </div>
        )}

        <h3>
          Student Status
          <span style={{ fontSize: "1rem" }}>(check one response)</span>
        </h3>
        <div className="row">
          <div className="col-3">
            <RadioButton
              label="Continuing Student"
              field="extraData.studentStatus"
              value="1"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>
          {studentStatus == "1" && (
            <div className="col-6">
              <TextField
                errors={errors}
                label="Last time benefits used at SDGKU"
                placeholder="mm/dd/yyyy"
                field="extraData.lastTimeBenefitsUsed"
                register={register}
                type="text"
                rules={{ required: studentStatus == "1" }}
              />
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-3">
            <RadioButton
              label="New Student"
              field="extraData.studentStatus"
              value="2"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>
          {studentStatus == "2" && (
            <div className="col-3">
              <CheckboxList
                items={[
                  {
                    field: "extraData.vaBenefits",
                    label: "VA Benefits Coding Request",
                  },
                  {
                    field: "extraData.educationalPlan",
                    label: "Educational Plan",
                  },
                  {
                    field: "extraData.evaluationRequest",
                    label: "Evaluation Request Form",
                  },
                ]}
                register={register}
                getValues={getValues}
                errors={errors}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
