import Modal from "../modal/modal";
import Select from 'react-select';
import { useEffect, useState } from "react";

const ChecklistModal = (props: any) => {
    const [selectedSchools, setSelectedSchools] = useState([] as any[]);
    const [name, setName] = useState() as any;

    const handleSubmit = () => {
        let data = {
            "name": name,
            "schools": selectedSchools.length > 0 ? selectedSchools.map((s: any) => s.value) : [],
        };
        props.handleChecklistSubmit(data);
        setSelectedSchools([] as any[]);
        setName("")
        props.handleModalVisible();
    }

    useEffect(() => {
        if (props.selectedChecklist?.id) {
            setName(props.selectedChecklist.name);
            setSelectedSchools(props.selectedChecklist.assignedSchools.map((s: any) => ({ label: s.shortName, value: s.id })));
        }
        else {
            setName("");
            setSelectedSchools([] as any[]);
        }
    }, [props.visible]);

    const getBody = () => {
        return (
            <div className="checklist-form">
                <div className="input-wrapper">
                    <label>Name:</label>
                    <input type="text" className="form-control form-control-sm"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="input-wrapper">
                    <label>Schools:</label>
                    <Select
                        isMulti
                        options={props.getSchoolOptions()}
                        className="select wd-100p"
                        onChange={(e) => {
                            setSelectedSchools(e as any[]);
                        }}
                        value={selectedSchools}
                    />
                </div>
            </div>
        )
    }
    return (
        <Modal
            title={!props.selectedChecklist?.id ? "Add Checklist" : "Edit Checklist"}
            body={getBody()}
            visible={props.visible}
            cancelButtonText="Cancel"
            mainButtonText={!props.selectedChecklist?.id ? "Add" : "Edit"}
            onAccept={() => handleSubmit()}
            onCancel={props.close}
            onClose={props.close}
        />
    )
}
export default ChecklistModal;