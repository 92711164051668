import { useState, useEffect, useMemo, useContext } from 'react'

import { UploadRequest, UploadRequests } from '../../models/uploadRequest.model'
import { SchoolID, UploadRequestStatus, UploadRequestStatusText } from '../../models/Enums'

import uploadRequestService from '../../services/uploadRequest.service'

import ActionIcon from '../shared/action-icon/actionIcon'
import { ArrowRightCircle } from "react-feather";

import Table from '../shared/table/table'
import { UploadRequestDetailsModal } from '../../components/upload-request/uploadRequestModal'

import globalContext from '../../context/globalContext'
import './uploadRequestViewer.scss';
const moment = require('moment-timezone');

interface StatusProps {
  value: number | undefined;
  label: string;
}

interface UploadRequestViewerProps {
  search: string;
  selectedStatus: StatusProps;
  trigger: boolean;
}


export const UploadRequestsTable = ({ search, selectedStatus, trigger }: UploadRequestViewerProps) => {
  const [uploadedRequests, setUploadedRequests] = useState<UploadRequests[]>()
  const [dataFiltered, setDataFiltered] = useState<UploadRequests[]>()
  const { setLoading, saveFilter } = useContext(globalContext);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedUploadRequest, setSelectedUploadRequest] = useState<UploadRequests>()

  const getUploadedRequests = async () => {
    setLoading(true)
    const response = await uploadRequestService.getUploadRequests();
    if (response.data.length > 1) {
      let sortByDate = response.data.sort((a: UploadRequests, b: UploadRequests) => {
        const dateA = new Date(a.updatedAt as any);
        const dateB = new Date(b.updatedAt as any);
        if (dateA < dateB) {
          return 1;
        } else if (dateB < dateA) {
          return -1;
        }
        return 0;
      })
      setUploadedRequests(sortByDate)
      setDataFiltered(sortByDate)
    }
    setLoading(false)
  }

  useEffect(() => {
    getUploadedRequests();
  }, [trigger])

  useEffect(() => {
    handleFilter();
  }, [search, selectedStatus])

  // cleanup funciton
  useEffect(() => {
    return () => {
      saveFilter("search", "");
      console.log('cleanig');

    }
  }, [])

  const handleUpdateRequestDate = (row: UploadRequests) => {
    setIsModalVisible(true)
    setSelectedUploadRequest(row)
  }

  const statusTypeBadge = (status: any) => {
    let asignedClass = 'missing-tag';

    switch (status) {
      case UploadRequestStatus.PENDING:
        asignedClass = 'pending-tag'
        break;

      case UploadRequestStatus.VIEWED:
        asignedClass = 'viewed-tag'
        break;

      case UploadRequestStatus.IN_PROGRESS:
        asignedClass = 'inprogress-tag'
        break;

      case UploadRequestStatus.COMPLETED:
        asignedClass = 'completed-tag'
        break;

      case UploadRequestStatus.CANCELLED:
        asignedClass = 'rejected-tag'
        break;

      default:
        break;
    }

    return (
      <div className={`status-badge ${asignedClass}`}>
        {UploadRequestStatusText(status)}
      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Student",
        accessor: (row: UploadRequests) => (
          <div>
            {row.student?.firstName} {row.student?.middleName} {row.student?.lastName}
          </div>
        )
      },
      {
        Header: "School",
        accessor: (row: UploadRequests) => (
          <div className='badge'>
            {SchoolID[row?.schoolId!]}
          </div>
        )
      },
      {
        Header: "Status",
        accessor: (row: UploadRequests) => (
          <div> {statusTypeBadge(row?.status)} </div>
        )
      },
      {
        Header: "CreatedAt",
        accessor: (row: UploadRequests) => (
          <div>
            {moment(row.createdAt).format('MM-DD-YYYY')}
          </div>
        )
      },
      {
        Header: "Valid Until",
        accessor: (row: UploadRequests) => (
          <div>
            {moment(row.validUntil).format('MM-DD-YYYY')}
          </div>
        )
      },
      {
        Header: "Actions",
        accessor: (row: UploadRequest) => (
          <>
            <ActionIcon
              icon={<ArrowRightCircle />}
              color="primary"
              onClick={() => handleUpdateRequestDate(row)}
            />
          </>
        ),
      },
    ], []);

  const checkStatus = (uploadedRequestStatus: number | undefined) => {
    if (selectedStatus.value === 4) {
      return uploadedRequestStatus !== selectedStatus?.value
    }

    return uploadedRequestStatus === selectedStatus?.value
  }

  const handleFilter = () => {
    let dataToSearch = search.toLowerCase();

    const dataFilteredByStudent = uploadedRequests?.filter((uploadedRequest: UploadRequests) =>
      (
        uploadedRequest.student?.firstName.toLowerCase().includes(dataToSearch) ||
        uploadedRequest.student?.lastName.toLowerCase().includes(dataToSearch) ||
        uploadedRequest?.student?.middleName?.toLowerCase().includes(search.toLowerCase())
      ) && checkStatus(uploadedRequest?.status))

    setDataFiltered(dataFilteredByStudent)
  }

  return (
    <div className=" ">
      <UploadRequestDetailsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedUploadRequest={selectedUploadRequest}
      />
      <div className="card-body overflow-auto">
        <div className="card ">
          <Table
            columns={columns}
            data={dataFiltered}
          />
        </div>
      </div>
    </div>
  )
}