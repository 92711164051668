import * as React from "react";

class Footer extends React.Component<any, any> {
  render() {
    return (
      <div className="footer bg-gray-200">
        <p>All the copyrights reserved. FinAid360 &copy; 2024</p>
      </div>
    );
  }
}

export default Footer;
