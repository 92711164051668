import { DocumentTemplate } from "../models/Document.model";
import http from "./http-common";

class DocumentTemplateService {
	async getAllDocumentTemplates() {
		try {
			let response = await http.get("documents_templates/");
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading document templates");
			return undefined;
		}
	}

	async addDocumentTemplate(payload: any) {
		try {
			let formData = new FormData();
			formData.append("file", payload.file);
			formData.append("name", payload.name);
			if (payload.school) formData.append("school", payload.school.toString());
			if (payload.documentType) formData.append("document_type", payload.documentType.toString());
			formData.append("revision", payload.revision);
			let response = await http.post("documents_templates/", formData);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while adding document template");
			return undefined;
		}
	}

	async deleteDocumentTemplate(payload: number) {
		try {
			const response = await http.delete(`documents_templates/${payload}`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while deleting document template");
			return undefined;
		}
	}

	async archiveDocumentTemplate(payload: any) {
		try {
			let formData = new FormData();

			formData.append("archived", payload.archived);

			let response = await http.patch(`documents_templates/${payload?.id}/`, formData);

			return response;
		} catch (error){
			http.fa360.displayError("Unexpected error while adding document template");
			return undefined;
		}
		
	}

	async updateDocumentTemplate(payload: any) {
		try {
			let formData = new FormData();
			if(payload.file) formData.append("file", payload.file);
			formData.append("name", payload.name);
			if (payload.school) formData.append("school", payload.school.toString());
			if (payload.documentType) formData.append("document_type", payload.documentType.toString());
			formData.append("revision", payload.revision);
			let response = await http.patch(`documents_templates/${payload?.id}/`, formData);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while adding document template");
			return undefined;
		}
	}

	async emailTemplates(emailRequest: any) {
		try {
			let data = {
				email_to: emailRequest.to,
				subject: emailRequest.subject,
				message: emailRequest.message,
				files: emailRequest.files,
			};
			let res = await http.patch("/templates/email/", data);
			return res;
		} catch (error) {
			http.fa360.displayError("Unexpected error while sending templates");
			return undefined;
		}
	}
}

export default new DocumentTemplateService();
