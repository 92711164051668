import documentService from "../../../services/document.service";
import StudentDocumentUpload from "../../../components/student/documentUpload/studentDocumentUpload";
import globalContext from "../../../context/globalContext";
import React from "react";
import schoolService from "../../../services/school.service";
import Select from "react-select";
import studentService from "../../../services/student.service";
import { AlertType } from "../../../models/AlertType.enum";
import { Document } from "../../../models/Document.model";
import { DocumentStatus, DocumentUploadType, StudentStatus } from "../../../models/Enums";
import { FilePlus as IconDoc, User as IconUser } from "react-feather";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { Link } from "react-router-dom";
import { School } from "../../../models/School.model";
import { SelectOption } from "../../../models/common/Base.model";
import { Student } from "../../../models/Student.model";
import { toast } from "react-toastify";
import "./studentQuickCreate.scss";
import TitleBar from "../../../components/shared/titleBar/titleBar";
import { CacheConstants } from '../../../services/constants.service';

class StudentQuickCreate extends React.Component<any, any> {
  static contextType = globalContext;
  state = {
    studentId: 0,
    student: new Student({ status: StudentStatus.APPLICATION_ALLOWED }),
    schools: [],
    selectedSchool: null as any,
    selectedDocType: {} as SelectOption,
    selectedDocument: {} as File,
    uploadStatus: 0,
    buttonDisabled: false,
  };

  multiSchool = () => {
    return this.context.user.isReviewer || this.context.user.isAdmin;
  };

  getSchoolGroup = () => {
    const isOneSchoolOnly = this.state.schools?.length === 1;
    const options = this.state.schools.map((school: School) => ({ label: school.name, value: school.id }))
    if (isOneSchoolOnly) this.state.selectedSchool = options[0];
    return this.multiSchool() ? (
      <div className="form-group">
        <label className="d-block">School:</label>

        <Select
          placeholder="Select or Search School"
          options={options}
          value={isOneSchoolOnly ? options[0] : this.state.selectedSchool}
          defaultValue={isOneSchoolOnly ? options[0] : null}
          isDisabled={isOneSchoolOnly}
          onChange={(selectedSchool: SelectOption) => this.setState({ selectedSchool })}
        />

      </div>
    ) : null;
  };

  getGroupClasses = () => {
    return this.multiSchool() ? 'three-columns' : 'two-columns';
  };

  render() {
    return (
      <>
        <TitleBar header="Student Creation" subheader="Register basic information" />
        <div className="page create-student-page quick-create-student-page container pd-t-10">
          <legend>
            <IconUser className="icon" /> Personal Information
          </legend>
          <div className="two-columns">
            <div className="form-group">
              <label className="d-block">First name:</label>
              <input
                type="text"
                className="form-control"
                required
                id="fname"
                placeholder="Enter your first name"
                name="student.firstName"
                value={this.state.student.firstName}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="form-group">
              <label className="d-block">Last name:</label>
              <input
                type="text"
                className="form-control"
                required
                id="lname"
                placeholder="Enter your last name"
                name="student.lastName"
                value={this.state.student.lastName}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className={this.getGroupClasses()}>
            {this.getSchoolGroup()}

            <div className="form-group">
              <label className="d-block">Email:</label>
              <input
                type="email"
                className="form-control"
                required
                id="email"
                placeholder="missing@mail.com"
                name="student.email"
                value={this.state.student.email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label className="d-block">Status:</label>
              <select className="custom-select" name="student.status" value={this.state.student.status} onChange={this.handleInputChange} required>
                <option value={0} disabled>
                  -- Select an option --
                </option>
                <option value={StudentStatus.APPLICATION_REQUEST}>App Request</option>
                <option value={StudentStatus.APPROVED}>App Approved</option>
                <option value={StudentStatus.ENROLLED}>Enrolled</option>
                <option value={StudentStatus.ACTIVE}>Active</option>
                <option value={StudentStatus.GRADUATED}>Graduated</option>
                <option value={StudentStatus.CANCELLED}>Cancelled</option>
                <option value={StudentStatus.WITHDRAWN}>Withdrawn</option>
                <option value={StudentStatus.LEAVE_OF_ABSENCE}>Leave of Absence</option>
              </select>
            </div>
          </div>

          <legend className="mg-t-20">
            <IconDoc className="icon" /> Upload Document
          </legend>
          <StudentDocumentUpload
            uploadVisible={false}
            headingVisible={false}
            containerClass="pd-10"
            docTypeLabel="Document Type"
            selectedDocType={this.state.selectedDocType}
            status={this.state.uploadStatus}
            onDocTypeChange={(selectedDocType: SelectOption) => this.setState({ selectedDocType })}
            onFileChange={(doc) => {
              this.setState({ selectedDocument: doc });
            }}
          />

          <button disabled={this.context.isLoading || this.state.buttonDisabled} onClick={this.saveStudent} className="btn btn-primary px-5 mg-t-10 mb-3">
            Create Student
            {/* {console.log(this.context.user)} */}
          </button>
        </div>
      </>
    );
  }

  async componentDidMount() {
    let schools = await this.context.getWithCache(CacheConstants.SCHOOLS, schoolService.getSchools);
    this.setState({ schools });
  }

  saveStudent = async () => {
    this.context.setLoading(true);
    this.setState({ buttonDisabled: true })
    this.context.clearAlert();

    let student = this.state.student;
    student.school = this.multiSchool() ? this.state.selectedSchool?.value : this.context.user.schools?.[0] || 0;
    student.birthDate = undefined;
    student.startDate = undefined;
    student.email = student.email || 'missing@mail.com';

    // todo: validate doc type if file is selected
    let errors = this.validateForm(student);
    if (errors.length) {
      this.showErrors(errors);
      return;
    }

    this.context.clearAlert();
    this.setState({ status: 1 }); // show loading

    this.context.clearCacheKey(CacheConstants.STUDENTS);
    let response = await studentService.saveStudent(this.state.student);
    this.context.setLoading(false);

    if (response?.status === HttpStatusCode.Created) {
      let studentId = response.data?.id;
      const doc = this.state.selectedDocument;
      const docType = this.state.selectedDocType;

      if (doc.name) {
        let payLoad = {
          documentType: docType.value,
          uploadType: DocumentUploadType.MANUAL_UPLOAD,
          file: doc,
          fileName: doc.name,
          fileSize: doc.size,
          student: studentId,
          status: DocumentStatus.APPROVED,
        } as Document;

        let responseDoc = await documentService.uploadStudentDocument(payLoad);
        if (responseDoc?.status === HttpStatusCode.Created) {
          this.showSuccessMessage(studentId);
        }
      } else {
        this.showSuccessMessage(studentId);
      }
    }

    this.setState({ status: 0 }); // hide loading
  };

  showSuccessMessage = (studentId: number) => {
    let message = 'Student created successfully';
    toast.dark('✔️ ' + message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });

    this.context.showAlert(
      <div>
        <h5>{message}</h5>
        <Link to={'/student/overview/' + studentId}>Go to student overview</Link>
      </div>,
      AlertType.Success
    );

    this.clearForm();
    setTimeout(() => {
      this.context.clearAlert();
    }, 8000);
  };

  clearForm = () => {
    this.setState({
      student: new Student(),
      selectedState: null as any,
      selectedSchool: null as any,
      selectedReviewer: null as any,
    });
  };

  handleInputChange = (event: any) => {
    const form = this.state as any;
    let name = event.target.name;
    if (name.includes('student.')) {
      name = name.replace('student.', '');
      form.student[name] = event.target.value;
    } else {
      form[event.target.name] = event.target.value;
    }

    this.setState({ form });
    if (name === 'email') {
      this.validateEmail(event.target.value);
    }
    this.setState({ buttonDisabled: false })
  };

  validateEmail = async (email: string) => {
    let response = await studentService.validateEmail(email);
    if (response?.status !== HttpStatusCode.Ok) {
      const error = (
        <>
          <h5>Student Already Exist</h5>
          <p>
            There is an student on the system with the same email. <br />
            Please search the student on the student list. Do not duplicate the record.
          </p>
        </>
      );

      this.context.showAlert(error, AlertType.Error);
    } else {
      this.context.clearAlert();
    }
  };

  validateForm = (student: Student) => {
    let errors: string[] = [];

    if (!student.firstName) errors.push('First Name is required');
    if (!student.lastName) errors.push('Last Name is required');
    if (this.multiSchool() && !student.school) errors.push('Please select an School');

    if (this.state.selectedDocument.name && !this.state.selectedDocType.value) {
      errors.push('Please select an Document Type');
    }

    return errors;
  };

  showErrors = (errors: string[]) => {
    let errorList = errors.map((error: string, index: number) => {
      return <li key={index}>{error}</li>;
    });

    let alert = (
      <div>
        <h4>Please fix the following issues and try again</h4>
        <ul className="error-list">{errorList}</ul>
      </div>
    );

    this.context.showAlert(alert, AlertType.Error);
  };
}

export default StudentQuickCreate;
