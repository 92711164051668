import "./eventModal.scss";
import globalContext from "../../context/globalContext";

import { useState, useEffect, useContext, FunctionComponent } from "react";
import { Department, Event, EventType } from "../../models/Event.model";
import { School } from "../../models/School.model";
import { SchoolSelector } from "../shared/school-selector/schoolSelector";
import { SelectOption } from "../../models/common/Base.model";
import { useForm } from "react-hook-form";

import Modal from "../modal/modal";
import SelectField from "../shared/Input/selectField";
import TextField from "../shared/Input/textField";
import calendarService from "../../services/calendar.service";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import { toast } from "react-toastify";
import { EventReminderOptions } from "../shared/constants/constants";
const moment = require("moment-timezone");

interface EventModalProps {
  eventId?: any;
  visible: boolean;
  canDelete?: boolean;
  onClose: () => void;
  onEventAdded?: (event: Event) => void;
  onEventEdited?: (event: Event) => void;
  onEventDeleted?: (event: Event) => void;
}

const EventModal: FunctionComponent<EventModalProps> = (props) => {
  const { user, setLoading, loaders, showAlert } = useContext(globalContext);
  const [eventTypes, setEventTypes] = useState([] as EventType[]);
  const [hasReminder, setHasReminder] = useState(false);
  const [minDate, setMinDate] = useState() as any;
  const [userSchools, setUserSchools] = useState([] as School[]);
  const [selectedEvent, setSelectedEvent] = useState({} as Event);
  const [selectedEndDate, setSelectedEndDate] = useState() as any;
  const [selectedSchools, setSelectedSchools] = useState([]) as any[];
  const [selectedStartDate, setSelectedStartDate] = useState() as any;

  const [creatingNewEvent, setCreatingNewEvent] = useState(true);
  const [departments, setDepartments] = useState<Department[]>([]);

  const timezone = moment.tz.guess();

  const allDepartmentsOption = { value: 0, label: "All Departments" };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<Event>();

  const watchStartDate = watch("startDate");

  const loadData = async () => {
    setLoading(true);
    let [schoolsRes, eventTypeRes, departmentsRes] = await Promise.all([
      loaders.schools(),
      loaders.eventTypes(),
      calendarService.getDepartments(),
    ]);

    setUserSchools(schoolsRes);
    setEventTypes(eventTypeRes);
    setDepartments(departmentsRes?.data);

    if (schoolsRes.length < 2) {
      setSelectedSchools([schoolsRes[0].id]);
    }

    setLoading(false);
  };

  const loadEvent = async (eventId: any) => {
    if (eventId != 0) {
      let res = await calendarService.getSingleEvent(eventId);
      if (res?.status === HttpStatusCode.Ok) {
        const event = res.data;
        setSelectedEvent(event);

        let start = event.startDate;
        let end = event.endDate;

        // Start and end dates stored in utc time and converted to local timezone when displayed
        setValue("department", event.department || allDepartmentsOption.value);
        setValue("name", event.name);
        setValue("eventType", event.eventType);
        setValue("description", event.description);
        setValue("url", event.url);
        setValue(
          "startDate",
          moment.tz(start, timezone).format("YYYY-MM-DDTHH:mm")
        );
        setValue(
          "endDate",
          moment.tz(end, timezone).format("YYYY-MM-DDTHH:mm")
        );
        setValue("reminderTime", event.reminderTime);
        setValue("reminderText", event.reminderText);

        setHasReminder(event.hasReminder);
        setSelectedSchools(event.schools);
      }
    }
  };

  const handleSelectSchool = (school_id: number) => {
    let tempSelected = [...selectedSchools];

    const index = tempSelected.findIndex((id) => id === school_id);

    if (index === -1) {
      tempSelected.push(school_id);
    } else {
      tempSelected.splice(index, 1);
    }

    setSelectedSchools(tempSelected);
  };

  const getEventTypes = () => {
    let options = eventTypes.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    return [...options] as SelectOption[];
  };

  const getDepartments = () => {
    let options = departments
      .filter(
        (d) => selectedSchools.length > 0 && d.school == selectedSchools[0]
      )
      .map((type) => ({
        value: type.id,
        label: type.name,
      }));

    if (selectedSchools.length > 1) {
      setValue("department", allDepartmentsOption.value);
    }

    return [allDepartmentsOption, ...options] as SelectOption[];
  };

  const handleAddEvent = async (tempEvent: Event) => {
    let response = await calendarService.addEvent(tempEvent);
    if (response?.status === HttpStatusCode.Created) {
      props.onEventAdded?.(response.data);
    }
  };

  const handleEditEvent = async (tempEvent: Event) => {
    let eventRes = await calendarService.updateEvent(tempEvent);
    if (eventRes?.status === HttpStatusCode.Ok) {
      props.onEventEdited?.(eventRes.data);
    }
  };

  const handleDeleteEvent = async () => {
    let res = await calendarService.removeEvent(
      props.eventId as string | number
    );
    if (res?.status === HttpStatusCode.NoContent) {
      props.onEventDeleted?.(res.data);
    }
  };

  const hasMultipleSchools = () => userSchools.length > 2;

  const handleSave = async (form: any) => {
    if (form.department === allDepartmentsOption.value) {
      // all departments is not a valid db department
      delete form.department;
    }

    if (props.eventId) {
      // edit event
      let tempEvent = {
        ...form,
        id: props.eventId,
        creator: user.id,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        schools: hasMultipleSchools()
          ? [...selectedSchools]
          : [userSchools[0].id],
        hasReminder: hasReminder,
      } as Event;

      handleEditEvent(tempEvent);
    } else {
      // add event
      let tempStart = moment.tz(selectedStartDate, timezone);
      let tempEnd = moment.tz(selectedEndDate, timezone);

      let tempAddEvent = {
        ...form,
        id: props.eventId,
        creator: user.id,
        startDate: moment.utc(tempStart.startOf("hour")).format(),
        endDate: moment.utc(tempEnd.startOf("hour").add(1, "hour")).format(),
        schools: hasMultipleSchools()
          ? [...selectedSchools]
          : [userSchools[0].id],
        hasReminder: hasReminder,
      } as Event;

      if (!tempAddEvent?.schools?.length) {
        toast.dark("❌ Please select at least one school", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        return;
      }

      await handleAddEvent(tempAddEvent);
    }

    setCreatingNewEvent(false);
  };

  const handleClose = () => {
    setSelectedEvent({} as Event);
    props.onClose();
  };

  const getDefaultDates = () => {
    if (!props.eventId) {
      setValue(
        "startDate",
        moment().tz(timezone).startOf("hour").format("YYYY-MM-DDTHH:mm")
      );
      setValue(
        "endDate",
        moment()
          .tz(timezone)
          .startOf("hour")
          .add(1, "hour")
          .format("YYYY-MM-DDTHH:mm")
      );
    }
  };

  useEffect(() => {
    loadData();
    setMinDate(new Date().toISOString().slice(0, -8));
    if (props.eventId !== 0) {
      loadEvent(props.eventId);
    } else {
      reset();
      setSelectedSchools([]);
      setSelectedEvent({} as Event);
      getDefaultDates();
    }
  }, [props.eventId]);

  useEffect(() => {
    if (props.eventId === 0) {
      reset();
      setSelectedEvent({} as Event);
      setHasReminder(false);
      setSelectedSchools([]);
      getDefaultDates();
      setCreatingNewEvent(true);
    }
  }, [creatingNewEvent]);

  // useEffect(() => {
  //   if (watchStartDate) {
  //     const adjustedEndDate = moment
  //       .tz(watchStartDate, timezone)
  //       .add(1, "hour")
  //       .format("YYYY-MM-DDTHH:mm");

  //     setValue("endDate", adjustedEndDate);
  //   }
  // }, [watchStartDate]);

  const checkKeyDown = (e: any) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const isEventCreator = () => {
    return !props.eventId || selectedEvent.creator === user.id; //|| user.isAdmin;
  };

  const isFormDisabled = () => {
    return selectedEvent ? (isEventCreator() ? false : true) : false;
  };

  const handleDateChange = (event: any) => {
    if (event.target.name === "startDate") {
      setSelectedStartDate(event.target.value);
    } else {
      setSelectedEndDate(event.target.value);
    }
  };

  const body = () => {
    return (
      <div className="event-form-container">
        <form onKeyDown={(e) => checkKeyDown(e)}>
          {hasMultipleSchools() ? (
            <div className="form-group">
              <label htmlFor="schools">Schools:</label>
              <SchoolSelector
                schools={userSchools}
                selectedSchools={selectedSchools}
                selectedEvent={selectedEvent}
                isEventCreator={isEventCreator}
                handleSelectSchool={handleSelectSchool}
              />
            </div>
          ) : null}

          <SelectField
            field="department"
            label="Select Department"
            placeHolder="-- Select Department --"
            disabled={isFormDisabled() || selectedSchools.length > 1}
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={getDepartments()}
          />

          <SelectField
            field="eventType"
            label="Event Type"
            disabled={isFormDisabled()}
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={getEventTypes()}
          />

          <TextField
            field="name"
            type="text"
            label="Event Name"
            placeholder="Type event name"
            register={register}
            errors={errors}
            rules={{ required: true }}
            disabled={isFormDisabled()}
          />

          <TextField
            field="description"
            type="text"
            rows={6}
            multiLine={true}
            label="Event Description"
            placeholder="Provide an event description"
            register={register}
            errors={errors}
            rules={{ required: true }}
            disabled={isFormDisabled()}
          />

          {/* Add Timezone Conversion and minimum dates (end date min 1 hr after start) */}
          <div className="form-row">
            <TextField
              parentClass="form-group col-md-6"
              field="startDate"
              type="datetime-local"
              label="Start Date"
              register={register}
              errors={errors}
              rules={{ required: true }}
              disabled={isFormDisabled()}
              onChange={handleDateChange}
              minDate={minDate}
            />

            <TextField
              parentClass={`form-group col-md-6`}
              field="endDate"
              type="datetime-local"
              label="End Date"
              register={register}
              errors={errors}
              rules={{
                required: true,
                validate: {
                  dateOrder: (endDate: string) => {
                    const startDateValue = watch("startDate");
                    return moment(endDate).isSameOrAfter(startDateValue);
                  },
                },
              }}
              disabled={isFormDisabled()}
              onChange={handleDateChange}
              minDate={selectedStartDate}
            />

            {errors.endDate && errors.endDate.type === "dateOrder" && (
              <div className="text-danger mx-2 py-1">
                The end date must be equal to or later than the start date.
              </div>
            )}
          </div>

          {/* reminder */}
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              checked={hasReminder}
              id="hasReminder"
              name="hasReminder"
              onChange={(e) => setHasReminder(e.target.checked)}
            ></input>
            <label className="form-check-label" htmlFor="hasReminder">
              Set Reminder
            </label>
          </div>

          {hasReminder ? (
            <div className="reminder-container">
              <div className="form-row">
                <SelectField
                  parentClass="form-group col-md-6"
                  field="reminderTime"
                  label="Notify"
                  disabled={!hasReminder}
                  errors={errors}
                  control={control}
                  rules={{ required: true }}
                  options={EventReminderOptions}
                />
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  };

  return (
    <Modal
      body={body()}
      bodyTitle={
        !selectedEvent.id ? <h5>Add Event</h5> : <h5>Event Details</h5>
      }
      size="md"
      mainClass="event-calendar-modal"
      visible={props.visible}
      flyout={true}
      mainButtonText={
        !selectedEvent.id
          ? "Create Event"
          : isEventCreator()
          ? "Save Changes"
          : ""
      }
      cancelButtonText={
        props.canDelete && selectedEvent.id && isEventCreator() ? "Delete" : ""
      }
      cancelButtonClass="btn-danger"
      onCancel={handleDeleteEvent}
      onClose={handleClose}
      onAccept={handleSubmit((d) => handleSave(d))}
      secondaryButtonClass="btn-secondary"
      secondaryButtonText={selectedEvent.id && !isEventCreator() ? "Close" : ""}
      onSecondary={handleClose}
    />
  );
};

export default EventModal;
