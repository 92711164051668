import http from "./http-common";

class StudentNoteService {

    async getStudentNotes(student_Id: number) {
        try {
            let response = await http.get(`/student_notes/byStudent/${student_Id}/`);
            return response
        }
        catch (error) {
            http.fa360.displayError("Unexpected error while loading student notes");
        }
    }

    async saveStudentNote(Note: any) {
        // Note needs to have text, creator_id, and student_id
        try {
            let response = await http.post(`/student_notes/`, Note);
            return response;
        } catch (error) {
            http.fa360.displayError("Unexpected error while creating student note");
        }
    }

    async deleteStudentNote(note_Id: number) {
        try {
            let response = await http.delete(`/student_notes/${note_Id}/`);
            return response?.data;
        } catch (error) {
            http.fa360.displayError("Unexpected error while deleting student note");
        }
    }

    async updateStudentNote(note_Id: number) {
        try {
            let response = await http.patch(`/student_notes/${note_Id}/`);
            return response?.data;
        } catch (error) {
            http.fa360.displayError("Unexpected error while updating student note");
        }
    }

}

export default new StudentNoteService();