import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Modal } from 'react-bootstrap';
import { Check, FileText } from 'react-feather';
import './fileField.scss';
import { toast } from 'react-toastify';

interface ModalFormProps {
  field: string;
  label?: string;
  includeColon?: boolean;
  register?: any;
  setValue?: any;
  rules?: any;
  errors?: any;
}

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="upload-icon">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
      />
    </svg>
  );
};

function FileField({ field, register, setValue, label = 'Attachment', includeColon = true, rules = {}, errors = null }: ModalFormProps) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const fileSizeInMB = file.size / (1024 * 1024); // To MB
    if (fileSizeInMB > 10) {
      toast.dark("❌ The file is too big, the max file size is 10MB.", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }
    
    setValue(field, acceptedFiles);
    setFileName(file.name);
    closeModal();
  }, []);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function openModal() {
    setIsOpen(true);
  }

  function openFilePicker() {
    fileInputRef.current?.click();
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // To MB
      if (fileSizeInMB > 10) {
        toast.dark("❌ The file is to big, the max file size is 10MB", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        return;
      }
      setValue(field, [file]);
      setFileName(file.name);
      closeModal();
    }
  }

  const getRules = () => {
    if (rules) {
      rules['required'] = false;
    }
    return rules;
  };

  return (
    <div className="form-input-file">
      <div className="btn-container">
        <label className="label-input">
          {label}
          {includeColon && ':'}
        </label>
        <button type='button' className={fileName ? 'btn btn-success input-file' : ' btn btn-outline-primary input-file'} onClick={openModal}>
          {!fileName && Icon()}
          {fileName && <Check size="3xl" />}
          <p className="ms-3 upload-btn">{fileName || 'Upload file'}</p>
        </button>
        <input {...getInputProps()} {...register(field, { ...getRules() })} ref={fileInputRef} onChange={handleFileChange} accept=".pdf" />        
      </div>

      <Modal show={modalIsOpen} centered backdrop={'static'} animation={true} onBackdropClick={closeModal} onEscapeKeyDown={closeModal}>
        <div {...getRootProps()} className="dropzone">
          <div className="modal-container">
            {isDragActive ? (
              <p className="drop-title"> Drop Files Here...</p>
            ) : (
              <div className="modal-message">
                <FileText size="1xl" className="modal-icon" />
                <p>{fileName ? 'Selected: ' + fileName : 'Drag & Drop some files here or click to select files.'}</p>
              </div>
            )}
            <p>(max. 6 MB)</p>
            <p className=" btn btn-dark input-file">
              {Icon()}
              <p className="upload-btn ms-3">Find Files</p>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FileField;
