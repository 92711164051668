import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import documenTemplateService from "../../services/documenTemplate.service";
import Modal from "../modal/modal";
import { PATTERNS } from "../shared/Input/patterns";
import TextField from "../shared/Input/textField";

const EmailTemplateModal = (props: any) => {
    const [selectedTemplates, setSelectedTemplates] = useState([] as any);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const loadData = async () => {
        // Get array of selected documents by searching through the documents array with ids from the selected document array
        let temp = props.selectedTemplates.map((item: any) => props.templates.find((document: any) => document.id === item))
        setSelectedTemplates(temp)

    }

    const checkKeyDown = (e: any) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const handleSend = async (form: any) => {
        form.files = props.selectedTemplates;
        const res = await documenTemplateService.emailTemplates(form);
        if (res?.status === HttpStatusCode.Ok) {

            toast.dark('Documents Sent', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        } else {
            toast.error('❌ Error Sending Documents', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });

        }
    };

    useEffect(() => {
        loadData();
    }, [props.visible]);

    const body = () => {
        return (
            <form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="modal-new-upload-request">
                <TextField
                    field="to"
                    type="text"
                    label="Email to"
                    placeholder="Enter the recipient email address"
                    register={register}
                    errors={errors}
                    rules={{
                        required: true,
                        pattern: PATTERNS.emailRegEx,
                    }}
                />

                <TextField
                    field="subject"
                    type="text"
                    label="Email Subject"
                    placeholder="Enter the subject of the email"
                    register={register}
                    errors={errors}
                    rules={{ required: true }}
                />

                <TextField field="message" type="text" multiLine={true} label="Message signature" register={register} errors={errors} />

                {/* List of documents being sent */}
                <h5>Attached Files:</h5>
                {selectedTemplates.map((doc: any) => (<p key={doc.id}>{doc.name}</p>))}

            </form>
        );
    };

    return (
        <Modal
            body={body()}
            bodyTitle={<h5>Email Templates</h5>}
            bodySubtitle="Email selected templates to recipient"
            size="lg"
            visible={props.visible}
            flyout={true}
            mainButtonText="Send"
            onCancel={props.close}
            onClose={props.close}
            cancelButtonClass="btn-dark"
            onAccept={handleSubmit((d) => handleSend(d))}
        />
    )
}

export default EmailTemplateModal;