import { useEffect, useContext, useState } from "react"
import globalContext from "../../../../context/globalContext";
import ProgramService from "../../../../services/programs.service";
import { Program } from "../../../../models/Program.model";
import { Edit3 as IEdit, Plus as IAdd, EyeOff as IDisable, Eye as IEnable } from "react-feather";
import ActionIcon from "../../../shared/action-icon/actionIcon";
import { School } from "../../../../models/School.model";
import ProgramModal from "../../programModal/programModal";
import "./cardProgramView.scss"
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { toast } from "react-toastify";

interface CardProgramViewProps {

  selectedSchool: School;
  schoolsLength: number;
  showPicker: boolean;
  selectedProgram: Program;
  setSelectedProgram: React.Dispatch<React.SetStateAction<Program>>;
  handleSelectProgram: any;
  handleShowPicker: any;
}


const CardProgramView: React.FC<CardProgramViewProps> = (props) => {

  const { setLoading } = useContext(globalContext);
  const [programs, setPrograms] = useState([] as Program[]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAdding, setModalAdding] = useState(true);

  const alphabetical = (a: Program, b: Program) => {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  }


  const loadData = async () => {
    setLoading(true);

    let programStartDateRes = await ProgramService.getPrograms();
    setPrograms(programStartDateRes);

    setLoading(false);
  }

  const getProgramsHeader = () => {
    if (!props.selectedSchool) return <i>Please select a school</i>;

    let multiSchoolPreHeader = (props.schoolsLength > 1 && props.selectedSchool?.shortName) ? `(${props.selectedSchool?.shortName}) ` : "";
    let headerText = `${multiSchoolPreHeader} Available programs`;

    return (
      <>
        {props.schoolsLength > 1 && !props.showPicker ? (
          <button className="btn btn-sm btn-outline-primary custom-btn" onClick={changeSchools}>
            Change School
          </button>
        ) : null}

        <h5 className="programs-header-text">{headerText}</h5>
        <button className="btn btn-sm btn-outline-primary btn-icon" onClick={() => handleModalVisible(true)}>
          <IAdd />
        </button>
      </>
    );
  };


  const changeSchools = () => {
    props.setSelectedProgram({} as Program);
    props.handleShowPicker();
  };


  const getPrograms = () => {
    if (!props.selectedSchool) return <i>Please select a school</i>;


    return (
      <>
        {programs.filter(x => x.schoolId == props.selectedSchool.id).map((program: Program) => (
          <li key={program.id} className={`program list-group-item list-group-item-action ${!program.enabled ? "disabled" : ""} ${props.selectedProgram.id === program.id ? "active" : ""}`}>
            <div
              className="program-info"
              onClick={() => {
                props.handleSelectProgram(program);
              }}
            >
              <div className="info-name">
                <p>{program.name}</p>
              </div>
              <div className="info-length">
                <p>
                  {program.length} {program?.unit}
                </p>
              </div>
            </div>

            <ActionIcon
              label="Edit"
              tooltip="Edit File Type"
              icon={<IEdit />}
              color="primary"
              onClick={() => editProgramButton(program)}
            />

            <ActionIcon
              label={program.enabled ? "Disable" : "Enable"}
              tooltip={program.enabled ? "Disable Program" : "Enable Program"}
              icon={program.enabled ? <IDisable /> : <IEnable />}
              color={program.enabled ? "danger" : "success"}
              extraClass={program.enabled ?  "always-active enable" : "always-active disable" }
              onClick={async () => await handleProgramEnabled(program)}
            />
            
          </li>

        ))}
      </>
    );
  };
  const editProgramButton = (program : Program) =>{
    props.setSelectedProgram(program);
    handleModalVisible(false);
  }

  const handleProgramEnabled = async (program: any) => {
    let status = program.enabled;
    program.enabled = !status;
    handleEditProgram(program);
  }

  const showToast = (message: string) => {
    toast.dark(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
};


  const handleAddProgram = async (program: Program) => {
    try {
      program.schoolId = props.selectedSchool.id;
      setLoading(true);
      const response = await ProgramService.addProgram(program);
      setLoading(false);

      if (
        (response && response.status === HttpStatusCode.NoContent ||
          response && response.status === HttpStatusCode.Created )
      ) {
        setModalVisible(false);
        var temp = [...programs];
        temp.unshift(response.data);
        temp.sort(alphabetical);
        setPrograms(temp);

        showToast(`✔️ Program Added`);
      } else {
        showToast("❌ Something went wrong, try again");
      }
    } catch (error) {
      showToast("❌ Something went wrong, try again");
    }
  };

  const handleEditProgram = async (program: Program) => {
    try {
      setLoading(true);
      const response = await ProgramService.updateProgram(program);
      setLoading(false);
      props.setSelectedProgram({} as Program);

      if (
        (response && response.status === HttpStatusCode.Ok) ||
        (response && response.status === HttpStatusCode.NoContent)
      ) {
        let temp = [...programs];
        let index = temp.findIndex((element) => element.id === program.id);
        if (index !== -1) {
          temp[index] = program;
        }
        temp.sort(alphabetical);
        setPrograms(temp);

        showToast(`✔️ Saved Changes`);
        setModalVisible(false);
      } else {
        showToast("❌ Unexpected status code, try again");
      }
    } catch (error) {
      showToast("❌ Something went wrong, try again");
      setLoading(false);
    }
    setModalVisible(false);
  };




  const handleModalVisible = (adding: boolean) => {
    setModalVisible(!modalVisible);
    setModalAdding(adding);
    if (modalVisible) {
      props.setSelectedProgram({} as Program);
    }
  };

  useEffect(() => {
    if (!props.selectedSchool) return;

    loadData();
  }, [props.selectedSchool]);

  return (
    <div className="card programs">
      <ProgramModal
        closeModal={handleModalVisible}
        visible={modalVisible}
        selectedProgram={modalAdding ? null : props.selectedProgram}
        handleAddProgram={handleAddProgram}
        handleEditProgram={handleEditProgram}
        school={props.selectedSchool}
      ></ProgramModal>
      <div className="card-header programs-header">
        <div className="d-flex">{getProgramsHeader()}</div>
      </div>

      <div className="card-body overflow-auto">
        <ul className="list-group ">{getPrograms()}</ul>
      </div>
    </div>
  )
}

export default CardProgramView 