export const raceOptions = [
    { value: "American Indian or Alaskan Native", label: "American Indian or Alaskan Native" },
    { value: "Asian", label: "Asian" },
    { value: "Black or African American", label: "Black or African American" },
    { value: "Native Hawaiian or Other Pacific Islander", label: "Native Hawaiian or Other Pacific Islander" },
    { value: "Nonresident alien", label: "Nonresident alien" },
    { value: "Race/ethnicity unknown", label: "Race/ethnicity unknown" },
    { value: "Resident alien (and other eligible non-citizens)", label: "Resident alien (and other eligible non-citizens)" },
    { value: "Two or More Races", label: "Two or More Races" },
    { value: "White", label: "White" },
    { value: "Decline Answer", label: "Decline to answer" },
  ];
  
  export const vaChapters = [
	{ label: "Chapter 30: Montgomery G.I. Bill®", value: "1" },
	{ label: "Chapter 31: Vocational Rehabilitation", value: "2" },
	{ label: "Chapter 32: Veterans Educational Assistance Program (VEAP)", value: "3" },
	{ label: "Chapter 33: Post-9/11 G.I. Bill®", value: "4" },
	{ label: "Chapter 33D: Post-9/11 G.I. Bill® (Dependent)", value: "5" },
	{ label: "Chapter 35: Dependent Education Assitance", value: "6" },
];

export const militaryBranchOptions = [
	{ value: "None", label: "None" },
	{ value: "Air Force", label: "Air Force" },
	{ value: "Army", label: "Army" },
	{ value: "Coast Guard", label: "Coast Guard" },
	{ value: "National Guard", label: "National Guard" },
	{ value: "Navy", label: "Navy" },
	{ value: "Marine Corps", label: "Marine Corps" },
	{ value: "Space force", label: "Space force" },
];

export const maritalStatusOptions = [
	{ value: "divorced", label: "Divorced" },
	{ value: "married", label: "Married" },
	{ value: "separated", label: "Separated" },
	{ value: "single", label: "Single" },
	{ value: "widowed", label: "Widowed" },
];


export const preferredPronounOptions = ["She, her, hers", "He, him, his", "They, them, theirs", "Other"];