import http from "./http-common";
import {
  ApplicationForm,
  ApplicationFormSDGKUStep2Request,
} from '../models/ApplicationForm.model';
import { ApplicationStatus, SchoolID } from '../models/Enums';
import { AxiosResponse } from 'axios';

class ApplicationRequestService {
  // Method in progress
  async getPendingApplicationRequests() {
    try {
      let response = await http.get('/students/pending_applications');
      return response.data;
    } catch (error) {
      http.fa360.displayError('Unexpected error while loading application requests');
    }
  }

  async getFilteredApplicationRequests(fromMonth: any, toMonth: any, status: any): Promise<ApplicationForm[]> {
    try {
      let response = await http.get(`/application_requests/filter/?from=${fromMonth}&to=${toMonth}&status=${status}`);
      return response.data as ApplicationForm[];
    } catch (error) {
      http.fa360.displayError('Unexpected error while loading application request');
      return [];
    }
  }

  async getAllApplicationRequests(): Promise<ApplicationForm[]> {
    try {
      let response = await http.get('/application_requests/');
      return response.data as ApplicationForm[];
    } catch (error) {
      http.fa360.displayError('Unexpected error while loading application request');
      return [];
    }
  }

  async getApplicationRequest(requestId: number, token: string): Promise<ApplicationForm | undefined> {
    try {
      let response = await http.get(`/application_requests/${requestId}/?token=${token}/`);
      return response.data as ApplicationForm;
    } catch (error) {
      http.fa360.displayError('Unexpected error while loading application request');
      return undefined;
    }
  }

  async saveApplicationRequest(request: ApplicationForm): Promise<ApplicationForm | undefined> {
    try {
      let response = await http.post('/application_requests/', request);
      return response.data as ApplicationForm;
    } catch (error) {
      http.fa360.displayError('Unexpected error while saving application');
    }
  }

  async updateApplicationRequest(request: ApplicationForm, token: string) {
    try {
      let response = await http.post(`/application_requests/${request.id}/update_application/`, request);
      return response;
    } catch (error) {
      http.fa360.displayError('Unexpected error while updating application');
    }
  }

  async updateSDGKUAppliactionStep2(request: ApplicationFormSDGKUStep2Request): Promise<AxiosResponse<any>> {
    try {
      let response = await http.post(`/application_requests/${request.requestId}/update_sdgku_step2/`, request);
      return response;
    } catch (error) {
      http.fa360.displayError('Unexpected error while updating application');
      return {} as AxiosResponse<any>;
    }
  }

  async changeApplicationStatus(requestId: number, status: ApplicationStatus) {
    try {
      let response = await http.patch(`/application_requests/${requestId}/status/`, { id: requestId, status });
      return response;
    } catch (error) {
      http.fa360.displayError('Unexpected error while changing application');
    }
  }

  async getReadApplicationToken(formId: number) {
    try {
      let response = await http.post('/auth/generate_application_token/', { id: formId });
      return response;
    } catch (error) {
      http.fa360.displayError('Unexpected error while generating token');
      return null;
    }
  }  
  async getProgramsbyId(school_id: number){
    try {
      let response = await http.get(`/programs/by_school/${school_id}/`);
      return response.data;
    } catch (error) {
      http.fa360.displayError('Unexpected error while loading application request');
      return undefined;
    }
  }

  async getPrograms(){
    try {
      let response = await http.get('programs/all/schools');
      return response.data;
    } catch (error) {
      http.fa360.displayError('Unexpected error while loading application request');
      return undefined;
    }
  }
  
  }


export default new ApplicationRequestService();