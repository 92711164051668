import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import globalContext from '../../context/globalContext';
import documentService from '../../services/document.service';
import Modal from '../modal/modal';

import './zipFileModal.scss';

export interface ZipFileModalProps {
  studentId: number;
  zipVisible: boolean;
  onClose: () => void;
  getSelectedFiles: () => number[];
  onZip: (response: any) => Promise<void>;
}

const ZipFileModal = (props: ZipFileModalProps) => {
  const [docTypeOptions, setDocTypeOptions] = useState([] as any);
  const [zipDocType, setZipDocType] = useState(null as any);
  const [zipFileName, setZipFileName] = useState(null as any);
  const { setLoading, isLoading, loaders } = useContext(globalContext);

  const loadDocTypes = async () => {
    const docTypes = await loaders.documentTypes();
    setDocTypeOptions(docTypes.map((x: any) => ({ value: x.id, label: x.name })));
  };

  const createZip = async () => {
    var payload = {
      documents: props.getSelectedFiles().join(','),
      name: zipFileName.includes('.zip') ? zipFileName : zipFileName + '.zip',
      type: zipDocType.value,
    };

    setLoading(true, 'Creating zip file');
    // calls the api to create a zipfile of the selected documents
    let response = await documentService.zipStudentDocuments(props.studentId, payload);
    setLoading(false);
    props.onZip(response);
  };

  const title = () => {
    return (
      <h5>
        Create <span className="inline-blue">Zip Files</span>
      </h5>
    );
  };

  const body = () => {
    return (
      <div className="zip-file-modal">
        <div className="form-group">
          <label className="d-block">Document Type</label>
          <Select
            className="form-group"
            placeholder="Select document type"
            onChange={(val: any) => {
              setZipDocType(val);
            }}
            options={docTypeOptions}
          />
        </div>

        <div className="form-group">
          <label className="d-block">File Name:</label>
          <input
            type="text"
            className="form-control"
            onChange={(val: any) => {
              setZipFileName(val.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadDocTypes();
  }, []);

  return (
    <Modal
      body={body()}
      bodyTitle={title()}
      visible={props.zipVisible && !isLoading}
      cancelButtonClass="btn-dark"
      mainButtonText="Create Zip"
      onAccept={createZip}
      onCancel={props.onClose}
      onClose={props.onClose}
      mainButtonDisabled={!zipDocType || !zipFileName}
    />
  );
};

export default ZipFileModal;
