import { useEffect, useState } from "react";
import "./barComponent.scss";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export interface BarComponentProps {
  labels: any;
  label: any;
  data: any;
}
const BarComponent = (props: BarComponentProps) => {
  const info = {
    labels: props.labels,
    datasets: [
      {
        label: props.label,
        backgroundColor: "#3d5c7f",
        borderColor: "black",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(0,23,54,0.5)",
        hoverBorderColor: "#3d5c7f",
        data: props.data,
      },
    ],
  };
  return (
    <div className="graph">
      <h5>{info.datasets[0].label}</h5>

      <Bar data={info} options={{ maintainAspectRatio: false, responsive: true }}></Bar>
    </div>
  );
};

export default BarComponent;
