import { useContext, useEffect, useRef, useState } from 'react';
import { Stepper, Step, useStepper } from '../../../../components/shared/stepper/';
import './cobaDisclosure.scss';
import DisclosurePage1 from './disclosurePage1';
import DisclosurePage2 from './disclosurePage2';
import DisclosurePage3 from './disclosurePage3';
import DisclosurePage4 from './disclosurePage4';
import DisclosurePage5 from './disclosurePage5';
import DisclosureMindTapForm from './disclosureMindTapForm';
import { SignedPageData } from '../../../../viewControllers/signedPage';
import DisclosureCompleted from './disclosureCompleted';
import globalContext from '../../../../context/globalContext';
import { AlertType } from '../../../../models/AlertType.enum';
import signedFormService from '../../../../services/signedForm.service';
import { SignedFormKey } from '../../../../models/Forms.enum';
import { SignedForm } from '../../../../models/Forms.model';
import { COBAProgram, COBAProgramFromName } from '../../../../models/Enums';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';

const CobaDisclosure = (props: any) => {
  const NumOfSteps = 5;

  const { clearAlert, setLoading, showAlert } = useContext(globalContext);
  const [token, setToken] = useState('');
  const [studentId, setStudentId] = useState(0);
  const [tokenError, setTokenError] = useState(false);

  const [canMoveNext, setCanMoveNext] = useState(false);
  const [viewControllers, setViewControllers] = useState<SignedPageData[]>([]);
  const { step, incrementStep, decrementStep } = useStepper(0, NumOfSteps);

  const pagesRef = useRef<any[]>([]);

  useEffect(() => {
    loadInitialData();
  }, []);

  const initializeControllers = (formData: SignedForm, program: COBAProgram, token: string) => {
    if (!formData) return;

    const page1 = new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setCanMoveNext);
    const page2 = new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setCanMoveNext);
    const page3 = new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setCanMoveNext);
    const page4 = new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setCanMoveNext);
    const page5 = new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setCanMoveNext);
    // const page5 = new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setCanMoveNext);

    setViewControllers([page1, page2, page3, page4, page5]);
  };

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    const sudentId = params.get('id');

    if (token && sudentId) {
      setToken(token);
      setStudentId(+sudentId);
      loadForm(+sudentId, token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    const resp = await signedFormService.getFormData(studentId, SignedFormKey.DISCLOSURE, token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      const program = COBAProgramFromName(resp.data.programName); // TODO: remove once we store program id on Application Forms
      initializeControllers(resp?.data, program, token);
    }
  };

  const handleDecrementStep = () => {
    decrementStep();
  };

  const handleIncrementStep = async () => {
    if (await pagesRef.current[step].saveData()) {
      // setCanMoveNext(true);
      incrementStep();
    } else {
      //   showAlert('Error while saving. Please verify all field are completed', AlertType.Error);
    }
  };

  const stepControls = () => (
    <div className="stepper-controls">
      <button disabled={step === 0} className="btn btn-sm btn-primary" onClick={handleDecrementStep}>
        <i className="fa fa-chevron-left"></i> Back
      </button>
      <button disabled={!canMoveNext} className="btn btn-sm btn-primary" onClick={handleIncrementStep}>
        {step !== NumOfSteps - 1 ? 'Next' : 'Complete'} <i className="fa fa-chevron-right"></i>
      </button>
    </div>
  );

  const loadingView = () => (
    <div className="loading">
      <h3>Loading data, please wait</h3>
    </div>
  );

  const getPages = () => {
    if (viewControllers.length === 0) return loadingView();

    return (
      <>
        <DisclosurePage1 visible={step === 0} data={viewControllers[0]} ref={(el: any) => (pagesRef.current[0] = el)} />
        <DisclosurePage2 visible={step === 1} data={viewControllers[1]} ref={(el: any) => (pagesRef.current[1] = el)} />
        <DisclosurePage3 visible={step === 2} data={viewControllers[2]} ref={(el: any) => (pagesRef.current[2] = el)} />
        <DisclosurePage4 visible={step === 3} data={viewControllers[3]} ref={(el: any) => (pagesRef.current[3] = el)} />
        <DisclosurePage5 visible={step === 4} data={viewControllers[4]} ref={(el: any) => (pagesRef.current[4] = el)} />
        {/* <DisclosureMindTapForm visible={step === 4} data={viewControllers[4]} ref={(el: any) => (pagesRef.current[4] = el)} /> */}
      </>
    );
  };

  const getCompletedView = () => {
    return <DisclosureCompleted />;
  };  

  const getContent = () => {
    if (tokenError) {
      return (
        <h5 className="error-token">
          Your Token seems invalid or it expired, please get in contact with the university personnel to request a new one if you haven't
          completed the form.
        </h5>
      );
    }

    return (
      <>
        <Stepper step={step}>
          {Array(NumOfSteps)
            .fill(1)
            .map((x, index) => (
              <Step key={index}></Step>
            ))}
        </Stepper>

        {stepControls()}

        {getPages()}

        {stepControls()}
      </>
    );
  };

  return (
    <div className="preapp-form container cobaDisclosure-form">
      <h2>
        <img src="/static/coba/logo-coba.png" alt="coba logo"></img>
      </h2>

      <div className="doc-area">{step !== NumOfSteps ? getContent() : getCompletedView()}</div>
    </div>
  );
};

export default CobaDisclosure;
