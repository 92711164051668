import { useEffect, useState, useContext } from "react";
import globalContext from "../../../../context/globalContext";
import ProgramService from "../../../../services/programs.service";
import { ProgramStartDate } from "../../../../models/Program.model";
import dateService from "../../../../services/date.service";
import ActionIcon from "../../../shared/action-icon/actionIcon";
import {
  XCircle as IconReject,
  Edit3 as IEdit,
  Plus as IAdd,
} from "react-feather";
import StartDateModal from "../../startDateModal/startDateModal";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import "./cardStartDates.scss";
import { toast } from "react-toastify";
const moment = require("moment-timezone");

const CardStartDates = (props: any) => {
  const { setLoading } = useContext(globalContext);
  const [programStartDates, setProgramStartDates] = useState(
    [] as ProgramStartDate[]
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<string>("");
  const [selectedStartDate, setSelectedStartDate] = useState(
    {} as ProgramStartDate | null
  );

  const showToast = (message: string, ) => {
    toast.dark(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const loadData = async (schoolID: string) => {
    if (!schoolID) return;

    setLoading(true);

    let programStartDateRes = await ProgramService.getStartDatesBySchool(
      schoolID
    );
    programStartDateRes.reverse();
    let sortedProgramStartData = [...programStartDateRes].sort(sortDate);
    setProgramStartDates(sortedProgramStartData);

    setLoading(false);
  };

  const getStartDateList = () => {
    return (
      <ul className="list-group">
        {programStartDates
          .filter((x) => x.programId == props.selectedProgram.id)
          .map((date: ProgramStartDate, index) => (
            <li
              key={date.id}
              className="startdate list-group-item list-group-item-action border-bottom"
            >
              <div className="date">
                <p>
                  Start: {dateService.formatYMD(date.startDate)} - End:{" "}
                  {dateService.formatYMD(date.endDate)}
                </p>
              </div>

              <ActionIcon
                label="Edit"
                tooltip="Edit"
                icon={<IEdit />}
                color="primary"
                onClick={() => showEditStartDate(date)}
              />

              <ActionIcon
                label="Delete"
                tooltip="Delete"
                icon={<IconReject />}
                color="danger"
                extraClass="custom-delete-btn"
                onClick={() => handleDeleteStartDate(date.id)}
              />
            </li>
          ))}
      </ul>
    );
  };

  const showEditStartDate = (startDate: ProgramStartDate) => {
    setModalVisible(true);
    setSelectedStartDate(startDate);
  };

  const handleDeleteStartDate = async (startDateId: any) => {
    try {
      setLoading(true);
      let response = await ProgramService.removeStartDate(startDateId);
      setLoading(false);

      if (
        response &&
        (response.status === HttpStatusCode.Ok ||
          response.status === HttpStatusCode.NoContent)
      ) {
        setProgramStartDates(
          programStartDates.filter((x: ProgramStartDate) => x.id !== startDateId)
        );

        showToast("✔️ Start date deleted successfully");
      } else {
        showToast("❌ Error deleting start date. Please try again.");
      }
    } catch (error) {
      showToast("❌ Unexpected error. Please try again.");
    }
  };

  const handleAddStartDate = async (sDate: any, eDate: any) => {
    let startDate = sDate;
    let endDate = eDate;
    let programId = props.selectedProgram.id;

    try {
      if (programId != null && startDate !== "" && endDate !== "") {
        setLoading(true);
        let payload = { startDate, endDate, programId } as ProgramStartDate;
        let response = await ProgramService.addStartDate(payload);
        setLoading(false);

        if (
          response &&
          (response.status === HttpStatusCode.Created ||
            response.status === HttpStatusCode.NoContent)
        ) {
          let temp = [...programStartDates];
          temp.push(response.data);
          let sortedTemp = temp.sort(sortDate);
          setProgramStartDates(sortedTemp);

          showToast("✔️ Start date added successfully");
        } else {
          showToast("❌ Error adding start date. Please try again.");
        }
      }
    } catch (error) {
      showToast("❌ Unexpected error. Please try again.");
    }
  };

  const sortDate = (a: ProgramStartDate, b: ProgramStartDate) => {
    let dateA = a.startDate;
    let dateB = b.startDate;

    return moment(dateB) - moment(dateA);
  };

  const handleModalVisible = (type: string) => {
    setModalType(type);
    setModalVisible(!modalVisible);
  };

  const handleEditStartDate = async (programStartDate: ProgramStartDate) => {
    try {
      setLoading(true);
      let res = await ProgramService.editStartDate(programStartDate);
      setLoading(false);

      if (res?.status === HttpStatusCode.Ok) {
        let temp = [...programStartDates];
        const index = temp.findIndex(
          (startDate: ProgramStartDate) => startDate.id === res?.data.id
        );

        if (index !== -1) {
          temp[index] = res?.data;
          setProgramStartDates(temp);

          showToast("✔️ Start date edited successfully");
        }
      } else {
        showToast("❌ Error updating start date. Please try again.");
      }
    } catch (error) {
      showToast("❌ Unexpected error. Please try again.");
    }
  };

  useEffect(() => {
    if (!props.selectedSchool) return;
    loadData(props.selectedSchool.id);
  }, [props.selectedSchool]);

  return (
    <div className="card startdates-dates scrollable">
      <StartDateModal
        closeModal={handleModalVisible}
        visible={modalVisible}
        selectedStartDate={selectedStartDate}
        handleEditStartDate={handleEditStartDate}
        handleAddStartDate={handleAddStartDate}
        setSelectedStartDate={setSelectedStartDate}
        modalType={modalType}
      ></StartDateModal>
      <div className="card-header startdate-header">
        <h5>Start Dates</h5>
        <button
          onClick={() => handleModalVisible("add")}
          className="btn btn-sm btn-outline-primary btn-icon"
          disabled={!props.selectedProgram?.id}
        >
          <IAdd />
        </button>
      </div>
      <div className="card-body overflow-auto">
        {!props.selectedProgram?.id && (
          <p className="no-data">
            Select a program to see/modify it start dates.
          </p>
        )}
        {getStartDateList()}
      </div>
      <div className="card-footer ">
        <div></div>
      </div>
    </div>
  );
};

export default CardStartDates;