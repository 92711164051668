import './sdgku.header.scss';

export const Header = () => {
    return (
        <header className="header mx-auto px-4 py-2 gap-2 d-flex justify-content-between align-items-center">
            <img src="/static/sdgku/logo.png" alt="san diego global knowledge university" className="img" />
            <p className="address">
                1095 K St., Suite B <br />
                San Diego, CA. 92101 <br />
                U.S.A. <br />
                Tel: (619) 934-0797 <br />
                www.sdgku.edu <br />
            </p>
        </header>
    )
}

export const Header_Mobile = () => {
    return (
        <header className="header_mobile mx-auto mb-4 px-4 py-2 gap-2 d-flex align-items-center">
            <img src="/static/sdgku/logo_mobile.png" alt="san diego global knowledge university" className="logo_mobile ms-auto" />
            <p className="info my-0 me-auto">
                <b>San Diego Global Knowledge University</b><br />
                Website: www.sdgku.edu | Email: info@sdgku.edu | Tel: (619) 934-0797 | Toll Free: (800) 215-0541 | Fax: (888) 454-7320 <br />
                Instruction provided at 1095 K Street, Suite B, San Diego, California 92101
            </p>
        </header>
    )
}