import { EmailType } from "../models/Enums";
import http from "./http-common";

class EmailTemplateService {

    async getTemplateById(id: number) {
        try {
            if (!id) return null;
            let response = await http.get(`/email_templates/${id}/`);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error loading email template");
        }
    }

    async getTemplate(type: EmailType, schoolId: number) {
        try {
            if (!type || !schoolId) return null;
            let response = await http.get(`/email_templates/single/?type=${type}&school=${schoolId}`);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error loading email template");
        }
    }

    async getTemplateSubject(type: EmailType, schoolId: number) {
        try {
            if (!type || !schoolId) return null;
            let response = await http.get(`/email_templates/subject/?type=${type}&school=${schoolId}`);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error loading email template");
        }
    }

    async getTemplates() {
        try {
            let response = await http.get(`/email_templates/`);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error loading email template list");
        }
    }
}

export default new EmailTemplateService();