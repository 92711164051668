import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const AgreementPage3 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement3)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <p>
                <b>Sample Refund Calculation:</b> The following is a time-based refund calculation for a hypothetical example of a student withdrawal from the Full Stack development Immersive program. Students that have withdrawn will receive a refund if the Period Attended is 60% or less. The student in the example below attended less than 60% and is owed a refund. Therefore, the refund calculation proceeds as follows:
                <ul>
                    <li>Charges of Period of Attendance: $10,100.00</li>
                    <li>Number of Days in Period of Attendance: 105 (15 weeks)</li>
                    <li>Number of Days Attended in the Period: 36</li>
                    <li>Percent of Period Attended: (36 days  105 days) X 100% = 34%</li>
                    <li>Period Attended is 34%, which is less than 60%, therefore a refund is owed to student.</li>
                    <li>Amount Owed to Student: (100% - 34%) X $10,100.00 = $6,666.00</li>
                </ul>
            </p>

            <p>
                <b>Withdrawal and Reentry:</b> For determining the amount you owe for the time you attended you shall be deemed to have withdrawn from the course or program when any of the following occurs:
            </p>
            <ol>
                <li>You notify the Director of Admissions and Registrar of withdrawal or the actual date of withdrawal by mail (1095 K Street, Suite B, San Diego, CA 92101), email (info@sdgku.edu), or telephone (619-934-0797).</li>

                <li>The university terminates enrollment or expels you.</li>

                <li>The student fails to attend classes for 14 calendar days. In this case, the date of withdrawal should be deemed to be the last date of recorded attendance. (Unofficial withdrawals will be determined by monitoring attendance every 30 days).</li>

                <li>If an approved leave of absence (LOA) is allowed and you tell the school that you will not be returning, the withdrawal date shall be the earlier of the scheduled date of return from the LOA or the date the student notifies the school of not returning.</li>
            </ol>

            <p>
                A student who withdraws in good standing may be accepted for reentry at the next class start date based on seating availability and that the discretion of the school official.
            </p>
            <p>
                <b>STUDENT’S RIGHT TO CANCEL:</b> You have the right to cancel the enrollment agreement and obtain a refund of charges paid through attendance at the first-class session, or the seventh day after enrollment, whichever is later. If the instruction is not offered in real time, the student shall have the right to cancel the enrollment agreement and receive a full refund before the first lesson and materials are received. This is applicable to online and hybrid courses. Cancellation is effective on the date written notice of cancellation is sent. San Diego Global Knowledge University shall make the refund pursuant to section 71750 of the CA Ed. Code Regulations. If San Diego Global Knowledge University sent the first lesson and the materials before an effective cancellation notice was received, San Diego Global Knowledge University shall make a refund within 30 days after the student’s return of the materials. You may cancel this contract and receive a refund by submitting notice to: San Diego Global Knowledge University, 1095 K Street, Ste. B, San Diego, California 92101. Students who cancel within seven days receive a refund of all charges including the registration/enrollment fee. Students who cancel more than seven days after enrollment receive a refund of all charges minus the registration/enrollment fee.
            </p>
            <p>
                <b>Federal Student Financial Aid Programs: </b> SDGKU complies with applicable regulations of the federal student financial aid programs under Title IV of the federal Higher Education Act of 1965. SDGKU advises each student that a notice of cancellation shall be in writing, and that a withdrawal may be effectuated by the student’s written notice or by the student’s conduct, including, but not necessarily limited to, a student’s lack of attendance. A pro rata refund of nonfederal student financial aid program moneys will be made for institutional charges to students who have completed 60 percent or less of the period of attendance. SDGKU will refund 100 percent of the amount paid for institutional charges, less a reasonable deposit or application fee not to exceed two hundred fifty dollars ($250), if notice of cancellation is made through attendance at the first class session, or the seventh day after enrollment, whichever is later.
            </p>
                        
            <div className="position-relative">
                {getInitials('page3')}
            </div>
        </div>
    )
})