import axios, { AxiosInstance } from "axios";
import React from "react";
import { HttpStatusCode } from "../models/common/HttpStatusCode.enum";
import { errorToHtml } from "../components/utils/error.utils";

const KEY_SEND_TO_LOGIN = 'LOGIN_REQUIRED';


export interface IAxiosFA360 {
  error: any;
  response: any;
  showError: (message: any) => void;
  getErrorMessage: () => string;
  getErrorToDisplay: (errorTitle: string) => JSX.Element;
  displayError: (errorTitle: string) => void;
}

export interface CustomAxios extends AxiosInstance {
  history: any;
  fa360: IAxiosFA360;
}

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
}) as CustomAxios;

instance.interceptors.request.use((config) => {
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    let fa = instance.fa360;
    fa.error = error.response?.data || error.response || undefined;
    fa.response = error.response;

    if (error.config.url === '/auth/me/') {
      return undefined;
    }

    if (
      fa.error?.key === KEY_SEND_TO_LOGIN &&
      !instance.history.location.pathname.includes('/logout') &&
      !instance.history.location.pathname.includes('/public')
    ) {
      //catches if the session ended!
      instance.history?.push('/public/logout');
    }

    throw error;
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === HttpStatusCode.Forbidden) {
      //instance.history?.push('/public/logout');
    }
    throw error;
  }
);

instance.fa360 = {
  error: {},
  response: {},

  showError: (message: any) => {},

  getErrorMessage() {
    if (this.error?.key === KEY_SEND_TO_LOGIN) {
      return KEY_SEND_TO_LOGIN;
    }

    if (this.response.status === 500) {
      console.log(this.error);
      return '[500] Internal Server Error';
    }

    if (this.error?.error) return this.error.error;
    //if (this.error?.detail) return this.error.detail;

    try {
      return <ul>{errorToHtml(this.error)}</ul>;
    } catch {
      return `[${this.response.status}] ${this.response.statusText}`;
    }
  },

  getErrorToDisplay(errorTitle: string): JSX.Element | null {
    let errorMessage = this.getErrorMessage();
    if (errorMessage === KEY_SEND_TO_LOGIN) {
      if (instance.history.location.pathname.includes('/public')) return null;

      errorTitle = 'Session ended';
      errorMessage = 'Please login again';
    }

    return (
      <React.Fragment>
        <h6>{errorTitle}</h6>
        {errorMessage}
      </React.Fragment>
    );
  },

  displayError(error) {
    let errorElement = this.getErrorToDisplay(error);
    errorElement && this.showError?.(errorElement);
  },
} as IAxiosFA360;

export default instance;

// todo: add error.response.data to response to show more info
/* example content of a 400 bad request
      {firstName: Array(1), lastName: Array(1), email: Array(1), phone: Array(1)}
        email: ["This field may not be blank."]
        firstName: ["This field may not be blank."]
        lastName: ["This field may not be blank."]
        phone: ["This field may not be blank."]
    */
