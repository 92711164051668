import classNames from './sdgku.vib.module.css'; // we are not doing this anymore
import "./sdgku.vib.scss";
import '../index.css';
import TextField from '../../../../components/shared/Input/textField';
import { useForm } from 'react-hook-form';
import { ApplicationForm } from '../../../../models/ApplicationForm.model';
import { useContext, useEffect, useState } from 'react';
import { ProgramRequirementsFees } from '../types';
import { getVibProgramCourses } from '../sdgkuController';
import { AlertType } from '../../../../models/AlertType.enum';
import globalContext from '../../../../context/globalContext';
import { FinishPage, TokenError } from '../enrollment/sdgku.extrapages';
import { SDGKUFormsPage, SignedFormKey } from '../../../../models/Forms.enum';
import signedFormService from '../../../../services/signedForm.service';
import { SDGKUProgram, SDGKUProgramFromName } from '../../../../models/Enums';
import { SignedPageData } from '../../../../viewControllers/signedPage';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import usePageSignature from '../../../../hooks/pageSignature';
import { SignedForm } from '../../../../models/Forms.model';

interface Props {
  match: any;
  location: any;
}

const formatMoney = (number: number): string => {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const VIB = ({ match, location }: Props) => {
  const [programData, setProgramData] = useState<ProgramRequirementsFees>();
  const { setLoading, showAlert } = useContext(globalContext);
  const [tokenError, setTokenError] = useState(false);
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { saveForm, getSignature, getAcknowledge } = usePageSignature(viewController);
  const [completed, setCompleted] = useState(false);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    loadInitialData();
  }, []);

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<ApplicationForm>();

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const sudentId = params.get('id');

    if (token && sudentId) {
      loadForm(+sudentId, token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    const resp = await signedFormService.getFormData(studentId, SignedFormKey.VIB, token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      const program: SDGKUProgram = SDGKUProgramFromName(resp.data.programName); // TODO: remove once we store program id on Application Forms
      getVibProgramCourses(program).then(setProgramData);
      initializeViewController(resp?.data, program, token);
    }
  };

  const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
    if (!formData) return;

    setValue('student.firstName', formData.student.firstName);
    setValue('student.lastName', formData.student.lastName);
    setFullName(`${formData.student.firstName} ${formData.student.lastName}`);

    setViewController(new SignedPageData(SignedFormKey.VIB, program, formData, token, setEnableSubmit));
  };

  const submitForm = async () => {
    if (await saveForm(SDGKUFormsPage.VIB, true)) {
      // setCanMoveNext(true)
      setCompleted(true);
    } else {
      showAlert('Error while saving. Please verify that all fields are completed', AlertType.Error)
    }
  };

  if (tokenError) return <TokenError />;

  if (completed)
    return (
      <>
        <div className="doc-area">
          <header className={`${classNames.header} d-flex flex-column pb-4`}>
            <div className={`${classNames.info} ${classNames.bg_gradient} mx-auto mt-2`}>
              <h1 className="text-center">VETERANS INFORMATION BULLETIN</h1>
            </div>
            <img className={classNames.img} src="/static/sdgku/logo.png" alt="san diego global knowledge university logo" />
            <p className="m-0 text-center">January 1, 2024 – December 31, 2025</p>
            <p className="m-0 text-center">Addendum to General Catalog</p>
          </header>
          <FinishPage pageMessage="VIB form" />
        </div>
      </>
    );

  return (
    <div className="doc-area">
      <header className={`${classNames.header} d-flex flex-column pb-4`}>
        <div className={`${classNames.info} ${classNames.bg_gradient} mx-auto mt-2`}>
          <h1 className="text-center">VETERANS INFORMATION BULLETIN</h1>
        </div>
        <img className={classNames.img} src="/static/sdgku/logo.png" alt="san diego global knowledge university logo" />
        <p className="m-0 text-center">January 1, 2024 – December 31, 2025</p>
        <p className="m-0 text-center">Addendum to General Catalog</p>
      </header>
      <div className="doc-content">
        <h2 className={`${classNames.bg_gradient} ${classNames.banner} pt-4 pb-2 mt-2 px-2`}>SDGKU</h2>
        <div className="container-fluid mt-4">
          <div className="row align-items-center py-1">
            <div className="col-6">
              <p>Name of Institution:</p>
            </div>
            <div className="col-6">
              {/* uppercase */}
              <p className="text-uppercase">San Diego Global Knowledge University</p>
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">
              <p>Physical Address:</p>
            </div>
            <div className="col-6">
              <p>
                1095 K Street, Suite B <br />
                San Diego, CA 92101
              </p>
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">Mailing Address:</div>
            <div className="col-6">
              <p>
                1095 K Street, Suite B <br />
                San Diego, CA 92101
              </p>
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">
              <p>Telephone Number:</p>
            </div>
            <div className="col-6">
              <p>(619) 934-0797 / Toll Free (800) 251-0541</p>
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">
              <p>Fax</p>
            </div>
            <div className="col-6">
              <p>(888) 454-7320</p>
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">
              <p>Approved by:</p>
            </div>
            <div className="col-6">
              Bureau for Private Postsecondary Education <br />
              Institution Approval School Code #76587244
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-12">
              <p>Approved for the training of veterans and eligible persons under the provisions of Title 38, United States Code.</p>
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">
              <p>Accreditation:</p>
            </div>
            <div className="col-6">
              Accrediting Council for Independent Colleges and Schools <br />
              School Code: 00264110
            </div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-8">US Department of Education OPE ID:</div>
            <div className="col-4">04265700</div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-6">VA Facility Code:</div>
            <div className="col-6">21-1203-05</div>
          </div>
          <div className="row align-items-center py-1">
            <div className="col-12">Bulletin effective January 1, 2024 – December 31, 2025</div>
          </div>
        </div>
        <hr className={`${classNames.division}`} />
        <p>The information contained in this bulletin is true and correct in content and policy.</p>
        <div className="container-fluid text-center">
          <div className="row align-items-center py-1">
            <div className="col-6">
              <img className={classNames.signature} src="/static/sdgku/signature.png" />
              <p>
                Dr. Miguel A Cardenas <br />
                Chief Academic Officer
              </p>
            </div>
            <div className="col-6">Date: December 17, 2023</div>
          </div>
        </div>
        <h3>
          <b>SCHOOL GOVERNING BODY, ADMINISTRATORS, AND FACULTY</b>
        </h3>
        <ol type="A">
          <li>
            <u>Owner</u>
            <p>
              San Diego Global Knowledge University <br />
              (A California Corporation)
            </p>
          </li>
          <li>
            <u>Directors</u>
            <p>
              Dr. Miguel A. Cardenas - President <br />
              Dr. Miguel A. Cardenas Jr. – Secretary/Treasurer
            </p>
          </li>
          <li>
            <u>Administrative Officials</u>
            <p>
              Dr. Miguel A. Cardenas – President <br />
              Dr. Miguel A. Cardenas Jr. – Chief Academic Officer/Veterans Record Clerk/VA School Certifying Official <br />
              Devahn Parker – Director of Admissions and Registrar/VA School Certifying Official <br />
              Tonya Parker-Jones – Chief Compliance Officer/VA School Certifying Official <br />
            </p>
          </li>
          <li>
            <u>Instructors</u>
            <div className="instructors d-flex">
              <p>
                Dr. Fernanda Murillo <br />
                Dr. Sergio Inzunza <br />
                Dr. Samantha Jimenez <br />
                Mr. Rafael Gonzalez <br />
                Dr. Mayra Nava <br />
                Dr. Eric Rios <br />
                Dr. Miguel A. Cardenas <br />
                Dr. Miguel A. Cardenas Jr <br />
              </p>
              <p className='pl-5'>
                Dr. Patricia Escobar <br />
                Dr. Cecilia Rico <br />
                Dr. Jorge Fernandez Osiris Arias <br />
                Mr. Marial del Carmen Perez <br />
                Dr. Gerardo Arturo Galvan Rubio <br />
                Dr. Marco Antonio Galvan Rubio <br />                
              </p>
            </div>
          </li>
          <li>
            <u>Librarian</u>
            <p>Briana Ochoa</p>
          </li>
        </ol>
        <h3>
          <b>INSTRUCTIONAL FACILITIES</b>
        </h3>
        <p>
          The school’s physical address is 1095 K Street, Suite B, San Diego, California, 92101. It consists of 2000 square feet of classrooms and offices on one floor specifically designed for the school’s operations. There are three
          administrative offices and two separate classrooms, each with a capacity for 25 students, with teaching equipment. There is also a break room and restroom that is located in the facility. Maximum capacity for each course is
          follows:
        </p>
        <ul className="list-unstyled">
          <li>BS degree courses – 50</li>
          <li>MS degree courses – 50</li>
          <li>Non-degree courses – 50</li>
        </ul>
        <h3>
          <b>ENTRANCE REQUIREMENTS</b>
        </h3>
        <p>
          Enrollment in any of our courses and programs requires that prospective students possess a high school diploma or its equivalent. Additionally, a bachelor’s degree is required for master’s degree courses. Each person is accepted
          only if, in the school’s opinion, such individual has the possibility of success in the chosen program or course of study.
        </p>
        <h3>
          <b>POLICIES</b>
        </h3>
        <ol type="A">
          <li>
            <u>Enrollment Policy</u>
            <p>
              Students may enroll on any day school is in session. SDGKU complies with Title 38 of the United States Code (U.S.C) §3679(e) and pertains to educational institutions approved under Title 38 U.S.C from the Veterans Benefits and
              Transition Act of 2018. In particular, SDGKU ensures that:
            </p>
            <ol type="1">
              <li>
                <p>
                  Permits any covered individual to attend or participate in the course of education during the period beginning on the date on which the individual provides to SDGKU a certificate of eligibility for entitlement to
                  educational assistance under chapter 31 or 33 of this title and ending on the earlier of the following dates:
                </p>
                <ol type="a">
                  <li>
                    <p className='mb-0'>
                      Permits any covered individual to attend or participate in the course of education during the period beginning on the date on which the individual provides to SDGKU a certificate of eligibility for entitlement to
                      educational assistance under chapter 31 or 33 of this title and ending on the earlier of the following dates:
                    </p>
                  </li>
                  <li>
                    <p>The date that is 90 days after the date on which SDGKU certifies for tuition and fees following receipt from the student such certificate of eligibility.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  Will not impose any penalty, including the assessment of late fees, the denial of access to classes, libraries, or other institutional facilities, or the requirement that a covered individual borrow additional funds, on
                  any covered individual because of the individual's inability to meet his or her financial obligations to SDGKU due to the delayed disbursement of a payment to be provided by the Secretary under chapter 31 or 33 of this
                  title.
                </p>
              </li>
              <li>
                <p>For purposes of this subsection, a covered individual is any individual who is entitled to educational assistance under chapter 31 or 33 of this title.</p>
              </li>
              <li><p>The Secretary may waive such requirements of paragraph (1) as the Secretary considers appropriate.</p></li>
              <li>
                It shall not be inconsistent with a policy described in paragraph (1) for SDGKU to require a covered individual to take the following additional actions:
                <ol type="a">
                  <li>
                    Submit a certificate of eligibility for entitlement to educational assistance not later than the first day of a course of education for which the individual has indicated the individual wishes to use the individual's
                    entitlement to educational assistance.
                  </li>
                  <li>Submit a written request to use such entitlement.</li>
                  <li>Provide additional information necessary to the proper certification of enrollment by the educational institution.</li>
                </ol>
              </li>
            </ol>
          </li>
            <br />
          <li>
            <u>Credit Evaluation Policy</u>
            <p>
              Students with previous training in the course to be pursued may be tested upon enrollment and given appropriate credit if approved. Evaluation will be based upon either a written exam, and oral exam, or both. Transfer credits
              obtained at other universities may be evaluated based on school policy per the General Catalog.
            </p>
            <p>
              The student’s record will clearly indicate if a credit has been granted, with the training period shortened proportionately and the student notified accordingly, per 21.4253 (d) (3). If there is no credit to be granted, SDGKU
              will have the student acknowledge no credit is granted prior to enrollment.
              <br />
              (NOTE: ALL PRIOR TRAINING MUST BE EVALUATED.)
            </p>
          </li>
          <li>
            <u>Attendance Policy</u>
            <ol type="1">
              <li>
                <p>
                  <u>Absence</u>– Absence will be considered excused under the following circumstances: Illness, death, or birth in the immediate family, and other valid reason substantiated in writing and at the discretion of the Chief
                  Academic Officer. All other absences will be considered unexcused.
                </p>
              </li>
              <li>
                <p>
                  <u>Tardiness</u>– Tardiness is a disruption of a good learning environment and is to be discouraged. Tardiness without legitimate reason on two occasions in one class will be considered as one unexcused absence.
                </p>
              </li>
              <li>
                <p>
                  <u>Interruption for Unsatisfactory Attendance</u>– Students with three unexcused absences in any class may receive notification of attendance warning for a period of 5-weeks. Any unexcused absences during such warning
                  period will be a cause for interruption of the student’s training program.
                </p>
              </li>
              <li>
                <p>
                  <u>Cutting Classes</u> – Cutting of classes will be considered as unexcused absences.
                </p>
              </li>
              <li>
                <p>
                  <u>Make-Up Work</u> – Make-up work may be required for any absence. However, hours of makeup work cannot be accepted as hours of class attendance.
                </p>
              </li>
              <li>
                <p>
                  <u>Leave of Absence</u> – Written requests for leaves of absence will be considered and such leaves may be granted to students at the discretion of the school. However, if a leave of absence is granted, the student’s
                  enrollment certification with the VA will be terminated, until such time that the student returns from the leave of absence and is re-certified as active with the VA to receive education benefits.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <u>Progress Policy</u>
            <ol type="1">
              <li>
                <p>
                  The grade point average (GPA) required for certifying completion of a program is C (2.0) for non-degree and bachelor’s programs, and B (3.0) for master’s programs. Upon successful completion, a diploma or certificate will
                  be awarded.
                </p>
              </li>
              <li>
                <p>
                  Conditions for interruption due to unsatisfactory progress: <br />
                  Veteran students are held to the standards of academic progress as stated in the General Catalog. If a veteran student is placed on probation status their enrollment certification will be terminated and the VA will be
                  promptly notified.
                </p>
              </li>
              <li>
                <p>
                  Condition for re-enrollment: <br />
                  Re-enrollment or re-entrance will be approved only after evidence is shown to the Chief Academic Officer’s satisfaction that conditions which caused the interruption for unsatisfactory progress have been rectified.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <u>Conduct Policy</u>
            <p>
              Students shall at all times when on school premises conduct themselves in an orderly and considerate manner and shall appear for classes in a sober and receptive condition. Violation of this condition is a just cause for
              dismissal. All veteran students must abide by the student conduct policies per the General Catalog.
            </p>
          </li>
          <li>
            <u>Refund Policy</u>
            <p>
              Students may withdraw from a course after instruction has started and receive a pro-rata refund for the unused portion of the tuition and other refundable charges if the student has completed 60% or less of the instruction. If
              the school cancels or discontinues a course or educational program, the school will make a full refund of all charges. Refunds will be paid to the student within 30 days of cancellation or withdrawal.
            </p>
          </li>
        </ol>
        <h3 className='mt-4'>
          <b>COURSE REQUIREMENTS AND FEES</b>
        </h3>
        {
          // TODO: Add course requirements and fees
        }
        <h4>
          <u>{programData?.programName}</u>
        </h4>
        <p>The program curriculum consists of the following required number of courses and hours per course:</p>
        <div className="container mx-auto p-no-space">
          {programData?.courses?.map((course, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-sm-2 col-0" />
                <div className="col-sm-6 col-9">
                  <p className='mb-0'>{course?.courseName}</p>
                </div>
                <div className="col-sm-2 col-3 text-right">
                  <p className='mb-0'>{`${course?.courseHours} hours`}</p>
                </div>
                <div className="col-sm-2 col-0" />
              </div>
            );
          })}
          <div className="row">
            <div className="col-8 col-sm-6" />
            <div className="col-4 text-right">
              <p>
                <b className='total-border'>{programData?.courses?.reduce((total, course) => total + course.courseHours, 0)} Total Hours</b>
              </p>
            </div>
          </div>
        </div>
        <p>
          <i>Note:</i> {programData?.courseNote}{' '}
        </p>
        <h5>Couse Fees</h5>
        <div className="container mx-auto">
          {programData?.fees?.map((fee, index) => {
            return (
              <div className="row" key={index}>
                <div className="col-sm-2 col-0" />
                <div className="col-sm-6 col-10">
                  <p className='mb-0'>{fee?.feeName}</p>
                </div>
                <div className="col-2 text-right">
                  <p className='mb-0'>{fee?.feeAmount > 0 ? `$${formatMoney(fee?.feeAmount)}` : 'Waived'}</p>
                </div>
                <div className="col-sm-2 col-0" />
              </div>
            );
          })}
          <div className="row">
            <div className="col-8 col-sm-6" />
            <div className="col-4 text-right">
              <p>
                <b className='total-border'>${programData?.fees && formatMoney(programData.fees.reduce((total, fee) => total + fee.feeAmount, 0))} Total Cost</b>
              </p>
            </div>
          </div>
        </div>
        <h3>
          <b className='pt-5'>REIMBURSEMENT TO VETERANS AND ELIGIBLE PERSONS</b>
        </h3>
        <p>For More information or for the resolution of specific payment problems, the veteran should call the DVA nationwide toll-free number at 1-888-442-4551.</p>
        <h3>VETERANS INFORMATION BULLETIN</h3>
        <p>
          SAN DIEGO GLOBAL KNOWLEDGE UNIVERSITY <br />
          1095 K Street, Suite B <br />
          San Diego, CA 92101 <br />
        </p>
        <p className="position-relative pl-4 mt-3">
          {getAcknowledge(
            'received',
            `I have received a copy of the Veterans Information Bulletin, which contains
                    the rules, regulations, course completion requirements, and costs for the
                    specific course in which I have enrolled.`
          )}
        </p>
        <div className='mt-3'>
          <div style={{ maxWidth: '50ch' }}>
            <TextField disabled errors={errors} label="Name (Veteran or Eligible Person)" field="student.firstName" value={fullName} type="text" register={register} />
          </div>
          <div className="pad">{getSignature()}</div>
        </div>
        <div className="text-center pb-2">
          <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
            Save and Complete
          </button>
        </div>
      </div>
      <footer className={`${classNames.footer} w-100 text-center p-3 mb-3 mt-5 fw-bold`}>
        <p className="m-0">VETERANS INFORMATION BULLETIN Rev. 9/17/2023</p>
      </footer>
    </div>
  );
};
