import { AgreementProps } from '../../types';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import usePageSignature from '../../../../../hooks/pageSignature';
import { SDGKUFormsPage } from '../../../../../models/Forms.enum';
import '../../index.css';
import TextField from '../../../../../components/shared/Input/textField';
import { useForm } from 'react-hook-form';
import dateService from '../../../../../services/date.service';

const formatMoney = (number: number): string => {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

interface AgreementPage2Form {
  cancellationDate: string;
}

export const SpanishAgreementPage2 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
  const { saveForm, getInitials } = usePageSignature(data, false);
  const { register, setValue } = useForm<AgreementPage2Form>();
  let cancellDate = "";
  if (data?.formData?.cancelDate) {
    cancellDate = dateService.formatYMD(data.formData.cancelDate);
  }

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(SDGKUFormsPage.Agreement2);
    },
  }));

  useEffect(() => {
    visible && window.scrollTo(0, 0);
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="doc-content py-4">
      <p>
        <b>Preguntas:</b> Cualquier pregunta que un estudiante pueda tener en relación con este acuerdo de inscripción que no haya sido respondida satisfactoriamente por la
        institución puede dirigirse a la Oficina de Educación Postsecundaria Privada (Bureau for Private Postsecondary Education) en 1747 North Market Blvd., Suite 225,
        Sacramento, CA 95834, www.bppe.ca.gov, teléfono (916) 574-8900 y fax (916) 263-1897.
      </p>
      <p>
        <b>Queja:</b> Un estudiante o cualquier miembro del público puede presentar una queja sobre esta institución ante la Oficina del Bureau for Private Postsecondary
        Education llamando al (888) 370-7589 (llamada gratuita) o llenando un formulario de queja, que puede obtenerse en el sitio web de la oficina www.bppe.ca.gov. Un
        estudiante o cualquier miembro del público también puede presentar una queja ante la Comisión de Acreditación de Educación a Distancia (Distance Education Accrediting
        Commission) en www.deac.org.
      </p>
      <h4>INFORMACIÓN/POLÍTICA DE CANCELACIÓN Y REEMBOLSO:</h4>
      <p>
        El estudiante tiene derecho a cancelar el acuerdo de inscripción y obtener el reembolso de los cargos pagados hasta la asistencia a la primera sesión de clase, o el
        séptimo día después de la inscripción, lo que ocurra más tarde. Los estudiantes que cancelen en un plazo de siete días recibirán el reembolso de todos los cargos, incluida
        la cuota de inscripción. Los estudiantes que cancelen más de siete días después de la inscripción recibirán el reembolso de todos los cargos menos la cuota de inscripción/registro.
        Si la instrucción no se ofrece en tiempo real, el estudiante tendrá derecho a cancelar el acuerdo de inscripción y recibir un reembolso completo antes de recibir la primera
        lección y los materiales. Esto es aplicable a los cursos en línea e híbridos.
      </p>
      <p>
        La institución aplica una política de prorrateo, basada en el número de días matriculados dividido por el número de días totales de un periodo de matrícula. Si un estudiante
        pagó tasas y no accedió al programa de instrucción, o no asistió a clase, se le devolverán todas las tasas excepto la tasa de inscripción/matrícula no reembolsable. El reembolso
        se enviará por correo en un plazo de 30 días a partir del momento de la baja o cancelación, de acuerdo con el calendario que figura a continuación.
      </p>
      <p>
        Los estudiantes pueden darse de baja una vez iniciada la enseñanza y recibir un reembolso prorrateado de la parte no utilizada de la matrícula y otros gastos reembolsables si
        el estudiante ha completado el 60% o menos del periodo de asistencia. Un periodo de asistencia es de 15 semanas (105 días) para los programas de Certificado (sin titulación) y
        Maestría. Un periodo de asistencia es de 20 semanas (140 días) para los programas de Licenciatura. Un ejemplo hipotético de un período de asistencia de 20 semanas (140 días) de
        $1200 tiene el siguiente calendario de reembolso:
      </p>
      <p className="text-center">
        <b>Ejemplo Después de asistir</b>
      </p>
      <table className="table table-bordered table-refund-example">
        <thead>
          <tr>
            <th className="fw-bold" scope="col">
              Días programados
            </th>
            <th className="fw-bold" scope="col">
              Reembolso
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>7 días</td>
            <td>$1,200.00</td>
          </tr>
          <tr>
            <td>28 días</td>
            <td>$960.00</td>
          </tr>
          <tr>
            <td>56 días</td>
            <td>$720.00</td>
          </tr>
          <tr>
            <td>84 días (60%)</td>
            <td>$480.00</td>
          </tr>
          <tr>
            <td>Después de 84 días </td>
            <td>Sin reembolso</td>
          </tr>
        </tbody>
      </table>
      <p>
        Si la escuela cancela o interrumpe un curso o programa educativo, la escuela hará un reembolso completo de todos los cargos. Los reembolsos se
        abonarán en un plazo de 30 días a partir de la cancelación o retirada.
      </p>
      <p>
        <div className="pl-0 col-4 mb-5">
          <TextField disabled label="Fecha límite para cancelar" field="cancellationDate" type="text" register={register} value={cancellDate || "Loading..."} />
        </div>
      </p>
      <div className="position-relative">{getInitials('page2')}</div>
    </div>
  );
});
