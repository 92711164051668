import { FunctionComponent, useEffect, useState } from 'react';
import './pagination.scss';
import PaginationItem from './paginationItem';

interface PaginationProps {
  pageIndex?: number;
  pageSize?: number;
  totalRows: number;
  paginationButtons?: number;
  autoHide?: boolean;
  pageSizes?: number[];

  onGotoPage?: (page: number) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onSetPageSize?: (size: number) => void;
}

const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const defaultPaginationButtons = 6;
  const defaultPageSize = 15;
  const [pageIndex, setPageIndex] = useState(props.pageIndex || 1);
  const [pageCount, setPageCount] = useState(Math.ceil(props.totalRows / (props.pageSize || defaultPageSize)));
  const [pageSizes, setPageSizes] = useState(props.pageSizes || [15, 30, 50, 100]);

  useEffect(() => {
    setPageIndex(props.pageIndex || 0);
  }, [props.pageIndex]);

  useEffect(() => {
    setPageCount(Math.ceil(props.totalRows / (props.pageSize || defaultPageSize)));
  }, [props.totalRows, props.pageSize]);

  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;

  const onPreviousPage = () => {
    if (!canPreviousPage) return;
    setPageIndex(pageIndex - 1);
    props.onPreviousPage();
  };

  const onNextPage = () => {
    if (!canNextPage) return;
    setPageIndex(pageIndex + 1);
    props.onNextPage();
  };

  const onGotoPage = (page: number) => {
    setPageIndex(page);
    props.onGotoPage?.(page);
  };

  const getPageOptions = () => {
    const pageOptions = [];
    for (let i = 0; i < pageCount; i++) {
      pageOptions.push(
        <option key={i} value={i}>
          {i + 1}
        </option>
      );
    }
    return pageOptions;
  };

  const getVisiblePages = () => {
    const buttons = props.paginationButtons || defaultPaginationButtons;
    const visiblePages = [];
    const half = Math.floor(buttons / 2);
    let start = pageIndex - half + 1 - (buttons % 2);
    let end = pageIndex + half + 1;

    if (start < 0) {
      end -= start;
      start = 0;
    }
    if (end > pageCount) {
      start -= end - pageCount;
      end = pageCount;
      if (start < 0) start = 0;
    }
    for (let i = start; i < end; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  };

  if (props.autoHide && props.totalRows < pageSizes[0]) return null;

  return (
    <nav aria-label="Page navigation" className="general-table-pagination">
      <ul className="pagination justify-content-center">
        <PaginationItem text="&laquo;" action={onPreviousPage} disabled={!canPreviousPage} />
        {getVisiblePages().map((p) => (
          <PaginationItem key={p} text={(p + 1).toString()} action={() => onGotoPage(p)} active={pageIndex === p} />
        ))}
        <PaginationItem text="&raquo;" action={onNextPage} disabled={!canNextPage} />

        <li className="current-page">
          Page {pageIndex + 1} of {pageCount}
        </li>

        <li className="goto-page">
          <label>Go to </label>
          <select className="custom-select" value={pageIndex} onChange={(x) => onGotoPage(+x.target.value)}>
            {getPageOptions()}
          </select>
        </li>

        <li className="page-size">
          <label>Page size </label>
          <select className="custom-select" value={props.pageSize || defaultPageSize} onChange={(x) => props.onSetPageSize?.(+x.target.value)}>
            {pageSizes.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </li>

        <li className="total-records">{props.totalRows} records total</li>
      </ul>
    </nav>
  );
};

export default Pagination;
