import "../facts.scss";
import { Fact as Props } from '../'

export const Fact = ({ title, data }: Props) => {
    return (
        <div className={`facts mb-4`}>
            <h2><u>{title}</u></h2>
            Total charges for the program for students completing on time in {data?.[0][0]}: ${data?.[0][1]} <br />
            <i>Additional charges may be incurred in the program is not completed on time.</i><br /><br />

            Total charges for the program forstudents completing on time in {data?.[1][0]}: ${data?.[1][1]}<br />
            <i>Additional charges may be incurred in the program is not completed on time.</i><br />
        </div>
    )
}