import { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import globalContext from "../../../context/globalContext";
import authService from "../../../services/auth.service";

import "./changePassword.scss";

const ChangePassword = (props: any) => {
  const { setLoading } = useContext(globalContext);

  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleProceed();
    }
  };

  const handleProceed = async () => {
    if (password !== confirmation) {
      setError(true);
      setErrorMessage('Error: Passwords do not match');
      return;
    }

    if (password.length < 8) {
      setError(true);
      setErrorMessage('Error: Password must be at least 8 characters long');
      return;
    }

    // verify password contains upper can lower and number
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if (!regex.test(password)) {
      setError(true);
      setErrorMessage('Error: Password does not match requirements');
      return;
    }

    setError(false);
    setLoading(true, 'Changing your password');
    const resp = await authService.changePassword(password, token);
    setLoading(false);

    if (resp?.success) {
      setSent(true);
      setError(false);
      toast.dark('Email sent, please check your inbox.');
    } else {
      setError(true);
      setErrorMessage(resp?.message);
    }
  };

  const validateToken = async () => {
    // get token from query params
    const token = props.location.search.split('=')[1];

    if (!token) {
      setIsTokenValid(false);
      return;
    }

    setLoading(true, 'Validating token...');
    const response = await authService.validateToken(token);
    setLoading(false);

    if (response?.success) {
      setToken(token);
      setIsTokenValid(true);
    } else {
      setIsTokenValid(false);
    }
  };

  useEffect(() => {
    validateToken();
    setToken(token);
  }, []);

  const passwordForm = (
    <>
      <h1 className="tx-20 tx-sm-24">Reset your password</h1>
      <h6 className="tx-color-03 mg-b-25 mg-t-15 tx-center">Please set a secure and easy to remember password.</h6>
      <p className="password-description">A secure password:</p>
      <ul>
        <li>
          Should be at least <b>8</b> characters long
        </li>
        <li>Contain uppercase and lowercase letters</li>
        <li>Contain numbers and/or special characters (! @ # $ % &amp; *)</li>
      </ul>
      {error && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="form-floating">
        <input
          type="password"
          className="form-control"
          name="password"
          value={password}
          onChange={(val: any) => setPassword(val.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Password"
        />
        <label>Password</label>
      </div>

      <div className="form-floating">
        <input
          type="password"
          className="form-control"
          name="password"
          value={confirmation}
          onChange={(val: any) => setConfirmation(val.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Confirm Password"
        />
        <label>Confirm Password</label>
      </div>

      <div className="wd-100p mg-t-15 tx-center">
        <button onClick={handleProceed} className="btn btn-primary mg-sm-l-10 mg-t-10 mg-sm-t-0">
          Reset Password
        </button>
      </div>
    </>
  );

  const invalidToken = (
    <>
      <h1 className="tx-20 tx-sm-24">FinAid360</h1>
      <div className="alert mg-10 alert-danger" role="alert">
        <h5 className="tx-center">Invalid token</h5>
        The security token is invalid. Please start the password reset process again.
      </div>

      <div className="tx-15 tx-center">
        <a href="/public/password-recovery">Reset your password</a>
      </div>
    </>
  );

  const emailSent = (
    <>
      <h1 className="tx-20 tx-sm-24">FinAid360</h1>
      <div className="alert mg-10 alert-success" role="alert">
        <h5 className="tx-center">Password Changed</h5>
        Your password has been changed. Please login with your new password.
      </div>

      <div className="tx-15 tx-center">
        <a href="/public/login">Go to login page</a>
      </div>
    </>
  );

  return (
    <div className="page password-change-page">
      <div className="wd-sm-100p wd-md-450 wd-lg-550 pass-change-form ">
        <ToastContainer></ToastContainer>
        {isTokenValid ? (sent ? emailSent : passwordForm) : invalidToken}
      </div>
    </div>
  );
};

export default ChangePassword;
