import { useState, useEffect, useContext, useMemo } from "react";
import globalContext from "../../context/globalContext";

import Select from "react-select";
import ActionIcon from "../shared/action-icon/actionIcon";
import {
  Edit3 as IconEdit,
  Plus as IAdd,
  Download,
  Archive as IArchive,
  XSquare as IUnArchive,
} from "react-feather";
import { toast } from "react-toastify";
import DocumentTemplateService from "../../services/documenTemplate.service";

import { School } from "../../models/School.model";
import { DocumentTemplate, DocumentType } from "../../models/Document.model";
import { SchoolID } from "../../models/Enums";

import Table from "../shared/table/table";
import DocumentTemplateModal from "./documentTemplateModal";

import { Send as IconSend } from "react-feather";
import "./documentTemplateViewer.scss";
import EmailTemplateModal from "./emailTemplateModal";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import SearchBox from "../shared/searchBox/searchBox";
import PDFViewer from "../pdfViewer/pdfViewer";
import ImageViewer from "../imageViewer/imageViewer";

const DocumentTemplateViewer = () => {
  const enum option {
    create = "create",
    update = "update",
    archive = "archive",
  }

  interface schoolFilterI {
    label: string,
    value: number
  }

  const { setLoading, loaders, user, saveFilter, getFilter } = useContext(globalContext);

  const [documentTemplateList, setDocumentTemplateList] = useState<DocumentTemplate[]>([]);
  const [documentTypes, setDocumentTypes] = useState([] as DocumentType[]);
  const [optionSelected, setOptionSelected] = useState<option>();
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState<DocumentTemplate>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedTemplates, setSelectedTemplates] = useState([] as number[]);
  const [emailTemplateVisible, setEmailTemplateVisible] = useState(false);

  const [schools, setSchools] = useState<School[]>([]);
  const [search, setSearch] = useState("");
  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedType, setSelectedType] = useState();

  const [showAll, setShowAll] = useState(false) as any;

  const defaultSchoolOption = { value: 0, label: '- Any School -' };
  const defaultTypeOption = { value: 0, label: '- Any Type -' };

  const [viewingPdf, setViewingPdf] = useState('');
  const [viewingImage, setViewingImage] = useState('');
  const [viewingImageTitle, setViewingImageTitle] = useState('');

  const alphabetical = (a: DocumentTemplate, b: DocumentTemplate) => {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  }

  const loadData = async () => {
    setLoading(true);
    let [schoolsResponse, documentTypes] = await Promise.all([
      loaders.schools(),
      loaders.documentTypes(),
    ]);
    setSchools(schoolsResponse);
    setDocumentTypes(documentTypes);

    let documentTemplatesRes = await DocumentTemplateService.getAllDocumentTemplates();
    if (documentTemplatesRes?.status === HttpStatusCode.Ok) {
      const templatesWithType = documentTemplatesRes?.data.map((doc: DocumentTemplate) => ({
        ...doc,
        documentType: documentTypes.find((x) => x.id === doc.documentType),
      })
      );

      // Filtering, showing only docTemp according user's account
      const docTempFilteredByUserAccount = templatesWithType.filter((school2: DocumentTemplate) => schoolsResponse.some((school1: School) => school2?.school === school1?.id))
      // Filtering, showing only docTemp according user's account and archived
      docTempFilteredByUserAccount.sort(alphabetical);
      setDocumentTemplateList(docTempFilteredByUserAccount);
    } else {
      showLoadingError();
    }
    setLoading(false);
  };


  const loadFilters = () => {
    let filters = getFilter() as any;
    if (filters?.search) {
      setSearch(filters.search)
    }
    setSelectedSchool(filters?.school || defaultSchoolOption);
    setSelectedType(filters?.type || defaultTypeOption);
  }


  const templateSearch = (documentTemplate: DocumentTemplate) => {
    let filter = getFilter() as any;
    let pass = true;

    if (documentTemplate.archived && !showAll) {
      return false;
    }

    if (filter.search) {
      let src = filter.search.toLowerCase();
      pass = documentTemplate.name.toLowerCase().includes(src);
    }

    if (pass && filter.school && filter.school.value) {
      pass = documentTemplate.school === +filter.school.value
    }

    if (pass && filter.type && filter.type.value) {
      pass = documentTemplate.documentType?.id === filter.type.value
    }

    return pass;
  }


  const showLoadingError = () => {
    toast.error("❌ Error loading data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };


  useEffect(() => {
    loadData();
    loadFilters();
  }, []);


  const handleCheckBoxChange = (event: any) => {
    const id = parseInt(event.target.id);
    const checkState = event.target.checked;

    var docsCopy = [...documentTemplateList];
    docsCopy.find((doc) => doc.id === id)!.selected = checkState;
    setDocumentTemplateList(docsCopy);

    var current = [...selectedTemplates];
    if (checkState) {
      current.push(id);
    } else {
      current.splice(current.indexOf(id), 1);
    }

    setSelectedTemplates(current);
  };


  const handleEmailTemplateVisible = () => {
    setEmailTemplateVisible(!emailTemplateVisible);
  };


  const handleClearSelection = () => {
    var copy = documentTemplateList.map((doc) => ({ ...doc, selected: false }));
    setDocumentTemplateList(copy);
    setSelectedTemplates([]);
  };


  const handleCreateDocument = () => {
    setOptionSelected(option.create);
    setIsModalVisible(true);
  };


  const handleUpdateDocument = async (documentData: DocumentTemplate) => {
    setSelectedDocumentTemplate(documentData);
    setOptionSelected(option.update);
    setIsModalVisible(true);
  };


  const handleArchiveDocument = async (documentData: DocumentTemplate) => {

    let archived;
    let payload;

    if (documentData.archived) {
      payload = {
        ...documentData,
        archived: false,
      };
      archived = false;

    } else {
      payload = {
        ...documentData,
        archived: true,
      };
      archived = true;
    }
    setLoading(true);
    let res = await DocumentTemplateService.archiveDocumentTemplate(payload);
    setLoading(false);

    if (res?.status == HttpStatusCode.Ok) {

      let id = documentData.id;
      var docsCopy = [...documentTemplateList];

      if (docsCopy.length == 0) {
        loadData();
        return;
      }

      docsCopy.find((doc) => doc.id == id)!.archived = archived;
      setDocumentTemplateList(docsCopy);
    }

    // handleResponse(res, "archived");

  };

  const handleShowAll = (option: Boolean) => {
    setShowAll(option);
  }

  const handleResponse = async (response: any, action: string) => {
    if (response?.status === 200 || response?.status === 201 || response.status === 204) {
      toast.dark(`✔️ Document templated ${action}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // await updateDocumentTemplatesOnParent();
    } else {
      toast.dark("❌ Something went wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      console.error(response?.data);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Select",
        accessor: "selected",
        Cell: ({ cell, onCheckChange }: { cell: any; onCheckChange: any }) => (
          <>
            <div className="col-md">
              <input
                type="checkbox"
                className="big"
                id={cell.row.values.id}
                value={cell.row.index}
                checked={cell.value}
                onClick={(arg) => onCheckChange(arg)}
              />
            </div>
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        width: "auto",
      },
      {
        Header: "Document Type",
        accessor: "documentType",
        Cell: ({ row }: any) => <div>{row?.values?.documentType?.name}</div>,
      },
      {
        Header: "School",
        accessor: "school",
        Cell: ({ row }: any) => (
          <div className="badge">{SchoolID[row?.values?.school]}</div>
        ),
      },
      {
        header: "Archived",
        accessor: "archived",
        hidden: false,
        Cell: ({ row }: any) => (
          <div className="badge badge-archived">{row?.values.archived ? "Archived" : ""}</div>
        )
      },
      {
        header: "Revision",
        accessor: "revision",
        hidden: true,
      },
      {
        Header: "Actions",
        accessor: "file",
        Cell: ({ row }: any) => (
          <>
            <ActionIcon
              label="Download"
              icon={<Download />}
              color="primary"
              onClick={() => handleDownloadForm(row?.values?.file, row?.values?.file.fileName, row?.values?.documentType)}
            />
            {
              (user?.isReviewer || user?.isAdmin) &&
              (<>
                <ActionIcon
                  label="Edit"
                  icon={<IconEdit />}
                  color="info"
                  onClick={() => handleUpdateDocument(row?.values)}
                />

                <ActionIcon
                  label={(row?.values.archived) ? "Unarchive" : "Archive"}
                  icon={(row?.values.archived) ? <IUnArchive /> : <IArchive />}
                  color="info"
                  onClick={() => handleArchiveDocument(row?.values)}
                  extraClass="button-archived"
                />
              </>)
            }
          </>
        ),
      },
    ],
    []
  );


  const getSchoolOptions = () => {

    let options = schools.map((school) => ({
      label: school.shortName,
      value: school.id,
    }));
    options.unshift(defaultSchoolOption);
    return options;
  };


  const getTypeOptions = () => {
    let options = documentTypes.map((type) => ({
      label: type.name,
      value: type.id,
    }))
    options.unshift(defaultTypeOption);
    return options;
  }


  const handleDownloadForm = (file: any, fileName: string, documentType: any) => {
    if (file.includes('pdf') || file.includes('api/reports/application_requests/')) {
      setViewingPdf(file);
    }
    else if (file.includes('jpg') || file.includes('png') || file.includes('jpeg')) {
      setViewingImage(file);
      setViewingImageTitle("[" + documentType.name + "] " + fileName);
    } else {
      // download
      window.open('' + file, 'newwindow', 'width=820px,height=950px');
      return false;
    }
  };


  const searchTextChanged = (text: string) => {
    saveFilter('search', text);
    setSearch(text);
  };


  const selectedSchoolChanged = (option: any) => {
    saveFilter('school', option);
    setSelectedSchool(option);
  }


  const selectedTypeChanged = (option: any) => {
    saveFilter('type', option);
    setSelectedType(option);
  }


  const documentTemplatesHeader = () => {
    return (
      <div className="templates-header d-flex">
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <input
            type="radio"
            className="btn-check"
            name="options-outlined"
            id="primary-outlined"
            autoComplete="off"
            onClick={() => handleShowAll(false)}
            checked={!showAll}
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="primary-outlined"
          >
            Active Templates
          </label>

          <input
            type="radio"
            className="btn-check"
            name="options-outlined"
            id="primary-2-outlined"
            autoComplete="off"
            onClick={() => handleShowAll(true)}
            checked={showAll}
          />
          <label
            className="btn btn-outline-primary"
            htmlFor="primary-2-outlined"
          >
            All Templates
          </label>
        </div>

        <SearchBox
          className="col-md-4 col-lg-3 mx-sm-0 mx-lg-2"
          value={search}
          label=""
          placeholder="Search templates"
          onChange={searchTextChanged}
        />
        <div className="input-wrapper">
          <Select
            options={getTypeOptions()}
            className="select wd-100p"
            onChange={selectedTypeChanged}
            value={selectedType}
          />
        </div>

        <div className="input-wrapper">
          {schools.length > 1 && (
            <Select
              options={getSchoolOptions()}
              className="select wd-100p"
              onChange={selectedSchoolChanged}
              value={selectedSchool}
            />
          )}
        </div>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => handleCreateDocument()}
        >
          <IAdd />
        </button>
      </div>
    );
  };

  return (
    <div className="documentTemplateViewer">
      {isModalVisible && (
        <DocumentTemplateModal
          optionSelected={optionSelected}
          isModalVisible={true}
          closeModal={() => {
            setIsModalVisible(false);
            setSelectedDocumentTemplate({ name: "", file: "" });
          }}
          existingDocument={selectedDocumentTemplate}
          setDocumentTemplateList={setDocumentTemplateList}
          // setSelectedDocumentTemplate={setSelectedDocumentTemplate}
          loadData={loadData}
          schools={getSchoolOptions()}
        />
      )}
      <EmailTemplateModal
        visible={emailTemplateVisible}
        selectedTemplates={selectedTemplates}
        templates={documentTemplateList}
        close={handleEmailTemplateVisible}
      />

      <PDFViewer pdfUrl={viewingPdf} onClose={() => setViewingPdf('')} />
      <ImageViewer imageUrl={viewingImage} title={viewingImageTitle} onClose={() => setViewingImage('')} />

      <div className="templateWrapper col-sm-12 col-md-10 offset-md-1">
        <div className="card templates">
          <div className="card-header templates-header">
            {documentTemplatesHeader()}
          </div>

          <div className="card-body overflow-auto">
            <div className="col-md-12 row-actions d-flex justify-content-between mb-2">
              {selectedTemplates.length > 0 && (
                <>
                  <button
                    onClick={() => {
                      handleClearSelection();
                    }}
                    className="clearSelection btn btn-link"
                  >
                    Clear Selection
                  </button>
                  <button
                    onClick={() => {
                      setEmailTemplateVisible(!emailTemplateVisible);
                    }}
                    className="btn btn-sm btn-success"
                  >
                    <IconSend />
                    &nbsp; Send Documents
                  </button>
                </>
              )}
            </div>
            <Table
              columns={columns}
              data={documentTemplateList?.filter(templateSearch)}
              instanceFns={{
                onCheckChange: handleCheckBoxChange,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTemplateViewer;
