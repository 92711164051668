import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import calendarService from "../../../services/calendar.service";
import DataTable from "../department/Table";
import "./addUserModelBody.scss";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";

export interface UserOptionType {
  inputValue?: string;
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface AddUserModelBodyProps {
  selectedUsers: UserOptionType[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserOptionType[]>>;
}

const filter = createFilterOptions<UserOptionType>();

const AddUserModelBody: React.FunctionComponent<AddUserModelBodyProps> = ({ selectedUsers, setSelectedUsers }) => {
  const [value, setValue] = React.useState<UserOptionType | null>(null);
  const [users, setUsers] = React.useState<UserOptionType[]>([]);
  const [autocompleteResetKey, setAutocompleteResetKey] = React.useState<number>(0);

  React.useEffect(() => {
    async function loadUsers() {
      try {
        const response = await calendarService.getAllUsers();
        if (response?.status === HttpStatusCode.Ok) {
          setUsers(response.data);
        }
      } catch (error) {
        console.error("Error loading users:", error);
      }
    }
    loadUsers();
  }, []);

  React.useEffect(() => {
    setValue(null);
  }, [selectedUsers]);

  return (
    <div>
      <Autocomplete
        key={autocompleteResetKey}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            const newUser = {
              email: newValue,
              firstName: "",
              lastName: "",
            };
            setValue(newUser);
            setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, newUser]);
          } else if (newValue && newValue.inputValue) {
            const newUser = {
              email: newValue.inputValue,
              firstName: "",
              lastName: "",
            };
            setValue(newUser);
            setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, newUser]);
          } else {
            setValue(newValue);
            if (newValue) {
              setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, newValue]);
            }
          }

          setValue(null);
          setAutocompleteResetKey((prevKey) => prevKey + 1);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Filtrar solo direcciones de correo electrónico
          const { inputValue } = params;
          const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

          return isEmail
            ? [
                ...filtered,
                {
                  inputValue,
                  email: `Add "${inputValue}"`,
                  firstName: "",
                  lastName: "",
                },
              ]
            : filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id=""
        options={users}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.email;
        }}
        renderOption={(props, option) => <li {...props}>{option.email}</li>}
        sx={{ width: 300 }}
        size="small"
        freeSolo
        renderInput={(params) => <TextField {...params} label="Add or invite user by email" />}
      />
      <div className="py-4">
        <DataTable
          columns={[
            {
              title: "Email",
              field: "email",
            },
            {
              title: "First Name",
              field: "firstName",
            },
            {
              title: "Last Name",
              field: "lastName",
            },
          ]}
          data={selectedUsers}
          title=""
          actions={[
            {
              icon: "delete",
              tooltip: "Delete User",
              onClick: (event: React.MouseEvent, rowData: UserOptionType) => {
                const updatedSelectedUsers = selectedUsers.filter((user) => user.email !== rowData.email);
                setSelectedUsers(updatedSelectedUsers);
              },
            },
          ]}
          options={{
            actionsColumnIndex: 3,
            padding: "dense",
            search: false,
            paging: false,
          }}
        />
      </div>
    </div>
  );
};

export default AddUserModelBody;