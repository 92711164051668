import { useState } from 'react';
import Modal from '../../components/modal/modal';
import { UploadRequests } from '../../models/uploadRequest.model';
import { UploadRequestDocumentStatus, UploadRequestDocumentStatusText } from '../../models/Enums';
import { Link } from 'react-router-dom';
const moment = require('moment-timezone');

interface UploadRequestModalProps {
  isModalVisible: boolean;
  selectedUploadRequest?: UploadRequests;
  setIsModalVisible: (data: boolean) => void;
}

export const UploadRequestDetailsModal = ({ isModalVisible, selectedUploadRequest, setIsModalVisible }: UploadRequestModalProps) => {
  const [dueDate, setDueDate] = useState<any>('05-21-2023');
  const fullName = `${selectedUploadRequest?.student?.firstName || ''} 
                        ${selectedUploadRequest?.student?.lastName || ''} 
                        ${selectedUploadRequest?.student?.middleName || ''}`;

  const getUploadRequestDocumentStatusTag = (status: UploadRequestDocumentStatus) => {
    switch (status) {
      case UploadRequestDocumentStatus.MISSING:
        return 'pending-tag';
      case UploadRequestDocumentStatus.IN_REVIEW:
        return 'inprogress-tag';
      case UploadRequestDocumentStatus.ACCEPTED:
        return 'completed-tag';
      case UploadRequestDocumentStatus.REJECTED:
        return 'rejected-tag';
      default:
        return 'pending-tag';
    }
  };

  const body = () => {
    return (
      <div className="template-form mt-2">
        <p>Student: <Link to={"/student/overview/" + selectedUploadRequest?.student?.id}>{fullName}</Link> </p>
        <p>Creator: <span className='gray-text'>{selectedUploadRequest?.creator?.firstName + ' ' + selectedUploadRequest?.creator?.lastName}</span></p>

        <br />

        <div>
          {selectedUploadRequest?.documents.map((document: any) => (
            <h6 key={document.id} style={{ display: 'block' }}>
              <span style={{ color: 'gray', display: 'inline-block', width: '220px', height: '25px', textAlign: 'start' }}>{document?.documentType.name}</span>

              <span style={{ display: 'inline-block' }} className={'ml-2 status-badge ' + getUploadRequestDocumentStatusTag(document?.status)}>
                {UploadRequestDocumentStatusText(document?.status)}
              </span>
            </h6>
          ))}
        </div>

        <br />

        <div className="dates">
          <div className="input-wrapper">
            <p>Created At:</p>
            <input type="text" className="form-control form-control-sm" value={moment(selectedUploadRequest?.createdAt).format('MM-DD-YYYY')} maxLength={50} disabled />
          </div>

          <div className="input-wrapper">
            <p>Due Date:</p>
            <input type="text" className="form-control form-control-sm" value={selectedUploadRequest?.validUntil ? moment(selectedUploadRequest?.validUntil).format('MM-DD-YYYY') : dueDate} maxLength={50} onChange={(e) => setDueDate(e.target.value)} disabled/>
          </div>
        </div>

        {/* <pre>{JSON.stringify(selectedUploadRequest,null,3)}</pre> */}
      </div >
    );
  };

  const handleVisibile = () => {
    setIsModalVisible(false);
  };
  return <Modal
    title={'Upload Request Details'}
    body={body()}
    visible={isModalVisible}
    onClose={handleVisibile}
    cancelButtonText="Close"
    onCancel={handleVisibile}
    onAccept={() => console.log('SAVE')}
  />;
};
