import "../facts.scss";
import { Facts } from '../types'
import { Table } from '../table'

export const Multiple = ({ title, subtitle, description, tables, datas, cssClass }: Facts) => {

    return (
        <div className={`monoTable mb-4 ${cssClass}`}>
           <h2 className="factsTitle">{title}</h2>
           {subtitle && <p>{subtitle}</p>}
            { description && <p><i>{description}</i></p>}
            {tables?.map((table, index) => (
                <Table {...table} key={index} rows={datas?.[index]} />
            ))}
        </div>
    )
}