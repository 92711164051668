import "./fileUpload.scss";

export interface FileUploadProps {
  onChange: (file: File) => void;
  onReuploadClick?: () => void;
  status: FileUploadStatus;
  message?: string;
  reupload?: boolean;
}

export enum FileUploadStatus {
  SELECT = 0,
  UPLOADING = 1,
  COMPLETED = 2,
  ERROR = 9,
}

function FileUpload(props: FileUploadProps) {
  // todo: add valid to show an error when the file was not uploaded successfully

  const { status, message, reupload, onReuploadClick } = props;
  const handleSelectFiles = (event: any) => {
    props.onChange(event.target.files[0]);
  };

  return (
    <div className="shared-component file-upload">
      {message && (
        <div className="alert alert-light" role="alert">
          {message}
        </div>
      )}

      {status === FileUploadStatus.UPLOADING ? (
        <div className="loader">
          <div className="loaderBar"></div>
        </div>
      ) : status !== FileUploadStatus.COMPLETED ? (
        <input type="file" className="form-control" accept=".png,.jpeg,.jpg,.pdf,.doc,docx,.xls,.xlsx,.zip,.rar,.heic" onChange={handleSelectFiles} />
      ) : (
        <div className="completed-message">
          <h6>File uploaded.</h6>
          {reupload ? (
            <div className="upload-again">
              <p>Do you need to upload another file?</p>
              <button onClick={() => onReuploadClick?.()} className="btn btn-md btn-link re-upload">
                Click Here!
              </button>

            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default FileUpload;
