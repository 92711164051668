import { Program } from '../../../models/Program.model';
import Modal from '../../modal/modal';
import { useEffect, useState } from 'react';
import { SchoolID } from '../../../models/Enums';

const ProgramModal = (props: any) => {
  const [name, setName] = useState() as any;
  const [length, setLength] = useState() as any;
  const [unit, setUnit] = useState() as any;
  const [shortName, setShortName] = useState() as any;
  const [clockHours, setClockHours] = useState() as any;
  const [partTime, setPartTime] = useState(false);

  const getModalBody = () => {
    return (
      <>
        <div className="program-form flex-form">
          <div className="input-row">
            <div className="input-wrapper input-wrapper__lg">
              <input
                type="text"
                placeholder="Program name"
                className="form-control form-control-sm"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-wrapper input-wrapper__lg">
              <input
                type="text"
                placeholder="Program short name"
                className="form-control form-control-sm"
                name="shortName"
                value={shortName}
                onChange={(e) => {
                  setShortName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-wrapper">
              <input
                type="number"
                placeholder="Duration"
                className="form-control form-control-sm"
                name="length"
                value={length}
                onChange={(e) => {
                  setLength(e.target.value);
                }}
              />
            </div>
            <div className="input-wrapper">
              <select
                className="form-select form-select-sm"
                name="unit"
                value={unit}
                onChange={(e) => {
                  setUnit(e.target.value);
                }}
              >
                <option disabled hidden selected>-- Select Unit --</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
              </select>
            </div>
          </div>
          {props?.school?.id != SchoolID.SDGKU ? <div className="input-row">
            <div className="input-wrapper">
              <input
                type="number"
                placeholder="Clock Hours"
                className="form-control form-control-sm"
                name="clockHours"
                value={clockHours}
                onChange={(e) => {
                  setClockHours(e.target.value);
                }}
              />
            </div>
            <div className="form-check wd-50p d-flex align-items-center pl-5">
              <input
                className="form-check-input"
                type="checkbox"
                checked={partTime}
                id="hasReminder"
                name="hasReminder"
                onChange={(e) => setPartTime(e.target.checked)}
              ></input>
              <label className="form-check-label" htmlFor="hasReminder">
                Part time?
              </label>
            </div>
          </div> : null}

        </div>
      </>
    );
  };

  const clear = () => {
    setName('');
    setShortName('');
    setLength(undefined);
    setUnit(undefined);
    setClockHours(undefined);
    setPartTime(false);
  };

  const addProgram = () => {
    let program = { name, shortName, length, unit, clockHours, partTime } as Program;
    props.handleAddProgram(program);
  };

  const saveChanges = () => {
    let program = { ...props.selectedProgram } as Program;
    program.name = name;
    program.shortName = shortName
    program.length = length;
    program.unit = unit;
    program.clockHours = clockHours;
    program.partTime = partTime;
    props.handleEditProgram(program);
  };

  useEffect(() => {
    if (props.selectedProgram?.id) {
      setName(props.selectedProgram.name);
      setShortName(props.selectedProgram.shortName)
      setLength(props.selectedProgram.length);
      setUnit(props.selectedProgram.unit);
      setClockHours(props.selectedProgram.clockHours);
      setPartTime(props.selectedProgram.partTime);
    }
    else {
      clear();
    }
  }, [props.selectedProgram]);

  return (
    <Modal
      visible={props.visible}
      title={props.selectedProgram?.id ? 'Edit Program' : 'Register New Program'}
      body={getModalBody()}
      cancelButtonClass={'btn-dark'}
      cancelButtonText={'Cancel'}
      mainButtonClass={'btn-primary'}
      mainButtonText={props.selectedProgram?.id ? 'Save Changes' : 'Add Program'}
      mainButtonDisabled={length == null || name == null || unit == undefined}
      onAccept={() => {
        props.selectedProgram?.id ? saveChanges() : addProgram();
        clear();
      }}
      onCancel={() => {
        clear();
        props.closeModal();
      }}
      onClose={() => {
        clear();
        props.closeModal();
      }}
    ></Modal>
  );
};

export default ProgramModal;
