import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../modal/modal";
import TextField from "../../shared/Input/textField";

interface IAddNoteModalProps {
    visible?: boolean;
    close: () => void;
    saveNote: (text: string) => void;
}

const AddNoteModal = ({
    visible = false,
    close,
    saveNote,
}: IAddNoteModalProps) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const checkKeyDown = (e: any) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const handleSend = async (form: any) => {
        saveNote(form.note);
    };

    // useEffect(() => {
    //     if (student) {
    //         setStudentEmail(student.email);
    //         setValue('student_id', student.id);
    //     }
    //     if (user) {
    //         setUserEmail(user.email);
    //     }
    // }, [user, student]);

    const body = () => {
        return (
            <form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="modal-new-note">               
                <TextField 
                    field="note"
                    type="text"
                    multiLine={true}
                    label="Note"
                    register={register}
                    errors={errors}
                    rules={{ required: true }}
                />
            </form>
        );
    };

    return (
        <Modal
            body={body()}
            title="Add New Note"
            bodySubtitle="Add a new note to student's record"
            size="md"
            visible={visible}
            mainButtonText="Add Note"
            onCancel={close}
            onClose={close}
            cancelButtonClass="btn-dark"
            onAccept={handleSubmit((d) => handleSend(d))}
        />
    )
}

export default AddNoteModal;