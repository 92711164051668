import { FunctionComponent, useState } from 'react';
import { ChevronsDown as IDown, ChevronsRight as IRight } from 'react-feather';

import './collapsible.scss';

interface CollapsibleProps {
  text: string;
  parentClass?: string;
  contentClass?: string;
  textClass?: string;
}

const Collapsible: FunctionComponent<CollapsibleProps> = (props) => {
  const [visible, setVisible] = useState(false);

  const getContentClasses = () => {
    let classes = 'collapsible-content';
    if (props.contentClass) {
      classes += ` ${props.contentClass}`;
    }

    if (visible) {
      classes += ' visible';
    }

    return classes;
  };

  return (
    <div className={'collapsible ' + props.parentClass || ''}>
      <h6 className={props.textClass || ''} onClick={() => setVisible(!visible)}>
        <span>{visible ? <IDown /> : <IRight />}</span>
        {props.text}
      </h6>
      <div className={getContentClasses()}>{props.children}</div>
    </div>
  );
};

export default Collapsible;
