const DisclosureCompleted = () => {
  return (
    <div className="doc-content thank-you">
      <div>
        <h3>Thank you for completed the Disclosure Form.</h3>
        <h5>You will receive a copy of the completed form(s) in you email.</h5>
        <p>You can now close this page.</p>
      </div>
    </div>
  );
};

export default DisclosureCompleted;
