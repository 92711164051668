import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import InitialsComponent from '../components/initialsComponent/initialsComponent';
import Acknowledgement from '../components/shared/acknowledgement/acknowledgement';
import Signature from '../components/shared/signature/signature';
import globalContext from '../context/globalContext';
import { SignedFormStatus } from '../models/Forms.enum';
import { SignedFormData } from '../models/Forms.model';
import signedFormService from '../services/signedForm.service';
import { SignedPageData } from '../viewControllers/signedPage';
interface PageSignatureReturn {
  isFormValid: boolean;
  signatureData: string;
  initialsData: string;
  saveForm: (pageKey: string, completed?: boolean, extra_data?: any) => Promise<boolean>;
  customSavePrevForm: (pageKey: string, extra_data?: any) => Promise<boolean>;
  validateForm: () => void;
  setRequiredCustomValidation: (isValid: boolean) => void;
  setIsCustomValid: (isValid: boolean) => void;
  signatureChanged: (isEmpty: boolean, signature: string) => void;
  initialsChanged: (initialsImage: string, name: string, isCopy: boolean) => void;
  acknowledgeChanged: (checked: boolean, name: string) => void;
  getAcknowledge: (key: string, text: string) => JSX.Element;
  getInitials: (key: string, copy?: boolean) => JSX.Element;
  getSignature: () => JSX.Element;
}

const usePageSignature = (viewController: SignedPageData, isRequiredSignature: boolean = true): PageSignatureReturn => {
  const { setLoading } = useContext(globalContext);

  const [isFormValid, setIsFormValid] = useState(false);
  const [requiredSignature, setRequiredSignature] = useState(isRequiredSignature);
  const [signatureData, setSignatureData] = useState('');
  const [requiredCustomValidation, setRequiredCustomValidation] = useState(false);
  const [isCustomValid, setIsCustomValid] = useState(false);

  const [requiredInitials, setRequiredInitials] = useState<string[]>([]);
  const [completedInitials, setCompletedInitials] = useState<string[]>([]);
  const [initialsData, setInitialsData] = useState('');

  const [requiredChecks, setRequiredChecks] = useState<string[]>([]);
  const [completedChecks, setCompletedChecks] = useState<string[]>([]);

  useEffect(() => {
    validateForm();
  }, [signatureData, initialsData, completedChecks, completedInitials, isCustomValid, requiredSignature, requiredInitials, requiredChecks]);

  const validateForm = () => {
    const isSignatureValid = !requiredSignature || signatureData !== '';
    const areInitialsValid = requiredInitials.length === 0 || initialsData !== '';
    const areChecksValid = completedChecks.length === requiredChecks.length;
    const areAllInitialsCompleted = completedInitials.length === requiredInitials.length;
    const isCustomValidationValid = !requiredCustomValidation || isCustomValid !== false;
  
    const formValid = isSignatureValid && areInitialsValid && areChecksValid && areAllInitialsCompleted && isCustomValidationValid;
  
    setIsFormValid(formValid);
    viewController?.canMoveNext(formValid);
  };

  const updateOrCreateFormData = (formData: SignedFormData[], pageKey: string, key: string, value: string, image: string) => {
    if (!formData) {
      formData = [];
    }

    let index = formData.findIndex((item) => item.pageKey === pageKey && item.key === key);
    if (index > -1) {
      formData[index].value = value;
      formData[index].image = image;
    } else {
      formData.push({
        id: 0,
        pageKey: pageKey,
        key: key,
        value: value,
        image: image,
      });
    }
  };

  const saveForm = async (pageKey: string, completed?: boolean, extra_data?: any) => {
    setLoading(true, 'Saving form, please wait...');
    let form = viewController.formData;
    const token = viewController.token;
    form.formKey = viewController.formKey;
    if (completed) {
      form.status = SignedFormStatus.Completed;
    } else {
      form.status = SignedFormStatus.InProgress;
    }

    if (!form.formData) {
      form.formData = [];
    }

    // add your data here to save it
    if (requiredSignature) {
      updateOrCreateFormData(form.formData, pageKey, 'signature', '', signatureData);
    }

    if (requiredInitials.length > 0) {
      updateOrCreateFormData(form.formData, pageKey, 'initials', '', initialsData);
    }

    // add extra data
    if (extra_data) {
      for (let key in extra_data) {
        updateOrCreateFormData(form.formData, pageKey, key, extra_data[key], '');
      }
    }

    // save only data about this page
    let formToSave = { ...form };
    formToSave.formData = formToSave.formData.filter((item) => item.pageKey === pageKey);
    let res = await signedFormService.save(formToSave, token);
    setLoading(false);

    if (res?.status === 200) {
      form = res.data;
      return true;
    } else {
      return false;
    }
  };
  
  const customSavePrevForm = async (pageKey: string, extra_data: any) => {
    setLoading(true, 'Saving form, please wait...');
    let form = viewController.formData;
    const token = viewController.token;
    form.formKey = viewController.formKey;
    if (!form.formData) {
      form.formData = [];
    }

    // add your data here to save it
    if (requiredSignature) {
      updateOrCreateFormData(form.formData, pageKey, 'officer_signature', '', signatureData);
    }

    // add extra data
    if (extra_data) {
      for (let key in extra_data) {
        updateOrCreateFormData(form.formData, pageKey, key, extra_data[key], '');
      }
    }

    // save only data about this page
    let formToSave = { ...form };
    formToSave.formData = formToSave.formData.filter((item) => item.pageKey === pageKey);
    let res = await signedFormService.sendFormWithPrevData(formToSave);
    setLoading(false);

    if (res?.status === 200) {
      form = res.data;
      return true;
    } else {
      return false;
    }
  };

  const signatureChanged = (isEmpty: boolean, signature: string) => {
    setSignatureData(signature);
  };

  const initialsChanged = (initialsImage: string, name: string, isCopy: boolean, clear: boolean = false) => {
    if (initialsImage !== '' && !completedInitials.includes(name)) {
      setCompletedInitials([...completedInitials, name]);
    }

    if (initialsImage === '' && !clear && completedInitials.includes(name)) {
      setCompletedInitials(completedInitials.filter((item) => item !== name));
    }
    else if(clear) {
      setCompletedInitials([]);
    }

    if (!isCopy) {
      setInitialsData(initialsImage);
    }

  };

  const isChecked = (name: string) => {
    return completedChecks.includes(name);
  };

  const acknowledgeChanged = (checked: boolean, name: string) => {
    if (checked && !completedChecks.includes(name)) {
      setCompletedChecks([...completedChecks, name]);
    }
    if (!checked && completedChecks.includes(name)) {
      setCompletedChecks(completedChecks.filter((item) => item !== name));
    }
  };

  const getAcknowledge = (key: string, text: string) => {
    if (!requiredChecks.includes(key)) {
      setRequiredChecks([key, ...requiredChecks]);
    }

    return <Acknowledgement name={key} text={text} onChange={acknowledgeChanged} checked={isChecked(key)} />;
  };

  const getInitials = (key: string, copy?: boolean) => {
    if (!requiredInitials.includes(key)) {
      setRequiredInitials([key, ...requiredInitials]);
    }

    if (copy) {
      return <InitialsComponent name={key} masterInitials={initialsData} copy={true} onChange={initialsChanged} />;
    }

    return <InitialsComponent name={key} data={initialsData} onChange={initialsChanged} />;
  };

  const getSignature = () => {
    // Dev NOTE: Just 1 signature per page is allowed
    return <Signature data={signatureData} onChange={signatureChanged} />;
  };

  return {
    isFormValid,
    signatureData,
    initialsData,

    saveForm,
    customSavePrevForm,
    validateForm,
    setRequiredCustomValidation,
    setIsCustomValid,
    signatureChanged,
    initialsChanged,
    acknowledgeChanged,

    getAcknowledge,
    getInitials,
    getSignature,
  };
};

export default usePageSignature;
