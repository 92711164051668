import "../../forms.scss";

import { Header } from "../header";

import { useContext, useEffect, useState } from "react";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import globalContext from "../../../../context/globalContext";
import usePageSignature from "../../../../hooks/pageSignature";
import { useForm } from "react-hook-form";
import { ApplicationForm } from "../../../../models/ApplicationForm.model";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { SDGKUFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { SDGKUProgram } from "../../../../models/Enums";
import { SignedForm } from "../../../../models/Forms.model";
import { FinishPage, TokenError } from "../enrollment/sdgku.extrapages";
import { Mono } from "../facts/mono";
import { Cost, Federal_Loan, Gainfully_Employed, Graduation_Rates, Job_Placement, Pass_Rates, Salary_Wage, Students_Completing } from "./tables.info";
import { Multiple } from "../facts/multiple";
import { Fact } from "../facts/fact_t";
import { getFactsheet } from "./factSheetController";
import { Factsheet as FactSheetType } from "./types";
import TextField from "../../../../components/shared/Input/textField";

interface Props {
  location: any;
}

export const Factsheet = ({ location }: Props) => {
  const { setLoading, showAlert } = useContext(globalContext);
  const [tokenError, setTokenError] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [facts, setFacts] = useState<FactSheetType>();
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const { saveForm, getSignature, getInitials } = usePageSignature(viewController);
  const [completed, setCompleted] = useState(false);
  const [fullStudentName, setFullStudentName] = useState("");

  useEffect(() => {
    loadInitialData();
  }, []);

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<ApplicationForm>();

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const sudentId = params.get("id");

    if (token && sudentId) {
      loadForm(+sudentId, token);
    } else {
      showAlert("Invalid token", AlertType.Error);
      setTokenError(true);
    }
  };

  const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
    if (!formData) return;

    setValue("student.firstName", formData.student.firstName);
    setValue("student.lastName", formData.student.lastName);
    setFullStudentName(`${formData.student.firstName} ${formData.student.lastName}`);

    setViewController(new SignedPageData(SignedFormKey.FACTSHEET, program, formData, token, setEnableSubmit));
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    try {
      const resp = await signedFormService.getFormData(studentId, SignedFormKey.FACTSHEET, token);
      if (resp?.status === HttpStatusCode.Ok) {
        const program: SDGKUProgram = resp.data.programId;
        getFactsheet(program).then(setFacts);
        initializeViewController(resp?.data, program, token);
      } else if (resp?.status === HttpStatusCode.Forbidden) {
        showAlert("Invalid token", AlertType.Error);
        setTokenError(true);
      }
    } catch {
      showAlert("Unexpected error", AlertType.Error);
      setTokenError(true);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = async () => {
    if (await saveForm(SDGKUFormsPage.Factsheet, true)) {
      // setCanMoveNext(true)
      setCompleted(true);
    } else {
      showAlert("Error while saving. Please verify all field are completed", AlertType.Error);
    }
  };

  if (tokenError) return <TokenError />;

  if (completed)
    return (
      <>
        <div className="doc-area">
          <Header />
          <FinishPage pageMessage="Factsheet" />
        </div>
      </>
    );

  return (
    <div className="mx-auto p-3 doc-area sdgku-factsheet">
      <Header />
      <div className="doc-content pt-3">
        <h1 className="text-center mt-5 mb-1">{viewController?.formData?.programName}</h1>
        <p className="text-center">
          <b>
            <i>Note: </i>
          </b>
          The data, information, or both provided for this program excludes students meeting the qualifications set forth in CEC §94909(d).
        </p>

        <div className="container text-center pt-4">
          <Mono {...Graduation_Rates} data={facts?.Graduation_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main")}</span>
            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Mono {...Students_Completing} data={facts?.Completing_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main-2", true)}</span>
            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Mono {...Job_Placement} data={facts?.Placement_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main-3", true)}</span>

            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Multiple {...Gainfully_Employed} datas={facts?.Gainfully_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center relative`}>
            <span className="position-relative">{getInitials("main-4", true)}</span>

            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Mono {...Pass_Rates} data={facts?.Pass_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main-5", true)}</span>

            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Mono {...Salary_Wage} data={facts?.Salary_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main-6", true)}</span>

            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Fact {...Cost} data={facts?.Costs_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main-7", true)}</span>

            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <div className="container text-center pt-4">
          <Mono {...Federal_Loan} data={facts?.Federal_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials("main-8", true)}</span>

            <p>
              <small>
                <i>Initial only after you have had sufficient time to read and understand the information.</i>
              </small>{" "}
              <br />
            </p>
          </div>
        </div>

        <p>
          This fact sheet is filed with the Bureau for Private Postsecondary Education. Regardless of any information you may have relating to completion rates,
          placement rates, starting salaries, or license exam passage rates, this fact sheet contains the information as calculated pursuant to state law.{" "}
          <br />
          <br />
          Any questions a student may have regarding this fact sheet that have not been satisfactorily answered by the Institution may be directed to the Bureau
          for Private Postsecondary Education at 1747 N. Market Blvd, Suite 225, Sacramento, CA 95834, www.bppe.ca.gov, toll-free telephone number (888)
          370-7589 or by fax (916) 263-1897.
        </p>

        <div style={{ maxWidth: "50ch" }}>
          <TextField disabled errors={errors} label="Student Name - Print" field="student.firstName" value={fullStudentName} type="text" register={register} />
        </div>
        <div className="pad">{getSignature()}</div>

        <Definitions />
        <Cancel />

        <div className="text-center pb-2 mb-3 mt-3">
          <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
            Save and Complete
          </button>
        </div>
      </div>
    </div>
  );
};

const Definitions = () => {
  return (
    <div className="mt-4">
      <h2 className="text-center">
        <u>Definitions</u>
      </h2>
      <ul>
        <li>
          “Number of Students Who Began the Program” means the number of students who began a program who were scheduled to complete the program within 100% of
          the published program length within the reporting calendar year and excludes all students who cancelled during the cancellation period.,
        </li>
        <li>
          “Students Available for Graduation” is the number of students who began the program minus the number of students who have died, been incarcerated, or
          been called to active military duty.,
        </li>
        <li>
          “Number of On-time Graduates” is the number of students who completed the program within 100% of the published program length within the reporting
          calendar year.,
        </li>
        <li>“On-time Completion Rate” is the number of on-time graduates divided by the number of students available for graduation.,</li>
        <li>“150% Graduates” is the number of students who completed the program within 150% of the program length (includes on-time graduates).,</li>
        <li>
          “150% Completion Rate” is the number of students who completed the program in the reported calendar year within 150% of the published program length,
          including on-time graduates, divided by the number of students available for graduation.,
        </li>
        <li>“Graduates Available for Employment” means the number of graduates minus the number of graduates unavailable for employment.,</li>
        <li>
          “Graduates Unavailable for Employment” means the graduates who, after graduation, die, become incarcerated, are called to active military duty, are
          international students that leave the United States or do not have a visa allowing employment in the United States, or are continuing their education
          in an accredited or bureau-approved postsecondary institution.,
        </li>
        <li>
          “Graduates Employed in the Field” means graduates who beginning within six months after a student completes the applicable educational program are
          gainfully employed, whose employment has been reported, and for whom the institution has documented verification of employment. For occupations for
          which the state requires passing an examination, the six months period begins after the announcement of the examination results for the first
          examination available after a student completes an applicable educational program.,
        </li>
        <li>
          “Placement Rate Employed in the Field” is calculated by dividing the number of graduates gainfully employed in the field by the number of graduates
          available for employment.,
        </li>
        <li>
          “Number of Graduates Taking Exam” is the number of graduates who took the first available exam in the reported calendar year.Published: October 4,
          2016,
        </li>
        <li>“First Available Exam Date” is the date for the first available exam after a student completed a program.,</li>
        <li>
          “Passage Rate” is calculated by dividing the number of graduates who passed the exam by the number of graduates who took the reported licensing exam.,
        </li>
        <li>
          “Number Who Passed First Available Exam” is the number of graduates who took and passed the first available licensing exam after completing the
          program.,
        </li>
        <li>“Salary” is as reported by graduate or graduate’s employer.,</li>
        <li>
          “No Salary Information Reported” is the number of graduates for whom, after making reasonable attempts, the school was not able to obtain salary
          information.,
        </li>
      </ul>
    </div>
  );
};

const Cancel = () => {
  return (
    <div className="mt-4">
      <h2 className="text-center">
        <u>STUDENT’S RIGHT TO CANCEL</u>
      </h2>
      <p>
        You have the right to cancel the enrollment agreement and obtain a refund of charges paid through attendance at the first class session, or the seventh
        day after enrollment, whichever is later. This is applicable to on-ground, online, and hybrid courses. Cancellation is effective on the date written
        notice of cancellation is sent. San Diego Global Knowledge University shall make the refund pursuant to section 71750 of the CA Ed. Code. Regulations.
        If San Diego Global Knowledge University sent the first lesson and the materials before an effective cancellation notice was received, San Diego Global
        Knowledge University shall make a refund within 45 days after the student’s return of the materials. You may cancel this contract, and receive a refund
        by submitting notice to “SDGKU Administration Services, 1095 K Street, Ste. B, San Diego, California 92101”.
      </p>
    </div>
  );
};
