import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import authService from "../../../services/auth.service";

import "./recovery.scss";

const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleProceed();
    }
  };

  const handleProceed = async () => {
    await authService.recovery(email);
    toast.dark("Email sent, please check your inbox.");
    setSent(true);
  };

  return (
    <div className="page pass-recovery-page">
      <div className="wd-sm-100p wd-md-450 wd-lg-550 recovery-form ">
        <ToastContainer></ToastContainer>
        <h1 className="tx-20 tx-sm-24">Reset your password</h1>
        {!sent ? (
          <>
            <h3 className="tx-color-03 mg-b-30 mg-t-15 tx-center">Enter your email address and we will send you a link to reset your password.</h3>
            <div className="wd-100p mg-t-20">
              <input
                type="text"
                className="form-control flex-fill"
                placeholder="Enter username or email address"
                name="email"
                value={email}
                onChange={(val: any) => setEmail(val.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="wd-100p mg-t-15 tx-center">
              <button onClick={handleProceed} className="btn btn-primary mg-sm-l-10 mg-t-10 mg-sm-t-0">
                Reset Password
              </button>
            </div>
          </>
        ) : (
          <>
            <h3 className="tx-color-03 mg-t-30 tx-center">We sent you an email with a link to reset your password.</h3>
            <h5 className="tx-color-04 tx-15 mg-b-30 tx-center">Please notice: it may take a couple of minutes for the email to arrive</h5>
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordRecovery;
