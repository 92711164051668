import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { COBAProgram } from '../../../../models/Enums';
import { CobaDisclosurePageProps } from './cobaDisclosurePageProps';
import usePageSignature from '../../../../hooks/pageSignature';
import { COBAFormsPage } from '../../../../models/Forms.enum';

const DisclosurePage5 = forwardRef(({ visible, data: viewController }: CobaDisclosurePageProps, ref) => {
  const { isFormValid, validateForm, saveForm, getSignature } = usePageSignature(viewController);

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(COBAFormsPage.Disclosure5, true);
    },
  }));

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        validateForm();
      }, 500);
    }
  }, [visible]);

  const getTuitonFee = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER_HYBRID:
        return (
          <table className="table table-bordered  col-md-6">
            <thead>
              <tr>
                <th colSpan={2}> 1000 Hour Barbering Program Costs </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hourly Rate </td>
                <td> $14.50 </td>
              </tr>
              <tr>
                <td>Total Hours </td>
                <td> 1000 </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td>Total Tuition </td>
                <td> $14,500.00 </td>
              </tr>
              <tr>
                <td>Kit and Book </td>
                <td> $2,000.00 </td>
              </tr>
              <tr>
                <td>Application Fee </td>
                <td> $125.00 </td>
              </tr>
              <tr>
                <td>STRF Fee</td>
                <td> $0.00 </td>
              </tr>
              <tr>
                <td>Total Costs</td>
                <td> $16,625.00 </td>
              </tr>
            </tbody>
          </table>
        );
      case COBAProgram.BARBER:
        return (
          <table className="table table-bordered  col-md-6">
            <thead>
              <tr>
                <th colSpan={2}> 1000 Hour Barbering Program Costs </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hourly Rate </td>
                <td> $14.50 </td>
              </tr>
              <tr>
                <td>Total Hours </td>
                <td> 1000 </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td>Total Tuition </td>
                <td> $14,500.00 </td>
              </tr>
              <tr>
                <td>Kit and Book </td>
                <td> $2,000.00 </td>
              </tr>
              <tr>
                <td>Application Fee </td>
                <td> $125.00 </td>
              </tr>
              <tr>
                <td>STRF Fee</td>
                <td> $0.00 </td>
              </tr>
              <tr>
                <td>Total Costs</td>
                <td> $16,625.00 </td>
              </tr>
            </tbody>
          </table>
        );
      case COBAProgram.COSMETOLOGY:
        return (
          <table className="table table-bordered  col-md-6">
            <thead>
              <tr>
                <th colSpan={2}> 1000 Hour Cosmetology Program Costs </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hourly Rate </td>
                <td> $14.50 </td>
              </tr>
              <tr>
                <td>Total Hours </td>
                <td> 1000 </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td>Total Tuition </td>
                <td> $14,500.00 </td>
              </tr>
              <tr>
                <td>Kit and Book </td>
                <td> $2,140.00 </td>
              </tr>
              <tr>
                <td>Application Fee </td>
                <td> $125.00 </td>
              </tr>
              <tr>
                <td>STRF Fee</td>
                <td> $0.00 </td>
              </tr>
              <tr>
                <td>Total Costs</td>
                <td> $16,765.00 </td>
              </tr>
            </tbody>
          </table>
        );
      case COBAProgram.ESTHETICIAN:
        return (
          <table className="table table-bordered  col-md-6">
            <thead>
              <tr>
                <th colSpan={2}> 600 Hour Esthetics Program Costs </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hourly Rate </td>
                <td> $18.50 </td>
              </tr>
              <tr>
                <td>Total Hours </td>
                <td> 600 </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td>Total Tuition </td>
                <td> $11,100.00 </td>
              </tr>
              <tr>
                <td>Kit and Book </td>
                <td> $2,230.00 </td>
              </tr>
              <tr>
                <td>Application Fee </td>
                <td> $125.00 </td>
              </tr>
              <tr>
                <td>STRF Fee</td>
                <td> $0.00 </td>
              </tr>
              <tr>
                <td>Total Costs</td>
                <td> $13,455.00 </td>
              </tr>
            </tbody>
          </table>
        );
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="doc-content">
      <div>
        <h3>US BUREAU OF LABOR STATISTICS - SKINCARE SPECIALISTS</h3>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <b>Quick Facts: Skincare Specialists</b>
              </td>
            </tr>
            <tr>
              <td>2021 Median Pay</td>
              <td>
                $37,300 per year <br></br>
                $17.93 per hour
              </td>
            </tr>
            <tr>
              <td>Typical Entry-Level Education</td>
              <td>Postsecondary nondegree award</td>
            </tr>
            <tr>
              <td>Work Experience in a Related Occupation</td>
              <td>None</td>
            </tr>
            <tr>
              <td>On-the-job Training</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Number of jobs, 2020</td>
              <td>68,700</td>
            </tr>
            <tr>
              <td>Job Outlook, 2020-30</td>
              <td>29% (Much faster than average)</td>
            </tr>
            <tr>
              <td>Employment Change, 2020-30</td>
              <td>19,800</td>
            </tr>
          </tbody>
        </table>

        <h6>What Skincare Specialists Do</h6>
        <p>Skincare specialists cleanse and beautify the face and body to enhance a person's appearance.</p>

        <h6>Work Environment</h6>
        <p>Skincare specialists usually work in salons and beauty and health spas, and some are self-employed. Although most work full time, many work evenings and weekends.</p>

        <h6>How to Become a Skincare Specialist</h6>
        <p>Skincare specialists must complete a state-approved cosmetology or esthetician program and then pass a state exam for licensure, which all states except Connecticut require.</p>

        <h6>Pay</h6>
        <p>The median hourly wage for skincare specialists was $17.93 in May 2021.</p>

        <h6>Job Outlook</h6>
        <p>Employment of skincare specialists is projected to grow 29 percent from 2020 to 2030, much faster than the average for all occupations.</p>

        <p>
          About 10,100 openings for skincare specialists are projected each year, on average, over the decade. Many of those openings are expected to result from the need to replace workers who transfer to different occupations or exit the
          labor force, such as to retire.
        </p>

        <h6>State & Area Data</h6>
        <p>Explore resources for employment and wages by state and area for skincare specialists.</p>

        <h6>Similar Occupations</h6>
        <p>Compare the job duties, education, job growth, and pay of skincare specialists with similar occupations.</p>
      </div>

      <div>
        <h3>US BUREAU OF LABOR STATISTICS - BARBER, HAIRSTYLISTS, AND COSMETOLOGISTS</h3>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>
                <b>Quick Facts: Barbers, Hairstylists, and Cosmetologists</b>
              </td>
            </tr>
            <tr>
              <td>2021 Median Pay</td>
              <td>
                $29,680 per year <br></br>
                $14.27 per hour
              </td>
            </tr>
            <tr>
              <td>Typical Entry-Level Education</td>
              <td>Postsecondary nondegree award</td>
            </tr>
            <tr>
              <td>Work Experience in a Related Occupation</td>
              <td>None</td>
            </tr>
            <tr>
              <td>On-the-job Training</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Number of jobs, 2020</td>
              <td>622,700</td>
            </tr>
            <tr>
              <td>Job Outlook, 2020-30</td>
              <td>19% (Much faster than average)</td>
            </tr>
            <tr>
              <td>Employment Change, 2020-30</td>
              <td>119,800</td>
            </tr>
          </tbody>
        </table>

        <h6>What Barbers, Hairstylists, and Cosmetologists Do</h6>
        <p>Barbers, hairstylists, and cosmetologists provide haircutting, hairstyling, and a range of other beauty services.</p>

        <h6>Work Environment</h6>
        <p>
          Barbers, hairstylists, and cosmetologists work mostly in a barbershop or salon. Physical stamina is important, because they are on their feet for most of their shift. Many work full time, but part-time positions are also common.
        </p>

        <h6>How to Become a Barber, Hairstylist, or Cosmetologist</h6>
        <p>
          All states require barbers, hairstylists, and cosmetologists to be licensed. To qualify for a license, candidates are required to graduate from a state-approved barber or cosmetology program and then pass a state exam for
          licensure.
        </p>

        <h6>Pay</h6>
        <p>The median hourly wage for barbers was $14.41 in May 2021.</p>
        <p>The median hourly wage for hairdressers, hairstylists, and cosmetologists was $14.26 in May 2021.</p>

        <h6>Job Outlook</h6>
        <p>Overall employment of barbers, hairstylists, and cosmetologists is projected to grow 19 percent from 2020 to 2030, much faster than the average for all occupations.</p>

        <p>
          About 85,300 openings for barbers, hairstylists, and cosmetologists are projected each year, on average, over the decade. Many of those openings are expected to result from the need to replace workers who transfer to different
          occupations or exit the labor force, such as to retire.
        </p>

        <h6>State & Area Data</h6>
        <p>Explore resources for employment and wages by state and area for barbers, hairstylists, and cosmetologists.</p>

        <h6>Similar Occupations</h6>
        <p>Compare the job duties, education, job growth, and pay of barbers, hairstylists, and cosmetologists with similar occupations.</p>
      </div>

      <div>
        <h3>Tuition and Fees</h3>

        {getTuitonFee()}

        <div className="foot-info">
          <p>Weekly, Bi-Monthly and Monthly payment schedules are available. Please discuss your preference with the Administrator during your interview. </p>

          <p>*All programs have the option to add an iPad for $350 (added to the "Kit & Book" cost) </p>
          <p>** Student Tuition Recovery Fund Payable to the State of California (Non-Refundable). As of April 1, 2022, the rate is $2.50 per one thousand dollars ($1,000) of Institutional Charges. </p>

          <p>
            ***Non-Returnable due to Health and Sanitary Reasons. Includes all required kit equipment, supplies, books, and materials necessary to complete the specific Program of study within the enrollment period. Except for Coba Academy
            T-Shirts and Aprons, personal effects such as clothing and uniforms are not included.
          </p>
        </div>
      </div>

      {getSignature()}
    </div>
  );
});

export default DisclosurePage5;
