import React, { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/debounce";

import "./dateRangePicker.scss";

export interface IMonthPicker {
  sendRange: (fromMonth: string, toMonth: string) => void;
  fromValue: string;
  toValue: string;
  sideLabel?: boolean
}

const DateRangePicker = ({ sendRange, fromValue, toValue, sideLabel }: IMonthPicker) => {
  const [fromMonth, setFromMonth] = useState() as any;
  const [toMonth, setToMonth] = useState() as any;
  useDebouncedEffect(() => {
    if (fromMonth || toMonth) {
      sendRange(fromMonth, toMonth);
    }
  }, 1000, [fromMonth, toMonth]);

  const setRange = (value: string, position: string) => {
    if (position === "from") {
      setFromMonth(value);
    } else {
      setToMonth(value);
    }
  };

  useEffect(() => {
    setFromMonth(fromValue);
    setToMonth(toValue);
  }, []);

  return (
    <div className="month-picker">
      <div className={sideLabel ? "side-label" : "row"}>
        <div className={sideLabel ? "" : "col-6"}>
          <label>From</label>
          <input
            type="date"
            id="monthFilterFrom"
            className="form-control month-filter"
            defaultValue={fromValue}
            onChange={(e) => setRange(e.target.value, "from")}
          />
        </div>
        <div className={sideLabel ? "" : "col-6"}>
          <label>To</label>
          <input
            type="date"
            id="monthFilterTo"
            className="form-control month-filter"
            defaultValue={toValue}
            onChange={(e) => setRange(e.target.value, "to")}
            />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
