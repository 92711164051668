import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import globalContext from "../../../context/globalContext";
import { SelectOption } from "../../../models/common/Base.model";
import FileUpload, { FileUploadStatus } from "../../shared/file-upload/fileUpload";

import "./studentDocumentUpload.scss";

export interface StudentDocumentUploadProps {
  uploadVisible: boolean;
  uploadEnabled?: boolean;
  selectedDocType: SelectOption;
  status: FileUploadStatus;
  headingVisible?: boolean;
  onDocTypeChange: (option: SelectOption) => void;
  onFileChange: (document: File) => void;
  onFileUpload?: () => void;
  docTypeClass?: string;
  fileUploadClass?: string;
  uploadClass?: string;
  containerClass?: string;
  docTypeLabel?: string;
}

const StudentDocumentUpload = (props: StudentDocumentUploadProps) => {
  const [docTypeOptions, setDocTypeOptions] = useState([] as any);
  const { loaders } = useContext(globalContext);

  const loadDocTypes = async () => {
    const docTypes = await loaders.documentTypes();
    setDocTypeOptions(docTypes.map((x: any) => ({ value: x.id, label: x.name })).sort((a, b) => (a.label < b.label ? -1 : 1)));
  };

  useEffect(() => {
    loadDocTypes();
  }, []);

  let docTypeClass = props.docTypeClass || (props.uploadVisible ? "col-md-5" : "col-md-6");
  let fileUploadClass = props.fileUploadClass || (props.uploadVisible ? "col-md-5" : "col-md-6");
  let uploadClass = props.fileUploadClass || "col-md-2";
  let containerClass = props.containerClass || "bg-gray-100 pd-10";
  let docTypeLabel = props.docTypeLabel || "Type";

  if (props.headingVisible !== false) {
    fileUploadClass += " pd-t-30 ";
    uploadClass += " pd-t-35 ";
  } else {
    containerClass += " pd-10 pd-t-20 ";
  }

  return (
    <div className={"form-row upload-new-document " + containerClass}>
      <div className={"form-group " + docTypeClass}>
        {props.headingVisible !== false ? <label className="tx-15 tx-primary">Upload new document</label> : null}
        <div className="d-flex">
          <label className="mg-r-10 pd-t-5">{docTypeLabel}</label>
          <Select
            className="flex-grow-1"
            value={props.selectedDocType}
            placeholder="Select document type"
            onChange={(val: any) => {
              props.onDocTypeChange(val);
            }}
            options={docTypeOptions}
          />
        </div>
      </div>
      <div className={"form-group " + fileUploadClass}>
        <FileUpload
          status={props.status}
          message=""
          onChange={(file: any) => {
            props.onFileChange(file);
          }}
        ></FileUpload>
      </div>
      {props.uploadVisible ? (
        <div className={"form-group " + uploadClass}>
          <button disabled={!props.uploadEnabled} className="btn btn-sm btn-primary" onClick={props.onFileUpload}>
            Upload File
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default StudentDocumentUpload;
