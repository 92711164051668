import * as React from 'react';
import globalContext from '../../context/globalContext';
import { AlertType } from '../../models/AlertType.enum';
import "./globalAlert.scss";
class GlobalAlert extends React.Component<any, any> {
  static contextType = globalContext;

  render() {
    if (!this.context.alert || !this.context.alert.alertMessage) {
      return null;
    }

    return (
      <div className={"global-alert alert alert-dismissible " + this.getAlertClass()}>
        {this.context.alert.alertMessage}
        <button onClick={this.context.clearAlert} type="button" className="btn-close"></button>
      </div>
    );
  }

  // todo: should hide success alert but not error alert
  startAlertTimer = () => {
    //setTimeout(this.context.clearAlert, this.context.alert.duration);
    setTimeout(this.context.clearAlert, 4000);
  };

  getAlertClass = () => {
    switch (this.context.alert.alertType) {
      case AlertType.Success:
        return "alert-success";
      case AlertType.Info:
        return "alert-info";
      case AlertType.Warning:
        return "alert-warning";
      case AlertType.Error:
        return "alert-danger";
    }
  };
}
 
export default GlobalAlert;