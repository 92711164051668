import React from 'react';
import './errorBoundary.scss';

export interface IErrorBoundaryState {
  message?: string;
  hideRetryButton?: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundaryState> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.log(error, info.componentStack);
  }

  handleReload = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="boundary-failed">
          <p className="title">Oops, something went wrong!</p>
          <p className="info">{this.props.message || 'Click the button below to try again.'}</p>
          {!this.props.hideRetryButton && (
            <button className="btn btn-outline-primary" onClick={this.handleReload}>
              Try Again
            </button>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
