import http from "./http-common";

class StudentService {
	async getStudents() {
		try {
			let response = await http.get("/students/");
			return response?.data;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading students");
		}
	}

	async getStudentById(id: number | string) {
		try {
			let response = await http.get(`/students/${id}/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading student data");
		}
	}

	async saveStudent(student: any) {
		try {
			let response = await http.post(`/students/`, student);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while saving student");
		}
	}

	async updateStudent(studentId:number, student: any) {
		try {
			let response = await http.patch(`/students/${studentId}/`, student);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while updating student");
		}
	}

	async partialUpdateStudent(studentId: any, data: any) {
		try {
			let response = await http.patch(`/students/${studentId}/`, data);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while updating student data");
		}
	}

	async getStudentProgram(studentId: number | string) {
		try {
			let response = await http.get(`/students/${studentId}/get_program/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while fetching students program");
		}
	}

	async isVa(studentId: number | string) {
		try {
			let response = await http.get(`/students/${studentId}/is_va/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while fetching students va status");
		}
	}

	async resendAdmissionEmail(studentId: number) {
		try {
		  let response = await http.get(`/students/${studentId}/resend_admissions_email/`);
		  return response;
		} catch (error) {
		  http.fa360.displayError('Unexpected error while resending email');
		  return null;
		}
	  }

	async updateStudentProgram(studentId: any, programId: any) {
		try {
			let response = await http.patch(`/students/${studentId}/update_program/?program_id=${programId}`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while fetching student program");
		}
	}

	async updateStudentStartDate(studentId: number, startDate: string) {
		try {
		  let response = await http.patch(`/students/${studentId}/update_start_date/?start_date=${startDate}`);
		  return response;
		} catch (error) {
		  http.fa360.displayError('Unexpected error while updating Start Date');
		}
	  }
	
	async deleteStudent(student_Id: any) {
		try {
			let response = await http.delete(`/students/${student_Id}/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while fetching students program");
		}
	}

	async validateEmail(email: any) {
		try {
			let response = await http.get(`/public/students/validate_email/?email=${email}`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while fetching students program");
		}
	}

	async getStudentsByName(name: any) {
		try {
			let response = await http.get(`/student/get_students_by_name/?name=${name}`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while fetching students");
		}
	}
}

export default new StudentService();
