import { PeriodSchool } from "../models/Program.model";
import http from "./http-common";

class SchoolService {

    async getSchools() {
        try {
            let response = await http.get(`/schools/`);
            return response.data;
        }
        catch (error) {
            http.fa360.displayError("Error loading school list");
        }
    }

    async getSchoolById(id: number) {
        try {
            if (!id) return null;
            let response = await http.get(`/schools/${id}/`);
            return response.data;
        }
        catch (error) {
            http.fa360.displayError("Error loading school");
        }
    }

    async getPeriodsBySchool(schoolId: string | number) {
        try {
            let response = await http.get(`public/periods/school/${schoolId}/`);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error retrieving periods");
        }
    }

    async addPeriod(period: PeriodSchool) {
        try {
            let response = await http.post("/periods_school/", period);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error Adding Period");
        }
    }

    async updatePeriod(period: PeriodSchool) {
        try {
            let response = await http.patch(`/periods_school/${period.id}/`, period);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error Updating Period");
        }
    }

    async removePeriod(id: number) {
        try {
            let response = await http.delete(`/periods_school/${id}/`);
            return response;
        }
        catch (error) {
            http.fa360.displayError("Error Removing Period");
        }
    }

}

export default new SchoolService();