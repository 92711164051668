import * as React from "react";

import FileUpload from "../../components/shared/file-upload/fileUpload";
import { DocumentTemplate } from "../../models/Document.model";
import fileService from "../../services/file.service";

class CreateDocumentTemplate extends React.Component<any, any> {
  state = {
    status: 0,
    message: "",
    name: "",
    file: null,
  };

  render() {
    return (
      <div className="page create-document-template">
        <div className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-2 control-label">Name</label>
            <div className="col-sm-10">
              <input name="name" type="text" className="form-control" placeholder="Name" value={this.state.name} onChange={this.handleInputChange} />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">File</label>
            <div className="col-sm-10">
              <FileUpload status={this.state.status} message={this.state.message} onChange={this.handleFileSelected}></FileUpload>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <button className="btn btn-primary" onClick={this.handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleInputChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFileSelected = (file: any) => {
    this.setState({ file: file });
  };

  handleCreate = async () => {
    let payload: DocumentTemplate = {
      name: this.state.name,
      file: this.state.file,
    };

    try {
      this.setState({ status: 1 });
      await fileService.upload(payload);
      this.setState({ status: 0, message: 'Successfully created' });
    } catch (error: any) {
      console.error('Error', error);
      this.setState({ status: 2, message: error.message });
    }
  };
}

export default CreateDocumentTemplate;
