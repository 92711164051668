import React from "react";
import { AlertType } from "../models/AlertType.enum";
import { User } from "../models/User.model";
import { IDataLoaders } from "./dataLoaders";
import { IGlobalAlert, LoggedInStatus, ModulesData } from "./IContext";
import { Permission } from "../models/Enums";

// crate react context
export default React.createContext({
  // data
  isLoading: false,
  loadingMessage: "",
  isLoggedIn: 0,
  user: {} as User,
  alert: {} as IGlobalAlert,
  cache: {} as any,
  loaders: {} as IDataLoaders,
  modules: { filter: {} } as ModulesData,

  // functions
  setLoading: (isLoading: boolean, message?: string) => {},
  setLoggedInStatus: (status: LoggedInStatus, user: User) => {},
  hasPermission: (permission: Permission): boolean => {
    return false;
  },
  logOut: async () => {},
  verifyLoggedIn: async () => {},
  showAlert: (alertMessage: any, alertType: AlertType = AlertType.Error) => {},
  clearAlert: () => {},
  clearCacheKey: (key: string) => {},
  getWithCache: async <T extends unknown>(
    key: string,
    getData: () => {},
    preProcess?: (data: any) => {},
    postProcess?: (data: any) => {}
  ): Promise<T> => {
    return null as T;
  },
  saveFilter: (action: string, value: any) => {},
  getFilter: () => {},
});
