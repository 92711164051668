import { Dispatch, SetStateAction } from "react";
import { SignedFormKey } from "../models/Forms.enum";
import { SignedForm } from "../models/Forms.model";

export class SignedPageData {
    private saveFn: any;
    private pageKey = "";

    constructor(
        public formKey: SignedFormKey,
        public program: number,
        public formData: SignedForm,
        public token = "",
        public canMoveNext: Dispatch<SetStateAction<boolean>> = () => { },
        public feeGroupId?: number
    ) {        
    }

}