import { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import './paginatedList.scss';
import Pagination from '../pagination/pagination';

export interface PaginationTableProps {
  title: string;
  filters?: JSX.Element;
  headers?: string[];
  data: JSX.Element[];
  className: string;
  loading?: boolean;
  recordsPerPage?: number; //Defaults to 10 if not supplied
}

const PaginatedList = ({ title, filters, headers, data, className, recordsPerPage = 10, loading = false }: PaginationTableProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(recordsPerPage);

  const incrementPages = (n: number) => {
    setCurrentPage(currentPage + n);
  };

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getHeaders = () => {
    if (headers) {
      return (
        <div className={`${className}-list-headers`}>
          {headers.map((header, index) => (
            <b key={index}>{header}</b>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [data]);

  const getActionPagination = () => {
    return (
      <Pagination
        autoHide
        pageIndex={currentPage}
        pageSize={pageSize}
        totalRows={data?.length || 0}
        onGotoPage={(page) => goToPage(page)}
        onPreviousPage={() => incrementPages(-1)}
        onNextPage={() => incrementPages(1)}
        onSetPageSize={(size) => (setPageSize(size), setCurrentPage(0))}
      />
    );
  };

  const displayPageData = () => {
    if (data.length === 0) {
      return <h5 className={`${className}-list-empty`}>No Data Found</h5>;
    }

    let startingIndex = currentPage * recordsPerPage;
    let endingIndex = startingIndex + recordsPerPage;
    return data.slice(startingIndex, endingIndex);
  };

  return (
    <div className="paginated-list">
      <div className="list-actions">
        <h4 className="list-title">{title}</h4>
        {filters}
      </div>
      <div className="list-container">
        {loading ? (
          <div className="inline-waiting text-center">
            <Loader type="Circles" color="#001737" height="100" width="100" />
            <h6 className="mg-t-10">Loading data...</h6>
          </div>
        ) : (
          <>
            {getHeaders()}
            <div className={`${className}-list`}>{displayPageData()}</div>
          </>
        )}
      </div>
      {getActionPagination()}
    </div>
  );
};

export default PaginatedList;
