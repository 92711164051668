import Modal from "../modal/modal";
import { ArrowLeft, ArrowRight } from "react-feather";
import './monthModal.scss'
import { useEffect, useState } from "react";
import moment from "moment";

const MonthModal = (props: any) => {
    const [year, setYear] = useState(0);
    const [months, setMonths] = useState([] as string[]);

    useEffect(() => {
        setYear(moment().year());
        setMonths(moment.months())
    }, []);


    const changeYear = (move: number) => {
        setYear(year + move);
    }

    const selectMonth = (index: any) => {
        let month = index;
        props.close();
        props.goToDate(month, year);
    }

    const getBody = () => {
        const yearsRange = 8;
        const yearOptions = Array.from({ length: yearsRange * 2 + 1 }, (_, i) => year - yearsRange + i);
        // Each month returned will have an on click that will set the selected month and also close the modal
        return (
            <>
                <div className="year-selector">
                    <button className="arroww btn btn-sm btn-dark" onClick={() => { changeYear(-1) }}><ArrowLeft /></button>
                    <select className="year-dropdown" value={year} onChange={(e) => setYear(Number(e.target.value))}>
                        {yearOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button className="arroww btn btn-sm btn-dark" onClick={() => { changeYear(1) }}><ArrowRight /></button>
                </div>

                <div className="months">
                    {months.map((month, index) => (<div className="month" key={index} onClick={() => { selectMonth(index) }}>
                    {month}</div>))}
                </div>
            </>
        )
    }
    return (
        <div className="month-modal">
            <Modal
                visible={props.visible}
                title="Select Month"
                body={getBody()}
                onClose={props.close}
                hideFooter
            />
        </div>
    )
}

export default MonthModal;