import { AlertType } from "../models/AlertType.enum";

export interface IGlobalAlert {
    alertMessage: any,
    alertType: AlertType
}

export enum LoggedInStatus {
    NOT_VERIFIED = 0, // first page load ?
    NOT_LOGGED_IN = 1,
    LOGGED_IN = 2
}


export interface ModulesData {
    filter: { } | any
}