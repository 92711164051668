import { useEffect, useState } from "react";
import Modal from "../../modal/modal";
import dateService from "../../../services/date.service";
import TextField from "../../shared/Input/textField";
import { set, useForm } from "react-hook-form";
import { PeriodSchool } from "../../../models/Program.model";

const PeriodsDateModal = (props: any) => {
    const [modalBody, setModalBody] = useState('');
    const [validationError, setValidationError] = useState(false);
    const [validateDateOrder, setValidateDateOrder] = useState(false);
    const [period, setPeriod] = useState({} as PeriodSchool);
    const { register, formState: { errors, isValid }, setValue, getValues, watch, handleSubmit } = useForm<PeriodSchool>();

    const getModalBody = () => {
        return (
            <>
                <div className="program-form flex-form">
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                label="Start Date"
                                field="startDate"
                                type="date"
                                placeholder="Start Date"
                                rules={{ required: true }}
                                parentClass={validationError && !period?.startDate ? 'error-input' : ''}
                                errors={errors}
                                register={register}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                label="End Date"
                                field="endDate"
                                type="date"
                                placeholder="End Date"
                                parentClass={(validationError && !period?.endDate) || validateDateOrder ? 'error-input' : ''}
                                register={register}
                                rules={{ required: true }}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <TextField
                            label="Name of Period (optional)"
                            field="name"
                            type="text"
                            placeholder="Name of Period"
                            register={register}
                            errors={errors}

                        />
                    </div>
                    {validateDateOrder ? (
                        <div className="text-danger py-1">
                            The end date must be equal to or later than start date.
                        </div>
                    ) : ('')}
                </div>
            </>
        );
    }


    const savePeriod = (form: any) => {
        if (validateDates(form)) {
            props.handleAddPeriod(form as PeriodSchool);
            clear();
            props.closeModal();
        }
    }

    const saveChanges = (form: any) => {
        period.startDate = form.startDate;
        period.endDate = form.endDate;
        period.name = form.name;
        if (validateDates(form)) {
            props.handleEditPeriod(period as PeriodSchool);
            clear();
            props.closeModal();
        }
    }

    const validateDates = (form: any) => {

        if (!form.startDate || !form.endDate) {
            setValidationError(true);
            return false;
        }
        if (new Date(form.endDate) < new Date(form.startDate)) {
            setValidateDateOrder(true);
            return false;
        }
        return true;
    }

    const clear = () => {
        setValue('startDate', '');
        setValue('endDate', '');
        setValue('name', '');
    }

    useEffect(() => {
        setValidationError(false);
        setValidateDateOrder(false);
        setModalBody(props.modalType);

        if (props.modalType) {
        }
        else if (props.selectedPeriod?.id) {
            setValue('startDate', dateService.formatToYMD(props.selectedPeriod.startDate));
            setValue('endDate', dateService.formatToYMD(props.selectedPeriod.endDate));
            setValue('name', props.selectedPeriod.name ? props.selectedPeriod.name : '');
            setPeriod(props.selectedPeriod);

        } else {
            clear();
        }
    }, [props.modalType, props.selectedPeriod]);

    useEffect(() => {
        setValidateDateOrder(false)
    }, [watch('endDate')]);

    return (
        <Modal
            visible={props.modalVisible}
            title={modalBody === 'add' ? 'New Period' : 'Edit Period'}
            body={getModalBody()}
            cancelButtonClass={'btn-dark'}
            cancelButtonText={'Cancel'}
            mainButtonClass={'btn-primary'}
            mainButtonText={modalBody === 'add' ? 'Save Period' : 'Save Changes'}
            mainButtonDisabled={watch('startDate') == "" || watch('endDate') == ""}
            onAccept={modalBody === 'add' ?
                handleSubmit((d) => savePeriod(d))
                :
                handleSubmit((d) => saveChanges(d))
            }
            onCancel={() => {
                clear();
                props.closeModal();
                props.setSelectedPeriod(null)
            }}
            onClose={() => {
                clear();
                props.closeModal();
                props.setSelectedPeriod(null)
            }}
        ></Modal>
    )
}

export default PeriodsDateModal;