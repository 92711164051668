import "./asideDashboard.scss";
import EventComponent from "./eventComponent";

import { Chart, registerables } from "chart.js";
import { useContext, useEffect, useState } from "react";
import CalendarService from "../../services/calendar.service";
import { Event } from "../../models/Event.model";
import { Link } from "react-router-dom";
import EventModal from "../calendar/eventModal";
import globalContext from "../../context/globalContext";
import NotificationModal from "./notificationModal";
import emailService from "../../services/email.service";
import { Bell, MessageCircle, MessageSquare } from "react-feather";

Chart.register(...registerables);

const AsideDashboard = (props: any) => {
  const { loaders } = useContext(globalContext);
  const [eventTypes, setEventTypes] = useState([] as any);
  const [upcomingEvents, setUpcomingEvents] = useState([] as Event[]);
  const [selectedEventId, setSelectedEventId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [updateNotifications, setUpdateNotifications] = useState(false);
  const [unreadNot, setUnreadNot] = useState([] as any);

  const loadData = async () => {
    let eventTypeReq = loaders.eventTypes();
    let eventReq = CalendarService.getAllEvents();
    let unreadNot = emailService.getUnreadEmails();

    let [eventTypesRes, eventListRes, unreadRes] = await Promise.all([
      eventTypeReq,
      eventReq,
      unreadNot,
    ]);

    const currentDate = new Date();

    const Events = eventListRes?.data.filter((event: Event) => {
      const eventStartDate = new Date(event.startDate);
      const eventEndDate = new Date(event.endDate);

      const reminderTime = event.reminderTime || 1;
      const reminderDate = new Date(eventStartDate);
      reminderDate.setDate(reminderDate.getDate() - reminderTime);

      return (
        (currentDate >= eventStartDate && currentDate <= eventEndDate) ||
        (currentDate >= reminderDate && currentDate <= eventEndDate)
      );
    });
    setEventTypes(eventTypesRes);
    setUpcomingEvents(Events);
    setUnreadNot(unreadRes?.data);
  };

  const showEventDetails = async (eventId: any) => {
    setSelectedEventId(eventId);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(!modalVisible);
    setSelectedEventId(0);
  };

  const handleNotification = () => {
    setNotificationVisible(!notificationVisible);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <aside className="aside-dashboard-container">
      <div className="upcoming-events">
        <div className="upcoming-event-header">
          <h5>Notifications</h5>
          <button
            className={
              unreadNot.length === 0
                ? "btn btn-sm btn-outline-primary d-grid gap-2"
                : "btn-message btn btn-sm btn-primary d-grid gap-2"
            }
            onClick={handleNotification}
            disabled={unreadNot.length === 0 ? true : false}
          >
            {/* <Bell /> */}
            View {unreadNot.length} messages
          </button>
        </div>
      </div>
      <div className="upcoming-events">
        <div className="upcoming-event-header">
          <h5>Upcoming Events</h5>
          <Link
            className="btn btn-sm btn-outline-primary d-grid gap-2"
            to="/events/calendar"
          >
            View Calendar
          </Link>
        </div>

        {modalVisible ? (
          <EventModal
            eventId={selectedEventId}
            visible={modalVisible}
            onClose={handleModalClose}
          />
        ) : null}

        {notificationVisible && (
          <NotificationModal
            unreadNot={unreadNot}
            setUpdateNotifications={setUpdateNotifications}
            updateNotifications={updateNotifications}
            visible={notificationVisible}
            onClose={() => setNotificationVisible(false)}
          />
        )}

        {upcomingEvents?.length > 0 ? (
          <ul className="event-list">
            {upcomingEvents?.map((event: Event) => (
              <EventComponent
                key={event.id}
                event={event}
                onClick={() => showEventDetails(event.id)}
              />
            ))}
          </ul>
        ) : (
          <h6>No Upcoming Events</h6>
        )}
      </div>
    </aside>
  );
};

export default AsideDashboard;
