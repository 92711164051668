import TitleBar from "../../components/shared/titleBar/titleBar";
import DocumentTypeViewer from "../../components/documentType/documentTypeViewer";

const DocumentTypePage = () => {
    return (
        <div className="home-page pd-0">
            <TitleBar header="Manage Document Types" subheader="Presented on student documents" />

            <DocumentTypeViewer></DocumentTypeViewer>
        </div>
    )
}

export default DocumentTypePage;