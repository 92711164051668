import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const SpanishAgreementPage5 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement5)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <h4>TRANSFERENCIA DE CRÉDITOS:</h4>
            <p>
                AVISO RELATIVO A LA TRANSFERIBILIDAD DE LOS CRÉDITOS Y CREDENCIALES OBTENIDOS EN NUESTRA INSTITUCIÓN
            </p>
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5')}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p className="m-0">
                            <div className="position-relative">
                                La transferibilidad de los créditos que usted obtenga en SDGKU queda a la completa discreción de la institución a la que usted busque transferirse. La aceptación de los créditos que obtenga en el programa Bachelor of Science in Global Management - Español también queda a la entera discreción de la institución a la que desee trasladarse. Si los créditos que ha obtenido en esta institución no son aceptados en la institución a la que desea trasladarse, es posible que tenga que repetir parte o la totalidad de sus estudios en dicha institución. Por este motivo, debe asegurarse de que su asistencia a esta institución cumplirá sus objetivos educativos. Esto puede incluir ponerse en contacto con una institución a la que usted busque transferirse después de asistir a SDGKU para determinar si sus créditos serán transferidos.
                            </div>
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-2', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            Si deseo transferir unidades a otro colegio o universidad, la universidad receptora puede o no aceptar las unidades tomadas en San Diego Global Knowledge University. Antes de transferir, por favor verifique si la otra universidad aceptará las horas de crédito de San Diego Global Knowledge University.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-3', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            Se me ha explicado que aunque las universidades estén acreditadas por la WASC, la universidad aceptante no está obligada a aceptar unidades de una universidad acreditada por la WASC.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-4', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            Se me ha explicado que SDGKU participa en los programas del Title IV (Federal Pell Grant, Federal Direct Loans (Subsidized, Unsubsidized, Federal PLUS)).
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-5', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            He recibido información sobre los derechos de los estudiantes y sobre las políticas de acoso sexual, Title IX y seguridad del campus.
                        </p>
                    </div>
                </div>
            </div>
            <p>
                <b>DIVULGACIÓN DEL FONDO DE RECUPERACIÓN DE MATRÍCULA ESTUDIANTIL: </b> El Estado de California estableció el Fondo de Recuperación de Matrículas para Estudiantes (STRF, por sus siglas en inglés) para aliviar o mitigar la pérdida económica sufrida por un estudiante en un programa educativo en una institución calificada, que es o era residente de California mientras estaba inscrito, o estaba inscrito en un programa de residencia, si el estudiante se inscribió en la institución, pagó por adelantado la matrícula y sufrió una pérdida económica. A menos que se le exima de la obligación de hacerlo, usted debe pagar la cuota impuesta por el estado para el STRF, o ésta debe ser pagada en su nombre, si usted es un estudiante en un programa educativo, que es residente de California, o está inscrito en un programa de residencia, y paga por adelantado toda o parte de su matrícula. Usted no tiene derecho a la protección del STRF y no está obligado a pagar la cuota del STRF, si no es residente de California, o no está inscrito en un programa de residencia.
            </p>
            <p>
                Es importante que guarde copias de su acuerdo de inscripción, documentos de ayuda financiera, recibos o cualquier otra información que documente la cantidad pagada a la escuela. Las preguntas relativas al STRF pueden dirigirse a la Oficina de Educación Postsecundaria Privada (Bureau for Private Postsecondary Education), 1747 North Market Blvd., Suite 225, Sacramento, California, 95834, (916) 574-8900 o (888) 370-7589.
            </p>
            <p>
                Para tener derecho al STRF, debe ser residente de California o estar inscrito en un programa de residencia, haber pagado por adelantado la matrícula, haber pagado o considerarse que ha pagado la cuota del STRF y haber sufrido una pérdida económica como resultado de cualquiera de los siguientes factores:
            </p>
            <div className="position-relative">
                {getInitials('i_page5', true)}
            </div>
        </div>
    )
})