import { useContext, useEffect, useState } from "react";
import { School } from "../../../models/School.model";
import { PeriodSchool } from "../../../models/Program.model";
import dateService from "../../../services/date.service";
import ActionIcon from "../../shared/action-icon/actionIcon";
import { Edit3 as IEdit, Plus as IAdd, XCircle as IconReject } from "react-feather";

import "./periodsViewer.scss";
import PeriodsDateModal from "./periodsModal";
import { toast } from "react-toastify";
import globalContext from "../../../context/globalContext";
import schoolService from "../../../services/school.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
const moment = require("moment-timezone");

interface PeriodsViewerProps {
    selectedSchool: School;
    setShowPicker: any;
}

const PeriodsViewer = (props: PeriodsViewerProps) => {
    const { selectedSchool, setShowPicker } = props;
    const [periods, setPeriods] = useState([] as PeriodSchool[]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState<string>("");
    const [selectedPeriod, setSelectedPeriod] = useState({} as PeriodSchool);
    const { setLoading } = useContext(globalContext);

    const showToast = (message: string) => {
        toast.dark(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
        });
    };

    const loadData = async () => {
        setLoading(true);
        let response = await schoolService.getPeriodsBySchool(selectedSchool?.id);
        setLoading(false);
        if (response?.status === HttpStatusCode.Ok) {
            let periods = response?.data as PeriodSchool[];
            let sortedPeriods = [...periods].sort(sortDate);
            setPeriods(sortedPeriods);
        } else {
            showToast("❌ Error loading periods. Please try again.");
        };
    }
    const handleDeletePeriod = async (periodId: any) => {
        try {
            setLoading(true);
            let response = await schoolService.removePeriod(periodId);
            setLoading(false);

            if (
                response &&
                (response?.status === HttpStatusCode.Ok ||
                    response?.status === HttpStatusCode.NoContent)
            ) {
               setPeriods(
                    periods.filter((period: PeriodSchool) => period.id !== periodId)
                );

                showToast("✔️ Period deleted successfully"
               )
            } else {
                showToast("❌ Error deleting period. Please try again.");
            };
        } catch (error) {
            showToast("❌ Unexpected error. Please try again.");
        };
    }

    const handleEditPeriod = async (period: PeriodSchool) => {
        try {
            setLoading(true);
            let response = await schoolService.updatePeriod(period);
            setLoading(false);

            if (response?.status === HttpStatusCode.Ok) {
                let temp = [...periods];
                const index = temp.findIndex(
                    (period: PeriodSchool) => period.id === response?.data.id
                );

                if (index !== -1) {
                    temp[index] = response?.data;
                    setPeriods(temp);

                    showToast("✔️ Period edited successfully");
                };
            } else {
                showToast("❌ Error updating period. Please try again.");
            };
        } catch (error) {
            showToast("❌ Unexpected error. Please try again.");
        };
    }

    const handleAddPeriod = async (data: any) => {
        let startDate = data.startDate;
        let endDate = data.endDate;
        let name = data.name;
        let schoolId = selectedSchool?.id;

        try {
            if (schoolId != null && startDate !== "" && endDate !== "") {
                setLoading(true);
                let payload = { startDate, endDate, name, schoolId } as PeriodSchool;
                let response = await schoolService.addPeriod(payload);
                setLoading(false);

                if (
                    response &&
                    (response.status === HttpStatusCode.Created ||
                        response.status === HttpStatusCode.NoContent)
                ) {
                    let temp = [...periods];
                    temp.push(response.data);
                    let sortedTemp = temp.sort(sortDate);
                    setPeriods(sortedTemp);

                    showToast("✔️ Period added successfully");
                } else {
                    showToast("❌ Error adding period. Please try again.");
                };
            };
        } catch (error) {
            showToast("❌ Unexpected error. Please try again.");
        };
    };

    const sortDate = (a: PeriodSchool, b: PeriodSchool) => {
        let dateA = a.startDate;
        let dateB = b.startDate;

        return moment(dateB) - moment(dateA);
    }

    const showEditPeriod = (period: PeriodSchool) => {
        setModalVisible(true);
        setSelectedPeriod(period);
    }

    const handleModalVisible = (type: string) => {
        setModalType(type);
        setModalVisible(!modalVisible);
    }

    const getHeader = () => {
        return (
            <>
                <div className="header-component-1 schoolsPickerButton btn btn-outline-primary" onClick={setShowPicker}>
                    Change School
                </div>

                <h4 className="header-component-2">
                    Periods
                </h4>

                <div className="header-component-1 right-buttons">
                    <div className="add-new-period btn btn-outline-primary" onClick={() => handleModalVisible("add")}>
                        <IAdd />
                        
                    </div>
                </div>
            </>
        );
    }

    const getPeriosList = () => {
        return (
            <ul className="list-group">
                {periods
                    .map((period: PeriodSchool, index) => (
                        <li
                            key={period.id}
                            className="period-item list-group-item list-group-item-action border-bottom"
                        >
                            <div className="date">
                                <p>
                                    Start: {period.startDate} - End:{" "}
                                    {period.endDate}
                                </p>
                            </div>
                            <div className="name">
                                <p>{period.name}</p>
                            </div>
                            <div className="period-actions">
                                <ActionIcon
                                    label="Edit"
                                    tooltip="Edit"
                                    icon={<IEdit />}
                                    color="primary"
                                    onClick={() => showEditPeriod(period)}
                                />
                                <ActionIcon
                                    label="Delete"
                                    tooltip="Delete"
                                    icon={<IconReject />}
                                    color="danger"
                                    extraClass="custom-delete-btn"
                                    onClick={() => handleDeletePeriod(period?.id)}
                                />
                            </div>
                        </li>
                    ))}
            </ul>
        )
    }

    useEffect(() => {
        if (selectedSchool?.id) {
            loadData();
        }
    }, [selectedSchool]);
    return (
        <div className="period-viewer">
            <PeriodsDateModal
                closeModal={handleModalVisible}
                modalVisible={modalVisible}
                selectedPeriod={selectedPeriod}
                handleAddPeriod={handleAddPeriod}
                handleEditPeriod={handleEditPeriod}
                setSelectedPeriod={setSelectedPeriod}
                modalType={modalType}
            />
            <div className="periods-card card">
                <div className="card-header">{getHeader()}</div>
                <div className="card-body overflow-auto">
                    {!selectedSchool?.id && (
                        <div className="alert alert-info">
                            <h5>No school selected</h5>
                            <p>Please select a school to view the periods dates</p>
                        </div>
                    )}
                    {getPeriosList()}
                </div>
                <div className="card-footer">
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default PeriodsViewer;