import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const AgreementPage5 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement5)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <h4>TRANSFER OF CREDIT:</h4>
            <p>
                NOTICE CONCERNING TRANSFERABILITY OF CREDITS AND CREDENTIALS EARNED AT OUR INSTITUTION
            </p>
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5')}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p className="m-0">
                            <div className="position-relative">
                                The transferability of credits you earn at SDGKU is at the complete discretion of an institution to which you may seek to transfer. Acceptance of the credits you earn in the {program?.programName} program is also at the complete discretion of the institution to which you may seek to transfer. If the credits that you earned at this institution are not accepted at the institution to which you seek to transfer, you may be required to repeat some or all of your coursework at that institution. For this reason you should make certain that your attendance at this institution will meet your educational goals. This may include contacting an institution to which you may seek to transfer after attending SDGKU to determine if your credits will transfer.
                            </div>
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-2', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            If I wish to transfer units to another college or university, the receiving university may or may not accept the units taken at San Diego Global Knowledge University. Before transferring, please verify if the other university will accept San Diego Global Knowledge University credit hours.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-3', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            It has been explained to me that even if colleges and universities are WASC accredited, the accepting university is not obligated to accept units from a WASC accredited college.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-4', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                        It has been explained to me that SDGKU participates in Title IV programs (Federal Pell Grant, Federal Direct Loans (Subsidized, Unsubsidized, Federal PLUS).
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page5-5', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            I have received information on student rights and on Sexual Harassment, Title IX, and Campus Security Policies.
                        </p>
                    </div>
                </div>
            </div>
            <p>
                <b>STUDENT TUITION RECOVERY FUND DISCLOSURE: </b> The State of California established the Student Tuition Recovery Fund (STRF) to relieve or mitigate economic loss suffered by a student in an educational program at a qualifying institution, who is or was a California resident while enrolled, or was enrolled in a residency program, if the student enrolled in the institution, prepaid tuition, and suffered an economic loss. Unless relieved of the obligation to do so, you must pay the state-imposed assessment for the STRF, or it must be paid on your behalf, if you are a student in an educational program, who is a California resident, or are enrolled in a residency program, and prepay all or part of your tuition. You are not eligible for protection from the STRF and you are not required to pay the STRF assessment, if you are not a California resident, or are not enrolled in a residency program.
            </p>
            <p>
                It is important that you keep copies of your enrollment agreement, financial aid documents, receipts, or any other information that documents the amount paid to the school. Questions regarding the STRF may be directed to the Bureau for Private Postsecondary Education, 1747 North Market Blvd., Suite 225, Sacramento, California, 95834, (916) 574-8900 or (888) 370-7589.
            </p>
            <p>
                To be eligible for STRF, you must be a California resident or enrolled in a residency program, prepaid tuition, paid or deemed to have paid the STRF assessment, and suffered an economic loss as a result of any of the following:
            </p>
            <div className="position-relative">
                {getInitials('i_page5', true)}
            </div>
        </div>
    )
})