import "./pieComponent.scss";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export interface PieComponentProps {
  labels: any;
  label: any;
  data: any;
  cssClass?: string;
}
const PieComponent = (props: PieComponentProps) => {
  const getInfo = () => {
    return {
      labels: props.labels,
      datasets: [
        {
          data: props.data,
          backgroundColor: [
            "#F20530",
            "#F2509C",
            "#05AFF2",
            "#6E8C03",
            "#465902",
            "#FF5733",
            "#FFC300",
            "#FFC0CB",
            "#00FFFF",
            "#9932CC",
            "#FFD700",
            "#228B22",
            "#4B0082",
            "#800080",
            "#2F4F4F",
          ],
        },
      ],
    };
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins:  {
      legend: {
        display: true,
        position: 'right' as 'right',
      },
    },
  };

  return (
    <div className={"graph pie-graph " + props.cssClass}>
      <h5>{props.label}</h5>
      <Pie data={getInfo()} options={chartOptions}></Pie>
    </div>
  );
};

export default PieComponent;