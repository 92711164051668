import ReactGA from 'react-ga';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';


let TRACKING_ID = window.G_ANALYTICS as string;
if(TRACKING_ID === "{{G_ANALYTICS}}") { 
  TRACKING_ID = "G-X8DB45Z2T7";
}
ReactGA.initialize(TRACKING_ID);

export const trackGAPageView = (page: string) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};


// Application Insights
var reactPlugin = new ReactPlugin();
let AZURE_CON_STR = window.AZI as string;
if(AZURE_CON_STR === "{{AZI}}") { 
  AZURE_CON_STR = "InstrumentationKey=f2d0e3c1-269e-45d7-a836-d12583cf4531;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/;ApplicationId=1cb37c86-8402-416b-a44b-17df2e9b33a1";
}
const appInsights = new ApplicationInsights({
  config: {
    connectionString: AZURE_CON_STR,    
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  }
});
appInsights.loadAppInsights();


export const trackAZPageView = (name?: string) => {
  appInsights.trackPageView({ name });
};

export const trackAZEvent = (name: string, properties?: { [key: string]: any }) => {
  appInsights.trackEvent({ name }, properties);
};

export default appInsights;