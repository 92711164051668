import { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { toast } from "react-toastify";

type Status = 'success' | 'error';

interface Options {
  successMessage?: string;
  errorMessage?: string;
}

const useToastFromAxiosResponse = () => {

  const handleResponse = (response: AxiosResponse<any> | null, options?: Options) => {
    if (!response) return false;
    const { successMessage = '✔️ Success', errorMessage = '❌ Something went wrong' } = options || {};
    const status: Status = (response.status >= 200 && response.status < 300) ? 'success' : 'error';

    const message = status === 'success' ? successMessage : errorMessage;
    toast.dark(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });

    return status === 'success';
  };

  return handleResponse;
};

export default useToastFromAxiosResponse;
