import { AgreementProps } from "../../types"
import classNames from '../sdgku.enrollment.module.css'
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

const formatMoney = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const AgreementPage8 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getSignature, getInitials } = usePageSignature(data)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement8, true)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">

            <p>
                <b>Payment Plan:</b> Payment plan options are avaialbe in monthly or per term installments. The instution does not charge any fees for late payments, charges interest on deferred payments, or charges any per payment or per term financing fees. No discounts are provided for payments. For any program that is over a year in length and allows students to pay for tuition and fees in four or more installments, the institution provides a “TILA Box” which discloses the APR cost for the educational program. A TILA Box example is shown below:
            </p>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Annual Percentage Rate (cost of credit provided as a yearly rate)*</th>
                        <th scope="col">Finance Charge (cost of total credit as a dollar amount)**</th>
                        <th scope="col">Amount Financed (the total cost of tuition and semester-based fees, as a dollar amount)</th>
                        <th scope="col">Total of Payments (total cost of tuition, semester-based fees, and finance charges as a dollar amount)</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>3.05%</td>
                    <td>$300</td>
                    <td>$9,520.00</td>
                    <td>$9,820.00</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={4}>
                        <p className='text-left'>
                    * Annual payment plan admin fee / Annual tuition, semester-based fees, and payment plan admin fee cost. <br />
                    **Based on program completion in six semesters
                    </p>
                        </td>
                    </tr>                    
                </tfoot>
            </table>

            <p>
                <b>NOTICE:</b>
                YOU MAY ASSERT AGAINST THE HOLDER OF THE PROMISSORY NOTE YOU SIGNED IN ORDER TO FINANCE THE COST OF THE EDUCATIONAL PROGRAM ALL OF THE CLAIMS AND DEFENSES THAT YOU COULD ASSERT AGAINST THIS INSTITUTION, UP TO THE AMOUT YOU HAVE ALREADT PAID UNDER THE PROMISSORY NOTE.
            </p>

            <p>
                San Diego Global Knowledge University shall transmit all lessons to the student if the student has fully paid for the educational program and, after having received the first lesson and initial materials, requests in writing that all of the material be sent. If San Diego Global Knowledge University transmits the balance of the material as the student requests, San Diego Global Knowledge University shall remain obligated to provide the other educational services it agreed to provide but shall not be obligated to pay any refund after all of the lessons and material are transmitted.
            </p>
            <div className="container-fluid">
                {
                    program?.charges.map((charge, index) => (
                        <div className="row mb-2" key={index}>
                            <div className='col-8'>
                                <b>{charge.chargeName}</b>
                            </div>
                            <div className={`${classNames.amount} col-4 text-right`}>
                                {`${formatMoney(charge.chargeAmount)} USD`}
                            </div>
                        </div>
                    ))
                }
            </div>
            <p>
                <b>
                    I understand that this is a legally binding contract. My signature below certifies that I have read, understood, and agreed to my rights and responsibilities, and that the institution's cancellation and refund polices have been clearly explained to me. If you are under the legal age in your state when you enroll in the Program, your parent  or legal guardian must sign as your "Guarantor" below and must agree to be responsible for your payment obligations for the Program. This is enrollment agreement is legally binding when signed by the student (or guarantor) and institution.
                </b>
            </p>
            <div className="position-relative mb-4">
                {getInitials('page8')}
            </div>
            <div id="pad">
                {getSignature()}
            </div>
        </div>
    )
})
