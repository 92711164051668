import React, { useContext } from "react";
import Loader from "react-loader-spinner";
import globalContext from "../../../context/globalContext";
import "./spinner.scss";

export const Spinner = () => {
  const { isLoading, loadingMessage } = useContext(globalContext);

  const loadingForm = (
    <React.Fragment>
      <div className="modal modal-spinner" id="exampleModal" tabIndex={-1} style={{ display: "block", zIndex:9999 }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body spinner">
              <Loader type="Circles" color="#001737" height="100" width="100" />
              <h6 className="mg-t-10">{loadingMessage}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </React.Fragment>
  );

  return isLoading ? loadingForm : null;
};
