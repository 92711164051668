import { EnrollmentStatus, SignedForm } from "../models/Forms.model";
import http from "./http-common";

class SignedFormService {

	async sendForm(studentId: number, formKey: string, feeGroupId: number) {
		try {
			let response = await http.post<SignedForm[]>(`/forms/signedform/send_form/`, { studentId, formKey, feeGroupId });
			return response;
		} catch (error) {
			http.fa360.displayError("Error sending form");
		}
	}
	
	async sendFormWithPrevData(form: SignedForm) {
		try {
			let response = await http.post<SignedForm>(`/forms/signedform/send_form_with_prev_data/`, { form });
			return response;
		} catch (error) {
			http.fa360.displayError("Error sending form");
		}
	}

	async getFormDataForPrevForm(studentId: number, formKey: string) {
        try {            
			let response = await http.post<SignedForm>(`/forms/signedform/retrieve/`, { studentId, formKey });
			return response;
		} catch (error: any) {
			http.fa360.displayError("Error retrieving data");
			return error.request;
		}
	}

	async getFormData(studentId: number, formKey: string, token: string, feeGroupId?: number) {
        try {            
			let response = await http.post<SignedForm>(`/forms/signedform/retrieve/`, { studentId, formKey, token, feeGroupId });
			return response;
		} catch (error: any) {
			http.fa360.displayError("Error retrieving data");
			return error.request;
		}
	}

	async save(form: SignedForm, token: string) {
		try {
			let response = await http.post<SignedForm>(`/forms/signedform/`, { form, token, studentId: form.student.id });
			return response;
		} catch (error) {
			http.fa360.displayError("Error saving changes");
		}
	}

	async checkEnrollmentStatus(studentId: string, token: string) {
		try {
			let response = await http.get<EnrollmentStatus>(`/forms/signedform/sdgku_status/?student_id=${studentId}&token=${token}`);
			return response;
		} catch (error) {
			http.fa360.displayError("Error sending form");
		}
	}

};

export default new SignedFormService();