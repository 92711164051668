import { COBAProgram } from '../../../../models/Enums';
import { Factsheet } from './types';

export const getFactsheet = (program: COBAProgram): Promise<Factsheet> => {
  return new Promise((resolve, reject) => {
    if (program === COBAProgram.BARBER_HYBRID) resolve(BARBER_HYBRID);
    else if (program === COBAProgram.BARBER) resolve(BARBER);
    else if (program === COBAProgram.COSMETOLOGY) resolve(COMSMO);
    else resolve(ESTHETICIAN);

    reject();
  });
};

const BARBER_HYBRID: Factsheet = {
  Graduation_Facts: [
    ['2021', 'N/A', 'N/A', 'N/A', 'N/A'],
    ['2022', 'N/A', 'N/A', 'N/A', 'N/A'],
  ],
  Completing_Facts: [
    ['2019', 'N/A', 'N/A', 'N/A', 'N/A'],
    ['2020', 'N/A', 'N/A', 'N/A', 'N/A'],
    ['2021', 'N/A', 'N/A', 'N/A', 'N/A'],
    ['2021', 'N/A', 'N/A', 'N/A', 'N/A'],
  ],
  Placement_Facts: [
    ['2021', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'],
    ['2022', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'],
  ],
  Gainfully_Facts: [
    [
      ['2021', 'N/A', 'N/A', 'N/A'],
      ['2022', 'N/A', 'N/A', 'N/A'],
    ],
    [
      ['2021', 'N/A', 'N/A', 'N/A'],
      ['2022', 'N/A', 'N/A', 'N/A'],
    ],
    [
      ['2021', 'N/A', 'N/A'],
      ['2022', 'N/A', 'N/A'],
    ],
    [
      ['2021', 'N/A', 'N/A'],
      ['2022', 'N/A', 'N/A'],
    ],
  ],
  Pass_Facts: [
    ['2021', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'],
    ['2022', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'],
  ],
  Salary_Facts: [
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0'],
      ['2021', '0', '0', '0'],
    ],
  ],
  Costs_Facts: [
    ['2021', 'N/A'],
    ['2022', 'N/A'],
  ],
  Federal_Facts: [
    ['2021', '3.50', '0%', '0%', '$7,810.23'],
    ['2022', '0.00', '0%', '0%', '$7,271.47'],
  ],
};

const BARBER: Factsheet = {
  Graduation_Facts: [
    ['2021', '0', '0', '0', '0%'],
    ['2022', '16', '16', '6', '38%'],
  ],
  Completing_Facts: [
    ['2019', '0', '0', '0', '0%'],
    ['2020', '0', '0', '0', '0%'],
    ['2021', '0', '0', '0', '0%'],    
    ['2022', '16', '16', '9', '56%'],
  ],
  Placement_Facts: [
    ['2021', '0', '0', '0', '0', '0%'],
    ['2022', '16', '9', '9', '3', '33%'],
  ],
  Gainfully_Facts: [
    [
      ['2021', '0', '0', '0'],
      ['2022', '0', '3', '3'],
    ],
    [
      ['2021', '0', '0', '0'],
      ['2022', '3', '0', '3'],
    ],
    [
      ['2021', '0', '0'],
      ['2022', '0', '3'],
    ],
    [
      ['2021', '0', '0'],
      ['2022', '0', '3'],
    ],
  ],
  Pass_Facts: [
    ['2021', '0', '0', '0', '0', '0%'],
    ['2022', '9', '5', '2', '3', '40'],
  ],
  Salary_Facts: [
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '9', '3', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '9', '3', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '9', '3', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0'],
      ['2021', '9', '3', '3'],
    ],
  ],
  Costs_Facts: [
    ['2021', 'N/A'],
    ['2022', '16,015.00'],
  ],
  Federal_Facts: [
    ['2021', '3.50', '0%', '0%', '$7,810.23'],
    ['2022', '0.00', '63%', '30%', '$7,271.47'],
  ],
};

const ESTHETICIAN: Factsheet = {
  Graduation_Facts: [
    ['2021', '75', '75', '36', '48%'],
    ['2022', '79', '79', '35', '44%'],
  ],
  Completing_Facts: [    
    ['2019', '32', '32', '20', '63%'],
    ['2020', '38', '38', '22', '58%'],
    ['2021', '75', '75', '59', '79%'],
    ['2022', '79', '79', '70', '89%'],
  ],
  Placement_Facts: [
    ['2021', '75', '59', '58', '18', '31%'],
    ['2022', '79', '70', '70', '21', '30%'],
  ],
  Gainfully_Facts: [
    [
      ['2021', '1', '17', '18'],
      ['2022', '6', '15', '21'],
    ],
    [
      ['2020', '18', '0', '18'],
      ['2021', '21', '0', '21'],
    ],
    [
      ['2021', '0', '18'],
      ['2022', '14', '21'],
    ],
    [
      ['2021', '0', '18'],
      ['2022', '14', '21'],
    ],
  ],
  Pass_Facts: [
    ['2021', '59', '40', '34', '6', '85%'],
    ['2022', '70', '56', '45', '11', '80%'],
  ],
  Salary_Facts: [
    [
      ['2021', '58', '18', '0', '0', '0', '0', '1', '0', '0'],
      ['2022', '70', '21', '0', '0', '0', '0', '0', '2', '1'],
    ],
    [
      ['2021', '58', '18', '0', '1', '0', '0', '0', '0', '0'],
      ['2022', '70', '21', '1', '1', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '58', '18', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '70', '21', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '58', '18', '16'],
      ['2022', '70', '21', '16'],
    ],
  ],
  Costs_Facts: [
    ['2021', '11,085'],
    ['2022', '12,550'],
  ],
  Federal_Facts: [
    ['2021', '3.50', '79%', '78%', '$7,810.23'],
    ['2021', '0.00', '74%', '74%', '$7,271.47'],
  ],
};

const COMSMO: Factsheet = {
  Graduation_Facts: [    
    ['2021', '0', '0', '0', '0%'],
    ['2022', '13', '13', '6', '46%'],
  ],
  Completing_Facts: [    
    ['2019', '0', '0', '0', '0%'],
    ['2020', '0', '0', '0', '0%'],
    ['2021', '0', '0', '0', '0%'],
    ['2022', '13', '13', '10', '77%'],
  ],
  Placement_Facts: [    
    ['2021', '0', '0', '0', '0', '0'],
    ['2022', '13', '10', '10', '3', '30%'],
  ],
  Gainfully_Facts: [
    [
      ['2021', '0', '0', '0'],
      ['2022', '0', '3', '3'],
    ],
    [
      ['2021', '0', '0', '0'],
      ['2022', '3', '0', '3'],
    ],
    [
      ['2021', '0', '0'],
      ['2022', '0', '3'],
    ],
    [
      ['2021', '0', '0'],
      ['2022', '0', '3'],
    ],
  ],
  Pass_Facts: [
    ['2020', '0', '0', '0', '0', '0'],
    ['2021', '10', '6', '5', '1', '83%'],
  ],
  Salary_Facts: [
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '10', '30', '0', '0', '0', '1', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '10', '3', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      ['2022', '10', '3', '0', '0', '0', '0', '0', '0', '0'],
    ],
    [
      ['2021', '0', '0', '0'],
      ['2022', '10', '3', '2'],
    ],
  ],
  Costs_Facts: [
    ['2021', 'N/A'],
    ['2022', '16,210.00'],
  ],
  Federal_Facts: [
    ['2021', '3.50', '0%', '0%', '$7,810.23'],
    ['2022', '0.00', '67%', '36%', '$7,270.47'],
  ],
};
