import React, { useContext, useState, useEffect } from "react";
import globalContext from "../../context/globalContext";

import schoolService from "../../services/school.service";
import { School } from "../../models/School.model";

import TitleBar from "../../components/shared/titleBar/titleBar";
import SchoolPicker from "../../components/programs/schoolPicker/schoolPicker";
import FeeViewer from "../../components/programs/fees/feeViewer";
import programsService from "../../services/programs.service";
import { Program } from "../../models/Program.model";

import './feePage.scss';

const FeePage = () => {
    const { setLoading, loaders } = useContext(globalContext);
    const [schools, setSchools] = useState([] as School[]);
    const [selectedSchool, setSelectedSchool] = useState({} as School)
    const [programs, setPrograms] = useState([] as Program[]);
    const [showPicker, setShowPicker] = useState(false);

    const loadData = async () => {
        setLoading(true);
        // Get schools that user has access to
        let schoolsRes = await loaders.schools();
        let programRes = await programsService.getPrograms();

        setLoading(false);

        setSchools(schoolsRes);
        setPrograms(programRes);

        // If the user has more than one school show the picker otherwise set the selectedSchool
        if (schoolsRes.length <= 1) {
            setSelectedSchool(schoolsRes[0]);
        } else {
            setShowPicker(true);
        }
    };

    const handleSchoolChange = (option: any) => {
        setSelectedSchool(option);
        setShowPicker(false);
    };

    const handleShowPicker = () => {
        setShowPicker(!showPicker);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="fee-page pd-0">
            <TitleBar header="Program Fees" subheader="Presented on application form" />

            <SchoolPicker onChange={handleSchoolChange} schoolChoices={schools} showPicker={showPicker} setShowPicker={handleShowPicker}></SchoolPicker>
            <FeeViewer selectedSchool={selectedSchool} setShowSchoolPicker={setShowPicker} programs={programs} />
        </div>
    )
}
export default FeePage;