import { FunctionComponent } from 'react';
import { Event } from '../../models/Event.model';
import EventComponent from '../dashboard/eventComponent';
import { ChevronLeft, ChevronRight } from 'react-feather';

import './eventList.scss'

interface EventListProps {
  events: Event[];
  showEventDetails: (eventId: any) => void;
  showMonthModal: () => void;
  handleCustomMonthNavigation: (move: any) => void;
  handleCustomTodayNavigation: () => void;
  getMonth: () => string;
}

const EventList: FunctionComponent<EventListProps> = (props) => {
  const sortedEvents = props.events.slice().sort((a, b) => {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  });

  return (
    <div className="events card">
      <div className="card-header">
        <h4>Events</h4>
        <div className="date-navigation">
          <button className="today btn btn-md md btn-secondary" onClick={() => props.handleCustomTodayNavigation()}>Today</button>
          <button className="prev btn btn-md btn-secondary" onClick={() => props.handleCustomMonthNavigation(-1)}><ChevronLeft /></button>
          <button className='btn btn-md md btn-secondary' onClick={() => props.showMonthModal()}>{props.getMonth()}</button>
          <button className="next btn btn-md btn-secondary" onClick={() => props.handleCustomMonthNavigation(1)}><ChevronRight /></button>
        </div>
      </div>
      <div className="card-body">
        <ul className="list-group">
          {sortedEvents ? sortedEvents.map((event: Event) => (
            <EventComponent
              key={event.id}
              event={event}
              extended={true}
              onClick={() => props.showEventDetails(event.id)}
            />
          )) : null}
        </ul>
      </div>
    </div>
  );
};

export default EventList;