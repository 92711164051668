const FactSheetCompleted = () => {
    return (
      <div className="doc-content thank-you">
        <div>
          <h3>Thank you for your interest in our program.</h3>
          <h5>We will review your application and an administrator will reach out to you shortly.</h5>
          <p>You can now close this page.</p>
        </div>
      </div>
    );
  };
  
  export default FactSheetCompleted;
  