import classNames from './sdgku.enrollment.module.css';

interface FinishPageProps {
  pageMessage: string;
  requiresApproval?: boolean;
}

export const FinishPage = ({ pageMessage, requiresApproval }: FinishPageProps) => {
  return (
    <div className={`${classNames.thank_you} pt-2 doc-content`}>
      <div className="pt-5">
        <h3>Thank you for completing the {pageMessage}.</h3>
        {requiresApproval ? (
          <h5>You will receive a copy of the completed form in you email once approved.</h5>
        ) : (
          <h5>You will receive a copy of the completed form in you email.</h5>
        )}
        <p>You can now close this page.</p>
      </div>
    </div>
  );
};

export const WizardCompletedPage = () => {
  return (
    <div className="doc-area">
    <div className={`${classNames.thank_you} pt-2 doc-content`}>
      <div className="pt-5">
        <h3>Thank you for completing the Enrollment Forms.</h3>
        <p>You can now close this page.</p>
      </div>
    </div>
    </div>
  );
};

export const TokenError = () => {
  return (
    <h5 className="error-token">
      Your token appears to be invalid or it may have expired. Please contact the university personnel to request a new one if you have not yet completed the form.
    </h5>
  );
};
