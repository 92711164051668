export const getDescendent = (obj: any, desc: string) => {
  return desc.split(".").reduce((a, b) => {
    if (b.includes("[")) {
      let index = parseInt(b.replace("[", "").replace("]", ""));
      return a?.[index];
    } else {
      return a?.[b];
    }
  }, obj);
};

export const getErrorElem = (content: any) => {
  return <p className="invalid-input-error">* {content}</p>;
};

export const getValidationErrors = (errors: any, field: string, label: string, rules: any, requiredError = "is required") => {
  let error = getDescendent(errors, field);

  if (error && error.type === "required") {
    return getErrorElem(`${label} ${requiredError}`);
  }

  if (error && error.type === "max") {
    return getErrorElem(`${label} should be equal or lower than ${rules.max}`);
  }

  if (error && error.type === "min") {
    return getErrorElem(`${label} should be equal or greater than ${rules.min}`);
  }

  if (error && error.type === "maxLength") {
    return getErrorElem(`${label} should be have maximum of ${rules.maxLength} characters`);
  }

  if (error && error.type === "minLength") {
    return getErrorElem(`${label} should be contain at least ${rules.minLength} characters`);
  }

  if (error && error.type === "pattern") {
    return getErrorElem(`${label} is invalid`);
  }

  return null;
};
