import http from "./http-common";
import { Department, Event, addDepartmentUsersRequest } from "../models/Event.model";
import { HttpStatusCode } from "../models/common/HttpStatusCode.enum";
import { DepartmentUserRequest } from '../models/User.model';

class CalendarService {
  getUsers(email: string) {
    throw new Error("Method not implemented.");
  }
  async getEvents(payload: any) {
    try {
      let response = await http.get(`/calendar/events/?from=${payload.fromDate}&to=${payload.toDate}`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while getting Events");
      return undefined;
    }
  }

  async getAllEvents() {
    try {
      let response = await http.get("/calendar/events/allEvents/");
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error occured while getting all Events");
      return undefined;
    }
  }


  async getUpcomingEvents() {
    try {
      let response = await http.get("/calendar/events/upcoming/");
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while getting Events");
      return undefined;
    }
  }

  async getSingleEvent(eventId: number | string) {
    try {
      let response = await http.get(`/calendar/events/${eventId}/`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error fetching event");
      return undefined;
    }
  }

  async updateEvent(event: Event) {
    try {
      let response = await http.patch(`/calendar/events/${event.id}/`, event);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while updating Event");
      return undefined;
    }
  }

  async addEvent(event: Event) {
    try {
      let response = await http.post("/calendar/events/", event);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error occured while adding Event");
      return undefined;
    }
  }

  async removeEvent(eventId: number | string) {
    try {
      let response = await http.delete(`/calendar/events/${eventId}/`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error occured while deleting Event");
      return undefined;
    }
  }

  async getEventTypes() {
    try {
      let response = await http.get("/calendar/event_type/");
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while fetching event types");
      return undefined;
    }
  }

  private fixDepartmentReturnData(department: any) {
    // move creatorData as creator and usersData as users
    department.creator = {
      ...department.creatorData,
      fullName: `${department.creatorData?.firstName} ${department.creatorData?.lastName}`,
    };
    department.users = department.usersData?.map((user: any) => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
    }));
    delete department.creatorData;
    delete department.usersData;
  }

  async getDepartments() {
    try {
      let response = await http.get("/calendar/departments/");
      if (response.status === HttpStatusCode.Ok && response.data?.length) {
        // move creatorData as creator and usersData as users
        response.data.map(this.fixDepartmentReturnData);
      }
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while fetching departments");
      return undefined;
    }
  }

  async getDepartment(departmentId: number | string) {
    try {
      let response = await http.get(`/calendar/departments/${departmentId}/`);
      if (response.status === HttpStatusCode.Ok && response.data) {
        // move creatorData as creator and usersData as users
        this.fixDepartmentReturnData(response.data);
      }
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occurred while fetching department");
      return undefined;
    }
  }

  async addDepartment(department: Department) {
    try {
      let response = await http.post("/calendar/departments/", department);
      if (response.status === HttpStatusCode.Ok && response.data) {
        // move creatorData as creator and usersData as users
        this.fixDepartmentReturnData(response.data);
      }
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occurred while adding department");
      return undefined;
    }
  }

  async editDepartment(department: Department) {
    // Users are not updated through this method
    try {
      let response = await http.patch(`/calendar/departments/${department.id}/`, department);
      if (response.status === HttpStatusCode.Ok && response.data) {
        // move creatorData as creator and usersData as users
        this.fixDepartmentReturnData(response.data);
      }
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occurred while updating department");
      return undefined;
    }
  }

  async removeDepartment(departmentId: number | string) {
    try {
      let response = await http.delete(`/calendar/departments/${departmentId}/`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while deleting department");
      return undefined;
    }
  }

  async removeUserFromDepartment(departmentId: number | string, userId: number | string) {
    try {
      let response = await http.delete(`/calendar/departments/${departmentId}/users/${userId}/`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while removing user from department");
      return undefined;
    }
  }

  async addUsersToDepartment(request: addDepartmentUsersRequest) {
    try {
      let response = await http.post(`/calendar/departments/${request.departmentId}/users/`, request);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while adding user to department");
      return undefined;
    }
  }

  async getAllUsers() {
    try {
      let response = await http.get(`/calendar/departments/users/`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while retrieving user to department");
      return undefined;
    }
  }

  async acceptInvite(userData: DepartmentUserRequest, token: string) {
    try {
      let response = await http.post(`/calendar/departments/accept_invitation/?token=${token}`, userData);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while accepting calendar invitation");
      return undefined;
    }
  }

}

export default new CalendarService();
