import React, { FunctionComponent, useEffect, useState, CSSProperties } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import useDebouncedEffect from '../../hooks/debounce';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './pdfViewer.scss';
import Modal from '../modal/modal';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type PDFFile = string | File | null;

interface PDFViewerProps {
  pdfUrl?: string;
  onClose?: () => void;
}

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

const PDFViewer: FunctionComponent<PDFViewerProps> = ({ pdfUrl, onClose }) => {
  const defaultScale = 1.45;
  const [file, setFile] = useState<PDFFile>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(defaultScale);
  const [zoomInputValue, setZoomInputValue] = useState<number>(scale * 100);
  const [rotation, setRotation] = useState<number>(0);
  const [debouncedZoomInputValue, setDebouncedZoomInputValue] = useState<number>(scale * 100);
  const [visible, setVisible] = useState<boolean>(false);

  useDebouncedEffect(() => setDebouncedZoomInputValue(zoomInputValue), 500);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  }

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  }

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  }

  const handleZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(e.target.value) / 100;
    if (!isNaN(newScale)) {
      setScale(newScale);
    }
  }

  const handleRotate = () => {
    let newRotation = (rotation + 90) % 360;
    setRotation(newRotation);
  }

  const handleRotateBack = () => {
    let newRotation = (rotation - 90) % 360;
    setRotation(newRotation);
  }

  const handleFitToScreen = () => {
    setScale(1);
    const container = document.querySelector('.pdf-viewer-document');
    if (container) {
      const { width: containerWidth } = container.getBoundingClientRect();
      const { width: pageWidth } = document.querySelector('.react-pdf__Page__canvas')?.getBoundingClientRect() || { width: 0 };
      const newScale = containerWidth / pageWidth;
      setScale(newScale);
      setZoomInputValue(newScale * 100);
      setDebouncedZoomInputValue(newScale * 100);
    }
  }

  const handleClose = () => {
    setVisible(false);
    setFile(null);
    setPageNumber(1);
    setScale(defaultScale);
    setRotation(0);
    onClose?.();    
  }

  const handleDownload = () => {
    window.open("" + file, "newwindow", "width=820px,height=950px");
    return false;
  };

  useEffect(() => {
    const newScale = debouncedZoomInputValue / 100;
    if (!isNaN(newScale)) {
      setScale(newScale);
    }
  }, [debouncedZoomInputValue]);

  useEffect(() => {
    if (pdfUrl) {
      setFile(pdfUrl);
      setVisible(true);
    }
  }, [pdfUrl]);


  if (!visible) {
    return null;
  }

  const style: CSSProperties = {
    '--override-scale-factor': '1.5',
  } as CSSProperties;

  return (
    <Modal visible={true} size="xl" disableClose={false} mainClass="modal-pdf-viewer">
      <div className="pdf-viewer-backdrop" onClick={() => setVisible(false)} style={{ display: visible ? 'block' : 'none' }} />
      <div className="pdf-viewer-container" style={{ display: visible ? 'block' : 'none' }}>
        <div className="pdf-viewer-header">
          <div className="pdf-viewer-controls d-flex justify-content-center mb-2">
            {/* Page controls */}
            <div className="pdf-viewer-navigation">
              <span className="mr-2">Page:</span>
              <button className="btn btn-sm btn-outline-primary mr-2" onClick={handlePrevPage} disabled={pageNumber <= 1}>
                <i className="fas fa-chevron-left" />
              </button>

              <span className="pdf-viewer-page-info">{`Page ${pageNumber} of ${numPages}`}</span>

              <button className="btn btn-sm btn-outline-primary ml-2" onClick={handleNextPage} disabled={pageNumber >= numPages}>
                <i className="fas fa-chevron-right" />
              </button>
            </div>

            {/* zoom controls */}
            <div className="pdf-viewer-zoom align-self-end ml-5">
              <span className="mr-2">Zoom:</span>
              <button className="btn btn-sm btn-outline-secondary mr-1" onClick={handleZoomOut}>
                <i className="fa-solid fa-magnifying-glass-minus"></i>
              </button>

              <input type="range" min="50" max="300" value={scale * 100} onChange={handleZoomChange} className="pdf-viewer-zoom-range" />

              <button className="btn btn-sm btn-outline-secondary ml-1" onClick={handleZoomIn}>
                <i className="fa-solid fa-magnifying-glass-plus"></i>
              </button>

              <button className="btn btn-sm btn-outline-secondary ml-2" onClick={handleRotateBack}>
              <i className="fa-solid fa-rotate-left"></i>
              </button>
              <button className="btn btn-sm btn-outline-secondary ml-1" onClick={handleRotate}>
                <i className="fa-solid fa-rotate-right"></i>
              </button>

              <button className="btn btn-sm btn-outline-secondary ml-2" onClick={handleFitToScreen}>
                <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
              </button>
              
              <button className="btn btn-sm btn-outline-primary ml-5" onClick={handleDownload}>Download PDF</button>
            </div>
        

            <span className="btn btn-sm btn-outline-danger x-close" onClick={handleClose}>X</span>
          </div>
        </div>

        <div className="pdf-viewer-body">
          <div className="pdf-viewer-document" style={style}>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
              <Page pageNumber={pageNumber} scale={scale} rotate={rotation} />
              {/* {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))} */}
            </Document>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PDFViewer;
