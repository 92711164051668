import { useContext, useEffect, useRef, useState } from "react";
import { FinancialAidPage1, FinancialAidPage2, FinancialAidPage3, FinancialAidPage4, FinancialAidPage5, FinancialAidPage6 } from "./steps"
import globalContext from "../../../../context/globalContext";
import { Step, Stepper, useStepper } from "../../../../components/shared/stepper";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import { SignedFormKey } from "../../../../models/Forms.enum";
import signedFormService from "../../../../services/signedForm.service";
import { AlertType } from "../../../../models/AlertType.enum";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import './financialAid.scss';
import { SignedForm } from "../../../../models/Forms.model";
import { COBAProgram, COBAProgramFromName } from "../../../../models/Enums";

interface Props {
    location: any;
}

export const FinancialAid = ({ location }: Props) => {
    const NumOfSteps = 6;

    const { setLoading, showAlert } = useContext(globalContext);
    const [token, setToken] = useState<string>(null!);
    const [tokenError, setTokenError] = useState(false);

    const [canMoveNext, setCanMoveNext] = useState<boolean>(false);
    const [viewControllers, setViewControllers] = useState<SignedPageData[]>([]);
    const { step, incrementStep, decrementStep } = useStepper(0, NumOfSteps);

    const pagesRef = useRef<any[]>([]);

    const loadInitialData = async () => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const studentId = params.get('id');

        if (token && studentId) {
            loadForm(+studentId, token);
        } else {
            showAlert('Invalid token', AlertType.Error);
            setTokenError(true);
        }
    };

    const loadForm = async (studentId: number, token: string) => {
        setLoading(true);
        try {
            const resp = await signedFormService.getFormData(studentId, SignedFormKey.FINANCIAL_AID, token);
            if (resp?.status === HttpStatusCode.Ok) {
                const program = COBAProgramFromName(resp.data.programName);
                initializeViewController(resp?.data, program, token);
            } else if (resp?.status === HttpStatusCode.Forbidden) {
                showAlert('Invalid token', AlertType.Error);
                setTokenError(true);
            }
        } catch {
            showAlert('Unexpected error', AlertType.Error);
            setTokenError(true);
        } finally {
            setLoading(false);
        }
    }

    const initializeViewController = (formData: SignedForm, program: COBAProgram, token: string) => {
        if (!formData) return;

        for (let i = 0; i < NumOfSteps; i++) {
            const page = new SignedPageData(SignedFormKey.FINANCIAL_AID, program, formData, token, setCanMoveNext);
            setViewControllers((prev) => [...prev, page]);
        }
    };


    const handleDecrementStep = () => {
        decrementStep();
    };

    const handleIncrementStep = async () => {
        if (await pagesRef.current[step].saveData()) {
            incrementStep();
        } else {
            showAlert('Error while saving...');
        }
    };


    useEffect(() => {
        loadInitialData();
    }, []);

    //Components
    const GetPage = () => (
        <>
            <FinancialAidPage1 visible={step === 0} data={viewControllers[0]} ref={(el: any) => (pagesRef.current[0] = el)} />
            <FinancialAidPage2 visible={step === 1} data={viewControllers[1]} ref={(el: any) => (pagesRef.current[1] = el)} />
            <FinancialAidPage3 visible={step === 2} data={viewControllers[2]} ref={(el: any) => (pagesRef.current[2] = el)} />
            <FinancialAidPage4 visible={step === 3} data={viewControllers[3]} ref={(el: any) => (pagesRef.current[3] = el)} />
            <FinancialAidPage5 visible={step === 4} data={viewControllers[4]} ref={(el: any) => (pagesRef.current[4] = el)} />
            <FinancialAidPage6 visible={step === 5} data={viewControllers[5]} ref={(el: any) => (pagesRef.current[5] = el)} />
        </>
    );


    const StepControls = () => (
        <div className="stepper-controls">
            <button disabled={step === 0} className="btn btn-sm btn-primary" onClick={handleDecrementStep}>
                <i className="fa fa-chevron-left"></i> Back
            </button>
            <button disabled={!canMoveNext} className="btn btn-sm btn-primary" onClick={handleIncrementStep}>
                {step !== NumOfSteps - 1 ? 'Next' : 'Complete'} <i className="fa fa-chevron-right"></i>
            </button>
        </div>
    );

    const getCompletedView = () => {
        return (
            <div className="completed-view">
                <h3>Thank you for submitting your application</h3>
                <p>You will receive an email with your application details</p>
            </div>
        );
    };

    const GetContent = () => {
        if (step === NumOfSteps) return getCompletedView();

        return (
            <>
                <Stepper step={step}>
                    {Array(NumOfSteps)
                        .fill(1)
                        .map((x, index) => (
                            <Step key={index} />
                        ))}
                </Stepper>
                <div className="header-form">
                    <div className="image">
                        <img src="/static/coba/logo-coba.png" alt="Coba Academy" />
                    </div>
                    <div className="info">
                        <p>663 N Euclid Street<br />
                            Anaheim, CA 92801 <br />
                            Phone: (714) 533-1400 <br />
                            Email: fa@coba.edu <br />
                            OFFICE OF FINANCIAL AID</p>
                    </div>
                </div>
                {GetPage()}
                {StepControls()}
            </>
        );
    };

    const LoadingView = () => (
        <div className="loading">
            <h3>Loading data, please wait</h3>
        </div>
    );

    if (viewControllers.length === 0) return <LoadingView />;

    if (tokenError) return <h5 className="error-token">Your Token seems invalid or it expired, please get in contact with the university personnel to request a new one if you haven't completed the form.</h5>;

    return (
        <div className={`mx-auto p-3 doc-area`}>
            <div className="financial-aid-pages">
                {GetContent()}
            </div>
        </div>
    );
}