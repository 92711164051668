import { useState, useEffect, useContext } from 'react';
import ChecklistService from '../../services/checklist.service';
import { HttpStatusCode } from '../../models/common/HttpStatusCode.enum';
import { DocumentType } from '../../models/Document.model';
import globalContext from '../../context/globalContext';
import { Edit3 as IEdit, Plus as IAdd, Eye as IEnable, EyeOff as IDisable } from 'react-feather';

import './documentTypeViewer.scss';
import documentService from '../../services/document.service';
import DocTypeModal from './docTypeModal';
import { CacheConstants } from '../../services/constants.service';
import SearchBox from '../shared/searchBox/searchBox';
import ActionIcon from '../shared/action-icon/actionIcon';

const DocumentTypeViewer = () => {
  const { setLoading, clearCacheKey } = useContext(globalContext);
  const [docTypes, setDocTypes] = useState([] as DocumentType[]);
  const [selectedDocType, setSelectedDocType] = useState({} as DocumentType);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAdding, setModalAdding] = useState(true);

  const [documentSearch, setDocumentSearch] = useState() as any;
  const [docTypeFiltered, setDocTypeFiltered] = useState([] as DocumentType[]);

  const loadData = async () => {
    let docTypeRes = await documentService.getDocumentTypes();

    if (docTypeRes?.status === HttpStatusCode.Ok) {
      const data = docTypeRes.data;
      setDocTypes(data);
      setDocTypeFiltered(data);
    }
  };

  const handleModalVisibility = (adding: boolean) => {
    setModalVisible(!modalVisible);
    setModalAdding(adding);
    if (modalVisible) {
      setSelectedDocType({} as DocumentType);
    }
  };

  const handleSelectDocType = (record: DocumentType) => {
    setModalAdding(false);
    setSelectedDocType(record);
    setModalVisible(true);
  };

  const handleAddDocType = async (name: any, enabled: boolean) => {
    setLoading(true);
    handleModalVisibility(true);

    let docType = { name: name, enabled: enabled } as DocumentType;
    let res = await ChecklistService.addDocType(docType);
    setLoading(false);

    if (res?.status === HttpStatusCode.Created) {
      clearCacheKey(CacheConstants.DOCUMENT_TYPES);
      let temp = [...docTypes];
      let newType = res.data as DocumentType;
      temp.push(newType);
      setDocTypes(temp);
      setDocTypeFiltered(temp);
      setDocumentSearch('');
    }
  };

  const handleEditDocType = async (name: any, enabled: boolean) => {
    if (modalVisible) {
      handleModalVisibility(true);
    }

    let tempDocType = { ...selectedDocType };
    tempDocType.name = name;
    tempDocType.enabled = enabled;

    setLoading(true);
    let res = await ChecklistService.editDocType(tempDocType);
    setLoading(false);

    if (res?.status == HttpStatusCode.Ok) {
      clearCacheKey(CacheConstants.DOCUMENT_TYPES);

      // update the full list
      let tempDocTypes = [...docTypes];
      let index = tempDocTypes.findIndex((docType) => docType.id === tempDocType.id);
      tempDocTypes[index] = res.data as DocumentType;
      setDocTypes(tempDocTypes);

      // update the filtered list
      let tempDocTypeFiltered = [...docTypeFiltered];
      index = tempDocTypeFiltered.findIndex((docType) => docType.id === tempDocType.id);
      tempDocTypeFiltered[index] = res.data as DocumentType;
      setDocTypeFiltered(tempDocTypeFiltered);
    }
  };

  const handleSearchChange = (text: string) => {
    setDocumentSearch(text);
    if (text.length > 0) {
      const filtered = docTypes.filter((type) => type.name.toLowerCase().includes(text.toLowerCase()));
      if (filtered.length > 0) {
        setDocTypeFiltered(filtered);
      } else {
        setDocTypeFiltered(docTypes);
      }
    } else if (text.length === 0) {
      if (docTypes.length > 1) {
        setDocTypeFiltered(docTypes);
      } else {
        loadData();
        setDocTypeFiltered(docTypes);
      }
    }
  };

  const searchDocuments = () => {
    if (documentSearch) {
      const filtered = docTypes.filter((type) => type.name.toLowerCase().includes(documentSearch.toLowerCase()));
      setDocTypeFiltered(filtered);
    } else {
      setDocTypeFiltered(docTypes);
    }
  };

  const handleDoctypeEnabled = async (docType: DocumentType) => {
    let tempDocType = { ...docType };
    tempDocType.enabled = !tempDocType.enabled;

    setLoading(true);
    let res = await ChecklistService.editDocType(tempDocType);
    setLoading(false);

    if (res?.status == HttpStatusCode.Ok) {
      clearCacheKey(CacheConstants.DOCUMENT_TYPES);

      // update the full list
      let tempDocTypes = [...docTypes];
      let index = tempDocTypes.findIndex((docType) => docType.id === tempDocType.id);
      tempDocTypes[index] = res.data as DocumentType;
      setDocTypes(tempDocTypes);

      // update the filtered list
      let tempDocTypeFiltered = [...docTypeFiltered];
      index = tempDocTypeFiltered.findIndex((docType) => docType.id === tempDocType.id);
      tempDocTypeFiltered[index] = res.data as DocumentType;
      setDocTypeFiltered(tempDocTypeFiltered);
    }
  };

  const getItemClass = (doc: DocumentType) =>
    `docType list-group-item list-group-item-action ${selectedDocType.id === doc.id ? 'active' : ''} ${doc.enabled ? '' : 'disabled-enabled'}`;

  const docTypeSortFn = (a: DocumentType, b: DocumentType) => {
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const getDocTypes = () => {
    return (
      <div className="list-wrapper">
        <ul className="list-group">
          {docTypeFiltered.sort(docTypeSortFn).map((docType: DocumentType) => (
            <li key={docType.id} className={getItemClass(docType)}>
              <div className="type-name">
                <p>{docType.name}</p>
              </div>
              <div className="type-edit">
                <ActionIcon label="Edit" icon={<IEdit />} color="primary" onClick={() => handleSelectDocType(docType)} />

                <ActionIcon
                  label={docType.enabled ? 'Disable' : 'Enable'}
                  tooltip={docType.enabled ? 'Disable Program' : 'Enable Program'}
                  icon={docType.enabled ? <IDisable /> : <IEnable />}
                  color={docType.enabled ? 'danger' : 'success'}
                  extraClass="icon-disable"
                  onClick={async () => await handleDoctypeEnabled(docType)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="docType-viewer">
      <DocTypeModal
        visible={modalVisible}
        handleAddDocType={handleAddDocType}
        handleEditDocType={handleEditDocType}
        selectedDocType={modalAdding ? null : selectedDocType}
        close={handleModalVisibility}
      />

      <div className="card docType-card">
        <div className="card-header docTypes-header">
          <div className="header-title"></div>
          <div className="header-search-box">
            <SearchBox value={documentSearch} placeholder="Search Document Type" onChange={handleSearchChange} onSearch={searchDocuments} />
          </div>
          <div className="header-buttons">
            <button className="btn-icon btn btn-sm btn-outline-primary" onClick={() => handleModalVisibility(true)}>
              <IAdd />
            </button>
          </div>
        </div>

        <div className="card-body overflow-auto">
          <ul className="list-group">{getDocTypes()}</ul>
        </div>
      </div>
    </div>
  );
};

export default DocumentTypeViewer;
