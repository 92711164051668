import React from 'react';
import "./permissionNotAllowed.scss";

const PermissionNotAllowed: React.FC = () => {
  return (
    <div className='permission-not-allowed'>
      <h1>Permission Not Allowed</h1>
      <h4>You do not have the required permission to view this page.</h4>
    </div>
  );
};

export default PermissionNotAllowed;
