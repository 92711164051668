import { clear } from "console";
import { useEffect, useState } from "react";
import Modal from "../modal/modal";

const DocTypeModal = (props: any) => {
    const [name, setName] = useState() as any;
    const [checked, setChecked] = useState<boolean>(true);

    const close = () => {
        setName('')
        setChecked(true)
        props.close()
    }

    const handleSubmit = () => {
        if (!props.selectedDocType) {
            props.handleAddDocType(name, checked);
        } else {
            props.handleEditDocType(name, checked);
        }
    }

    const handleSwitchChange = () => {
        const newState = !checked;
        setChecked(newState);
    }

    useEffect(() => {
        if (props.selectedDocType?.id) {
            setName(props.selectedDocType.name);
            setChecked(props.selectedDocType.enabled);
        }else{
            setName('');
            setChecked(true);
        }
    }, [props.selectedDocType]);

    const getBody = () => {
        return (
            <div>
                <div className="input-wrapper input-wrapper__lg">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        placeholder="Document Type Name"
                        className="form-control form-control-sm"
                        name="name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </div>
                <br/>
                <div className="form-check form-switch">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        role="switch" 
                        id="flexSwitchCheckDefault" 
                        checked={checked}
                        onChange={handleSwitchChange}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{checked ? "Enabled" : "Disabled"}</label>
                </div>
            </div>
        )
    }

    return (
        <Modal
            visible={props.visible}
            onCancel={close}
            onClose={close}
            onAccept={handleSubmit}

            title={props.selectedDocType ? 'Edit Document Type' : 'Add Document Type'}
            body={getBody()}
            mainButtonText={props.selectedDocType ? 'Edit' : 'Add'}
        />
    )
}

export default DocTypeModal;