import { useContext, useEffect, useState } from 'react';
import { Program, Fee, FeeValue } from '../../../models/Program.model';
import feeService from '../../../services/fee.service';
import './feeViewer.scss';
import globalContext from '../../../context/globalContext';

const FeeViewer = (props: any) => {
  const { setLoading } = useContext(globalContext);
  const [selectedProgram, setSelectedProgram] = useState({} as Program);
  const [editMode, setEditMode] = useState(false);
  const [fees, setFees] = useState([] as FeeValue[]);

  let editedFees = [] as any[];

  const loadData = async () => {
    setFees([]);
    if (selectedProgram.id) {
      setLoading(true);
      let feeRes = await feeService.getFees(selectedProgram.id as number, props.selectedSchool.id);
      setLoading(false);
      setFees(feeRes);
    }
  };

  const changeValue = (field: string, value: any, key: string) => {
    let existingFee = editedFees.find((item) => item.key === key);
    if (existingFee) {
      existingFee[field] = value;
    } else {
      let currentFee = { key: key } as any;
      currentFee[field] = value;
      editedFees.push(currentFee);
    }
  };

  const saveEditedFees = async () => {
    if (editedFees.length > 0) {
      setLoading(true);
      let resp = await feeService.updateFees(editedFees, selectedProgram.id as number, props.selectedSchool.id);
      setLoading(false);

      if (resp?.status === 200) {
        let copy = [...fees];
        resp.data.forEach((item: FeeValue) => {
          let index = copy.findIndex((feeValue) => feeValue.fee.key === item.fee.key);
          let copyFee = copy[index]
          copyFee.amount = parseFloat(item.amount as any);
          copyFee.description = item.description;
          copyFee.descSpanish = item.descSpanish;
          copyFee.regular = item.regular;
          copyFee.va = item.va;
        });
        setFees(copy);
      }
    }
    setEditMode(false);
  };

  const getHeader = () => {
    return (
      <>
        <div className="schoolPickerButton btn btn-outline-primary" onClick={props.setShowSchoolPicker}>
          Change School
        </div>

        <h4>Fees</h4>

        <div className="right-buttons">
          {editMode ? (
            <>
              <div onClick={() => saveEditedFees()} className="btn btn-primary">
                Save Changes
              </div>
              <div onClick={() => setEditMode(false)} className="btn btn-outline-dark">
                Cancel
              </div>
            </>
          ) : (
            <>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedProgram.id ? selectedProgram.name : 'Select a Program'}
                </button>
                <ul className="dropdown-menu">
                  <li className="dropdown-item" onClick={() => setSelectedProgram({} as Program)}>
                    -- Select A Program --
                  </li>
                  {props.programs.map((p: Program) =>
                    p.schoolId == props.selectedSchool.id ? (
                      <li className="dropdown-item" key={p.id} onClick={() => setSelectedProgram(p)}>
                        {p.name}
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
              <div className="btn btn-outline-primary" onClick={() => setEditMode(true)}>
                Edit
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const getTable = () => {
    return (
      <table className="table">
        <thead className="thead-secondary">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Info</th>
            <th scope="col">Regular</th>
            <th scope="col">VA</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {fees.map((feeValue: FeeValue, index) => (
            <>
              <tr key={index}>
                <td scope="row">
                  {feeValue.fee.name}                  
                </td>
                <td>
                  {!editMode ? (
                    feeValue.description
                  ) : (
                    // <OverlayTrigger placement="right" overlay={<Tooltip id={`info-tooltip-1}`}>{feeValue.description.length > 0 ? feeValue.description : 'No Description'}</Tooltip>} delay={{ show: 250, hide: 400 }}>
                    //   <Info className="info-icon" />
                    // </OverlayTrigger>
                    <input type="text" defaultValue={feeValue.description} onChange={(e) => changeValue('description', e.target.value, feeValue.fee.key)}></input>
                  )}                  
                </td>
                <td width={100}>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input custom-checkbox" disabled={!editMode} defaultChecked={feeValue.regular} onChange={(e) => changeValue('regular', e.target.checked, feeValue.fee.key)} />
                  </div>
                </td>
                <td width={100}>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input custom-checkbox" disabled={!editMode} defaultChecked={feeValue.va} onChange={(e) => changeValue('va', e.target.checked, feeValue.fee.key)} />
                  </div>
                </td>
                <td className="text-right" width={80}>{!editMode ? `$${feeValue.amount.toFixed(2)}` : <input type="number" defaultValue={fees[index].amount.toFixed(2)} onChange={(e) => changeValue('amount', Number(e.target.value), feeValue.fee.key)}></input>}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    loadData();
  }, [selectedProgram, props.selectedSchool]);

  return (
    <div className="fee-viewer">
      <div className="fee-card card">
        <div className="card-header">{getHeader()}</div>
        <div className="card-body">{getTable()}</div>
      </div>
    </div>
  );
};

export default FeeViewer;
