import { FunctionComponent, useContext } from 'react';
import Modal from '../../modal/modal';
import { ApplicationForm } from '../../../models/ApplicationForm.model';
import { toast } from 'react-toastify';
import './copyStepLink.scss';
import globalContext from '../../../context/globalContext';
import applicationRequestService from '../../../services/applicationRequest.service';
import { AlertType } from '../../../models/AlertType.enum';
import copy from 'copy-to-clipboard';
import { SchoolID } from '../../../models/Enums';
import studentService from '../../../services/student.service';

export interface CopyStepLinkProps {
  applicationForm: ApplicationForm;
  visible: boolean;
  onClose: () => void;
}

const CopyStepLink: FunctionComponent<CopyStepLinkProps> = ({ applicationForm, visible, onClose }) => {
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);

  const copyLink = async (step: number) => {
    // get token
    setLoading(true);
    let resp = await applicationRequestService.getReadApplicationToken(applicationForm.id);
    setLoading(false);

    if (resp && resp?.data?.success) {
      let token = resp.data.token;

      // copy to clipboard
      const url = `${window.location.origin}/public/SDGKU/${step}/${applicationForm.id}/?first=${applicationForm.student.firstName}&last=${applicationForm.student.lastName}&token=${token}`;
      copy(url);
      toast.dark('Link copied to clipboard', { autoClose: 2000 });
    } else {
      clearAlert();
      showAlert('Error generating a token for the link, please contact support', AlertType.Error);
      return;
    }
  };

  const getButtonForStep = (step: number) => {
    if (step > applicationForm.step + 1) {
      return (
        <button className="btn btn-outline-secondary" disabled={true}>
          Step {step}
        </button>
      );
    }
    return (
      <button onClick={() => copyLink(step)} className="btn btn-outline-primary">
        Step {step}
      </button>
    );
  };

  const resendAdmissionLetter = async () => {
    let response = await studentService.resendAdmissionEmail(applicationForm?.student?.id);
    if (response?.status == 200) {
      toast.dark('Email sent to Studnet', { autoClose: 2000 });
    }
  }

  const body = () => {
    return (
      <div className="modal-copy-link">
        <p>
          <span className="badge bg-light text-dark">Status</span>{' '}
          <span className="tx-bold">
            {applicationForm?.step}
            {applicationForm?.student?.school == SchoolID.SDGKU ? '/2' : '/3'}
          </span>
        </p>
        <p className="mb-1">
          <span className="badge bg-light text-dark">Student</span>{' '}
          <span className="tx-bold">{applicationForm?.student?.firstName + ' ' + applicationForm?.student?.lastName}</span>
        </p>
        {applicationForm?.step > 1 && (
          <p className="mb-0">
            <label className="lbl-info">Click here to</label>
            <button onClick={resendAdmissionLetter} className="btn btn-sm btn-link pl-1">resend Letter from Admissions</button>
          </p>
        )}

        <label className="lbl-info mt-4">Click on a button below to copy the link and send it to student.</label>
        <div className="button-container">
          {getButtonForStep(1)}
          {getButtonForStep(2)}
          {applicationForm?.student?.school == SchoolID.SDGKU ? null : getButtonForStep(3)}
        </div>
      </div>
    );
  };

  return (
    <Modal body={body()} onCancel={onClose} onClose={onClose} visible={visible} title="Copy link for student application" cancelButtonText="Close" cancelButtonClass="btn-dark" />
  );
};

export default CopyStepLink;
