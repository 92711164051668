import { FunctionComponent } from "react";
import Modal from "../modal/modal";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import "./imageViewer.scss";

interface ImageViewerProps {
  imageUrl?: string;
  title?: string;
  subTitle?: string;
  onClose?: () => void;
  onAction?: () => void;
}

const ImageViewer: FunctionComponent<ImageViewerProps> = ({
  imageUrl,
  title,
  onClose,
  onAction,
}) => {
  if (!imageUrl) return null;

  const handleDownloadClick = async () => {
    try {
      // download using fetch
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = title ? `${title}.jpg` : "image_download.jpg";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image", error);
      alert(
        "The image could not be downloaded. You can try to download it manually."
      );
    }
  };

  return (
    <Modal
      mainClass="modal-image-viewer"
      title={title || "Image Viewer"}
      bodySubtitle={title || "Image Viewer"}
      size="xl"
      visible={true}
      onCancel={() => {
        onClose?.();
      }}
      onClose={() => {
        onClose?.();
      }}
      disableAction={true}
      actionButtonText="Download image"
      onAction={handleDownloadClick}

    >
      <div className="tools">
        <ReactPanZoom image={imageUrl} alt="Student Document Image" />
      </div>
    </Modal>
  );
};

export default ImageViewer;
