import { useEffect, useState } from 'react';

import Select from 'react-select';

import { UploadRequestsTable } from '../../../components/upload-request/uploadRequestViewer';
import CreateUploadRequestModal from '../../../components/upload-request/create-upload-request-modal/createUploadRequestModal';
import TitleBar from '../../../components/shared/titleBar/titleBar';
import SearchBox from '../../../components/shared/searchBox/searchBox';

import './uploadRequest.scss';

const defaultStatusOption = { value: 4, label: '- Any Status -' };

// optimize ?
const statusFilterOptions = [
  { value: 4, label: '- Any Status -' },
  { value: 0, label: 'Missing' },
  { value: 1, label: 'In Review' },
  { value: 2, label: 'Accepted' },
  { value: 3, label: 'Rejected' },
];

interface StatusProps {
  value: number;
  label: string;
}

const UploadRequest = (props: any) => {
  const [newRequestVisible, setNewRequestVisible] = useState(false);
  const [newRequestStudentId, setNewRequestStudentId] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<StatusProps>(defaultStatusOption);
  const [statusOptions, setStatusOptions] = useState<StatusProps[]>(statusFilterOptions);
  const [trigger, setTrigger] = useState(false);

  const verifyDefaultToNewRequest = () => {
    let studentId = props.match.params?.studentId;
    if (studentId) {
      setNewRequestStudentId(studentId);
    }

    if (props.match.params?.newRequest) {
      setNewRequestVisible(true);
    }
  };

  useEffect(() => {
    verifyDefaultToNewRequest();
    setSelectedStatus(defaultStatusOption);
  }, []);

  const handleNewRequestButton = () => {
    setNewRequestStudentId(0);
    setNewRequestVisible(true);
  };

  const handleSearchByStudent = (text: string) => {
    setSearch(text);
  };

  const handleSearchByStatus = (option: any) => {
    setSelectedStatus(option);
  };

  return (
    <div className="upload-request-page">
      <CreateUploadRequestModal visible={newRequestVisible} studentId={newRequestStudentId} onClose={() => setNewRequestVisible(false)} onSend={() => setTrigger(!trigger)} showStudentSearch={!newRequestStudentId} />

      <div className="upload-request-title-bar ">
        <TitleBar header="Upload Requests" subheader="Document upload Requests">
          <div className="row row-filters">
            <SearchBox className="col-md-4 col-lg-3 mx-sm-0 mx-lg-2" value={search} label="" placeholder="Search student" onChange={handleSearchByStudent} />
            <div className="col-md-4 col-lg-3 col-xl-2 mx-sm-0 mx-lg-2">
              <Select placeholder="Filter by Status" options={statusOptions} value={selectedStatus} onChange={handleSearchByStatus} />
            </div>

            <div className="col-md-4 col-lg-3 col-xl-2 mx-sm-0 mx-lg-2">
              <button onClick={handleNewRequestButton} className="btn btn-primary mg-l-5 pd-r-10 tx-primary">
                <i data-feather="edit" className="mg-r-5"></i> New Request
              </button>
            </div>
          </div>
        </TitleBar>
      </div>

      <div className="row pd-xl-15 pd-md-10 pd-sm-0">
        <UploadRequestsTable search={search} selectedStatus={selectedStatus} trigger={trigger} />
      </div>
    </div>
  );
};

export default UploadRequest;
