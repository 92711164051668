import { CheckboxListItemProps } from "../../../components/shared/Input/checkBox";

export const preferredPronounOptions = ["He, him, his", "She, her, hers", "Prefer not to say"];

export const checkList = [
  { field: "payDebitCredit", label: "Debit or Credit" },
  { field: "payCheck", label: "Check or Money Order" },
  { field: "payFinancialAid", label: "Financial Aid" },
  { field: "payScholarship", label: "Grant or Scholarship" },
  { field: "payMilitaryBenefits", label: "Military Education Benefit" },
  { field: "payOther", label: "Other" },
] as CheckboxListItemProps[];

export const privacyCheckList = [
  { field: "privacyCertifyInfo", label: "I certify that the above information is accurate and correct to the best of my knowledge." },
  {
    field: "privacyConvicted",
    label:
      "I understand, if I have been convicted of or pled no contest to a violation of any law of the United States, in any state, local jurisdiction, or any foreign country. I will request the application to complete the Disclosure Statement Regarding Criminal Pleas/Convictions form from the Staff Member at Coba Academy.",
  },
  {
    field: "privacyAgreeContact",
    label:
      "I agree to be contacted by a Coba Academy Admissions team member. Standard Text Message and Data Rates Apply. I agree to allow Coba Academy to store my data.",
  },
] as CheckboxListItemProps[];

export const maritalStatusOptions = [
  { value: "single", label: "Single" },
  { value: "divorced", label: "Divorced" },
  { value: "separated", label: "Separated" },
  { value: "married", label: "Married" },
  // { value: "widowed", label: "Widowed" }
];

export const educationLevelOptions = [
  { value: "GED", label: "GED", level: 1 },
  { value: "High School", label: "High School", level: 2 },
  { value: "Bachelor", label: "Bachelor", level: 3 },
  { value: "Master", label: "Master", level: 4 },
  { value: "Other", label: "Other", level: 5 },
];
