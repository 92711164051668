import { FunctionComponent } from "react";

export interface PaginationItemProps {
  disabled?: Boolean;
  text: string;
  action: any;
  active?: boolean;
}

const PaginationItem: FunctionComponent<PaginationItemProps> = ({ disabled = false, text, action, active = false }) => {
  let appliedClasses = "page-item";
  if (disabled) appliedClasses += " disabled";
  if (active) appliedClasses += " active";

  return (
    <div className={appliedClasses} onClick={() => action()}>
      <div className="page-link" tabIndex={-1}>
        <span aria-hidden="true">{text}</span>
      </div>
    </div>
  );
};

export default PaginationItem;
