import { AxiosResponse } from 'axios';
import { FinancialEstimateTemplate, StudentEstimate } from '../models/FinancialEstimate.model';
import http from "./http-common";


class StudentEstimateService {
    /// Student Financial estimates
	async getStudentEstimates(studentId: string|number, force = false): Promise<AxiosResponse<StudentEstimate[]> | null> {
		try {
			return await http.get(`/estimates/student/${studentId}/?force=${force ? 1 : 0}`);
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async createOrUpdateEstimate(estimate: StudentEstimate): Promise<AxiosResponse<StudentEstimate> | null> {
		try {
			if(!estimate.id) {
				return await http.post(`/estimates/`, estimate);
			} else {
				return await http.put(`/estimates/${estimate.id}/`, estimate);
			}
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async getLatestEstimatePdf(studentId: string|number) {
		try {
			return await http.get(`/estimates/student/${studentId}/latest/`);
		} catch (error) {
			console.error(error);
			return null;
		}
	}

    /// Financial Estimate Templates
	async getAllFATemplates(): Promise<AxiosResponse<FinancialEstimateTemplate[]> | null> {
		try {
			let response = await http.get(`/estimates/templates/`);
			return response;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async getFATemplates(schoolId: string|number): Promise<AxiosResponse<FinancialEstimateTemplate[]> | null> {
		try {
			let response = await http.get(`/estimates/templates/school/${schoolId}/`);
			return response;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async getFATemplateById(id: number): Promise<AxiosResponse<FinancialEstimateTemplate> | null> {
		try {
			let response = await http.get(`/estimates/templates/${id}/`);
			return response;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async createFATemplate(template: FinancialEstimateTemplate): Promise<AxiosResponse<FinancialEstimateTemplate> | null> {
		try {
			let response = await http.post(`/estimates/templates/`, template);
			return response;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async updateFATemplate(template: FinancialEstimateTemplate): Promise<AxiosResponse<FinancialEstimateTemplate> | null> {
		try {
			if(!template.id) throw new Error("Template ID is required to update a template.");
			let response = await http.put(`/estimates/templates/${template.id}/`, template);
			return response;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	async deleteFATemplate(id: number): Promise<any> {
		try {
			let response = await http.delete(`/estimates/templates/${id}/`);
			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}
}

export default new StudentEstimateService();
