import { useContext, useEffect, useState } from "react";
import globalContext from "../../../../context/globalContext";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import usePageSignature from "../../../../hooks/pageSignature";
import { set, useForm } from "react-hook-form";
import { ApplicationForm } from "../../../../models/ApplicationForm.model";
import { SDGKUFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import { SignedForm } from "../../../../models/Forms.model";
import { SDGKUProgram } from "../../../../models/Enums";
import { Student } from "../../../../models/Student.model";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { getProgramData } from "../sdgkuController";
import { ProgramData } from "../types";
import { Header_Mobile } from "../header";
import TextField from "../../../../components/shared/Input/textField";
import "./transcriptEvaluation.scss";

interface extraData {
    university_1: string;
    university_2: string;
    university_3: string;
    university_4: string;
    university_5: string;
    university_6: string;
    university_7: string;
    university_8: string;
    university_9: string;
    university_10: string;
    university_11: string;
    university_12: string;
    numberOfUniversities: number;
}

interface transcriptEvaluation {
    student: Student;
    extraData: extraData;
}

export const TranscriptionEvaluation = (props: any) => {
    const { clearAlert, setLoading, showAlert } = useContext(globalContext);
    const [viewController, setViewController] = useState<SignedPageData>(null!);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const { saveForm, getSignature, setIsCustomValid, getAcknowledge, setRequiredCustomValidation, signatureData } = usePageSignature(viewController);
    const [tokenError, setTokenError] = useState(false);
    const [numberOfUniversities, setNumberOfUniversities] = useState(0);
    const [canAddOther, setCanAddOther] = useState(false);
    const [extraData, setExtraData] = useState<extraData>(null!);
    const [completed, setCompleted] = useState(false);

    const { register, formState: { errors, isValid }, setValue, trigger, watch } = useForm<transcriptEvaluation>();

    useEffect(() => {
        loadInitialData();
    }, []);

    //validate form inputs to enable submit button
    const formValues = watch();

    useEffect(() => {
        setIsCustomValid(isValid);
    }, [formValues]);

    useEffect(() => {
        // validate the forms fields when the signature changes
        manualValudation();
    }, [signatureData]);

    const manualValudation = async () => {
        if (signatureData) {
            const isItValidNow = await trigger();
            if(!isItValidNow) {
                // start a timer that will trigger the validation every 1 second
                setInterval(()=>{
                    trigger();
                }, 1000);
            }
        }
    }

    const loadInitialData = async () => {
        // get token from url params
        const params = new URLSearchParams(props.location.search);
        const token = params.get('token');
        const studentId = params.get('id');

        if (token && studentId) {
            loadForm(+studentId, token);
        } else {
            showAlert('Invalid token', AlertType.Error);
            setTokenError(true);
        }
    };

    const loadForm = async (studentId: number, token: string) => {
        setLoading(true);
        try {
            const resp = await signedFormService.getFormData(studentId, SignedFormKey.TRANSCRIPT_EVALUATION_REQUEST, token);
            if (resp?.status == HttpStatusCode.Ok) {
                const program = resp.data.programId;
                getProgramData(program, studentId, token);
                initializeViewController(resp.data, program, token);
            }
        } catch {
            showAlert('Error loading form', AlertType.Error);
            setTokenError(true);
        } finally {
            setLoading(false);
        }

    };
    const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
        if (!formData) return;

        setValue('student.firstName', formData.student.firstName);
        setValue('student.lastName', formData.student.lastName);
        if (formData.student.middleName) {
            setValue('student.middleName', formData.student.middleName.substring(0, 1));
        }
        setViewController(new SignedPageData(SignedFormKey.TRANSCRIPT_EVALUATION_REQUEST, program, formData, token, setEnableSubmit));
    };

    const submitForm = async () => {
        let data: extraData = null!;
        if (extraData) {
            data = { ...extraData }
            data.numberOfUniversities = numberOfUniversities;
        } else {
            data = { numberOfUniversities: numberOfUniversities } as extraData;
        }
        if (await saveForm(SDGKUFormsPage.Transcript_evaluation, true, data)) {
            setCompleted(true);
        } else {
            showAlert('Error saving form', AlertType.Error);
        }
    };

    const inputChanged = (e: any) => {
        if (e.target.name == 'university_1') {
            setNumberOfUniversities(1);
            setCanAddOther(true);
        }
        setExtraData({ ...extraData, [e.target.name]: e.target.value });
    }

    const handleAdd = () => {
        setNumberOfUniversities(numberOfUniversities + 1);
    };

    const handleDelete = (num: number) => {
        setExtraData({ ...extraData, [`school_${num}`]: '' });
        setValue(`school_${num}` as any, null);
        setNumberOfUniversities(numberOfUniversities - 1);
    };

    const GetContent = () => (
        <div className="content">
            <h3 className="form-title">VA Student Transcript  Evaluation Request Form</h3>
            <div className="row">
                <div className="col-4">
                    <TextField
                        disabled
                        label="First Name"
                        field="student.firstName"
                        register={register}
                        errors={errors}
                        type="text"
                    />
                </div>
                <div className="col-4">
                    <TextField
                        disabled
                        label="Last Name"
                        field="student.lastName"
                        register={register}
                        errors={errors}
                        type="text"
                    />
                </div>
                <div className="col-4">
                    <TextField
                        disabled
                        label="MI - Middle Initials"
                        field="student.middleName"
                        register={register}
                        errors={errors}
                        type="text"
                    />
                </div>
            </div>

            <p>List all colleges, training institutions, and technical schools you have attended.
                Also list any AP, CLEP, DANTES, or USAFI tests you have taken. Official
                transcripts of tests taken, and schools/ colleges attended (including military
                transcripts - JST and/or CCAF) must be submitted to the College Records Office.</p>

            <p>I am aware that if I have used my benefits at another school, it is my
                responsibility to complete and submit VA Form 22-1999 (or 5495 for CH.35) via
                VA.GOV.</p>

            <p>Failure to submit official transcripts in a timely manner could result in VA
                overpayments and/or impact my ability to use my benefits for future semesters.</p>

            <div className="university-college">
                <h4>Have you ever been in another University/College/School/Test ?</h4>
                <div className="col-10">
                    <TextField
                        label="1"
                        field="university_1"
                        register={register}
                        errors={errors}
                        type="text"
                        onChange={inputChanged}
                        rules={{ required: numberOfUniversities > 1 }}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="2"
                        field="university_2"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 1}
                        rules={{ required: numberOfUniversities > 1 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="3"
                        field="university_3"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 2}
                        rules={{ required: numberOfUniversities > 2 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="4"
                        field="university_4"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 3}
                        rules={{ required: numberOfUniversities > 3 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="5"
                        field="university_5"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 4}
                        rules={{ required: numberOfUniversities > 4 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="6"
                        field="university_6"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 5}
                        rules={{ required: numberOfUniversities > 5 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="7"
                        field="university_7"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 6}
                        rules={{ required: numberOfUniversities > 6 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="8"
                        field="university_8"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 7}
                        rules={{ required: numberOfUniversities > 7 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="9"
                        field="university_9"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 8}
                        rules={{ required: numberOfUniversities > 8 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="10"
                        field="university_10"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 9}
                        rules={{ required: numberOfUniversities > 9 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="11"
                        field="university_11"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 10}
                        rules={{ required: numberOfUniversities > 10 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="col-10">
                    <TextField
                        label="12"
                        field="university_12"
                        register={register}
                        errors={errors}
                        type="text"
                        visible={numberOfUniversities > 11}
                        rules={{ required: numberOfUniversities > 11 }}
                        onChange={inputChanged}
                    />
                </div>
                <div className="schools-control">
                    {(numberOfUniversities > 1) &&
                        <button className="btn btn-danger" onClick={() => handleDelete(numberOfUniversities)}>Delete Last School</button>}
                    {(numberOfUniversities < 12 && canAddOther) &&
                        <button className="btn btn-primary ml-3" onClick={handleAdd}>Add Another School</button>}
                </div>
            </div>
            {getAcknowledge('understand', 'I have read and acknowledge the above statements and have listed my prior education/training to the best of my knowledge.')}
            <br />
            {getSignature()}

            <div className="row">
                <div className="col-6">
                    <button className="btn btn-primary" onClick={submitForm} disabled={!enableSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );

    const FinishView = () => (
        <div className="content">
            <h3 className="form-title">VA Student Transcript  Evaluation Request Form</h3>
            <p>Your form has been submitted successfully</p>
        </div>
    );

    return (
        <div className="transcript-evaluation">
            <div className="form-container">
                <Header_Mobile />
                {!completed ? GetContent() : FinishView()}
            </div>
        </div>
    );
}

