import { useContext } from "react";
import { Link } from "react-router-dom";
import "./navBar.scss";
import { LogOut as ILogOut, User as IUser, Settings as ISettings } from "react-feather";
import globalContext from "../../context/globalContext";

const NavBar = () => {
  const user = useContext(globalContext).user;

  if (user?.isCalendar) {
    return null;
  }

  const userType = () => {
    if (user?.isAdmin) {
      return "Administrator";
    } else if (user?.isReviewer) {
      return "Reviewer";
    } else {
      return "User";
    }
  };

  const getSchoolAdminMenu = () => {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="/#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          School Administration
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <li>
            <Link className="dropdown-item" to="/admin/programs/periods">
              School Periods
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/admin/programs">
              Program List
            </Link>
          </li>
          {/* disabled as the data structure changed to support SDGKU BSGM combinations */}
          {/* <li>
            <Link className="dropdown-item" to="/student/program/fees">
              Program Fees
            </Link>
          </li> */}

          {(user?.isAdmin || user?.isReviewer) && (
            <>
              <div className="dropdown-divider"></div>
              <li>
                <Link className="dropdown-item" to="/admin/document-checklists">
                  Document Checklists
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/admin/document-types">
                  Document Types
                </Link>
              </li>
            </>
          )}
          <li>
            <Link className="dropdown-item" to="/admin/document-templates">
              Document Templates
            </Link>
          </li>
        </ul>
      </li>
    );
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/admin/dashboard">
          <img className="logo" src="/static/img2/logo-asap.png" alt="logo"></img>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/admin/dashboard">
                Dashboard
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/events/calendar">
                Calendar
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/admin/application-requests">
                Application Requests
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link className="nav-link" to="/admin/upload-requests">
                Upload Requests
              </Link>
            </li> */}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Students
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/student/list">
                    List
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/student/create">
                    Create
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="/student/quick-create">
                    Quick Create
                  </Link>
                </li>
              </ul>
            </li>

            {getSchoolAdminMenu()}
          </ul>

          <div className="d-flex">
            <div className="nav-item dropdown-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <IUser className="iUser"></IUser>
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <div className="user-info">
                  <p className="mg-b-3 tx-12 tx-color-03">{userType()}</p>
                  <h6 className="tx-semibold mg-b-3">{user?.firstName + " " + user?.lastName}</h6>
                  <p className="mg-b-5 tx-13 tx-primary">{user?.email}</p>
                </div>

                <div className="dropdown-divider"></div>

                <div className="mg-b-7">
                  <Link className="dropdown-item" to="/public/password-recovery">
                    <ISettings /> Change Password
                  </Link>
                </div>

                <div>
                  <Link className="dropdown-item" to="/public/logout">
                    <ILogOut></ILogOut>Sign Out
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
