import Modal from "../../modal/modal";
import TextField from "../../shared/Input/textField";
import { PATTERNS } from "../../shared/Input/patterns";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { toast } from "react-toastify";
import documentService from "../../../services/document.service";

const EmailDocumentModal = (props: any) => {
    const [selectedDocs, setSelectedDocs] = useState([] as any);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const loadData = async () => {
        // Get array of selected documents by searching through the documents array with ids from the selected document array
        let temp = props.selectedDocuments.map((item: any) => props.documents.find((document: any) => document.id === item))
        setSelectedDocs(temp)

        setDefaultValues();

    }

    const setDefaultValues = async () => {
        setValue('to', props.student.email);
    }

    const checkKeyDown = (e: any) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const handleSend = async (form: any) => {
        form.studentId = props.student.id;
        form.files = props.selectedDocuments;
        const res = await documentService.emailStudentDocuments(form);
        if (res?.status === HttpStatusCode.Ok) {

            toast.dark('Documents Sent', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        } else {
            toast.error('❌ Error Sending Documents', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });

        }
    };

    useEffect(() => {
        loadData();
    }, [props.visible]);

    const body = () => {
        return (
            <form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="modal-new-upload-request">
                <h5>
                    <span>Student:</span> {props.student.firstName} {props.student.lastName}
                </h5>

                <TextField
                    field="to"
                    type="text"
                    label="Email to"
                    placeholder="Enter the student email address"
                    register={register}
                    errors={errors}
                    rules={{
                        required: true,
                        pattern: PATTERNS.emailRegEx,
                    }}
                />

                <TextField
                    field="subject"
                    type="text"
                    label="Email Subject"
                    placeholder="Enter the subject of the email"
                    register={register}
                    errors={errors}
                    rules={{ required: true }}
                />

                <TextField field="message" type="text" multiLine={true} label="Message signature" register={register} errors={errors} />

                {/* List of documents being sent */}
                <h5>Attached Files:</h5>
                {selectedDocs.map((doc: any) => (<p key={doc.id}>{doc.fileName}</p>))}

            </form>
        );
    };

    return (
        <Modal
            body={body()}
            bodyTitle={<h5>Email Documents</h5>}
            bodySubtitle="Email selected documents to student"
            size="lg"
            visible={props.visible}
            flyout={true}
            mainButtonText="Send"
            onCancel={props.close}
            onClose={props.close}
            cancelButtonClass="btn-dark"
            onAccept={handleSubmit((d) => handleSend(d))}
        />
    )
}

export default EmailDocumentModal;