import { SDGKUProgramGroup, SDGKUProgram } from "../../../../models/Enums"
import { FactsheetFacts } from "../../../../models/Forms.enum"
import { Factsheet } from "./types"

export const getFactsheet = (program: SDGKUProgram): Promise<Factsheet> => {
    return new Promise((resolve, reject) => {
        const keys = [
            FactsheetFacts.Graduation_Facts,
            FactsheetFacts.Completing_Facts,
            FactsheetFacts.Placement_Facts,
            FactsheetFacts.Gainfully_Facts,
            FactsheetFacts.Pass_Facts,
            FactsheetFacts.Salary_Facts,
            FactsheetFacts.Costs_Facts,
            FactsheetFacts.Federal_Facts,
        ]

        if (SDGKUProgramGroup.allFSDIPrograms.includes(program))
            resolve(FSDI)
        else if (SDGKUProgramGroup.allBSGMPrograms.includes(program))
            resolve(BSGM)
        else if (SDGKUProgramGroup.allMSCTPrograms.includes(program))
            resolve(MSCT)
        else if (SDGKUProgramGroup.allMSIMPrograms.includes(program))
            resolve(MSIM)
        reject()
    })
}

const BSGM: Factsheet = {
    Graduation_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "1", "1", "0", "0"],
    ],
    Completing_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "1", "1", "0", "0"],
    ],
    Placement_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "1", "0", "0", "0", "0"],
    ],
    Gainfully_Facts: [
        [
            ["2020", "0", "0", "0"],
            ["2021", "0", "0", "0"],
        ], [
            ["2020", "0", "0", "0"],
            ["2021", "0", "0", "0"],
        ], [
            ["2020", "0", "0"],
            ["2021", "0", "0"],
        ], [
            ["2020", "0", "0"],
            ["2021", "0", "0"],
        ]
    ],
    Pass_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0"],
    ],
    Salary_Facts: [
        ["2020", "0", "0", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0", "0", "0"],
    ],
    Costs_Facts: [
        ["2020", "34,040"],
        ["2021", "51,685"]
    ],
    Federal_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0"],
    ]
}

const MSIM: Factsheet = {
    Graduation_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0"],
    ],
    Completing_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0"],
    ],
    Placement_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0"],
    ],
    Gainfully_Facts: [
        [
            ["2020", "0", "0", "0"],
            ["2021", "0", "0", "0"],
        ], [
            ["2020", "0", "0", "0"],
            ["2021", "0", "0", "0"],
        ], [
            ["2020", "0", "0"],
            ["2021", "0", "0"],
        ], [
            ["2020", "0", "0"],
            ["2021", "0", "0"],
        ]
    ],
    Pass_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0"],
    ],
    Salary_Facts: [
        ["2020", "0", "0", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0", "0", "0"],
    ],
    Costs_Facts: [
        ["2020", "19,905"],
        ["2021", "28,577"]
    ],
    Federal_Facts: [
        ["2019", "0", "0", "0", "0"],
        ["2020", "0", "0", "0", "0"],
    ]
}

const MSCT: Factsheet = {
    Graduation_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0"],
    ],
    Completing_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0"],
    ],
    Placement_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0"],
    ],
    Gainfully_Facts: [
        [
            ["2020", "0", "0", "0"],
            ["2021", "0", "0", "0"],
        ], [
            ["2020", "0", "0", "0"],
            ["2021", "0", "0", "0"],
        ], [
            ["2020", "0", "0"],
            ["2021", "0", "0"],
        ], [
            ["2020", "0", "0"],
            ["2021", "0", "0"],
        ]
    ],
    Pass_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0"],
    ],
    Salary_Facts: [
        ["2020", "0", "0", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0", "0", "0"],
    ],
    Costs_Facts: [
        ["2020", "19,905"],
        ["2021", "28,577"]
    ],
    Federal_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0"],
    ]
}

const FSDI: Factsheet = {
    Graduation_Facts: [        
        ["2020", "76", "76", "40", "53%"],
        ["2021", "77", "77", "56", "73%"],
    ],
    Completing_Facts: [
        ["2020", "76", "76", "47", "62%"],
        ["2021", "77", "77", "60", "78%"],
    ],
    Placement_Facts: [        
        ["2020", "76", "47", "47", "19", "40%"],
        ["2021", "77", "60", "60", "32", "53%"],
    ],
    Gainfully_Facts: [
        [
            ["2020", "0", "19", "19"],
            ["2021", "0", "32", "32"],
        ], [
            ["2020", "19", "0", "19"],
            ["2021", "32", "0", "32"],
        ], [
            ["2020", "0", "19"],
            ["2021", "0", "32"],
        ], [
            ["2020", "0", "19"],
            ["2020", "0", "32"],
        ]
    ],
    Pass_Facts: [
        ["2020", "0", "0", "0", "0", "0"],
        ["2021", "0", "0", "0", "0", "0"],
    ],
    Salary_Facts: [
        ["2020", "76", "19", "0", "0", "0", "0", "19"],
        ["2021", "77", "32", "0", "0", "0", "0", "32"],
    ],
    Costs_Facts: [
        ["2019", "14,200"],
        ["2021", "17,750"]
    ],
    Federal_Facts: [
        ["2020", "0", "0", "0", "0"],
        ["2021", "0", "10", "3", "9,402"],
    ]
}