import { forwardRef, useEffect, useImperativeHandle } from "react";
import { CobaDisclosurePageProps } from "./cobaDisclosurePageProps";
import usePageSignature from "../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../models/Forms.enum";

const DisclosurePage3 = forwardRef(({ visible, data: viewController }: CobaDisclosurePageProps, ref) => {
    const { getSignature, getAcknowledge, saveForm } = usePageSignature(viewController);

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(COBAFormsPage.Disclosure3);
        },
    }));

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 500);
        }
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="doc-content">
            <h5 className='mt-3'>CLASSROOM EXPECTATIONS -- Rules and Regulations</h5>

            <h6>BREAKS:</h6>
            <p>
                Please remember that if your schedule is more than a 6 hour shift you are allowed two 15-minute breaks in a day as well as a
                30-minute lunch break-for Day students. Night students are allowed a 15-minute break. There are no cigarette breaks or phone call
                breaks. If you need to place a call or receive one, do so at your designated break time; the same goes for cigarette breaks (25 feet
                away from School Premises). Students who do not adhere to this rule will be suspended and may be terminated from the program.
            </p>

            <h6>ATTIRE:</h6>
            <p>Students must always be in proper professional uniforms. Please refer to the dress code policy. </p>

            <h6>CELL PHONES:</h6>
            <p>
                The use of cell phones in the classroom is limited. If you need to make a call, do it in the designated assigned areas only or your
                break time.
            </p>

            <h6>FOOD:</h6>
            <p>Only close lid bottles are allowed on the floor as per the school policy. (Please refer to student catalog for details) </p>

            <h6>DAILY ATTENDANCE &#38; ACADEMICS:</h6>
            <p>
                All students are required to maintain a minimum of 75% attendance rate and maintain a written/practical grade average of a minimum
                of 75%.
            </p>

            <h6>CLIENT SERVICE:</h6>
            <p>
                Refusing clients is prohibited. This is a profession that involves working on people. At Coba Academy, you are in a learning
                environment. A large part of your training involves learning how to approach clients, practice and develop your skills upon them.
                Refusing clients may lead to a student being clocked out and sent home. Remember that the more practice on clients, the more
                comfortable you will feel working in a salon, spa or barbershop. Students will start to take clients once they become seniors.
                Students are provided kits which include books, supplies/tools and products to be used daily on hands on/practical assignments and
                clients.
            </p>

            {getAcknowledge("Main", "I have read and acknowledge the Classroom Expectations - Rules and Regulations information.")}

            {getSignature()}
        </div>
    );
});

export default DisclosurePage3;