import { useContext, useEffect, useState } from 'react';
import globalContext from '../../../../context/globalContext';
import { AlertType } from '../../../../models/AlertType.enum';
import { SignedPageData } from '../../../../viewControllers/signedPage';
import usePageSignature from '../../../../hooks/pageSignature';
import { FinishPage, TokenError } from '../enrollment/sdgku.extrapages';
import { Header } from '../header/sdgku.header';
import TextField from '../../../../components/shared/Input/textField';
import { useForm } from 'react-hook-form';
import { SDGKUFormsPage, SignedFormKey } from '../../../../models/Forms.enum';
import { SignedForm, getFullAddress } from '../../../../models/Forms.model';
import signedFormService from '../../../../services/signedForm.service';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import { useHistory } from 'react-router-dom';

interface Props {
  location: any;
}

interface SDKUTranscriptsCommitment {
  studentName: string;
  date: string;
  address: string;
  phone: string;
  email: string;
}

const TranscriptsCommitment = ({ location }: Props) => {
  const { setLoading, showAlert } = useContext(globalContext);
  const [tokenError, setTokenError] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const { saveForm, getSignature, getInitials } = usePageSignature(viewController);
  const [completed, setCompleted] = useState(false);
  const history = useHistory();

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<SDKUTranscriptsCommitment>();

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const sudentId = params.get('id');

    if (token && sudentId) {
      loadForm(+sudentId, token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    try {
      const resp = await signedFormService.getFormData(studentId, SignedFormKey.TRANSCRIPTS_COMMITMENT, token);
      if (resp?.status === HttpStatusCode.Ok) {
        initializeViewController(resp.data, token);
      } else if (resp?.status === HttpStatusCode.Forbidden) {
        showAlert('Invalid token', AlertType.Error);
        setTokenError(true);
      }
    } catch {
      showAlert('Unexpected error', AlertType.Error);
      setTokenError(true);
    } finally {
      setLoading(false);
    }
  };

  const initializeViewController = (formData: SignedForm, token: string) => {
    setViewController(new SignedPageData(SignedFormKey.TRANSCRIPTS_COMMITMENT, formData.programId, formData, token, setEnableSubmit));

    setValue('studentName', formData.student.firstName + ' ' + formData.student.lastName);
    setValue('date', new Date().toLocaleDateString());
    setValue('address', getFullAddress(formData));
    setValue('phone', formData.student.phone);
    setValue('email', formData.student.email);
  };

  const submitForm = async () => {
    if (await saveForm(SDGKUFormsPage.TRANSCRIPTS_COMMITMENT, true)) {
        setCompleted(true);
    } else {
        showAlert('Error while saving. Please verify that all fields are completed', AlertType.Error)
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  if (tokenError) return <TokenError />;

  if (completed)
    return (
      <>
        <div className="doc-area">
          <Header />
          <FinishPage pageMessage="Transcript Commitment Letter" />
        </div>
      </>
    );

  return (
    <div className="mx-auto p-3 doc-area sdgku-transcripts">
      <Header />
      <div className="doc-content px-5">
        <h3 className="text-center mt-3">TRANSCRIPT COMMITMENT LETTER</h3>

        <p className="mt-3">
          <b>Dear Admissions Officer:</b>
        </p>
        <p>
          This is to attest that San Diego Global Knowledge University has requested and attempted to obtain copies and/or original transcripts from previous schools that I have
          attended as part of the university’s admissions requirements. I commit to submitting the required documentation prior to enrollment in the university and to make my best
          efforts to obtain such documentation in a timely manner.
        </p>

        <p>Sincerely,</p>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextField disabled errors={errors} label="Student Name" field="studentName" type="text" register={register} />
          </div>
          <div className="col-sm-6 col-md-2">
            <TextField disabled errors={errors} label="Date" field="date" type="text" register={register} />
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <TextField disabled errors={errors} label="Student Address" field="address" type="text" register={register} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8 col-md-4">
            <TextField disabled errors={errors} label="Phone" field="phone" type="text" register={register} />
          </div>
          <div className="col-sm-8 col-md-4">
            <TextField disabled errors={errors} label="Email" field="email" type="text" register={register} />
          </div>
        </div>

        <div className="position-relative d-flex flex-row">
          <div className="pad">{getSignature()}</div>
        </div>
        <div className="text-center pb-2">
          <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={() => submitForm()}>
            Save and Complete
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranscriptsCommitment;
