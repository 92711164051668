class DateService {
	getFirstLastDayOfMonth(date: Date): { fromDate: Date; toDate: Date } {
		const fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
		const toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		return { fromDate, toDate };
	}

	getFirstLastDayOfMonthStr(date: Date): { fromDate: string; toDate: string } {
		var fromDate = date.toISOString().slice(0, 8) + "01";
		var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		var toDate = lastDayOfMonth.toISOString().slice(0, 10);
		return { fromDate, toDate };
	}

	formatYMD(date: string): string {
		if (date) {			
			const separator = date.includes("/") ? "/" : "-";
			const parts = date.split(separator);
			if (separator === "-" )
				return parts[1] + "/" + parts[2] + "/" + parts[0];
			else
				return parts[0] + "/" + parts[1] + "/" + parts[2];
		}
		else{
            return ""
        }
	}

	// Parse an formated date mm/dd/yyyy back to yyyy-mm-dd
	formatToYMD(MMDDYYY: string ): string {
		if (MMDDYYY) {
			const separator = MMDDYYY.includes("/") ? "/" : "-";
			const parts = MMDDYYY.split(separator);
			return parts[2] + "-" + parts[0] + "-" + parts[1];
		}
		else{
			return ""
		}

	}
}

export default new DateService();
