import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import globalContext from '../../context/globalContext';

import ActionIcon from '../shared/action-icon/actionIcon';
import { XCircle as IconReject, Edit3 as IEdit, Plus as IAdd } from 'react-feather';

import Select from 'react-select';
import SelectField from '../shared/Input/selectField';
import ChecklistModal from './checklistModal';

import ChecklistService from '../../services/checklist.service';

import { StudentChecklist, StudentChecklistDocument } from '../../models/Checklist.model';
import { School } from '../../models/School.model';
import { DocumentType } from '../../models/Document.model';
import { HttpStatusCode } from '../../models/common/HttpStatusCode.enum';

import './checklistViewer.scss';

const DocumentChecklistViewer = (props: any) => {
  const { setLoading, loaders } = useContext(globalContext);
  const [selectedChecklist, setSelectedChecklist] = useState<StudentChecklist>();
  const [schools, setSchools] = useState([] as School[]);
  const [checklists, setChecklists] = useState([] as StudentChecklist[]);
  const [docTypes, setDocTypes] = useState([] as DocumentType[]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAdding, setModalAdding] = useState(true);

  const allSchoolOption = { label: 'All Schools', value: 0 };

  const [schoolFilter, setSchoolFilter] = useState(allSchoolOption) as any;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  let documentSelected = watch('document');

  const addDocumentEnabled = () => {
    return documentSelected?.id;
  };

  const loadData = async () => {
    setLoading(true);
    const [docTypeRes, schoolsRes] = await Promise.all([loaders.documentTypes(), loaders.schools()]);
    let checklistRes = await ChecklistService.getAllChecklists();
    setChecklists(checklistRes?.data);
    setDocTypes(docTypeRes);
    setSchools(schoolsRes);

    setLoading(false);
  };

  const handleModalVisible = (adding: boolean) => {
    setModalVisible(!modalVisible);
    setModalAdding(adding);
  };

  const getDocumentOptions = () => {
    if (!selectedChecklist?.id) {
      return [{ label: 'Select a document', value: 0 }];
    }

    const results = [...docTypes].filter(
      ({ name }) => ![...selectedChecklist.documents].some(({ documentTypeName }) => documentTypeName === name)
    );
    results.sort((a,b)=> a.name.localeCompare(b.name))
    let options = results.map((type) => ({ label: type.name, value: type.id }));
    options.unshift({ label: 'Select a document', value: 0 });
    return options;
  };

  const getSchoolOptions = (extraOption?: any) => {
    let options = schools.map((school) => ({ label: school.shortName, value: school.id }));
    if (extraOption) {
      options.unshift(extraOption);
    }
    return options;
  };

  const getChecklistHeader = () => {
    return (
      <div className="checklists-header d-flex">
        <h5 className="checklists-header-text">Available Checklists</h5>

        {schools.length > 1 && (
          <div className="input-wrapper">
            <Select
              options={getSchoolOptions(allSchoolOption)}
              className="select wd-100p"
              onChange={(e) => {
                setSchoolFilter(e as any);
                setSelectedChecklist({} as StudentChecklist);
              }}
              value={schoolFilter}
            />
          </div>
        )}

        <button className="btn btn-sm btn-outline-primary" onClick={() => handleModalVisible(true)}>
          <IAdd />
        </button>
        <button disabled={!selectedChecklist} className="btn btn-sm btn-outline-primary" onClick={() => handleModalVisible(false)}>
          <IEdit />
        </button>
      </div>
    );
  };

  const handleChecklistSubmit = async (data: any) => {
    let payload = {
      id: undefined as any,
      name: data['name'],
      schools: data['schools'],
    };

    setLoading(true);
    if (modalAdding) {
      let addRes = await ChecklistService.addChecklist(payload);
      if (addRes?.status === HttpStatusCode.Ok) {
        let temp = [...checklists];
        temp.push(addRes.data);
        setChecklists(temp);
      }
    }
    else {
      payload.id = selectedChecklist?.id;
      let editRes = await ChecklistService.editChecklist(payload);
      if (editRes?.status === HttpStatusCode.Ok) {
        let temp = [...checklists];
        let index = temp.findIndex((c) => c.id === selectedChecklist?.id);
        if (index !== -1) {
          temp[index] = editRes.data;
        }
        setChecklists(temp);
        setSelectedChecklist({} as StudentChecklist);
      }
    }

    setLoading(false);
  };

  const handleSelectChecklist = (index: any) => {
    let checklist = checklists[index];
    setValue('document', 0);
    if (selectedChecklist?.id === checklist.id) {
      setSelectedChecklist({} as StudentChecklist);
    } else {
      setSelectedChecklist(checklist);
    }
  };

  const handleRemoveDocument = async (docId: any) => {
    if (!selectedChecklist?.id) return;

    setLoading(true);
    let res = await ChecklistService.removeDocument(selectedChecklist.id, docId);
    setLoading(false);

    if (res?.status === HttpStatusCode.Ok) {
      let temp = { ...selectedChecklist };
      let index = temp.documents.findIndex((doc) => doc.documentTypeId === docId);
      temp.documents.splice(index, 1);
      setSelectedChecklist(temp);
    }
  };

  const checklistHasSchool = (checklist: StudentChecklist) => {
    return checklist.assignedSchools.find((school) => school.id === schoolFilter.value) ? true : false;
  };

  const getChecklists = () => {
    let sortedChecklists = checklists.sort((a, b) => a.name.localeCompare(b.name));
    return checklists ? (
      <>
        {sortedChecklists.map((checklist: StudentChecklist, index) => (
          <>
            {checklistHasSchool(checklist) || schoolFilter.value === 0 ? (
              <>
                <li
                  className={`checklist list-group-item list-group-item-action ${selectedChecklist?.id === checklist.id ? 'active' : ''}`}
                  key={index}
                  onClick={() => {
                    handleSelectChecklist(index);
                  }}
                >
                  <div className="checklist-info">
                    <div className="info-name">
                      <p>{checklist.name}</p>
                    </div>
                    <div className="info-schools">
                      {checklist.assignedSchools.map((school) => (
                        <p className="badge" key={school.id}>
                          {school.shortName}
                        </p>
                      ))}
                    </div>
                  </div>
                </li>
              </>
            ) : null}
          </>
        ))}
      </>
    ) : null;
  };

  const getChecklistDocuments = () => {
    if (!selectedChecklist?.id) {
      return null;
    }

    let documents = selectedChecklist.documents.sort((a,b)=> a.documentTypeName.localeCompare(b.documentTypeName));
    return (
      <ul className="list-group">
        {documents.map((document: StudentChecklistDocument, index) => (
          <li className="document list-group-item list-group-item-action border-bottom" key={index}>
            <div className="date">
              <p>{document.documentTypeName}</p>
            </div>

            <ActionIcon
              label="Remove"
              tooltip="Remove Document"
              icon={<IconReject />}
              color="danger"
              extraClass="custom-delete-btn"
              onClick={() => handleRemoveDocument(document.documentTypeId)}
            />
          </li>
        ))}
      </ul>
    );
  };

  const checkKeyDown = (e: any) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const handleAddDocument = async (data: any) => {
    if (!selectedChecklist?.id) return;

    let document = [...docTypes].find((type) => type.id === data['document']);
    if (document) {
      setLoading(true);
      let res = await ChecklistService.addDocument(selectedChecklist.id, document.id as number);
      setLoading(false);

      if (res?.status === HttpStatusCode.Ok) {
        let temp = { ...selectedChecklist };
        let newDoc = {
          documentTypeId: document.id,
          documentTypeName: document.name,
        } as StudentChecklistDocument;
        temp.documents.push(newDoc);
        setValue('document', 0);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="checklistViewer">
      <ChecklistModal
        visible={modalVisible}
        handleChecklistSubmit={handleChecklistSubmit}
        handleModalVisible={handleModalVisible}
        getSchoolOptions={getSchoolOptions}
        selectedChecklist={modalAdding ? null : selectedChecklist}
        close={() => setModalVisible(false)}
      />

      <div className="checklistWrapper">
        <div className="card checklists">
          <div className="card-header checklists-header">{getChecklistHeader()}</div>

          <div className="card-body overflow-auto">
            <ul className="list-group ">{getChecklists()}</ul>
          </div>
        </div>

        <div className="card documents">
          <div className="card-header documents-header">
            <h5>Required Documents {selectedChecklist?.id}</h5>
          </div>
          <div className="card-body overflow-auto">
            {!selectedChecklist?.id && <p className="no-data">Select a checklist to see/modify it required documents.</p>}
            {getChecklistDocuments()}
          </div>
          <div className="card-footer ">
            <h6>Add required document</h6>
            <div className="flex-form">
              <form onKeyDown={(e) => checkKeyDown(e)}>
                <SelectField
                  initialValue={0}
                  field="document"
                  label={'Document'}
                  control={control}
                  parentClass="input-wrapper"
                  controlClass="select wd-70p"
                  options={getDocumentOptions()}
                />
                <button
                  type="submit"
                  className="btn btn-sm btn-primary flex-form-btn"
                  onClick={handleSubmit((d) => handleAddDocument(d))}
                  disabled={addDocumentEnabled()}
                >
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentChecklistViewer;
