import { useContext, useEffect, useState, useRef } from 'react';
import globalContext from '../../../context/globalContext';
import Modal from '../../../components/modal/modal';
import applicationService from '../../../services/applicationRequest.service';
import TextField from '../../../components/shared/Input/textField';
import {
  ApplicationForm,
  ApplicationFormAcademicInfo,
  ApplicationFormEmploymentReference,
  ApplicationFormPersonalReference,
  ApplicationFormSDGKUStep2Request,
} from '../../../models/ApplicationForm.model';
import { useForm } from 'react-hook-form';
import './sanDiegoGlobal.scss';
import applicationRequestService from '../../../services/applicationRequest.service';
import SelectField from '../../../components/shared/Input/selectField';
import { educationLevelOptions } from './sanDiegoGlobalOptions';
import RadioButton from '../../../components/shared/Input/radioButton';
import SignaturePad from 'react-signature-canvas';
import { PersonalReferenceType } from '../../../models/Enums';
import { ProgressBar } from 'react-bootstrap';
import { AlertType } from '../../../models/AlertType.enum';
import { MASKS } from '../../../components/shared/Input/patterns';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import PhoneField from '../../../components/shared/Input/phoneField/phoneField';

const SanDiegoGlobal2 = (props: any) => {
  let sigCanvas: any = useRef(null);
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    watch,
  } = useForm<ApplicationForm>();

  const [modalVisible, setModalVisible] = useState(false);
  const [form, setForm] = useState(new ApplicationForm());
  const [formId, setFormId] = useState(0);
  const [applicantName, setApplicantName] = useState('');
  const [queryString, setQueryString] = useState('');
  const [edLevel, setEdLevel] = useState(1);
  const [signatureError, setSignatureError] = useState(false);
  const [token, setToken] = useState('');

  const edLevelWatch = watch('student.educationLevel');
  const employedWatch = watch('currentlyEmployed');

  const otherSchollWatch = watch('academicInfo.trainingName');  

  const handleSignatureClear = () => {
    getSignature().clear();
  };

  const getSignature = () => {
    return sigCanvas.current;
  };

  const loadInitialData = async () => {
    let formId = props.match.params.id;
    setFormId(formId);

    setQueryString(props.location.search);
    const params = new URLSearchParams(props.location.search);
    const first = params.get('first');
    const last = params.get('last');
    setApplicantName(`${first} ${last}`);

    const token = params.get('token');
    if (token) {
      loadExistingApplication(token);
      setToken(token);
    }
  };

  const formatYesNoBoolean = (value: any) => {
    // if value is boolean
    if (typeof value === 'boolean') {
      return value ? '1' : '0';
    }
    return value;
  };

  const loadExistingApplication = async (token: string) => {
    try {
      // Load the form data
      setLoading(true, 'Loading your data...');
      let formId = props.match.params.id;
      if (formId) {
        let form = await applicationRequestService.getApplicationRequest(formId, token);
        if (form) {
          let adjustedForm = {
            ...form,
            guardianAgree: formatYesNoBoolean(form.guardianAgree),
          };

          if (adjustedForm.academicInfo) {
            adjustedForm.academicInfo.collegeGraduated = adjustedForm.academicInfo.collegeGraduated ? 1 : 0;
            adjustedForm.academicInfo.highGraduated = adjustedForm.academicInfo.highGraduated ? 1 : 0;
            adjustedForm.academicInfo.trainingGraduated = adjustedForm.academicInfo.collegeGraduated ? 1 : 0;
          }

          setForm(adjustedForm);
          setValue('academicInfo', adjustedForm.academicInfo);
          setValue('employment', adjustedForm.employment);
          setValue('student', adjustedForm.student);
          setValue('references', adjustedForm.references);
          setValue('whyChooseUs', adjustedForm.whyChooseUs);
          setValue('guardianAgree', adjustedForm.guardianAgree);

          let selectedOption = educationLevelOptions.find((x) => x.value === adjustedForm.student.educationLevel);
          handleEducationLevelChange(selectedOption);
        }
      }
    } catch (e) {
      console.log('error loading data', e);
    } finally {
      setLoading(false);
    }
  };

  const handleEducationLevelChange = (option: any) => {
    setEdLevel(option?.level || 1);
  };

  const handleChangeEducationLevel = () => {
    setValue('student.educationLevel', educationLevelOptions[1].value);
  };

  const handleSave = async (data: any) => {
    clearAlert();

    let sign = getSignature();
    if (sign.isEmpty()) {
      setSignatureError(true);
      return;
    }

    setSignatureError(false);
    let payload = { ...data } as ApplicationForm;
    var academicInfo = payload.academicInfo;
    var employment = payload.employment?.[0];
    payload.signature = sign.toDataURL();

    if (payload.references) {
      payload.references[0].type = PersonalReferenceType.NORMAL;
      payload.references[1].type = PersonalReferenceType.NORMAL;
      payload.references[2].type = PersonalReferenceType.EMERGENCY_CONTACT;
    }

    setLoading(true, 'Saving data, please wait');

    const updateRequest: ApplicationFormSDGKUStep2Request = {
      requestId: formId,
      currentlyEmployed: payload.currentlyEmployed === '1',
      educationLevel: payload.student.educationLevel,
      employmentData: employment as ApplicationFormEmploymentReference,
      referencesData: payload.references as ApplicationFormPersonalReference[],
      academicData: academicInfo as ApplicationFormAcademicInfo,
      signatureData: {
        signature: payload.signature,
        guardianAgree: payload.guardianAgree as any,
        whyChooseUs: payload.whyChooseUs,
      },
    };

    let response = await applicationService.updateSDGKUAppliactionStep2(updateRequest);

    setLoading(false);
    if (response.status === HttpStatusCode.Ok) {
      reset(); // reset form
      props.history.push('/public/sdgku/thank_you');
    } else {
      console.error(response);
      showAlert('Error saving data', AlertType.Error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setForm({ ...form, academicInfo: {} as any });
    loadInitialData();
  }, []);

  const checkKeyDown = (e: any) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const belowHighschool = () => edLevelWatch == 'Below high school';

  const highSchoolData = () => {
    return (
      <div className="three-columns group-box  group-box__first mg-t-10 pd-b-10">
        <TextField
          field="academicInfo.highName"
          maxLength="150"
          parentClass="form-group"
          type="text"
          label="Name of High School"
          rules={{ required: true }}
          errors={errors}
          register={register}
        />

        <TextField
          parentClass="form-group"
          field="academicInfo.highDegree"
          maxLength="150"
          type="text"
          label="Diploma or GED Certificate Earned"
          placeholder=""
          register={register}
          errors={errors}
          rules={{ required: true }}
        />

        <SelectField
          field="academicInfo.highGraduated"
          label="Did you graduate?"
          includeColon={false}
          errors={errors}
          control={control}
          rules={{ required: true }}
          options={[
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
          ]}
        />

        <TextField
          field="academicInfo.highFrom"
          maxLength="50"
          parentClass="form-group"
          type="text"
          label="Dates From (mm/yyyy)"
          rules={{ required: true }}
          errors={errors}
          register={register}
        />

        <TextField
          field="academicInfo.highTo"
          maxLength="50"
          parentClass="form-group"
          type="text"
          label="Dates To (mm/yyyy)"
          rules={{ required: true }}
          errors={errors}
          register={register}
        />

        <TextField
          field="academicInfo.highYear"
          maxLength="150"
          parentClass="form-group"
          type="text"
          label="Graduation Date (mm/yyyy)"
          rules={{ required: true }}
          errors={errors}
          register={register}
        />

        <TextField
          field="academicInfo.highAddress"
          maxLength="150"
          parentClass="form-group grid-child-full"
          type="text"
          label="Address (Street Number and Name, City, State, Zip Code, Country)"
          rules={{ required: true }}
          errors={errors}
          register={register}
        />
      </div>
    );
  };

  const collegeData = () => {
    return (
      <div className="three-columns group-box mg-t-10 pd-b-10">
        <TextField
          field="academicInfo.collegeName"
          maxLength="150"
          parentClass="form-group"
          type="text"
          label="Name of College/University"
          register={register}
          errors={errors}
          rules={{ required: true }}
        />

        <TextField
          parentClass="form-group"
          field="academicInfo.collegeDegree"
          maxLength="150"
          type="text"
          label="Degree Earned"
          placeholder=""
          register={register}
          errors={errors}
          rules={{ required: true }}
        />

        <SelectField
          field="academicInfo.collegeGraduated"
          label="Did you graduate?"
          includeColon={false}
          errors={errors}
          control={control}
          rules={{ required: true }}
          options={[
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
          ]}
        />

        <TextField
          field="academicInfo.collegeFrom"
          maxLength="50"
          parentClass="form-group"
          type="text"
          label="Dates From (mm/yyyy)"
          errors={errors}
          rules={{ required: true }}
          register={register}
        />

        <TextField
          field="academicInfo.collegeTo"
          maxLength="50"
          parentClass="form-group"
          type="text"
          label="Dates To (mm/yyyy)"
          errors={errors}
          rules={{ required: true }}
          register={register}
        />

        <TextField
          field="academicInfo.collegeDate"
          maxLength="50"
          parentClass="form-group"
          type="text"
          label="Graduation Date (mm/yyyy)"
          errors={errors}
          rules={{ required: true }}
          register={register}
        />

        <TextField
          field="academicInfo.collegeAddress"
          maxLength="150"
          parentClass="form-group grid-child-full"
          type="text"
          label="Address (Street Number and Name, City, State, Zip Code, Country)"
          register={register}
          errors={errors}
          rules={{ required: true }}
        />
      </div>
    );
  };

  return (
  <div className="sdgku-form">  
    <form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="preapp-form container sd-form">
      <h2>
        <img src="/static/sdgku/logo.png" alt="PA logo"></img>
        <span className="page-title">Application for Admission</span>
        {/* <span className="page-step">Step 2/3</span> */}
      </h2>

      <div className="custom-progress-wrapper col-sm-10 offset-sm-1 col-md-4 offset-md-4">
        <a
          href={`${window.location.origin}/public/SDGKU/1/${form.id}/?first=${form.student.firstName}&last=${form.student.lastName}&token=${token}`}
          className="step-button bg-primary progress-bar progress-bar completed"
          id="step-1"
        >
          <p>Step 1</p>
        </a>
        <div className="step-button bg-primary progress-bar progress-bar completed" id="step-2">
          <p>Step 2</p>
        </div>
        <div className="step-button" id="step-3">
          <p>Done</p>
        </div>
        <ProgressBar variant="primary" now={75} />
      </div>

      <p className="applicant">
        <span>Applicant: </span>
        {applicantName}
      </p>

      <h3>Academic History</h3>

      <div className="two-columns">
        <SelectField
          field="student.educationLevel"
          label="Highest level of education"
          errors={errors}
          control={control}
          rules={{ required: true }}
          onChange={handleEducationLevelChange}
          options={educationLevelOptions}
        />
      </div>

      {edLevel > 1 && highSchoolData()}

      {edLevel > 2 && collegeData()}

      <Modal
        visible={belowHighschool()}
        title="There was a problem"
        body={
          <>
            <h6 className="tx-danger">You must have completed High School in order to enroll.</h6>
            <p>Please contact SDGKU admissions at (619) 934 0797.</p>
          </>
        }
        mainButtonText="Change"
        onAccept={() => {
          handleChangeEducationLevel();
        }}
      />

      {!belowHighschool() ? (
        <>
          <div className="three-columns group-box mg-t-10 pd-b-10">
            <TextField
              field="academicInfo.trainingName"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Other (if any)"
              register={register}
            />

            <TextField
              parentClass="form-group"
              field="academicInfo.trainingDegree"
              maxLength="150"
              type="text"
              label="Degree, Diploma or Course Credit"
              placeholder=""
              register={register}
              errors={errors}
            />

            <SelectField
              field="academicInfo.trainingGraduated"
              label="Did you graduate?"
              includeColon={false}
              errors={errors}
              control={control}
              rules={{ required: otherSchollWatch ? true : false  }}
              options={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
            />

            <TextField
              field="academicInfo.trainingFrom"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Dates From (mm/yyyy)"
              register={register}
            />
            <TextField
              field="academicInfo.trainingTo"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Dates To (mm/yyyy)"
              register={register}
            />
            <TextField
              field="academicInfo.trainingDate"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Graduation Date (mm/yyyy)"
              register={register}
            />
            <TextField
              field="academicInfo.trainingAddress"
              maxLength="150"
              parentClass="form-group grid-child-full"
              type="text"
              label="Address (Street Number and Name, City, State, Zip Code, Country)"
              register={register}
            />
          </div>

          <h3>Employment History</h3>

          <div className="form-group">
            <label>Are you currently employed?</label>
            <RadioButton
              field="currentlyEmployed"
              label="Yes"
              value="1"
              register={register}
              errors={errors}
              rules={{ required: true }}
            ></RadioButton>
            <RadioButton
              field="currentlyEmployed"
              label="No"
              value="0"
              register={register}
              errors={errors}
              rules={{ required: true }}
            ></RadioButton>
          </div>

          <div className="three-columns">
            <TextField
              field="employment.[0].employerName"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Current Most Recent Employer"
              register={register}
              errors={errors}
              rules={{ required: employedWatch === '1' }}
            />
            <TextField
              field="employment.[0].employerWebsite"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Website"
              register={register}
            />

            <TextField
              field="employment.[0].employerAddress"
              maxLength="150"
              parentClass="form-group"
              type="text"
              label="Address"
              register={register}
            />

            <PhoneField
              field="employment.[0].employerPhone"
              parentClass="form-group"
              type="text"
              label="Phone Number"
              control={control}
              register={register}
              errors={errors}
              rules={{ required: employedWatch === '1' }}
            />

            <TextField
              field="employment.[0].employerEmail"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Email"
              register={register}
            />

            <TextField
              field="employment.[0].employerPosition"
              maxLength="100"
              parentClass="form-group"
              type="text"
              label="Position"
              register={register}
            />

            <TextField
              field="employment.[0].employerField"
              maxLength="100"
              parentClass="form-group"
              type="text"
              label="Field"
              register={register}
            />

            <TextField
              field="employment.[0].employerDateFrom"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Dates From (mm/yyyy)"
              register={register}
            />

            <TextField
              field="employment.[0].employerDateTo"
              maxLength="50"
              parentClass="form-group"
              type="text"
              label="Dates To (mm/yyyy)"
              register={register}
            />
          </div>

          <h3>Reference Data</h3>
          <label className="note">Must list three references</label>
          <div className="group-box group-box__first">
            <div className="two-columns">
              <TextField
                field="references.[0].name"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Name of Reference"
                register={register}
                errors={errors}
                rules={{ required: true }}
              />

              <TextField
                field="references.[0].relationship"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Relationship"
                rules={{ required: true }}
                register={register}
                errors={errors}
              />
              <TextField
                field="references.[0].email"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Email address"
                register={register}
              />

              <PhoneField
                field="references.[0].phone"
                parentClass="form-group"
                type="tel"
                label="Telephone "
                control={control}
                rules={{ required: true }}
                register={register}
                errors={errors}
              />

              <TextField
                field="references.[0].address"
                maxLength="150"
                parentClass="form-group grid-child-full"
                type="text"
                label="Address (Street Number and Name, City, State, Zip Code, Country)"
                register={register}
              />
            </div>

            <div className="two-columns group-box group-box__first">
              <TextField
                field="references.[1].name"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Name of Reference"
                register={register}
                errors={errors}
                rules={{ required: true }}
              />

              <TextField
                field="references.[1].relationship"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Relationship"
                register={register}
                errors={errors}
                rules={{ required: true }}
              />

              <TextField
                field="references.[1].email"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Email address"
                register={register}
              />

              <PhoneField
                field="references.[1].phone"
                parentClass="form-group"
                type="tel"
                label="Telephone "
                control={control}
                rules={{ required: true }}
                register={register}
                errors={errors}
              />

              <TextField
                field="references.[1].address"
                maxLength="150"
                parentClass="form-group grid-child-full"
                type="text"
                label="Address (Street Number and Name, City, State, Zip Code, Country)"
                register={register}
              />
            </div>
            <div className="two-columns">
              <TextField
                field="references.[2].name"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Name of Reference"
                register={register}
                errors={errors}
                rules={{ required: true }}
              />

              <TextField
                field="references.[2].relationship"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Relationship"
                register={register}
                errors={errors}
                rules={{ required: true }}
              />

              <TextField
                field="references.[2].email"
                maxLength="150"
                parentClass="form-group"
                type="text"
                label="Email address"
                register={register}
              />

              <PhoneField
                field="references.[2].phone"
                parentClass="form-group"
                type="tel"
                label="Telephone "
                control={control}
                rules={{ required: true }}
                register={register}
                errors={errors}
              />

              <TextField
                field="references.[2].address"
                maxLength="150"
                parentClass="form-group grid-child-full"
                type="text"
                label="Address (Street Number and Name, City, State, Zip Code, Country)"
                register={register}
              />
            </div>
          </div>

          <h3>Statement of purpose</h3>
          <label>
            In the space provided below, clearly explain why you desire to enroll at San Diego Global Knowledge
            University. Please describe the benefits you expect and how they align with your personal and/or
            professional goals. Please make sure to limit the length of your statement to 100 words or less.
          </label>

          <TextField
            field="whyChooseUs"
            parentClass="form-group"
            type="text"
            multiLine={true}
            label="Statement of purpose"
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <h3>Disclaimer &amp; Signature</h3>
          <h6 className="label-certify">
            My signature below, certifies that I have read and understand that any false statements or incomplete
            information in my application may result in denial of this application or dismissal from all course(s) or
            program(s) at San Diego Global Knowledge University. My signature also certifies and authorizes all schools
            and Universities you attended to provide all requested academic records transcripts and or proof of
            completion to San Diego Global Knowledge University and allows San Diego Global Knowledge University the
            approval to provide this Application for Admission to any school you attended as its evidence. I certify
            that all information in this application is factually true and honestly presented and that you are the
            person submitting this application.
            <br />
            <br />
            All transactions submitting personal information by the website user which is then collected by San Diego
            Global Knowledge University (SDGKU) requires the sender of such information (user) to attest their agreement
            to the following: “I understand that by clicking “Submit” below, San Diego Global Knowledge University
            (SDGKU) may email, call and/or text me about educational services and for related purposes such as advising,
            enrollment, tuition settlement or telemarketing at the email and phone number provided, including a wireless
            number, using automated technology. I understand that I am not required to provide this consent to attend
            SDGKU. As an alternative to providing this consent, you may receive information and/or enroll in a SDGKU
            program by calling (619) 934-0797 or Toll Free (800) 215-0541.”
            <br />
            SDGKU is committed to protecting the privacy of its website users by using data collection methods that are
            based on ethical practices and responsible risk management techniques. The user will be well informed about
            SDGKU’s use of any personal information submitted and will require their acceptance of the terms of use.
            <br />
            For more info please check our:
            <a href="https://sdgku.edu/privacy-policy/" className="mg-l-10 mg-r-10" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            <a href="https://sdgku.edu/consumer/#terms" target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
          </h6>

          <div>
            <label>
              To be completed by the applicant’s parent or guardian if the applicant is under 18 years of age: I confirm
              I have read, understood and agree to the declaration above on behalf of the applicant:
            </label>

            <RadioButton
              field="guardianAgree"
              label="Yes"
              value="1"
              register={register}
              errors={errors}
              rules={{ required: true }}
            ></RadioButton>
            <RadioButton
              field="guardianAgree"
              label="No"
              value="0"
              register={register}
              errors={errors}
              rules={{ required: true }}
            ></RadioButton>
          </div>
          <div className="two-columns">
            <div className="form-group sig-pad">
              <label className="form-check-label">
                <span className="required">*</span> Please add your Signature
              </label>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
              ></SignaturePad>
            </div>
            <div className="clear-container">
              <button type="button" className="btn btn-dark" onClick={handleSignatureClear}>
                Clear Signature
              </button>
              {signatureError ? <p className="invalid-input-error">* Please sign the application</p> : null}
            </div>
          </div>

          <div className="save-container">
            <button type="submit" className="btn btn-primary" onClick={handleSubmit((d) => handleSave(d))}>
              Submit Application Request
            </button>
          </div>
        </>
      ) : null}
    </form>
  </div>
  );
};

export default SanDiegoGlobal2;
