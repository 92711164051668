import { Fact, Facts, Table } from "../../sdgku/facts/types"

const Graduation_Rates_Table: Table = {
    headers: [
        "Calendar Year",
        "Number of Students who Began the Program",
        "Students Available for Graduation",
        "Number of On-Time Graduates",
        "On-Time Completion Rate"
    ]
}

export const Graduation_Rates: Facts = {
    title: "On-Time Completion Rates (Graduation Rates)",
    description: "Includes data for the two calendar years prior to reporting.",
    table: Graduation_Rates_Table,
}

const Students_Completing_Table: Table = {
    headers: [
        "Calendar Year",
        "Number of Students who Began the Program",
        "Students Available for Graduation",
        "150% Graduates",
        "150% Completion Rate"
    ]
}

export const Students_Completing: Facts = {
    title: "Students Completing Within 150%",
    description: "Includes data for the two calendar years prior to reporting.",
    table: Students_Completing_Table,
}

const Job_Placement_Table: Table = {
    headers: [
        "Calendar Year",
        "Number of Students who Began the Program",
        "Number of Graduates",
        "Graduates Available for Employment",
        "Graduates Employed in the Field",
        "Placement Rate % Employment in the Field",
    ],
    footer: `You may obtain from the Institution a list of employment positions determined to be in the field for which a student received
    education and training from the School Catalog or Admissions Office. `
}

export const Job_Placement: Facts = {
    title: "Job Placement Rates",
    description: "Includes data for the two calendar years prior to reporting.",
    table: Job_Placement_Table,
    footer: false
}

const PTime_FTime_Table: Table = {
    title: "Part-Time vs. Full-Time Employment",
    headers: [
        "Calendar Year",
        "Graduate Employed in the Field 20-29 Hours Per Week",
        "Graduates Employed in the Field at Least 30 Hours Per Week",
        "Total Graduates Employed in the Field",
    ]
}

const SPosition_APosition_Table: Table = {
    title: "Single Position vs. Concurrent Aggregated Position",
    headers: [
        "Calendar Year",
        "Graduate Employed in the Field in a Single Position",
        "Graduates Employed in the Field in Concurrent Aggregated Positions",
        "Total Graduates Employed in the Field",
    ]
}

const SEmployed_FLance_Table: Table = {
    title: "Self-Employed / Freelance Positions",
    headers: [
        "Calendar Year",
        "Graduates Employed who are Self-Employed or Working Freelance",
        "Total Graduates Employed in the Field",
    ]
}

const Institutional_Employment_Table: Table = {
    title: "Institutional Employment",
    headers: [
        "Calendar Year",
        "Graduates Employed in the Field who are Employed by the Institution, an Employer Owned by the Institution, or an Employer who Shares Ownership with the Institution",
        "Total Graduates Employed in the Field",
    ]
}

export const Gainfully_Employed: Facts = {
    title: "Gainfully Employed Categories",
    description: "Includes data for the two calendar years prior to reporting",
    tables: [
        PTime_FTime_Table,
        SPosition_APosition_Table,
        SEmployed_FLance_Table,
        Institutional_Employment_Table
    ]
}

const Pass_Rates_Table: Table = {
    headers: [
        "Calendar Year",
        "Number of Graduates in Calendar Year",
        "Number of Graduates Taking Exam",
        "Number Who Passed First Available Exam",
        "Number Who Failed First Available Exam",
        "Passage Rate",
    ],
    footer: `Licensure examination passage data is not available from the state agency administering the
    examination. We are unable to collect date from 0 graduates.`,
}

export const Pass_Rates: Facts = {
    title: "License Examination Passage Rates",
    description: "Includes data for the two calendar years prior to reporting.",
    table: Pass_Rates_Table,
}

const Salary_Wage_Table: Table = {
    headers: [
        "Calendar Year",
        "Graduates Available for Employment",
        "Graduates Employed in Field",
        "$20,001 - $25,000",
        "$35,001 - $40,000",
        "$40,001 - $45,000",
        "$45,001 - $50,000",
        "No Salary Information Reported",
    ],
    footer: `A list of sources used to substantiate salary disclosures is available from the school`,
}

export const Salary_Wage: Facts = {
    title: "Salary and Wage Information (includes data for the two calendar years prior to reporting) ",
    description: "Annual salary and wages reported for graduates employed in the field.",
    table: Salary_Wage_Table,
    footer: false
}

export const Cost: Fact = {
    title: "Cost of Educational Program",
}

const Federal_Loan_Table: Table = {
    headers: [
        "Calendar Year",
        "Most recent three year cohort default rate, as reported by the United State Department of Education.*",
        "The percentage of enrolled students in 2019/20 receiving federal student loans to pay for this program.",
        "The percentage of graduates in 2019/20 who took out federal student loans to pay for this program.",
        "The average amount of federal student loan debt of 2019/20 graduates who took out federal student loans at this institution.",
    ],
    footer: `* The percentage of students who defaulted on their federal student loans is called the Cohort
    Default Rate (CDR). It shows the percentage of this school’s students who were more than 270
    days (9 months) behind on their federal student loans within three years of when the first payment
    was due. This is the most recent CDR reported by the U.S. Department of Education.`,
}

export const Federal_Loan: Facts = {
    title: "Federal Student Loan Debt",
    description: "",
    table: Federal_Loan_Table,
}