import "./dashboard.scss";
import BarComponent from "./barComponent";
import PieComponent from "./pieComponent";
import DataIcon from "./dataIcon";
import globalContext from "../../context/globalContext";
import { useContext, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import applicationRequestService from "../../services/applicationRequest.service";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import { ApplicationStatus } from "../../models/Enums";

const Dashboard = (props: any) => {
  const [programsData, setProgramsData] = useState<any[]>([]);
  const { user, setLoading } = useContext(globalContext);
  const [program, setProgram] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [nameSchool, setNameSchool] = useState<any[]>([]);
  const [schools, setSchools] = useState<any[]>([]);
  const [status, setStatus] = useState("");

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await applicationRequestService.getPrograms();
      setProgramsData(response);
      setLoading(false);
    } catch (error) {
      console.error("error loading the programs:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const newData = user.schoolsData.map((school: any, index: any) => {
      const applicationRequest = props.requestData
        ? props.requestData.filter((t: any) => t.student.school === school.id)
        : [];

      let filteredApplicationRequest;
      if (status !== "") {
        filteredApplicationRequest = applicationRequest
          ? applicationRequest.filter((t: any) => t.status === status)
          : [];
      } else {
        filteredApplicationRequest = applicationRequest ? applicationRequest : [];
      }

      const programsForSchool = programsData.filter((t: any) => t.schoolId === school.id);

      return {
        ...school,
        numberOfPrograms: programsForSchool.length,
        applicationRequest: applicationRequest.length,
        applicationRequestFilter: filteredApplicationRequest.length,
      };
    });
    setSchools(newData);

    if (programsData.length > 0 && program !== "") {
      const programsForSchool = programsData.filter((t: any) => t.schoolId === program);
      const nameSchool = user.schoolsData.filter((t: any) => t.id === program).map((t: any) => t.shortName);
      const userCount: { [key: string]: number } = {};

      props.requestData
        .filter((entry: any) => entry.student.school === program)
        .forEach((entry: any) => {
          const programName = entry.program;
          userCount[programName] = (userCount[programName] || 0) + 1;
        });

      const data = programsForSchool.map((program: any) => {
        const shortName = program.shortName || program.name;
        const programName = program.name;
        const quantity = userCount[programName] || 0;
        return { name: shortName, quantity };
      });

      setData(data);
      setNameSchool(nameSchool);
    }
  }, [programsData, program, props.requestData, status]);

  const handleChange = (event: SelectChangeEvent) => {
    setProgram(event.target.value);
  };

  const handleChange2 = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  return (
    <div className="dashboard-container">
      {props.adminData && props.isAdmin ? (
        <div className="general-info-container">
          {props.adminData.totals
            ? props.adminData["totals"].map((t: any, index: any) => <DataIcon info={t} key={index}></DataIcon>)
            : null}
        </div>
      ) : null}

      {props.mounted ? (
        <div className="graphs-container">
          <div className="filter">
            <BarComponent
              label="Applications status"
              labels={props.applicationData.status ? Object.keys(props.applicationData.status) : ["", ""]}
              data={props.applicationData.status ? Object.values(props.applicationData.status) : [0, 0]}
            ></BarComponent>
          </div>

          <div className="filter">
            <BarComponent
              label="Student status"
              labels={props.studentData.status ? Object.keys(props.studentData.status) : null}
              data={props.studentData.status ? Object.values(props.studentData.status) : null}
            ></BarComponent>
          </div>

          <div className="filter">
            <BarComponent
              label="Finance Plan"
              labels={props.applicationData.status ? Object.keys(props.applicationData.finance) : null}
              data={props.applicationData.status ? Object.values(props.applicationData.finance) : null}
            ></BarComponent>
          </div>

          {props.adminData && props.isAdmin ? (
            <div className="filter">
              <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                <InputLabel id="demo-select-small-label">Filter by status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={status}
                  label="Filter by status"
                  onChange={handleChange2}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={0}>InReview</MenuItem>
                  <MenuItem value={1}>Approved</MenuItem>
                  <MenuItem value={2}>Rejected</MenuItem>
                  <MenuItem value={3}>Canceled</MenuItem>
                </Select>
              </FormControl>

              <PieComponent
                label="Applications in Schools"
                labels={schools.map((t: any) => t.shortName)}
                data={schools.map((t: any) => t.applicationRequestFilter)}
                cssClass="graph-xl"
              ></PieComponent>
            </div>
          ) : null}

          {props.adminData && props.isAdmin ? (
            <div className="filter">
              <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                <InputLabel id="demo-select-small-label">School</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={program}
                  label="School"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {schools.map((school: any) => (
                    <MenuItem
                      key={school.id}
                      value={school.id}
                      disabled={school.numberOfPrograms <= 1 || school.applicationRequest == 0}
                    >
                      {school.shortName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {program !== "" ? (
                <PieComponent
                  label={`Applications in ${nameSchool}`}
                  labels={data.map((t) => t.name)}
                  data={data.map((t) => t.quantity)}
                  cssClass="graph-xl"
                />
              ) : (
                <div className="info-graph">
                  <h5>
                    Select school <br />
                    to watch data
                  </h5>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Dashboard;
