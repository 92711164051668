import React from "react";
import { Search as IconSearch } from "react-feather";

import "./searchBox.scss";
import useDebouncedEffect from '../../../hooks/debounce';

export interface ISearchBoxProps {
  label?: string;
  placeholder?: string;
  value?: string;
  debounce?: number;
  onChange: (value: string) => void;
  className?: string;
  vertical?: boolean;
  onSearch?: () => void;
}

const SearchBox = ({ label, placeholder, className, value, onChange, vertical = true, onSearch, debounce=0 }: ISearchBoxProps) => {

  const [searchText, setSearchText] = React.useState<string>('');
  const [localValue, setLocalValue] = React.useState<string>(value || '');

  useDebouncedEffect(() => {
    onChange(searchText);
  }, 1000, [searchText]);

  const handleKeyUp = (e: any) => {
    if(e.keyCode === 13) {
      onSearch?.();
    }
  }

  const handleChange = (e: any) => {
    if(!debounce) {
      onChange(e.target.value);
    }
    else {
      setLocalValue(e.target.value);
      setSearchText(e.target.value);      
    }
  }

  return (<>
    <div className={"search-box-component " + className}>
      {label && vertical ? <label className="label-vertical">{label}</label> : null}
      <div className="search-form">
        {label && !vertical ? <label>{label}</label> : null}
        <input className="form-control" type="search" value={debounce? localValue : value} placeholder={placeholder} onChange={handleChange} onKeyUp={handleKeyUp} />
        <button className="btn" type="button" onClick={() => onSearch?.() }>
          <IconSearch />
        </button>
      </div>
    </div>
  </>
  );
};

export default SearchBox;
