import { useContext, useEffect, useState } from 'react';
import { Student } from '../../../models/Student.model';
import globalContext from '../../../context/globalContext';
import studentService from '../../../services/student.service';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import TitleBar from '../../../components/shared/titleBar/titleBar';
import StudentEstimate from '../../../components/student/StudentEstimate/studentEstimate';
import { Link } from 'react-router-dom';

import "./studentFinancialEstimate.scss";
import usePermissionRequired from '../../../hooks/permissionRequired';
import { Permission } from '../../../models/Enums';

const StudentFinancialEstimate = (props: any) => {
  const [loaded, setLoaded] = useState(false);
  const [studentId, setStudentId] = useState<number>(0);
  const [student, setStudent] = useState<Student>(new Student());

  const { clearAlert, setLoading } = useContext(globalContext);
  usePermissionRequired([Permission.VIEW_ESTIMATE_TEMPLATES])


  useEffect(() => {
    clearAlert();
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    let id = props.match.params.id;
    setStudentId(id);

    // load student data
    let studentRes = await studentService.getStudentById(id);
    if (studentRes?.status === HttpStatusCode.Ok) {
      setStudent(new Student(studentRes.data));
    }
    setLoaded(true);
    setLoading(false);
  };

  return (
    <div className="page page-student-financial-estimate">
      <TitleBar header={loaded ? student.fullName() : 'Loading...'} subheader={loaded ? student?.schoolData?.name || '⚠️ Warning: School not assigned' : ''}>
        <Link className="btn btn-outline-primary mg-l-5" to={'/student/overview/' + studentId}>
          Student Overview
        </Link>
      </TitleBar>

      <div className="col">
        {studentId && <StudentEstimate studentId={studentId} student={student} /> }
      </div>
    </div>
  );
};

export default StudentFinancialEstimate;
