import { AgreementProps } from '../../types';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import usePageSignature from '../../../../../hooks/pageSignature';
import { SDGKUFormsPage } from '../../../../../models/Forms.enum';
import '../../index.css';
import TextField from '../../../../../components/shared/Input/textField';
import { useForm } from 'react-hook-form';
import dateService from '../../../../../services/date.service';

const formatMoney = (number: number): string => {
  return number
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

interface AgreementPage2Form {
  cancellationDate: string;
}

export const AgreementPage2 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
  const { saveForm, getInitials } = usePageSignature(data, false);
  const { register, setValue } = useForm<AgreementPage2Form>();
  let cancellDate = "";
  if(data?.formData?.cancelDate) {
    cancellDate = dateService.formatYMD(data.formData.cancelDate);
  }

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(SDGKUFormsPage.Agreement2);
    },
  }));

  useEffect(() => {
    visible && window.scrollTo(0, 0);        
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="doc-content py-4">
      <p>
        <b>Questions:</b> Any questions a student may have regarding this enrollment agreement that have not been satisfactorily answered by the institution may be directed to the
        Bureau for Private Postsecondary Education at 1747 North Market Blvd., Suite 225, Sacramento, CA 95834, www.bppe.ca.gov, phone (916) 574-8900 and fax (916) 263-1897.
      </p>
      <p>
        <b>Complaint:</b> A student or any member of the public may file a complaint about this institution with the Bureau for Private Postsecondary Education by calling (888)
        370-7589 (toll free) or by completing a complaint form, which can be obtained on the bureau’s Internet Web site www.bppe.ca.gov. A student or any member of the public may
        also file a complaint with the Distance Education Accrediting Commission (DEAC) at www.deac.org.
      </p>
      <h4>CANCELLATION AND REFUND INFORMATION/POLICY:</h4>
      <p>
        The student has the right to cancel the enrollment agreement and obtain a refund of charges paid through attendance at the first-class session, or the seventh day after
        enrollment, whichever is later. Students who cancel within seven days receive a refund of all charges including the registration/enrollment fee. Students who cancel more
        than seven days after enrollment receive a refund of all charges minus the registration/enrollment fee. If the instruction is not offered in real time, the student shall
        have the right to cancel the enrollment agreement and receive a full refund before the first lesson and materials are received. This is applicable to online and hybrid
        courses.
      </p>
      <p>
        The institution applies a prorated policy, based on the number of days enrolled divided by the number of days total in an enrollment period. If a student paid fees and did
        not access the instructional program, or attended class, all fees except for the non-refundable registration/enrollment fee will be returned to them. The refund will be
        mailed within 30 days from the time of the withdrawal or cancellation, according to the schedule below.
      </p>
      <p>
        Students may withdraw after instruction has started and receive a pro-rata refund for the unused portion of the tuition and other refundable charges if the student has
        completed 60% or less of the period of attendance. A period of attendance is 15 weeks (105 days) for Certificate (non-degree) and Master’s programs. A period of attendance
        is 20 weeks (140 days) for Bachelor’s degree programs. A hypothetical example of a 20-week period of attendance (140 days) of $1200 has the following refund schedule:
      </p>
      <p className="text-center">
        <b>After Attending Example</b>
      </p>
      <table className="table table-bordered table-refund-example">
        <thead>
          <tr>
            <th className="fw-bold" scope="col">
              Days Scheduled
            </th>
            <th className="fw-bold" scope="col">
              Refund
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>7 days</td>
            <td>$1,200.00</td>
          </tr>
          <tr>
            <td>28 days</td>
            <td>$960.00</td>
          </tr>
          <tr>
            <td>56 days</td>
            <td>$720.00</td>
          </tr>
          <tr>
            <td>84 days (60%)</td>
            <td>$480.00</td>
          </tr>
          <tr>
            <td>After 84 days</td>
            <td>No Refund</td>
          </tr>
        </tbody>
      </table>
      <p>
        If the school cancels or discontinues a course or educational program, the school will make a full refund of all charges. Refunds will be paid within 30 days of
        cancellation or withdrawal.
      </p>
      <p>
        <div className="pl-0 col-4 mb-5">
          <TextField disabled label="Date by which to cancel" field="cancellationDate" type="text" register={register} value={cancellDate || "Loading..."}/>
        </div>
      </p>
      <div className="position-relative">{getInitials('page2')}</div>
    </div>
  );
});
