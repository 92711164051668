import React, { useState, useContext, useEffect } from "react";
import globalContext from "../../../context/globalContext";
import { LoggedInStatus } from "../../../context/IContext";
import AuthService from "../../../services/auth.service";
import { toast, ToastContainer } from "react-toastify";
import "./login.scss";
import  appInsights from '../../../app.analytics';

const Login = (props: any) => {
  const { clearAlert, setLoggedInStatus, logOut, setLoading, isLoggedIn, user } = useContext(globalContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const getDefaultPageForUser = () => {
    if (user?.isCalendar == true) {
      return calendarLocation;
    } else {
      return defaultHomeLocation;
    }
  };

  const defaultHomeLocation = "/admin/dashboard";
  const calendarLocation = "/events/calendar";
  let previousLocation: any = { from: props.history?.location?.state?.from || getDefaultPageForUser() };

  const handleLogin = async () => {
    clearAlert();

    setLoading(true, "Validating data...");
    let resp = await AuthService.login(email, password);
    setLoading(false);

    if (resp?.success) {
      setLoggedInStatus(LoggedInStatus.LOGGED_IN, resp.user);
      appInsights.setAuthenticatedUserContext(resp.user.id);

      if (resp.user.isCalendar === true) {
        props.history.push(calendarLocation);
      } else {
        props.history.push(defaultHomeLocation);
      }
    } else {
      setPassword("");
      toast.error("Invalid Credentials, try again!");
    }
  };

  const handleLogOut = async () => {
    await AuthService.logout();
    logOut();
    appInsights.clearAuthenticatedUserContext();
    props.history.push("/public/login", previousLocation);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("logout")) {
      handleLogOut();
    }
    setLoading(false);
  }, []);

  const getNextPage = () => {
    if (user.isCalendar) {
      return calendarLocation;
    } else {
      if (previousLocation.from && previousLocation.from !== "/public/login") {
        return previousLocation.from;
      }
      return defaultHomeLocation;
    }
  };

  useEffect(() => {
    if (isLoggedIn === LoggedInStatus.LOGGED_IN && !window.location.pathname.includes("logout")) {
      props.history.push(getNextPage());
    }
  }, [isLoggedIn]);

  return (
    <div className="page login-page">
      <div className="login-form">
        <ToastContainer></ToastContainer>
        <h1 className="tx-color-01 mg-b-5">FA360</h1>
        {/* <h1 className="tx-color-01 mg-b-5">
          <img className="logo" src="/static/img2/logo.png" alt="logo"></img>
        </h1> */}

        <h3 className="tx-color-03 tx-16 mg-b-40">Please sign in</h3>

        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            name="email"
            value={email}
            onChange={(val: any) => setEmail(val.target.value)}
            placeholder="email"
          />
          <label>Email address</label>
        </div>

        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            name="password"
            value={password}
            onChange={(val: any) => setPassword(val.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Password"
          />
          <label>Password</label>
        </div>

        <button className="btn btn-primary py-2 btn-block" onClick={handleLogin} type="button">
          Sign in
        </button>

        <div className="mg-t-25 mg-b-5 divider-text">having issues?</div>
        <div className="tx-15 tx-center">
          <a href="/public/password-recovery">Reset your password</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
