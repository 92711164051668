import { CheckboxListItemProps } from "../../../components/shared/Input/checkBox";


export const checkList = [
	{ field: "payCreditCard", label: "Debit or Credit" },
	{ field: "payCheck", label: "Check or Money Order" },
	{ field: "payFinancialAid", label: "Financial Aid" },
	{ field: "payScholarship", label: "Grant or Scholarship" },
	{ field: "payMilitaryBenefits", label: "VA Education Benefits" },
	{ field: "payOther", label: "Other" },
] as CheckboxListItemProps[];


export const programOptions = [
	{
		value: "Full Stack Development Immersive - Online",
		label: "Full Stack Development Immersive - Online",
		duration: "Approx. 30 weeks",
	},
	{
		value: "Full Stack Development Immersive - Hybrid",
		label: "Full Stack Development Immersive - Hybrid",
		duration: "Approx. 30 weeks",
	},
	{
		value: "Bachelor of Science in Global Management",
		label: "Bachelor of Science in Global Management",
		duration: "Approx. 4 years 2 months",
	},
	{
		value: "Bachelor of Science in Global Management (Spanish)",
		label: "Bachelor of Science in Global Management (Spanish)",
		duration: "Approx. 4 years 2 months",
	},
	{
		value: "Master in Science in International Management",
		label: "Master in Science in International Management",
		duration: "Approx. 1 year 6 months",
	},
	{
		value: "Master in Science in International Management (Spanish)",
		label: "Master in Science in International Management (Spanish)",
		duration: "Approx. 1 year 6 months",
	},
	{
		value: "Master in Science in Communication and Technology",
		label: "Master in Science in Communication & Technology",
		duration: "Approx. 1 year 6 months",
	},
	{
		value: "Master in Science in Communication and Technology (Spanish)",
		label: "Master in Science in Communication & Technology (Spanish)",
		duration: "Approx. 1 year 6 months",
	},
	{ value: "Undecided", label: "Undecided", duration: "" },
];


export const hearAboutUsOptions = [
	{ value: "Internet Search", label: "Internet Search - Referral" },
	{ value: "Referral/VA or Rehab", label: "Referral/VA or Rehab" },
	{ value: "Social Media", label: "Social Media" },
	{ value: "Other", label: "Other" },
];


export const militaryRecordOptions = [
	{ value: "None", label: "None" },
	{ value: "Active U.S. Military", label: "Active U.S Military" },
	{ value: "Dependant of U.S. Veteran", label: "Dependant of U.S. Veteran" },
	{ value: "U.S. National Guard or Active", label: "U.S National Guard or Active Reserve" },
	{ value: "U.S. Veteran", label: "U.S. Veteran" },
];

export const educationLevelOptions = [
	{ value: "Below high school", label: "Below high school", level: 1 },
	{ value: "High School", label: "High School", level: 2 },
	{ value: "Associate", label: "Associate", level: 2 },
	{ value: "Bachelor", label: "Bachelor", level: 3 },
	{ value: "Master", label: "Master", level: 3 },
	{ value: "Doctorate", label: "Doctorate", level: 3 },
];
