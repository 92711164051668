import { useContext, useEffect, useState } from "react";
import SchoolPicker from "../../components/programs/schoolPicker/schoolPicker"
import TitleBar from "../../components/shared/titleBar/titleBar"
import globalContext from "../../context/globalContext";
import { School } from "../../models/School.model";
import PeriodsViewer from "../../components/programs/periods/periodsViewer";

const PeriodsPage = () => {
    const {setLoading, loaders} = useContext(globalContext);
    const [schools, setSchools] = useState([] as School[]);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState({} as School);

    const loadData = async () => {
        setLoading(true);
        // Get Schools that user has access to
        let schoolsRes = await loaders.schools();
        setLoading(false);

        setSchools(schoolsRes);

        // If the user has more than one school show the picker otherwise set the selectedSchool
        if (schoolsRes.length == 1) {
            setSelectedSchool(schoolsRes[0]);
        } else {
            setShowPicker(true);
        }
    }

    const handleShowPicker = () => {
        setShowPicker(!showPicker);
    }

    const handleSchoolChange = (option: any) => {
        setSelectedSchool(option);
        setShowPicker(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="home-page pd-0">
            <TitleBar header="School Periods" subheader="School Periods/Semesters" />

            <SchoolPicker onChange={handleSchoolChange} schoolChoices={schools} showPicker={showPicker} setShowPicker={handleShowPicker}></SchoolPicker>

            <PeriodsViewer selectedSchool={selectedSchool} setShowPicker={setShowPicker}></PeriodsViewer>
        </div>
    )
}

export default PeriodsPage;