import http from './http-common';

class FeeService {
  async getFees(programId: number, schoolId: number) {
    try {
      let response = await http.get(`/forms/fees/?program=${programId}&school=${schoolId}`);
      return response.data;
    } catch (error) {
      http.fa360.displayError('Error fetching Fees');
    }
  }

  async getBatchFees(programId: number, studentId: number, schoolId: number, feeKeys: string[], token: string, alt?: string, feeGroupId?: number) {
    //  alt = alternative fee (e.g. Spanish for SDGKU BSGM)
    try {
      let response = await http.post(`/forms/fees/values/`, { program: programId, school: schoolId, studentId, keys: feeKeys, token, alt: alt || '', feeGroupId: feeGroupId || 0 });
      return response;
    } catch (error) {
      http.fa360.displayError('Error fetching Fees');
      return undefined;
    }
  }

  async updateFees(editedFees: any[], programId: number, schoolId: number) {
    try {
      // Update needs its on function based view to handle updating multiple records at once
      let response = await http.post(`/forms/fees/batch_update/?program=${programId}&school=${schoolId}`, editedFees);
      return response;
    } catch (error) {
      http.fa360.displayError('Error updating Fees');
    }
  }
}

export default new FeeService();
