import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const SpanishAgreementPage4 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement4)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <p>
                <b>Política de Retorno al Title IV (R2T4):</b> San Diego Global Knowledge University (SDGKU) está obligada a aplicar las disposiciones de Devolución al Title IV (R2T4) a los beneficiarios de la Ayuda Federal para Estudiantes (FSA) que se retiren de su programa de estudios. Esta fórmula de reembolso determina la cantidad de fondos FSA que un estudiante ha ganado a partir del último día de asistencia o actividad académica. La política R2T4 es adicional a la política de reembolso institucional de SDGKU. Si un estudiante se retira antes de completar más del 60% del término (por ejemplo, período de asistencia), el porcentaje de fondos FSA ganados será igual al porcentaje de días completados en el período de asistencia antes de la fecha de retiro. Después del punto del 60% en el periodo de asistencia, el estudiante habrá ganado el 100% de los fondos de la FSA que estaba programado que recibiera durante el periodo.
            </p>
            <p>Para determinar cuándo debe pagarse el reembolso, el reembolso del Título IV se emitirá 45 días después de la fecha de determinación. Si el estudiante ha recibido algún fondo de ayuda financiera federal del Título IV, la escuela está obligada a hacer un R2T4 incluso si ya se ha emitido un saldo a favor del estudiante. Para los programas más allá del período actual de asistencia, si un estudiante se retira antes del siguiente período de asistencia, todos los cargos cobrados para el siguiente período serán reembolsados. Si del cálculo R2T4 resulta un saldo pendiente, el estudiante será responsable del saldo impagado. Si el estudiante ha recibido fondos del programa federal de ayuda financiera al estudiante, tendrá derecho al reembolso del dinero no abonado con cargo a los fondos del programa federal de ayuda financiera al estudiante.</p>


            <p>
                <b>PRÉSTAMOS:</b> Si el estudiante obtiene un préstamo para pagar un programa educativo, tendrá la responsabilidad de devolver el importe total del préstamo más los intereses, menos el importe de cualquier reembolso. Si el estudiante tiene derecho a un préstamo garantizado por el gobierno federal o estatal y el estudiante incumple el pago del préstamo, pueden ocurrir las dos cosas siguientes:
            </p>

            <ul>
                <li>El gobierno federal o estatal o una agencia de garantía de préstamos puede tomar medidas contra el estudiante, incluida la aplicación de cualquier devolución del impuesto sobre la renta a la que la persona tenga derecho para reducir el saldo adeudado del préstamo.</li>

                <li>El estudiante no podrá optar a ninguna otra ayuda financiera federal para estudiantes en otra institución ni a ninguna otra ayuda gubernamental hasta que reembolse el préstamo.</li>
            </ul>
            <p>
                <strong>
                    AVISO: CUALQUIER TITULAR DE ESTE CONTRATO DE CRÉDITO AL CONSUMO ESTÁ SUJETO A TODAS LAS RECLAMACIONES Y DEFENSAS QUE EL DEUDOR PUDIERA HACER VALER CONTRA EL VENDEDOR O EL BIEN O LOS SERVICIOS OBTENIDOS DE CONFORMIDAD CON EL PRESENTE O CON EL PRODUCTO DEL MISMO. LA RECUPERACIÓN POR PARTE DEL DEUDOR EN VIRTUD DEL PRESENTE NO EXCEDERÁ LA CANTIDAD PAGADA POR EL DEUDOR EN VIRTUD DEL PRESENTE.
                </strong>
            </p>
            <h5>
                ASISTENCIA PARA EL EMPLEO: Servicios de Asesoramiento, Orientación Profesional y Colocación para Estudiantes
            </h5>
            <p>
                SDGKU ofrece servicios de asesoramiento y orientación sobre problemas personales o académicos y oportunidades de empleo, así como servicios de carrera, a los estudiantes que lo soliciten. La Institución no garantiza el empleo ni el salario inicial de sus graduados.
            </p>

            <div className="position-relative">
                {getInitials('page4')}
            </div>
        </div>
    )
})