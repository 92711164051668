import { useContext, useEffect, useState } from "react";
import Modal from "../modal/modal";
import { useHistory } from "react-router-dom";
import globalContext from "../../context/globalContext";
import authService from "../../services/auth.service";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import moment from "moment";

export const PasswordResetModal = (props: any) => {
    const { setLoading, user } = useContext(globalContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [daysRemaining, setDaysRemaining] = useState(10);
    const [closeDisabled, setCloseDisabled] = useState(false);
    const history = useHistory();

    const checkPastDue = () => {
        if (!user) return;
        const lastReset = moment(user.passwordResetDate);
        const now = moment();
        let difference = now.diff(lastReset, 'days')
        if (difference >= 90) {
            setDaysRemaining(100 - difference)
            setModalVisible(true)
            setCloseDisabled(true);
        }
    }

    const handleRedirect = async () => {
        setLoading(true);
        // get token for redirect to password reset
        let resp = await authService.generateRecoveryToken(user.id)
        setLoading(false);
        if (resp?.status === HttpStatusCode.Ok) {
            // Using token redirect to the password reset page
            history.push(`/public/password-change/?token=${resp.data['token']}`);
        }
        setModalVisible(false);
    }

    const handleDismissModal = () => {
        setModalVisible(false);
    }

    const getBody = () => {
        return (
            <>
                <p>Dear User, </p>
                <p>We take the security of our user information seriously. As part of our ongoing efforts we require our users to change their passwords every 90 days. You may dismiss this message for 10 days before you will not be allowed access to the site.</p>

                {daysRemaining > 0 ?
                    <p className="text-danger">You have {daysRemaining} days remaining.</p> :
                    <p className="text-danger">You must reset your password.</p>}

            </>
        )
    }

    useEffect(() => {
        checkPastDue();
    }, [user]);

    return (
        <Modal
            visible={modalVisible}
            title="Update your password"
            body={getBody()}
            cancelButtonText={daysRemaining > 0 ? "Dismiss" : ""}
            mainButtonText="Reset"
            onCancel={handleDismissModal}
            onClose={handleDismissModal}
            onAccept={handleRedirect}
            disableClose={closeDisabled}
        />

    )
}