import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const AgreementPage4 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement4)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <p>
                <b>Return to Title IV (R2T4) Policy:</b> San Diego Global Knowledge University (SDGKU) is required to apply Return to Title IV (R2T4) provisions to Federal Student Aid (FSA) recipients who withdraw from their program of study. This refund formula determines the amount of FSA funds a student has earned as of the last day of attendance or academic activity. The R2T4 policy is in addition to SDGKU institutional refund policy. If a student withdraws prior to completing more than 60 percent of the term (e.g., period of attendance), the percentage of FSA funds earned will equal the percentage of days completed in the period of attendance prior to the withdrawal date. After the 60% point in the period of attendance, a student has earned 100 percent of the FSA funds he or she was scheduled to receive during the period.
            </p>
            <p>For determining when the refund must be paid, the Title IV refund will be issued 45 days from the date of determination. If the student has received any Federal Title IV financial aid funds, the school is obligated to do a R2T4 even if a credit balance has already been issued to the student. For programs beyond the current period of attendance, if a student withdraws prior to the next period of attendance then all charges collected for the next period will be refunded. If a balance due results from the R2T4 calculation, the student will be responsible for the unpaid balance. If the student has received federal student Financial Aid funds, the student is entitled to a refund of the money’s not paid from federal student financial aid program funds.</p>

            
            <p>
                <b>LOANS:</b> If the student obtains a loan to pay for an educational program, the student will have the responsibility to repay the full amount of the loan plus interest, less the amount of any refund. If the student is eligible for a loan guaranteed by the federal or state government and the student defaults on the loan, both of the following may occur:
            </p>

            <ul>
                <li>The federal or state government or a loan guarantee agency may take action against the student, including applying any income tax refund to which the person is entitled to reduce the balance owed on the loan.</li>

                <li>The student may not be eligible for any other federal student financial aid at another institution or other government assistance until the loan is repaid.</li>
            </ul>
            <p>
                <strong>
                    NOTICE: ANY HOLDER OF THIS CONSUMER CREDIT CONTRACT IS SUBJECT TO ALL CLAIMS AND DEFENSE WHICH THE DEBTOR COULD ASSERT AGAINST THE SELLER OR GOOD OR SERVICES OBTAINED PURSUANT OR WITH THE PROCEEDS HEREOF. RECOVERY HEREUNDER BY THE DEBTOR SHALL NOT EXCEED AMOUNT PAID BY THE DEBTOR HEREUNDER.
                </strong>
            </p>
            <h5>
                EMPLOYMENT ASSISTANCE: Student Advising, Career, and Placement services
            </h5>
            <p>
                SDGKU provides advising and guidance services on personal or academic problems and employment opportunities, as well as career services, to students upon request. The Institution does not guarantee employment or the starting salary of its graduates.
            </p>

            <div className="position-relative">
                {getInitials('page4')}
            </div>
        </div>
    )
})