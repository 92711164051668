import http from "./http-common";

class DashboardService {
  async getStudentData(
    from_month: string,
    to_month: string,
    school_id: number
  ) {
    try {
      let response = await http.get(
        `/dashboard/students?from=${from_month}&to=${to_month}&school=${school_id}`
      );
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error while loading Student Data");
      return undefined;
    }
  }

  async getSchoolData(from_month: string, to_month: string, school_id: number) {
    try {
      let response = http.get(
        `/dashboard/schools?from=${from_month}&to=${to_month}&school=${school_id}`
      );
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error while loading School Data");
      return undefined;
    }
  }

  async getApplicationData(
    from_month: string,
    to_month: string,
    school_id: number
  ) {
    try {
      let response = http.get(
        `/dashboard/applications?from=${from_month}&to=${to_month}&school=${school_id}`
      );
      return response;
    } catch (error) {
      http.fa360.displayError(
        "Unexpected error while loading Application Data"
      );
      return undefined;
    }
  }

  async getAdminData(from_month: string, to_month: string) {
    try {
      let response = http.get(
        `/dashboard/totals?from=${from_month}&to=${to_month}`
      );
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error while loading Admin Data");
      return undefined;
    }
  }

  async getAllApplications(from_month: string, to_month: string) {
    try {
      let response = http.get(
        `/dashboard/all_applications?from=${from_month}&to=${to_month}`
      );
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected error while loading Admin Data");
      return undefined;
    }
  }
}

export default new DashboardService();
