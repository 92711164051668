import React from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

interface DataTableProps {
  columns: any[];
  data: any[];
  title: string;
  actions?: any[];
  options?: any;
  components?: any;
}

const DataTable: React.FC<DataTableProps> = ({ columns, data, title, actions, options, components }) => {
  const defaultMaterialTheme = createTheme();

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        actions={actions}
        options={options}
        components={components}
      />
    </ThemeProvider>
  );
};

export default DataTable;
