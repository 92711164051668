import { FunctionComponent, useEffect, useState } from 'react';

interface AcknowledgementProps {
  checked?: boolean;
  text: string;
  name: string;
  showArroaw?: boolean;
  onChange: (checked: boolean, name: string) => void;
}

const Acknowledgement: FunctionComponent<AcknowledgementProps> = ({
  checked = false,
  text = '',
  name = '',
  showArroaw = true,
  onChange = null,
}) => {

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const checkChanged = (e: any)=> {
    setIsChecked(e.target.checked);
    onChange?.(e.target.checked, e.target.name);
  };


  return (
    <div className="p-acknowledge">
      {showArroaw && !isChecked && <div className="arrow arrow--2">Check</div>}
      <input name={name} checked={isChecked} onChange={checkChanged} type="checkbox" className="form-check-input custom-checkbox"></input>
      <b>{text}</b>
    </div>
  );
};

export default Acknowledgement;
