import { useEffect, useState, useRef, useContext } from "react";

import "./notificationModal.scss";
import Modal from "../modal/modal";
import NotificationMessage from "./notification";
import emailService from "../../services/email.service";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import globalContext from "../../context/globalContext";
export interface StudentNotesProps {
  visible: boolean;
  updateNotifications: boolean;
  unreadNot: [];
  setUpdateNotifications: (value: boolean) => void;
  onClose?: () => void;
}

const NotificationModal = (props: StudentNotesProps) => {
  const [notifications, setNotifications] = useState([] as any);
  const notificationsEndRef = useRef<null | HTMLDivElement>(null);
  const { showAlert, setLoading } = useContext(globalContext);

  const loadCurrentNotifications = async () => {
    setNotifications(props.unreadNot);
  };

  const body = () => {
    return (
      <div className="container-comment-posting visible-scrollbar">
        <div className="notifications">
          {notifications?.map((t: any, index: any) => (
            <NotificationMessage data={t} key={index}></NotificationMessage>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadCurrentNotifications();
  }, []);

  useEffect(() => {
    if (props.setUpdateNotifications) {
      loadCurrentNotifications();
      props.setUpdateNotifications(false);
    }
  }, [props.updateNotifications]);

  useEffect(() => {
    notificationsEndRef.current?.scrollIntoView();
  }, [notifications]);

  return (
    <Modal
      body={body()}
      bodyTitle={
        <>
          <h5>Notifications</h5>
        </>
      }
      bodySubtitle="Unread replies from students"
      size="sm"
      mainClass="notification-modal"
      visible={props.visible}
      flyout={true}
      cancelButtonClass="btn-dark"
      onCancel={props.onClose}
      onClose={props.onClose}
    />
  );
};

export default NotificationModal;
