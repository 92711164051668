export const UserMessages = {
  errors: {
    student_duplicated_email: (
      <>
        <h5>Student Already Exist</h5>
        <p>
          We have a record for your email <br />
          Please contact the school if you need to update the provided information.
        </p>
      </>
    ),
  },
};


export  const EventReminderOptions = [
  {
    value: 1,
    label: '1 Day Before',
  },
  {
    value: 3,
    label: '3 Days Before',
  },
  {
    value: 7,
    label: '1 Week Before',
  },
  {
    value: 14,
    label: '2 Weeks Before',
  },
  {
    value: 21,
    label: '3 Weeks Before',
  },
  {
    value: 30,
    label: '1 Month Before',
  },
]; 
