import "./dataIcon.scss";

const DataIcon = (props: any) => {
  return (
    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-success tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke={props.info.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-bar-chart-2"
        >
          <line x1="18" y1="20" x2="18" y2="10"></line>
          <line x1="12" y1="20" x2="12" y2="4"></line>
          <line x1="6" y1="20" x2="6" y2="14"></line>
        </svg>
      </div>
      <div className="media-body">
        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">{props.info.label}</h6>
        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">{props.info.data}</h4>
      </div>
    </div>
  );
};

export default DataIcon;
