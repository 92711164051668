import { useContext, useEffect, useRef, useState } from "react";
import globalContext from "../../../../context/globalContext";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import { ProgramData } from "../types";
import {
  Step,
  Stepper,
  useStepper,
} from "../../../../components/shared/stepper";
import { SignedForm } from "../../../../models/Forms.model";
import { SDGKUProgram } from "../../../../models/Enums";
import { TokenError } from "../enrollment/sdgku.extrapages";
import { Header_Mobile as Header } from "../header";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { SignedFormKey } from "../../../../models/Forms.enum";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { getProgramData } from "../sdgkuController";
import { VaEducationPage1, VaEducationPage2 } from "./steps";
import "./vaEducation.scss";

interface Props {
  location: any;
}
export const VaEducationBenefits = ({ location }: Props) => {
  const NumOfSteps = 2;

  const { setLoading, showAlert } = useContext(globalContext);
  const [token, setToken] = useState<string>(null!);
  const [tokenError, setTokenError] = useState(false);

  const [canMoveNext, setCanMoveNext] = useState<boolean>(false);
  const [viewControllers, setViewControllers] = useState<SignedPageData[]>([]);
  const [programInfo, setProgramInfo] = useState<ProgramData>(null!);
  const { step, incrementStep, decrementStep } = useStepper(0, NumOfSteps);

  const pagesRef = useRef<any[]>([]);

  const loadInitialData = async () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const sudentId = params.get("id");

    if (token && sudentId) {
      loadForm(+sudentId, token);
    } else {
      showAlert("Invalid token", AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    try {
      const resp = await signedFormService.getFormData(
        studentId,
        SignedFormKey.VA_EDUCATION_BENEFIT_CERTIFICATION,
        token
      );
      if (resp?.status == HttpStatusCode.Ok) {
        const program = resp.data.programId;
        getProgramData(program, studentId, token).then(setProgramInfo);
        initializeControllers(resp.data, program, token);
      }
    } catch {
      showAlert("Invalid token", AlertType.Error);
      setTokenError(true);
    } finally {
      setLoading(false);
    }
  };

  const initializeControllers = (
    formData: SignedForm,
    program: SDGKUProgram,
    token: string
  ) => {
    if (!formData) return;

    for (let i = 0; i < NumOfSteps; i++) {
      const page = new SignedPageData(
        SignedFormKey.VA_EDUCATION_BENEFIT_CERTIFICATION,
        program,
        formData,
        token,
        setCanMoveNext
      );
      setViewControllers((prev) => [...prev, page]);
    }
  };

  const handleDecrementStep = () => {
    decrementStep();
  };

  const handleIncrementStep = async () => {
    if (await pagesRef.current[step].saveData()) {
      incrementStep();
    } else {
      showAlert("Error while saving data", AlertType.Error);
    }
  };

  const GetPage = () => (
    <>
      <VaEducationPage1
        visible={step === 0}
        program={programInfo}
        data={viewControllers[0]}
        ref={(el: any) => (pagesRef.current[0] = el)}
      />
      <VaEducationPage2
        visible={step === 1}
        program={programInfo}
        data={viewControllers[1]}
        ref={(el: any) => (pagesRef.current[1] = el)}
      />
    </>
  );

  const StepControls = () => (
    <div className="stepper-controls">
      <button
        disabled={step === 0}
        className="btn btn-sm btn-primary"
        onClick={handleDecrementStep}
      >
        <i className="fa fa-chevron-left" /> Back
      </button>
      <button
        disabled={step === 0 ? false : !canMoveNext}
        className="btn btn-sm btn-primary"
        onClick={handleIncrementStep}
      >
        {step !== NumOfSteps - 1 ? "Next" : "Complete"}{" "}
        <i className="fa fa-chevron-right" />
      </button>
    </div>
  );

  const GetContent = () => {
    if (step === NumOfSteps) {
      return (
        <div className="text-center">
          <h1>Thank you!</h1>
          <p>Your form has been submitted</p>
        </div>
      );
    }

    return (
      <>
        {GetPage()}
        {StepControls()}
      </>
    );
  };

  const LoadingView = () => (
    <div className="text-center">
      <h1>Loading data, please wait</h1>
    </div>
  );

  useEffect(() => {
    loadInitialData();
  }, []);

  // if (viewControllers.length === 0) return <LoadingView />;
  // if (tokenError) return <TokenError />;

  return (
    <div className="va-education">
      <div className="form-content">
        {/* <Stepper step={step}>
                    {Array(NumOfSteps)
                        .fill(1)
                        .map((x, index) => {
                            <Step key={index} />
                        })}
                </Stepper> */}
        <Header />
        {GetContent()}
      </div>
    </div>
  );
};
