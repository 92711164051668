import { forwardRef, useEffect, useImperativeHandle } from "react";
import { AgreementProps } from "../../types";
import usePageSignature from "../../../../../hooks/pageSignature";
import { SDGKUFormsPage } from "../../../../../models/Forms.enum";

export const VaEducationPage2 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials, getSignature, validateForm,  } = usePageSignature(data, true);

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Va_education2, true);
        }
    }));

    useEffect(() => {
        visible && window.scrollTo(0, 0);
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <div className="va-education-step2">
            <h3>STUDENT RESPONSIBILITIES</h3>
            <p><b>SCHOOL SEMESTER CERTIFICATION:</b> Students must submit a signed “VA Education Benefits Certification Request” prior to
                the start of EVERY PAYMENT PERIOD/TERM. All forms submitted electronically must be submitted from your student email
                account. Wet signatures and digital signatures can be substituted with your typed name in all signature blocks.</p>

            <p><b>VA MONTHLY CERTIFICATION:</b> Effective August 1st, 2021, students receiving VA Education Benefits must verify their
                enrollment monthly by Web Automated Verification of Enrollment (WAVE) or by Interactive Voice Response (IVR) by calling
                1-888-442-4551.</p>

            <p><b>EMAIL ACCOUNT</b> You have activated and regularly check your SDGKU student email account.</p>

            <p><b>CHANGE IN PROGRAM:</b> Programs can only be changed once and can only be done so by meeting with a Veterans
                Education Benefits School Certifying Official to formally complete and new student application and receive a new education
                plan. The program will not be updated until a new student enrollment agreement is signed and submitted top SDGKU by the
                VA student.</p>

            <p><b>APPROVED COURSES AND FEES:</b>You must register in courses that are required for your declared educational objective.
                The VA only pays benefits for courses that are part of your approved degree program that have not been previously
                successfully completed. You are responsible for reporting all prior credit and submitting those transcripts for transfer credit evaluation.
                You are aware that you will be financially responsible for payment of fees not covered by the VA. This includes NON-RESIDENT
                tuition and fees which must be paid prior to enrolling in additional classes.</p>

            <p><b>SCHEDULE ADJUSTMENT:</b>FEDERAL LAW REQUIRES YOU TO REPORT IMMEDIATELY TO SAN DIEGO GLOBAL
                KNOWLEDGE UNIVERSITY ANY CHANGES IN YOUR STUDENT STATUS (i.e. Adds, Drops, Withdrawals,
                Incompletes, last day of attendance, or contact info). Schedule changes MUST be done by a written request in person
                or an email from your student email address sent to info@sdgku.edu with your name, student ID #, and requesting the change.</p>

            <p><b>GRADES OF WITHDRAWAL:</b> You must report any grades of “W” (Withdrawal), “I” (Incomplete), or "F" (Failure) to
                the San Diego Global Knowledge University Veterans Education Benefits School Certifying Official. VA payment to you is
                based on “pursuit” of your program so you must be enrolled, attending, and successfully completing your courses to be
                eligible for VA education benefits. All grades of W, I, or F will be retroactively reported to the VA and may result
                in the retroactive loss of benefits. Any withdrawals reported retroactively may result in a debt with the VA as well as San
                Diego Global Knowledge University. Students are required to pay back a pro-rated tuition amount for classes dropped after
                the drop period.</p>

            <p><b>ACADEMIC PROBATION & DISMISSAL:</b> VA regulation states students using VA education benefits must maintain
                graduation standards (2.0 GPA or higher). In the event you receive a cumulative GPA below 2.0, you will be placed on
                Academic Probation. If you remain on Academic Probation for more than two consecutive terms (fall, spring, and/or summer)
                without having an individual term at or above a 2.0 GPA, you will be Academically Dismissed and ineligible to use VA
                education benefits at San Diego Global Knowledge University for one semester before being reinstated. These VA Academic
                Probation and Dismissal guidelines are not to be confused with the guidelines set forth by San Diego Global Knowledge
                University. Effective Fall 2014, students placed on academic probation for a 2nd consecutive term will result in the loss
                of priority registration until the student is no longer on probation status.</p>

            <h3>ACKNOWLEDGEMENT OF RESPONSIBILITIES</h3>
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page2-1')}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p className="m-0">
                            <div className="position-relative">
                                I, <b>{data.formData.student.firstName + ' ' + data.formData.student.lastName}</b> have read and understand the above student responsibilities.
                            </div>
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page2-2', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            I am aware I will be financially responsible for payment of fees not covered by the VA including Non-Resident tuition and fees.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page2-3', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            I am responsible for all VA debts resulting from reductions or termination of enrollment, even if the payment was directly submitted on my behalf.                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page2-4', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            I am aware that changes in my enrollment may alter the payment the VA will award me.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page2-5', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            I request to be certified to receive my VA education benefits for this term.
                        </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4 col-sm-12 order-sm-2 position-relative">
                        {getInitials('page2-6', true)}
                    </div>
                    <div className="col-md-8 col-sm-12 order-md-2">
                        <p>
                            I have the right to request a copy of this form for my record.
                        </p>
                    </div>
                </div>
            </div>
            <div className="signature">
                {getSignature()}
            </div>


        </div>
    );
});