import { CheckboxListItemProps } from "../../../components/shared/Input/checkBox";

export const gender = [
  { value: 0, label: "Decline to Answer" },
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Other" },
];

export const simpleHearAboutUsOptions = [
  { value: "Movie Theater", label: "Movie Theater" },
  { value: "Online School Search", label: "Online School Search" },
  { value: "PA Website", label: "PA Website" },
  { value: "Referral", label: "Referral" },
  { value: "Tucson Weekly", label: "Tucson Weekly" },
  { value: "Yellow Pages", label: "Yellow Pages" },
  { value: "Word Of Mouth", label: "Word of Mouth" },
  { value: "Other", label: "Other" },
];

export const paymentOptions = [
  { field: "payFinancialAid", label: "Title IV Funds (Federal Financial Aid)" },
  { field: "payEmployeeTuition", label: "Employee Tuition Assistance" },
  { field: "payMilitaryBenefits", label: "VA Benefits" },
  { field: "payOther", label: "Other (Installments / Private Pay)" },
  { field: "payPimaCounty", label: "Pima County One-Stop Grant" },
  { field: "payVocationalRehab", label: "Vocational Rehabilitation Grant" },
  { field: "payTribalGrant", label: "Tribal Grant" },
] as CheckboxListItemProps[];

export const educationLevelOptions = [
  { value: "GED", label: "GED", level: 1 },
  { value: "High School", label: "High School", level: 2 },
  { value: "Some College", label: "Some College", level: 3 },
  { value: "College Degree", label: "College Degree", level: 4 },
  { value: "Vocational", label: "Vocational Cert.", level: 5 },
];

export const convictedOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
