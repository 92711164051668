import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '../../../../components/shared/Input/textField';
import globalContext from '../../../../context/globalContext';
import usePageSignature from '../../../../hooks/pageSignature';
import { AlertType } from '../../../../models/AlertType.enum';
import { ApplicationForm } from '../../../../models/ApplicationForm.model';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import { COBAProgram, COBAProgramFromName } from '../../../../models/Enums';
import { COBAFormsPage, SignedFormKey } from '../../../../models/Forms.enum';
import { SignedForm } from '../../../../models/Forms.model';
import signedFormService from '../../../../services/signedForm.service';
import { SignedPageData } from '../../../../viewControllers/signedPage';
import { Mono, Multiple } from '../../sdgku/facts';
import { Fact } from '../../sdgku/facts/fact_t';
import { getFactsheet } from './service';
import { Cost, Federal_Loan, Gainfully_Employed, Graduation_Rates, Job_Placement, Pass_Rates, Salary_Wage, Students_Completing } from './tables.info';
import { Factsheet } from './types';

import './cobaFactSheet.scss';
import FactSheetCompleted from './factSheetCompleted';

interface Props {
  location: {
    search: string;
  };
}

export const CobaFactsheetPage = ({ location }: Props) => {
  const [tokenError, setTokenError] = useState(false);
  const { setLoading, showAlert } = useContext(globalContext);
  const [completed, setCompleted] = useState(false);
  const [facts, setFacts] = useState<Factsheet>();

  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { saveForm, getInitials, getSignature } = usePageSignature(viewController);

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<ApplicationForm>();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const sudentId = params.get('id');

    if (token && sudentId) loadForm(+sudentId, token);
    else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    const resp = await signedFormService.getFormData(studentId, SignedFormKey.FACTSHEET, token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      const program: COBAProgram = COBAProgramFromName(resp.data.programName); // TODO: remove once we store program id on Application Forms
      getFactsheet(program).then(setFacts);
      initializeViewController(resp?.data, program, token);
    }
  };

  const initializeViewController = (formData: SignedForm, program: COBAProgram, token: string) => {
    if (!formData) return;

    setValue('student.firstName', formData.student.firstName + " " + formData.student.lastName);
    setViewController(new SignedPageData(SignedFormKey.FACTSHEET, program, formData, token, setEnableSubmit));
  };

  const submitForm = async () => {
    if (await saveForm(COBAFormsPage.Factsheet, true)) setCompleted(true);
    else showAlert('Error while saving. Please verify all fields and try again', AlertType.Error);
  };

  const getHours = () => {
    switch (viewController?.program) {
      case COBAProgram.BARBER:
        return <span className="program-hours"> - 1,000 Hours</span>;
      case COBAProgram.BARBER_HYBRID:
        return <span className="program-hours"> - 1,000 Hours</span>;
      case COBAProgram.COSMETOLOGY:
        return <span className="program-hours"> - 1,000 Hours</span>;
      default:
        return <span className="program-hours"> - 600 Hours</span>;
    }
  };

  const pageContent = () => {
    return (
      <>
        <h3 className="page-subtitle fs-2 mb-4 text-center">
          PLEASE READ AND SIGN THE:
        </h3>

        <h2 className="page-title fs-2 mb-4 text-center">
          SCHOOL PERFORMANCE FACT SHEET <br />
          CALENDAR YEARS: 2021 & 2022
        </h2>

        <h3 className="text-center mb-5">
          {`${viewController?.formData.programName}`}
          {getHours()}
        </h3>

        <div className="container text-center">
          <Mono {...Graduation_Rates} data={facts?.Graduation_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main')}</span>
            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Mono {...Students_Completing} data={facts?.Completing_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main-2', true)}</span>
            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Mono {...Job_Placement} data={facts?.Placement_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main-3', true)}</span>

            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Multiple {...Gainfully_Employed} datas={facts?.Gainfully_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center relative`}>
            <span className="position-relative">{getInitials('main-4', true)}</span>

            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Mono {...Pass_Rates} data={facts?.Pass_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main-5', true)}</span>

            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Multiple {...Salary_Wage} datas={facts?.Salary_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main-6', true)}</span>

            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Fact {...Cost} data={facts?.Costs_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main-7', true)}</span>

            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <div className="container text-center">
          <Mono {...Federal_Loan} data={facts?.Federal_Facts} />
          <div className={`d-flex flex-column align-items-center justify-content-center`}>
            <span className="position-relative">{getInitials('main-8', true)}</span>

            <p>
              <b>Initial only after you have had sufficient time to read and understand the information.</b>
            </p>
          </div>
        </div>

        <p>
          This fact sheet is filed with the Bureau for Private Postsecondary Education. Regardless of any information you may have relating to completion rates, placement rates, starting salaries, or license exam passage rates, this fact
          sheet contains the information as calculated pursuant to state law. <br />
          Any questions a student may have regarding this fact sheet that have not been satisfactorily answered by the Institution may be directed to the Bureau for Private Postsecondary Education at 1747 N. Market Blvd, Suite 225,
          Sacramento, CA 95834, www.bppe.ca.gov, toll-free telephone number (888) 370-7589 or by fax (916) 263-1897.
        </p>
        <div style={{ maxWidth: '50ch' }}>
          <TextField disabled errors={errors} label="Student Name - Print" field="student.firstName" type="text" register={register} />
        </div>
        <div className="pad">{getSignature()}</div>

        <Definitions />
        <CancelRight />

        <div className="text-center pb-2">
          <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
            Submit Signed Form
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="cobaFactSheet">
      <div className="preapp-form container cobaDisclosure-form">
        <div className="row">
          <div className="col-9">
            <img src="/static/coba/logo-coba.png" alt="coba logo"></img>
          </div>
          <div className="col-3">
            <p className="text-center">
              Coba Academy <br />
              663 N. Euclid St., Anaheim, CA 92801 | 714-533-1400 | www.coba.edu <br />
            </p>
          </div>
        </div>
      </div>

      <div className="doc-content pt-4">
        {tokenError ? <TokenError /> : null}
        {completed ? <FactSheetCompleted /> : null}
        {!tokenError && !completed ? pageContent() : null}
      </div>
    </div>
  );
};

const TokenError = () => {
  return <h5 className="error-token">Your Token seems invalid or it expired, please get in contact with the university personnel to request a new one if you haven't completed the form.</h5>;
};

const Definitions = () => {
  return (
    <>
      <h3 className="text-center">
        <b>
          <u>Definitions</u>
        </b>
      </h3>
      <p>This program is new. Therefore, the number of students who graduate, the number of students who are placed, or the starting salary you can earn after finishing the educational program are unknown at this time. Information regarding general salary and placement statistics may be available from government sources or from the institution but is not equivalent to actual performance data. <b>This program began on 8/2/2023. As of 12/1/2026, two full years of data for this program will be available.</b></p>
      <ol>
        <li>
          “Number of Students Who Began the Program” means the number of students who began a program who were scheduled to complete the program within 100% of the published program length within the reporting calendar year and excludes all
          students who cancelled during the cancellation period.
        </li>
        <li>“Students Available for Graduation” is the number of students who began the program minus the number of students who have died, been incarcerated, or been called to active military duty.</li>
        <li>“Number of On-time Graduates” is the number of students who completed the program within 100% of the published program length within the reporting calendar year.</li>
        <li>“On-time Completion Rate” is the number of on-time graduates divided by the number of students available for graduation.</li>
        <li>“150% Graduates” is the number of students who completed the program within 150% of the program length (includes on-time graduates).</li>
        <li>
          “150% Completion Rate” is the number of students who completed the program in the reported calendar year within 150% of the published program length, including on-time graduates, divided by the number of students available for
          graduation.
        </li>
        <li>“Graduates Available for Employment” means the number of graduates minus the number of graduates unavailable for employment.</li>
        <li>
          “Graduates Unavailable for Employment” means the graduates who, after graduation, die, become incarcerated, are called to active military duty, are international students that leave the United States or do not have a visa allowing
          employment in the United States, or are continuing their education in an accredited or bureau-approved postsecondary Institution.
        </li>
        <li>
          “Graduates Employed in the Field” means graduates who beginning within six months after a student completes the applicable educational program are gainfully employed, whose employment has been reported, and for whom the
          Institution has documented verification of employment. For occupations for which the state requires passing an examination, the six months period begins after the announcement of the examination results for the first examination
          available after a student completes an applicable educational program.
        </li>
        <li>“Placement Rate Employed in the Field” is calculated by dividing the number of graduates gainfully employed in the field by the number of graduates available for employment.</li>
        <li>“Number of Graduates Taking Exam” is the number of graduates who took the first available exam in the reported calendar year.</li>
        <li>“First Available Exam Date” is the date for the first available exam after a student completed a program.</li>
        <li>“Passage Rate” is calculated by dividing the number of graduates who passed the exam by the number of graduates who took the reported licensing exam.</li>
        <li>“Number Who Passed First Available Exam” is the number of graduates who took and passed the first available licensing exam after completing the program.</li>
        <li>“Salary” is as reported by graduate or graduate’s employer.</li>
        <li>“No Salary Information Reported” is the number of graduates for whom, after making reasonable attempts, the school was not able to obtain salary information.</li>
      </ol>
    </>
  );
};

const CancelRight = () => {
  return (
    <>
      <h3 className="text-center">
        <b>
          <u>STUDENT’S RIGHT TO CANCEL</u>
        </b>
      </h3>
      <p>
        The student has the right to cancel the enrollment agreement and obtain a refund of charges paid through attendance at the first-class session (first day of classes), or the seventh calendar day after enrollment (seven calendar days
        from the date when enrollment agreement was signed), whichever is later. The application fee is a non-refundable item. Equipment, books, supplies, tools, uniforms, kits, and any other items issued and received by the student would
        not be returnable. Once received by the student it will belong to the student and will represent a liability to the student.
        <br />
        <br />
        The notice of cancellation shall be in writing and submitted directly to a School Official. A withdrawal may be initiated by the student’s written notice or by the Institution due to student’s academics or conduct, including, but
        not necessarily limited to student’s lack of attendance.
      </p>
    </>
  );
};
