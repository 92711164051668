import "./documentCheckList.scss";
import { StudentChecklist } from "../../../models/Checklist.model";
import { Bookmark as ICheckList } from "react-feather";
import { useState } from "react";
import Loader from "react-loader-spinner";

const InternalCheckListCard = (props: any) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="mg-b-10" key={props.checklist.id}>
      <h4 className="tx-normal tx-rubik tx-18 tx-spacing--1 mg-b-5">
        <ICheckList className="icon" />
        {props.checklist.name}
        <button className="btn btn-sm btn-link btn-show-details" onClick={() => setVisible(!visible)}>
          {visible ? "Hide" : "Show"}
        </button>
      </h4>

      {visible &&
        props.checklist.documents.sort((a: any, b: any) => a.documentTypeName.localeCompare(b.documentTypeName)).map((document: any) => (
          <div className="check-document" key={document.documentTypeId}>
            <input className="input-check document-name" type="checkbox" checked={document.checked} disabled />
            <label>{document.documentTypeName}</label>
          </div>
        ))}
    </div>
  );
};

const DocumentCheckList = ({ checklists, loading }: { checklists: StudentChecklist[], loading: boolean }) => {
  let sortedChecklists = checklists.sort((a, b) => a.name.localeCompare(b.name))
  return (
    <div className="document-list-card">
      <div className="card">
        <div className="card-header">
          <div className="title">
            <h6 className="mg-b-0">Document Checklists</h6>
          </div>
        </div>
        {!loading ? <div className='card-body'>
          {sortedChecklists.map((checklist) => (
            <InternalCheckListCard key={checklist.id} checklist={checklist} />
          ))}
        </div> :
          <div className='loading-body'>
            <Loader type="Circles" color="#001737" height="80" width="80" />
          </div>
        }
      </div>
    </div >
  );
};

export default DocumentCheckList;