import { useState, useEffect, useContext } from "react";
import Select from "react-select";

import Modal from "../modal/modal";
import FileUpload, { FileUploadProps } from "../shared/file-upload/fileUpload";

import { DocumentTemplate } from "../../models/Document.model";
import DocumentTemplateService from "../../services/documenTemplate.service";
import DocumentService from "../../services/document.service";
import { toast } from "react-toastify";

interface DocumentTypeDropdownItem {
  value: string;
  label: string;
}

const DocumentTemplateModal = (props: any) => {
  const {
    existingDocument,
    setSelectedDocumentTemplate,
    setDocumentTemplateList,
    optionSelected,
    isModalVisible,
    closeModal,
    schools,
    loadData,
  } = props;
  const [documentTemplate, setDocumentTemplate] = useState<DocumentTemplate>(existingDocument || ({} as DocumentTemplate));
  const [documentTypesOptions, setDocumentTypeOptions] = useState<DocumentTypeDropdownItem[]>();


  const handleModalTitle = (): string => {
    if (optionSelected === "create") {
      return "Add new document template";
    } else if (optionSelected === "update") {
      return "Update";
    } else {
      return "Delete";
    }
  };

  const getDocumentTypes = async () => {
    const response = await DocumentService.getDocumentTypes();
    const documentTypesModified: DocumentTypeDropdownItem[] = response?.data.map((data: any) => ({
      label: data?.name,
      value: data?.id,
    }));

    setDocumentTypeOptions(documentTypesModified.sort((a, b) => (a.label < b.label ? -1 : 1)));
  };

  useEffect(() => {
    getDocumentTypes();
    if (schools.length === 2) {
      setDocumentTemplate({ ...documentTemplate, school: schools[1] })
    }
  }, []);

  const getSelectedSchool = () => {
    if (!documentTemplate?.school) return null;
    if (typeof documentTemplate.school === "number") {
      let obj = {
        label: schools.find((x: any) => x.value === documentTemplate.school)?.label,
        value: documentTemplate.school,
      };
      return obj;
    }
  };

  const getSelectedDocumentType = () => {
    if (!documentTemplate?.documentType) return null;
    // if (typeof documentTemplate.documentType === "number") {
    if (typeof documentTemplate.documentType) {
      const obj = documentTypesOptions?.find((x: any) => x.label == documentTemplate.documentType?.name);

      let modifiedObject = { label: obj?.label, value: obj?.value };

      return modifiedObject;
    }
  };

  const handleModalBody = () => {
    switch (optionSelected) {
      case "create":
      case "update":
        return addDocumentBody();
      default:
        return "";
    }
  };

  const handleOnAccept = async () => {
    switch (optionSelected) {
      case "create":
        saveNewDocument();
        break;
      case "update":
        updateDocument();
        break;
      default:
        closeModal();
    }
  };

  const saveButtonDisabled = () => {

    if (!documentTemplate?.name || !documentTemplate?.school || !documentTemplate?.documentType || !documentTemplate?.file) return true;
    return false;
  };

  const saveNewDocument = async () => {
    const payload = {
      ...documentTemplate,
      school: documentTemplate?.school?.value,
      documentType: documentTemplate?.documentType?.id,
    };
    let resp = await DocumentTemplateService.addDocumentTemplate(payload);
    handleResponse(resp, "added");
  };

  const updateDocument = async () => {
    const payload = {
      ...documentTemplate,
      school: documentTemplate?.school?.value,
      documentType: documentTemplate?.documentType?.id,
    };
    if (typeof payload.file == "string") {
      delete payload["file"];
    }
    let resp = await DocumentTemplateService.updateDocumentTemplate(payload);
    handleResponse(resp, "updated");
  };

  const handleResponse = async (response: any, action: string) => {
    if (response?.status === 200 || response?.status === 201 || response.status === 204) {
      toast.dark(`✔️ Document templated ${action}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      await loadData();
      closeModal();
    } else {
      toast.dark("❌ Something went wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      console.error(response?.data);
    }
  };

  const OnChageDocumentType = (e: any) => {
    setDocumentTemplate({
      ...documentTemplate,
      name: e?.label,
      documentType: { name: e?.label, id: e?.value },
    });

  };

  const addDocumentBody = () => {
    return (
      <div className="template-form">

        <div className="input-wrapper">
          <label>Document Type:</label>
          <Select
            className="select wd-100p"
            placeholder="Select document type"
            options={documentTypesOptions as any[]}
            value={getSelectedDocumentType()}
            onChange={(e) => OnChageDocumentType(e)}
          />
        </div>

        <div className="input-wrapper">
          <label>Name:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={documentTemplate.name ?? ""}
            maxLength={50}
            onChange={(e) => setDocumentTemplate({ ...documentTemplate, name: e.target.value })}
          />
        </div>
        {
          schools.length < 3 ? (
            ''
          ) : (
            <div className="input-wrapper">
              <label>School:</label>
              <Select
                className="select wd-100p"
                options={schools}
                value={getSelectedSchool()}
                onChange={(e) =>
                  setDocumentTemplate({ ...documentTemplate, school: { ...e } })
                }
              />
            </div>
          )
        }


        <div className="input-wrapper">
          <label>Revision:</label>
          <input
            type="text"
            className="form-control form-control-sm"
            value={documentTemplate.revision}
            maxLength={254}
            onChange={(e) => setDocumentTemplate({ ...documentTemplate, revision: e.target.value })}
          />
        </div>

        <div className="input-wrapper" style={{ display: "flex", justifyContent: "center" }}>
          <FileUpload
            status={0}
            // message= {optionSelected === 'create' ? '' :  `${data?.name}`}
            onChange={(file: any) => {
              setDocumentTemplate({ ...documentTemplate, file: file });
            }}
          />
        </div>
      </div>
    );
  };


  return (
    <Modal
      title={handleModalTitle()}
      body={handleModalBody()}
      visible={isModalVisible}
      onClose={closeModal}
      cancelButtonText="Cancel"
      mainButtonText="Confirm"
      mainButtonClass="btn-primary"
      mainButtonDisabled={saveButtonDisabled()}
      onCancel={closeModal}
      onAccept={() => handleOnAccept()}
    />
  );
};

export default DocumentTemplateModal;
