import { useForm } from 'react-hook-form';
import usePageSignature from '../../../../../hooks/pageSignature';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import TextField from '../../../../../components/shared/Input/textField';
import { CobaFinancialAidPageProps } from '../cobaFinancialAidPageProps';
import { COBAFormsPage } from '../../../../../models/Forms.enum';
import SelectField from '../../../../../components/shared/Input/selectField';
import { SignedFormStudent } from '../../../../../models/Forms.model';
import { Student } from '../../../../../models/Student.model';
import { MASKS } from '../../../../../components/shared/Input/patterns';
import { State } from '../../../../../models/common/State.model';
import commonService from '../../../../../services/common.service';
interface extraData {
    driverState: string;
    numberLicense: string;
    homePhone: string;
    workPhone?: string;

    permanentAddressStreet: string;
    permanentAddressCity: string;
    permanentAddressState: string;
    permanentAddressZip: string;

    ref1Name: string;
    ref1Relationship: string;
    ref1Address: string;
    ref1Phone: string;

    ref2Name: string;
    ref2Relationship: string;
    ref2Address: string;
    ref2Phone: string;
}
interface FinancialPage1Form {
    student: Student;
    completionDate: string;
    extraData: extraData;
}

export const FinancialAidPage1 = forwardRef(({ data, visible }: CobaFinancialAidPageProps, ref) => {
    const { register, formState: { errors, isValid }, setValue, control, trigger, watch, getValues } = useForm<FinancialPage1Form>();
    const [extraData, setExtraData] = useState<extraData>(null!);
    const { saveForm, getSignature, validateForm, setIsCustomValid, setRequiredCustomValidation, signatureData } = usePageSignature(data);
    const [student, setStudent] = useState<SignedFormStudent>(null!);
    const [states, setStates] = useState<State[]>([]);

    const loadStates = async () => {
        const states = await commonService.getStates();
        setStates(states);
    }

    useImperativeHandle(ref, () => ({
        async saveData() {
            let data: extraData = null!;
            if (extraData) {
                data = { ...extraData };
                data.driverState = getValues('extraData.driverState');
                data.permanentAddressState = getValues('extraData.permanentAddressState');
            } else {
                data = extraData;
                data.driverState = "";
                data.permanentAddressState = "";
            }
            return await saveForm(COBAFormsPage.FinancialAid1, false, data);
        },
    }));

    const formValues = watch();

    useEffect(() => {
        // when the form values change, validate the form
        // trigger();    
        setIsCustomValid(isValid); // if the form is valid, notify the page signature controller
    }, [formValues])

    useEffect(() => {
        // validate the form fields when the signature changes
        manualFormValidation();
    }, [signatureData]);

    const manualFormValidation = async () => {
        if (signatureData) { // if there is a signature
            const isItValidNow = await trigger();
            if (!isItValidNow) { // if the form is not valid
                // start a timer that will trigger the validation every second
                setInterval(() => {
                    trigger();
                }, 1000);
            }
        }
    };

    const inputChanged = (e: any) => {
        setExtraData({ ...extraData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setRequiredCustomValidation(true); // the form required setIsCustomValid to be called with true
        setValue("student.firstName", data.formData.student.firstName);
        setValue("student.middleName", data.formData.student.middleName!);
        setValue("student.lastName", data.formData.student.lastName);
        setValue("student.ssn", data.formData.student.ssn!);

        setValue("student.birthDate", data.formData.student.birthDate!);
        setValue("student.email", data.formData.student.email);
        setValue("student.phone", data.formData.student.phone);
        data.formData.student.workPhone ? setValue("student.workPhone", data.formData.student.workPhone!) : setValue("student.workPhone", '');

        setValue("student.addressStreet", data.formData.student.addressStreet!);
        setValue("student.addressCity", data.formData.student.addressCity!);
        setValue("student.addressState", data.formData.student.addressState!);
        setValue("student.addressZip", data.formData.student.addressZip!);
        setStudent(data.formData.student);
    }, [])

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                window.scrollTo(0, 0);
                validateForm();
            }, 500);
            loadStates();
        }
    }, [visible])

    if (!visible) return null


    return (
        <div className='financialAid-page1'>
            <div className="title" >
                <h3>FINANCIAL AID</h3>
                <h4>STUDENT DATA FORM</h4>
            </div>
            <div className="container-fluid-mb-3 form-group">
                <div className="row">
                    <div className="col-4">
                        <TextField
                            disabled
                            errors={errors}
                            label="First Name"
                            field='student.firstName'
                            type='text'
                            register={register}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            disabled
                            errors={errors}
                            label="Middle Name"
                            field='student.middleName'
                            type='text'
                            register={register}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            disabled
                            errors={errors}
                            label="Last Name"
                            field='student.lastName'
                            type='text'
                            register={register}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <TextField
                            disabled
                            errors={errors}
                            label="SSN"
                            field='student.ssn'
                            type='text'
                            register={register}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            disabled
                            errors={errors}
                            label="Birthday"
                            field='student.birthDate'
                            type='text'
                            register={register}
                        />
                    </div>
                    <div className="col-4">
                        <SelectField
                            field="gender"
                            label="Gender"
                            errors={errors}
                            control={control}
                            disabled
                            initialValue={Number(student?.gender)}
                            options={[
                                { label: "Male", value: 1 },
                                { label: "Female", value: 2 },
                                { label: "Prefer not to say", value: 3 },
                            ]}
                        />

                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <TextField
                            errors={errors}
                            label="Driver license/ State ID #:"
                            field='numberLicense'
                            type='text'
                            register={register}
                            onChange={inputChanged}
                            rules={{ required: true }}
                        />
                    </div>
                    <div className="col-4">
                        <SelectField
                            field="extraData.driverState"
                            label="State Licence"
                            errors={errors}
                            control={control}
                            rules={{ required: true }}
                            labelKey="name"
                            valueKey="id"
                            options={states.map(s => ({ id: s.name, name: s.name }))}
                        />
                    </div>
                </div>

                <hr />

                <div className="address-section">
                    <div className="address-left">
                        <h3>Local Address <p style={{ fontSize: '1rem' }}>(For mailing purpose)</ p></h3>
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    disabled
                                    errors={errors}
                                    label="Street Address"
                                    field='student.addressStreet'
                                    type='text'
                                    register={register}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    disabled
                                    errors={errors}
                                    label="City"
                                    field='student.addressCity'
                                    type='text'
                                    register={register}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    disabled
                                    errors={errors}
                                    label="State"
                                    field='student.addressState'
                                    type='text'
                                    register={register}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    disabled
                                    errors={errors}
                                    label="Zip"
                                    field='student.addressZip'
                                    type='text'
                                    register={register}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="address-right">
                        <h3>Permanent Address</h3>
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    errors={errors}
                                    rules={{ required: true }}
                                    label="Street Address"
                                    field='permanentAddressStreet'
                                    type='text'
                                    register={register}
                                    onChange={inputChanged}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    errors={errors}
                                    rules={{ required: true }}
                                    label="City"
                                    field='permanentAddressCity'
                                    type='text'
                                    register={register}
                                    onChange={inputChanged}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <SelectField
                                    field="extraData.permanentAddressState"
                                    label="State"
                                    errors={errors}
                                    control={control}
                                    rules={{ required: true }}
                                    labelKey="name"
                                    valueKey="id"
                                    options={states.map(s => ({ id: s.name, name: s.name }))}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    errors={errors}
                                    rules={{ required: true }}
                                    label="Zip"
                                    field='permanentAddressZip'
                                    type='text'
                                    register={register}
                                    onChange={inputChanged}
                                    mask={MASKS.zipCodeMask}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className='contact-section'>

                    <h3>Contact Information</h3>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                disabled
                                errors={errors}
                                label="Email"
                                field='student.email'
                                type='text'
                                register={register}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                disabled
                                errors={errors}
                                label="Phone"
                                field='student.phone'
                                type='text'
                                register={register}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Home Phone"
                                field='homePhone'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                                mask={MASKS.USPhoneMask}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                disabled={student?.workPhone ? true : false}
                                label="Work Phone"
                                field={student?.workPhone ? 'student.workPhone' : 'workPhone'}
                                type='text'
                                onChange={inputChanged}
                                register={register}
                                mask={MASKS.USPhoneMask}
                            />
                        </div>
                    </div>

                </div>
                <hr />
                <div className="references-section">

                    <h3>References</h3>
                    <p>Please provide two personal references below which may be contacted by Coba Academy:</p>
                    <br />
                    <p style={{ fontWeight: '600' }}>Reference 1:</p>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Name"
                                field='ref1Name'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Relationship"
                                field='ref1Relationship'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Address"
                                field='ref1Address'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Phone"
                                field='ref1Phone'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                                mask={MASKS.USPhoneMask}
                            />
                        </div>
                    </div>
                    <p style={{ fontWeight: '600' }}>Reference 2:</p>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Name"
                                field='ref2Name'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Relationship"
                                field='ref2Relationship'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Address"
                                field='ref2Address'
                                type='text'
                                register={register}
                                onChange={inputChanged}

                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                rules={{ required: true }}
                                errors={errors}
                                label="Phone"
                                field='ref2Phone'
                                type='text'
                                register={register}
                                onChange={inputChanged}
                                mask={MASKS.USPhoneMask}
                            />
                        </div>
                    </div>
                </div>


                <b>By signing this form, I certify that the above information is true and correct</b>

                <div className="row">
                    <div className="col-4">
                        {getSignature()}
                    </div>
                </div>
            </div>
        </div>
    )
});