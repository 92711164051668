import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SchoolID } from '../../models/Enums';
import { Activity } from '../../models/Activity.model';
import { Check as ICheck } from 'react-feather';
import { HttpStatusCode } from '../../models/common/HttpStatusCode.enum';
import activityService from '../../services/activity.service';
import PaginatedList from '../shared/paginated-list/paginatedList';

import './activitiesComponent.scss';
import SearchBox from '../shared/searchBox/searchBox';
import Select from 'react-select';

import { SelectOption } from '../../models/common/Base.model';
import { School } from '../../models/School.model';
import DateRangePicker from '../shared/dateRangePicker/dateRangePicker';
import { DateComponent } from '@fullcalendar/react';
import globalContext from '../../context/globalContext';
import dateService from '../../services/date.service';

const moment = require('moment-timezone');

interface ActivitiesProps {
  schools: School[]
}

const Activities: FunctionComponent<ActivitiesProps> = ({ schools }: ActivitiesProps) => {
  const { saveFilter, getFilter } = useContext(globalContext);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState();
  const [search, setSearch] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState() as any;
  const [selectedToDate, setSelectedToDate] = useState() as any;

  const defaultSchoolOption = { value: undefined, label: '- Any School -' };

  const loadActivities = async (fromDate?: any, toDate?: any) => {
    setLoading(true);
    let response;
    if (fromDate || toDate) {
      response = await activityService.getActivityDataByDate(fromDate, toDate);
    } else {
      response = await activityService.getActivityData();
      // response = await activityService.getActivityDatatest();
    }
    setActivities(response?.data || []);
    setLoading(false);
  };

  const activitySearch = (activity: Activity) => {
    let filter = getFilter() as any;
    let pass = true;
    let studentPass;
    let userPass;

    if (filter.search) {
      let src = filter.search.toLowerCase()


      if (activity.student) {
        let studentFullName = activity.student.firstName + " " + activity.student.lastName

        studentPass = activity.student.firstName.toLowerCase().includes(src) || activity.student.lastName.toLowerCase().includes(src) || studentFullName.toLowerCase().includes(src)
      }
      if (activity.user) {
        userPass = activity.user.email.toLowerCase().includes(src);
      }

      if (studentPass || userPass) {
        pass = true
      }
      else { pass = false }

    }
    if (filter.school && filter.school?.value) {
      pass = pass && activity.school === +filter.school.value;
    }

    return pass
  }

  const formatActivityData = () => {
    let sortedActivity = activities.sort((a, b) => moment(b.createdOn) - moment(a.createdOn));
    let filteredActivity = sortedActivity.filter(activitySearch)
    let data = filteredActivity.map((i) => (
      <div key={i.id} className="custom-activity-item">
        <p>{moment(i.createdOn).format('MMM Do, YYYY  hh:mm a')}</p>
        <p>{i.title}</p>
        <Link className="title" to={'/student/overview/' + i.student?.id}>
          {i.student?.firstName + ' ' + i.student?.lastName}
        </Link>
        <p>{i.user?.email}</p>
        <p>{SchoolID[i.school || 0]}</p>
        <ICheck className="trash-icon" onClick={() => handleDeactivateActivity(i)} />
      </div>
    ));
    return data;
  };

  const getSchoolOptions = () => {
    let options = schools.map((school) => ({ value: school.id, label: school.shortName }));
    return [defaultSchoolOption, ...options] as SelectOption[];
  };

  const selectedSchoolChanged = (option: any) => {
    saveFilter('school', option)
    setSelectedSchool(option);
  }

  const searchTextChanged = (text: string) => {
    saveFilter('search', text)
    setSearch(text)

  }
  const handleMonthChange = (fromDate: string, toDate: string) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);

    loadActivities(fromDate, toDate)
  };

  const createFilters = () => {
    return (
      <div className='filters'>
         {/*<Select
          className='school-filter'
          placeholder="Filter by school"
          options={getSchoolOptions()}
          value={selectedSchool}
          onChange={selectedSchoolChanged}
        />*/}
        <DateRangePicker
          sideLabel
          fromValue={selectedFromDate}
          toValue={selectedToDate}
          sendRange={(from: string, to: string) => handleMonthChange(from, to)}
        ></DateRangePicker>
        <SearchBox
          className='activity-search'
          label=""
          placeholder="Search student"
          value={search}
          onChange={searchTextChanged} />
       
      </div>
    )
  }

  const handleDeactivateActivity = async (activity: Activity) => {
    activity.status = true;
    let response = await activityService.deactivateActivity(activity);
    if (response?.status == HttpStatusCode.Ok) {
      let temp = [...activities];
      let index = temp.findIndex((a) => (a.id = activity.id));
      temp.splice(index, 1);
      setActivities(temp);
    }
  };

  useEffect(() => {
    loadActivities();
  }, []);

  return (
    <div className="activities">
      <PaginatedList
        title={'Activity'}
        filters={createFilters()}
        headers={['Date', 'Activity', 'Student', 'User', 'School', ""]}
        data={formatActivityData()}
        className="activity"
        recordsPerPage={15}
        loading={loading}
      />
    </div>
  );
};

export default Activities;
