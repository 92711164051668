import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const AgreementPage6 = forwardRef(({ data, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials, getAcknowledge } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement6)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <ol>
                <li>The institution, a location of the institution, or an educational program offered by the institution was closed or discontinued, and you did not choose to participate in a teach-out plan approved by the Bureau or did not complete a chosen teach-out plan approved by the Bureau.</li>

                <li>You were enrolled at an institution or a location of the institution within the 120 day period before the closure of the institution or location of the institution, or were enrolled in an educational program within the 120 day period before the program was discontinued.</li>

                <li>You were enrolled at an institution or a location of the institution more than 120 days before the closure of the institution or location of the institution, in an educational program offered by the institution as to which the Bureau determined there was a significant decline in the quality or value of the program more than 120 days before closure.</li>

                <li>The institution has been ordered to pay a refund by the Bureau but has failed to do so.</li>

                <li>The institution has failed to pay or reimburse loan proceeds under a federal student loan program as required by law, or has failed to pay or reimburse proceeds received by the institution in excess of tuition and other costs.</li>

                <li>You have been awarded restitution, a refund, or other monetary award by an arbitrator or court, based on a violation of this chapter by an institution or representative of an institution, but have been unable to collect the award from the institution.</li>

                <li>You sought legal counsel that resulted in the cancellation of one or more of your student loans and have an invoice for services rendered and evidence of the cancellation of the student loan or loans. To qualify for STRF reimbursement, the application must be received within four (4) years from the date of the action or event that made the student eligible for recovery from STRF.</li>
            </ol>
            <p>
                A student whose loan is revived by a loan holder or debt collector after a period of noncollection may, at any time, file a written application for recovery from STRF for the debt that would have otherwise been eligible for recovery. If it has been more than four (4) years since the action or event that made the student eligible, the student must have filed a written application for recovery within the original four (4) year period, unless the period has been extended by another act of law. However, no claim can be paid to any student without a social security number or a taxpayer identification number.
            </p>


            <p><b>Understand:</b></p>
            <ul className="p-0 list-unstyled">
                <li>
                    <div className="mt-4 ps-4 position-relative">
                        {getAcknowledge('FFA', `I understand that San Diego Global Knowledge University is approved for student Federal Financial Aid.`)}
                    </div>
                </li>

                <li>
                    <div className="mt-4 ps-4 position-relative">
                        {getAcknowledge('degree', `I understand that the degree and diploma/certificate programs at SDGKU are designed to prepare students for a particular vocational, trade or career field and thus its graduates may have basic knowledge of a particular starting salary or availability of jobs in the labor market. `)}
                    </div>
                </li>

                <li>
                    <div className="mt-4 ps-4 position-relative">
                        {getAcknowledge('agreement', `I understand this agreement is not operative until I attend the first class or session of instruction. This requirement is not applicable to correspondence or online distance learning programs. I further understand that the SDGKU General Catalog and its contents, which I have received and reviewed, are a part of this enrollment agreement and that information presented therein is binding on the school and me.`)}
                    </div>
                </li>

            </ul>
            <div className="position-relative">
                {getInitials('page6')}
            </div>
        </div>
    )
})