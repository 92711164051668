import { Header } from '../header';
import { useContext, useEffect, useRef, useState } from 'react';
import globalContext from '../../../../context/globalContext';
import usePageSignature from '../../../../hooks/pageSignature';
import signedFormService from '../../../../services/signedForm.service';
import { SDGKUFormsPage, SignedFormKey } from '../../../../models/Forms.enum';
import { SignedForm } from '../../../../models/Forms.model';
import { SignedPageData } from '../../../../viewControllers/signedPage';
import { useForm } from 'react-hook-form';
import { ApplicationForm } from '../../../../models/ApplicationForm.model';
import { AlertType } from '../../../../models/AlertType.enum';
import { SDGKUProgram, SDGKUProgramFromName } from '../../../../models/Enums';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import { FinishPage, TokenError } from '../enrollment/sdgku.extrapages';

import './sdgkuDisclosure.scss';
import studentService from '../../../../services/student.service';

const DisclosurePage = (props: any) => {
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { saveForm, getSignature, getAcknowledge } = usePageSignature(viewController);
  const [completed, setCompleted] = useState(false);
  const [token, setToken] = useState('');
  const [studentId, setStudentId] = useState(0);
  const [tokenError, setTokenError] = useState(false);
  const [fullStudentName, setFullStudentName] = useState('');
  const [isVa, setIsVa] = useState(false);

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<ApplicationForm>();

  useEffect(() => {
    loadInitialData();
  }, []);

  const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
    if (!formData) return;

    setValue('student.firstName', formData.student.firstName);
    setValue('student.lastName', formData.student.lastName);
    setFullStudentName(`${formData.student.firstName} ${formData.student.lastName}`);

    setViewController(new SignedPageData(SignedFormKey.DISCLOSURE, program, formData, token, setEnableSubmit));
  };

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    const sudentId = params.get('id');

    if (token && sudentId) {
      loadForm(+sudentId, token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    const dataRequest = signedFormService.getFormData(studentId, SignedFormKey.DISCLOSURE, token);
    const vaRequest = studentService.isVa(studentId);

    const [dataResp, vaResp] = await Promise.all([dataRequest, vaRequest]);

    setLoading(false);

    if (dataResp?.status === HttpStatusCode.Ok) {
      const program = SDGKUProgramFromName(dataResp.data.programName); // TODO: remove once we store program id on Application Forms
      initializeViewController(dataResp?.data, program, token);
    }

    if (vaResp?.status === HttpStatusCode.Ok) {
      setIsVa(vaResp.data);
    }
  };

  const submitForm = async () => {
    if (await saveForm(SDGKUFormsPage.Disclosure, true)) {
      // setCanMoveNext(true)
      setCompleted(true);
    } else {
      showAlert('Error while saving. Please verify all field are completed', AlertType.Error);
    }
  };

  if (tokenError) return <TokenError />;

  if (completed)
    return (
      <>
        <div className="doc-area">
          <Header />
          <FinishPage pageMessage="Disclosure" />
        </div>
      </>
    );

  return (
    <div className="sdgkuDisclosure-form doc-content">
      <img className="logo" src="/static/sdgku/logo.png" alt="sdgku logo"></img>
      <h3 className="title">Enrollment Disclosure Acknowledgement </h3>

      <div className="text-container">
        <p className="applicant">Hello {fullStudentName}</p>
        <p>Thank you for submitting the initial enrollment documents for the {viewController?.formData?.programName}.</p>

        <p>To continue your enrollment process, please acknowledge that you have read and received the following documents:</p>

        <div className="container ack-zone">
          <p className="p-acknowledge">{getAcknowledge('Catalog', 'The San Diego Global General Catalog')}</p>

          {isVa && <p className="p-acknowledge">{getAcknowledge('VIB', 'The SDGKU Veterans Information Bulletin (VIB 2024-2025)')}</p>}

          <p className="p-acknowledge">{getAcknowledge('FS', 'The SDGKU 2020-2021 FSDI School Performance Fact Sheet')}</p>

          <p className="p-acknowledge">{getAcknowledge('EA', 'The New Student Enrollment Agreement')}</p>
        </div>

        <p>Please give us a call at (619) 934-0797 or send an email to info@sdgku.edu, if you have any questions or need any additional enrollment information.</p>

        <p>{getAcknowledge('Main', 'I acknowledge that I have received the Enrollment Disclosures Acknowledgement')}</p>
      </div>
      
      {getSignature()}

      <div className="text-center pb-2 mb-3 mt-3">
        <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
          Save and Complete
        </button>
      </div>
    </div>
  );
};

export default DisclosurePage;
