import { FC } from 'react';
import { Event } from '../../../models/Event.model';
import { School } from '../../../models/School.model';

export interface SchoolSelectorProps {
  selectedEvent?: Event;
  schools: School[];
  selectedSchools?: number[];

  isEventCreator?: () => boolean;
  handleSelectSchool?: (value: any) => void;
}

export const SchoolSelector: FC<SchoolSelectorProps> = ({
  selectedEvent,
  schools,
  selectedSchools,

  isEventCreator,
  handleSelectSchool,
}) => {
  return (
    <div className={`${selectedEvent ? (isEventCreator?.() ? 'schools-toggle-list' : 'schools-selected-list') : 'schools-toggle-list'}`}>
      {schools.map((school, index) => (
        <div
          className={`school-toggle ${selectedSchools?.includes(school.id) ? 'active' : null} `}
          key={index}
          onClick={() => {
            if (!selectedEvent || isEventCreator?.()) {
              handleSelectSchool?.(school.id);
            }
          }}
        >
          <p>{school.shortName}</p>
        </div>
      ))}
    </div>
  );
};
