import { useContext, useEffect, useState } from "react";
import { Header } from "../header";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import usePageSignature from "../../../../hooks/pageSignature";
import TextField from "../../../../components/shared/Input/textField";
import { SDGKUFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import { AlertType } from "../../../../models/AlertType.enum";
import globalContext from "../../../../context/globalContext";
import RadioButton from "../../../../components/shared/Input/radioButton";
import { SignedForm } from "../../../../models/Forms.model";
import { SDGKUProgram } from "../../../../models/Enums";
import signedFormService from "../../../../services/signedForm.service";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import dateService from "../../../../services/date.service";
import { CheckboxList } from "../../../../components/shared/Input/checkBox";
import { FinishPage, TokenError } from "../enrollment/sdgku.extrapages";
import { set, useForm } from "react-hook-form";


import "../../forms.scss";
import "../index.css"
import "../benefitCoding/sdgkuBenefitCoding.scss"


enum Benefit {
    CH30 = "Chapter 30: Montgomery G.I. Bill®",
    CH31 = "Chapter 31: Vocational Rehabilitation",
    CH32 = "Chapter 32: Veterans Educational Assistance Program (VEAP)",
    CH33 = "Chapter 33: Post 9/11 G.I. Bill® ",
    CH33D = "Chapter 33D: Post 9/11 G.I. Bill® (Dependent)",
    CH35 = "Chapter 35: Dependent Education Assistance",
}

enum BenefitUtilizingCodes {
    none = "",
    CH30 = "chapter30",
    CH31 = "chapter31",
    CH32 = "chapter32",
    CH33 = "chapter33",
    CH33D = "chapter33d",
    CH35 = "chapter35",
}

interface BenefitCodingForm {
    firstName: string;
    lastName: string;
    ssn: string;
    programName: string;
    programStartDate: string;
    programEndDate: string;
    benefitUtilizing: string;
}

const BenefitCoding = (props: any) => {

    const [tokenError, setTokenError] = useState(false);
    const [viewController, setViewController] = useState<SignedPageData>(null!);
    const { saveForm, getSignature, getInitials,setIsCustomValid, setRequiredCustomValidation } = usePageSignature(viewController);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const { setLoading, showAlert } = useContext(globalContext);

    const [benefitUtilizing, setBenefitUtilizing] = useState({} as any);
    
    const {
        register,
        formState: { errors, isValid },
        setValue,
        trigger,
    } = useForm<BenefitCodingForm>();
    
    const loadInitialData = async () => {
        // get token from url params
        const params = new URLSearchParams(props.location.search);
        const token = params.get("token");
        const sudentId = params.get("id");
        
        if (token && sudentId) {
            loadForm(+sudentId, token);
        } else {
            showAlert("Invalid token", AlertType.Error);
            setTokenError(true);
        }
    };
    
    const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
        if (!formData) return;
        let programStartDate = "Loading...";
        let programEndDate = "Loading...";
        let ssn = "Loading...";
        
        if (formData?.programStart) {
            programStartDate = dateService.formatYMD(formData.programStart);
        }
        
        if (formData?.programEnd) {
            programEndDate = dateService.formatYMD(formData.programEnd);
        }

        if (formData?.student.ssn) {
            ssn = formData.student.ssn;
            ssn = ssn.substring(ssn.length - 4, ssn.length);
        }

        setValue("firstName", formData.student.firstName);
        setValue("lastName", formData.student.lastName);
        setValue("ssn", ssn);
        setValue("programName", formData.programName);
        setValue("programStartDate", programStartDate || "");
        setValue("programEndDate", programEndDate || "");
        setRequiredCustomValidation(true);

        setViewController(new SignedPageData(SignedFormKey.BENEFIT_CODING, program, formData, token, setEnableSubmit));
    };

    const loadForm = async (studentId: number, token: string) => {
        setLoading(true);
        try {
            const resp = await signedFormService.getFormData(studentId, SignedFormKey.BENEFIT_CODING, token);
            if (resp?.status === HttpStatusCode.Ok) {
                const program: SDGKUProgram = resp.data.programId;
                initializeViewController(resp?.data, program, token);
            } else if (resp?.status === HttpStatusCode.Forbidden) {
                showAlert("Invalid token", AlertType.Error);
                setTokenError(true);
            }
        } catch {
            showAlert("Unexpected error", AlertType.Error);
            setTokenError(true);
        } finally {
            setLoading(false);
        }
    };

    const onBenefitUtilizingChange = (e: any) => {
        setBenefitUtilizing({ ...benefitUtilizing, [e.target.name]: e.target.value });
        trigger(['benefitUtilizing']);
        setIsCustomValid(isValid);
    }

    const submitForm = async () => {
        if (await saveForm(SDGKUFormsPage.BENEFIT_CODING, true, benefitUtilizing)) {
            setCompleted(true);
        } else {
            showAlert("Error while saving. Please verify all field are completed", AlertType.Error);
        }
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    if (tokenError) return <TokenError />;

    if (completed)
        return (
            <>
                <div className="doc-area">
                    <Header />
                    <FinishPage pageMessage="VA Benefit Coding Request" />
                </div>
            </>
        );

    return (
        <div className="benefitCoding mx-auto p-3 doc-area">
            <Header />
            <div className="doc-content pt-3">
                <h3 className="title">VA Benefit Coding Request</h3>

                <div className="row">
                    <div className="col-4">
                        <TextField disabled errors={errors} label="First Name" field="firstName" type="text" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Last Name" field="lastName" type="text" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Social Security Number (last 4 digits)" field="ssn" type="text" register={register} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <TextField disabled errors={errors} label="Program Name" field="programName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Date Start" field="programStartDate" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Date End" field="programEndDate" type="text" register={register} />
                    </div>
                </div>

                <h5 className="sub-title">Benefit Utilizing (Check One)</h5>

                <div className="mt-4 ps-4 position-relative">
                    <div className="row">
                        <RadioButton
                            label={Benefit.CH30}
                            field={"benefitUtilizing"}
                            value={BenefitUtilizingCodes.CH30}
                            register={register}
                            errors={errors}
                            onChange={onBenefitUtilizingChange}
                        />
                        <RadioButton
                            label={Benefit.CH31}
                            field={"benefitUtilizing"}
                            value={BenefitUtilizingCodes.CH31}
                            register={register}
                            errors={errors}
                            onChange={onBenefitUtilizingChange}
                        />
                        <RadioButton
                            label={Benefit.CH32}
                            field={"benefitUtilizing"}
                            value={BenefitUtilizingCodes.CH32}
                            register={register}
                            errors={errors}
                            onChange={onBenefitUtilizingChange}
                        />
                        <RadioButton
                            label={Benefit.CH33}
                            field={"benefitUtilizing"}
                            value={BenefitUtilizingCodes.CH33}
                            register={register}
                            errors={errors}
                            onChange={onBenefitUtilizingChange}
                        />
                        <RadioButton
                            label={Benefit.CH33D}
                            field={"benefitUtilizing"}
                            value={BenefitUtilizingCodes.CH33D}
                            register={register}
                            errors={errors}
                            onChange={onBenefitUtilizingChange}
                        />
                        <RadioButton
                            label={Benefit.CH35}
                            field={"benefitUtilizing"}
                            value={BenefitUtilizingCodes.CH35}
                            register={register}
                            errors={errors}
                            onChange={onBenefitUtilizingChange}
                        />
                    </div>
                </div>

                <div className="text-container">
                    <div className="disclosure-paragraph">
                        <p>I am responsible for paying all tuition and fees not covered by my VA Education Benefits. Failure to do so may
                            result in enrollment blocking and any balance due may be sent to a collections agency.</p>
                        <div className="initials-Pad">
                            {getInitials('main-0')}
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>{" "}
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>For CH 1606 and CH 30 recipients, tuition and fees are not covered by the VA. Fees are due at the time of class
                            registration. I may be dropped within 10 days of registration if tuition and fees are not paid.</p>
                        <div className="initials-Pad">
                            {getInitials('main-1', true)}
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>{" "}
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>If I stop using my VA Education Benefits, my coding may be deactivated. Once deactivated, tuition and fees
                            will be due at the time of registration. I may be dropped within 10 days of registration if tuition and fees are
                            not paid. To re-establish my coding benefit I will need to complete this request again with San Diego Global
                            Knowledge University Veterans Education Benefits School Certifying Official.</p>
                        <div className="initials-Pad">
                            {getInitials('main-2', true)}
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>{" "}
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="disclosure-paragraph">
                        <p>I understand that this request does not initiate my VA Education Benefits at San Diego Global
                            Knowledge University. To start my benefits, I must complete all admission requirements for VA
                            students established by San Diego Global Knowledge and any additional paperwork required at the time of
                            certification.</p>
                        <div className="initials-Pad">
                            {getInitials('main-3', true)}
                            <p>
                                <small>
                                    <i>Initial only after you have had sufficient time to read and understand the information.</i>
                                </small>{" "}
                                <br />
                            </p>
                        </div>
                    </div>


                    <div className="position-relative d-flex flex-row">
                        <div className="pad">{getSignature()}</div>
                    </div>

                    <div className="text-center pb-2 mb-3 mt-3">
                        <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
                            Save and Complete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitCoding;