import "./calendarPage.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import React, { useState, useEffect, useContext } from "react";
import globalContext from "../../context/globalContext";

import { Department, Event } from "../../models/Event.model";
import calendarService from "../../services/calendar.service";
import EventModal from "../../components/calendar/eventModal";
import EventList from "../../components/calendar/eventList";
import MonthModal from "../../components/calendar/monthModal";
import ManageDepartmentModal from "../../components/calendar/manageDepartment/manageDepartmentModal";
import { Link } from "react-router-dom";
import Select from "react-select";
import { HttpStatusCode } from "../../models/common/HttpStatusCode.enum";
import {
  LogOut as ILogOut,
  User as IUser,
  Settings as ISettings,
  Folder as FolderIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
} from "react-feather";
import { SelectOption } from "../../models/common/Base.model";
import { eventManager } from "react-toastify/dist/core";

const CalendarPage = (props: any) => {
  const moment = require("moment-timezone");

  const { user, setLoading, showAlert } = useContext(globalContext);
  const [selectedEventId, setSelectedEventId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [monthModalVisible, setMonthModalVisible] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([] as Event[]);
  const [timezone, setTimezone] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [fromDate, setFromDate] = useState(moment().startOf("month"));
  const [showManageDepartmentModal, setShowManageDepartmentModal] =
    useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState<SelectOption | null>(null);

  const userType = () => {
    if (user?.isAdmin) {
      return "Administrator";
    } else if (user?.isReviewer) {
      return "Reviewer";
    } else {
      return "User";
    }
  };

  const calendarRef = React.useRef({} as FullCalendar);

  const pages = ["Master Calendar", "Event List"];

  const handleAddEvent = async (savedEvent: Event) => {
    setModalVisible(false);
    setSelectedEventId(0);
    let temp = [...calendarEvents];
    temp.push(savedEvent);
    setCalendarEvents(temp);
  };

  const handleEditEvent = async (savedEvent: Event) => {
    setModalVisible(false);
    setSelectedEventId(0);
    loadData(fromDate);
  };

  const handleDeleteEvent = async (removedEvent: Event) => {
    loadData(fromDate);
    handleModalClose();
  };

  const ShowEventDetails = async (eventId: any) => {
    setSelectedEventId(eventId);
    setModalVisible(true);
  };

  const handleShowAddModal = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(!modalVisible);
    setSelectedEventId(0);
  };

  const handleMonthModalClose = () => {
    setMonthModalVisible(false);
  };

  const handleManageDepartment = () => {
    setShowManageDepartmentModal(true);
  };

  const handleCloseManageDepartmentModal = () => {
    setShowManageDepartmentModal(false);
  };

  const handleGoToDate = (month: any, year: any) => {
    // This function will trigger a new load data supplied with the date (start, end?)
    let fromDate = moment().startOf("month");
    fromDate.month(month);
    fromDate.year(year);

    setFromDate(fromDate);

    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(fromDate.format("YYYY-MM-DDTHH:mm"));
    loadData(fromDate);
  };

  const handleCustomMonthNavigation = (move: any) => {
    let tempDate = fromDate;
    tempDate.add(move, "months");
    tempDate.startOf("month");

    setFromDate(tempDate);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(tempDate.format("YYYY-MM-DDTHH:mm"));

    loadData(tempDate);
  };

  const handleCustomTodayNavigation = () => {
    let tempDate = moment().startOf("month");
    setFromDate(tempDate);
    loadData(tempDate);
  };

  const loadData = async (from: any) => {
    let tempDate = from;
    let toDate = moment(tempDate).add(1, "months");

    let payload;

    if (selectedDepartment && selectedDepartment.value !== null) {
      payload = {
        fromDate: tempDate.format("YYYY-MM-DD"),
        toDate: toDate.format("YYYY-MM-DD"),
        departmentId: selectedDepartment.value,
      };
    } else {
      payload = {
        fromDate: tempDate.format("YYYY-MM-DD"),
        toDate: toDate.format("YYYY-MM-DD"),
      };
    }

    setLoading(true);
    let eventReq = await calendarService.getEvents(payload);

    setCalendarEvents(eventReq?.data);

    setLoading(false);
  };

  const loadDepartment = async () => {
    setLoading(true);

    let response = await calendarService.getDepartments();

    setLoading(false);

    if (response?.status === HttpStatusCode.Ok) {
      const data = response.data || [];
      setDepartments(data);
    } else {
      showAlert("Error loading department");
    }
  };

  const getMonth = () => {
    return fromDate.format("MMMM");
  };

  useEffect(() => {
    // get starting month (current)
    let fromDate = moment().startOf("month");
    setFromDate(fromDate);

    loadData(fromDate);
    loadDepartment();

    let newTimezone = moment.tz.guess();
    setTimezone(newTimezone);

    const allDepartmentsOption = {
      value: undefined,
      label: "All Departments",
    };

    setSelectedDepartment(allDepartmentsOption);
  }, []);

  const getTabControl = () => {
    return (
      <ul className="pagination pagination-space justify-content-center">
        {pages.map((x, index) => (
          <li
            key={index}
            className={`page-item page-item-${index} ${
              currentPage === index ? "active" : null
            }`}
          >
            <button
              className="page-link"
              onClick={() => {
                setCurrentPage(index);
              }}
            >
              {pages[index]}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const getEventsForCalendar = () => {
    if (!calendarEvents) {
      return;
    }

    let events: any[] = [];

    calendarEvents.forEach((event) => {
      let start = moment.tz(event.startDate, timezone);
      let end = moment.tz(event.endDate, timezone);
      let current = start.clone();

      while (current.isSameOrBefore(end, "day")) {
        events.push({
          title: event.name,
          start: current.format("YYYY-MM-DD HH:mm"),
          end: current.clone().endOf("day").format("YYYY-MM-DD HH:mm"),
          publicId: event.id,
          color: event.departmentData?.color || "#6a6a6a",
          department: event.department,
        });

        current.add(1, "day").startOf("day");
      }
    });

    if (selectedDepartment && selectedDepartment.value !== undefined) {
      events = events.filter(
        (event) => event.department === selectedDepartment.value
      );
    }

    return events;
  };

  const getDepartments = () => {
    const allDepartmentsOption: SelectOption = {
      value: undefined,
      label: "All Departments",
    };

    const departmentOptions: SelectOption[] = departments.map((department) => ({
      value: department.id,
      label: department.name,
    }));

    // Then, combine the "All Departments" options and the actual department options
    return [allDepartmentsOption, ...departmentOptions];
  };

  return (
    <div className="calendar-page ht-100v pd-0">
      <div className="header-drop">
        <div className="header content bd-b bg-gray-200">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div className="title">
              <h4 className="mg-b-0 page-title">{pages[currentPage]}</h4>
            </div>
            {getTabControl()}
            <div className="mg-t-20 mg-sm-t-0">
              {/* <button
              onClick={handleManageDepartment}
              className="btn btn-dark mg-4"
              >
              Manage Departments
            </button> */}
              <button
                onClick={handleShowAddModal}
                className="btn btn-sm btn-primary"
              >
                Add new Event
              </button>
            </div>
          </div>
        </div>

        <div className="drop bd-b bg-gray-200">
          <div className="nav-item dropdown-profile dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {user.isCalendar ? (
                <IUser className="iUser" />
              ) : (
                <ISettings className="iUser" />
              )}
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {user.isCalendar ? (
                <div className="user-info">
                  <p className="mg-b-3 tx-12 tx-color-03">{userType()}</p>
                  <h6 className="tx-semibold mg-b-3">
                    {user?.firstName + " " + user?.lastName}
                  </h6>
                  <p className="mg-b-5 tx-13 tx-primary">{user?.email}</p>
                  <div className="dropdown-divider"></div>
                </div>
              ) : (
                ""
              )}

              <div className="mg-b-7 mg-t-7">
                <div className="mg-t-20 mg-sm-t-0">
                  <a
                    className="link-department"
                    href="#"
                    onClick={handleManageDepartment}
                  >
                    <FolderIcon className="icon" />
                    Manage Departments
                  </a>
                </div>
              </div>
              {user.isCalendar ? (
                <div>
                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    to="/public/password-recovery"
                  >
                    <ISettings /> Change Password
                  </Link>

                  <Link className="dropdown-item" to="/public/logout">
                    <ILogOut></ILogOut>Sign Out
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {modalVisible ? (
        <EventModal
          eventId={selectedEventId}
          visible={modalVisible}
          canDelete={true}
          onClose={handleModalClose}
          onEventAdded={handleAddEvent}
          onEventEdited={handleEditEvent}
          onEventDeleted={handleDeleteEvent}
        />
      ) : null}

      {showManageDepartmentModal ? (
        <ManageDepartmentModal
          visible={true}
          onAccept={handleCloseManageDepartmentModal}
          onClose={handleCloseManageDepartmentModal}
        />
      ) : null}

      <MonthModal
        visible={monthModalVisible}
        close={handleMonthModalClose}
        goToDate={handleGoToDate}
      />
      <div className="selectFilter">
        <div className={` ${currentPage === 0 ? "shown" : "hidden"}`}>
          <Select
            placeholder="All Departments"
            options={getDepartments()}
            value={selectedDepartment}
            onChange={(selectedOption: SelectOption | null) => {
              setSelectedDepartment(selectedOption);
              loadData(fromDate);
            }}
          />
        </div>
      </div>
      <div
        className={`calendar-page ${currentPage === 0 ? "shown" : "hidden"}`}
      >
        <FullCalendar
          height="700px"
          aspectRatio={1.5}
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={getEventsForCalendar()}
          eventChange={loadData}
          dayMaxEvents={4}
          expandRows={false}
          eventClick={(info: any) => {
            ShowEventDetails(info.event._def.extendedProps.publicId);
          }}
          datesSet={(info: any) => {
            //loadData(info);
          }}
          customButtons={{
            monthSelector: {
              text: getMonth(),
              click: () => {
                setMonthModalVisible(true);
              },
            },
            customPrevious: {
              icon: "chevron-left",
              click: () => {
                calendarRef.current.getApi().prev();
                handleCustomMonthNavigation(-1);
              },
            },
            customNext: {
              icon: "chevron-right",
              click: () => {
                calendarRef.current.getApi().next();
                handleCustomMonthNavigation(1);
              },
            },
            customToday: {
              text: "Today",
              click: () => {
                calendarRef.current.getApi().today();
                handleCustomTodayNavigation();
              },
            },

            customMonth: {
              text: getMonth(),
              click: () => {
                calendarRef.current.getApi().today();
                handleCustomTodayNavigation();
              },
            },
          }}
          headerToolbar={{
            right: "customToday customPrevious,monthSelector,customNext",
          }}
        />
      </div>
      <div className={`events-page ${currentPage === 1 ? "shown" : "hidden"}`}>
        <EventList
          handleCustomTodayNavigation={handleCustomTodayNavigation}
          handleCustomMonthNavigation={handleCustomMonthNavigation}
          showMonthModal={() => setMonthModalVisible(true)}
          events={calendarEvents}
          showEventDetails={ShowEventDetails}
          getMonth={getMonth}
        />
      </div>
    </div>
  );
};

export default CalendarPage;
