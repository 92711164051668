import { AgreementProps } from "../../types"
import classNames from '../sdgku.enrollment.module.css'
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

const formatMoney = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const SpanishAgreementPage8 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getSignature, getInitials } = usePageSignature(data)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement8, true)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">

            <p>
                <b>Plan de pago:</b> Las opciones del plan de pago están disponibles en cuotas mensuales o por plazo. La institución no cobra comisiones por pagos atrasados, no cobra intereses por pagos aplazados, ni cobra comisiones de financiación por pago o por plazo. No se ofrecen descuentos por pagos. Para cualquier programa de más de un año de duración que permita a los estudiantes pagar la matrícula y las cuotas en cuatro o más plazos, la institución proporciona un "Cuadro TILA" que revela el costo TAE del programa educativo. A continuación se muestra un ejemplo de TILA Box:
            </p>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Tasa anual equivalente (costo del crédito proporcionado como tasa anual)*.</th>
                        <th scope="col">Cargo financiero (costo del crédito total en dólares)**.</th>
                        <th scope="col">Importe financiado (el costo total de la matrícula y las tasas semestrales, expresado en dólares)</th>
                        <th scope="col">Total de pagos (costo total de la matrícula, tasas semestrales y gastos financieros en dólares)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>3.05%</td>
                        <td>$300</td>
                        <td>$9,520.00</td>
                        <td>$9,820.00</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={4}>
                            <p className='text-left'>
                                * Cuota de administración del plan de pago anual / Costo de la matrícula anual, las cuotas semestrales y la cuota de administración del plan de pago. <br />
                                **Basado en la finalización del programa en seis semestres
                            </p>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <p>
                <b>AVISO:</b>
                USTED PUEDE HACER VALER CONTRA EL TENEDOR DEL PAGARÉ QUE FIRMÓ PARA FINANCIAR EL COSTO DEL PROGRAMA EDUCATIVO TODAS LAS RECLAMACIONES Y DEFENSAS QUE PODRÍA HACER VALER CONTRA ESTA INSTITUCIÓN, HASTA EL MONTO QUE YA HAYA PAGADO EN VIRTUD DEL PAGARÉ.
            </p>

            <p>
                San Diego Global Knowledge University transmitirá todas las lecciones al estudiante si éste ha pagado en su totalidad el programa educativo y, después de haber recibido la primera lección y los materiales iniciales, solicita por escrito que se le envíe todo el material. Si San Diego Global Knowledge University transmite el resto del material como lo solicita el estudiante, San Diego Global Knowledge University seguirá obligada a proporcionar los demás servicios educativos que acordó proporcionar, pero no estará obligada a pagar ningún reembolso después de que se hayan transmitido todas las lecciones y el material.
            </p>
            <div className="container-fluid">
                {
                    program?.charges.map((charge, index) => (
                        <div className="row mb-2" key={index}>
                            <div className='col-8'>
                                <b>{charge.chargeName}</b>
                            </div>
                            <div className={`${classNames.amount} col-4 text-right`}>
                                {`${formatMoney(charge.chargeAmount)} USD`}
                            </div>
                        </div>
                    ))
                }
            </div>
            <p>
                <b>
                    Entiendo que éste es un contrato legalmente vinculante. Mi firma a continuación certifica que he leído, comprendido y aceptado mis derechos y responsabilidades, y que se me han explicado claramente las políticas de cancelación y reembolso de la institución. Si usted es menor de edad legal en su estado cuando se inscriba en el Programa, su padre, madre o tutor legal debe firmar como su "Garante" a continuación y debe aceptar ser responsable de sus obligaciones de pago del Programa. Este acuerdo de inscripción es legalmente vinculante cuando lo firman el estudiante (o el garante) y la institución.
                </b>
            </p>
            <div className="position-relative mb-4">
                {getInitials('page8')}
            </div>
            <div id="pad">
                {getSignature()}
            </div>
        </div>
    )
})
