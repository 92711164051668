import { FunctionComponent, useState } from "react";
import Modal from "../../modal/modal";
import AddUserModelBody from "./addUserModelBody";
import { UserOptionType } from "./addUserModelBody";
import "./addUserModelBody.scss";
import { User } from '../../../models/User.model';

interface AddUserModalProps {
  email?: string;
  visible: boolean;
  onClose?: () => void;
  onAccept?: (users: User[]) => void;
}

const AddUserModal: FunctionComponent<AddUserModalProps> = ({ visible, onClose, onAccept, email }) => {
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<UserOptionType[]>([]);

  const handleAccept = () => {
    const users = selectedUsers.map((user) => {
      return {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      } as User;
    });

    if(onAccept) {
      onAccept(users);
    }
  };

  return (
    <div className="flex items-center justify-self-center gap-4 pt-6 w-full">
      <Modal
        visible={visible}
        title="Add user to department"
        mainButtonText={email ? "Save User" : "Invite User"}
        onClose={onClose}
        onAccept={handleAccept}
        size="lg"
      >
        <AddUserModelBody selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
      </Modal>
    </div>
  );
};

export default AddUserModal;
