import { useContext, useEffect, useState } from 'react';
import globalContext from '../../../context/globalContext';
import usePageSignature from '../../../hooks/pageSignature';
import { AlertType } from '../../../models/AlertType.enum';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { PAFormsPage, SignedFormKey } from '../../../models/Forms.enum';
import signedFormService from '../../../services/signedForm.service';
import { SignedPageData } from '../../../viewControllers/signedPage';
import { useHistory } from 'react-router-dom';

import './questionaire.scss';
import { Scale } from 'chart.js';

export const GeneralQuestionaire = (props: any) => {
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const { saveForm, getInitials, getSignature } = usePageSignature(viewController, true);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { setLoading, showAlert } = useContext(globalContext);
  const history = useHistory();

  const loadForm = async () => {
    setLoading(true);
    const resp = await signedFormService.getFormData(props.studentId, SignedFormKey.GENQUESTIONAIRE, props.token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      setViewController(new SignedPageData(SignedFormKey.GENQUESTIONAIRE, props.program, resp?.data, props.token, setEnableSubmit));
    }
  };

  const submitForm = async () => {
    if (await saveForm(PAFormsPage.Questionaire, true)) {
      history.push(`/public/pure-aesthetics/thankYou`);
    } else {
      showAlert('Error while saving. Please verify all field are completed', AlertType.Error);
    }
  };

  useEffect(() => {
    if (props.token && props.studentId) loadForm();
  }, [props.token, props.studentId, props.program]);

  return (
    <div className="doc-content pa-questionaire py-5">
      <img src="/static/pureAesthetics/logo2.png" alt="" style={{ width: '300px'}} />
      <h3 className="text-center my-5">Thinking about a career in Aesthetics?</h3>
      <p className="mb-5">
        Please <b>initial</b> next to 1-12 and sign.
      </p>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2')}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              1. Are you comfortable touching and being touched by others, including teachers and students while in the process of learning treatment techniques?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-2', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              2. Our students learn treatments through practice on each other. Are you willing to receive services and temporarily grow out the hair on your legs, bikini area, arms
              and/or face to train in waxing techniques?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-3', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              3. We hold our students to a very high standard. Pure Aesthetics requires students to maintain a cumulative GPA of 80% and cumulative attendance of 90%. Are you
              willing and able to arrive on time and ready to learn on a daily basis?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-4', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              4. Learning about the products that we carry is an important part of the program at Pure Aesthetics. Are you willing to change your usual skin care regimen in order
              to try the products used in services and offered for retail sale?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-5', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              5. Students working with clients on the clinic floor find that their schedule for the day can change quickly with cancellations, walk-ins, and client requests. Will
              you be able to adapt to rapidly changing situations with grace and composure?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-6', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              6. Long and unkempt fingernails can be a liability when working on clients. Are you willing to keep your fingernails trimmed, neat, and without polish for the length
              of your program?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-7', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              7. Many of the services that our students perform require a steady hand. Do you possess the manual dexterity necessary to perform services such as waxing and makeup
              application?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-8', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              8. Many clients have allergies and sensitivities. Are you willing to change your routine by not smoking or wearing perfumes/colognes during your workday?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-9', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              9. The field of aesthetics is constantly changing in response to new technology and techniques. Staying up to date on new treatments can make a real difference to an
              aesthetician's earning potential. Are you willing to continue your education in aesthetics past your graduation?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-10', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              10. Aesthetics can be a very flexible career when it comes to making your own hours, however there will always be more clients wanting services on weekends and in the
              evenings. Are you able to work a varied schedule upon graduation?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-11', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">
              11. We do everything we can when it comes to assisting our students in finding employment upon program completion, however we cannot guarantee that every student will
              find placement. Are you willing to take equal responsibility when it comes to your job search?
            </div>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 col-sm-12 order-sm-2 position-relative">{getInitials('page2-12', true)}</div>
        <div className="col-md-8 col-sm-12 order-md-2">
          <p className="m-0">
            <div className="position-relative">12. An aesthetician's income can vary widely. Are you willing to promote yourself and/or up-sell to your clients?</div>
          </p>
        </div>
      </div>
      <div className="position-relative d-flex flex-row">{getSignature()}</div>
      <div className="text-center pb-2">
        <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={() => submitForm()}>
          Save and Complete
        </button>
      </div>
    </div>
  );
};
