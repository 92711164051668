import { AgreementProps } from "../../types"
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

export const SpanishAgreementPage3 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement3)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <p>
                <b>Ejemplo de cálculo de reembolso:</b> A continuación se muestra un cálculo de reembolso basado en el tiempo para un ejemplo hipotético de retirada de un estudiante del programa Bachelor of Science in Global Management. Los estudiantes que se hayan dado de baja recibirán un reembolso si el Periodo Asistido es del 60% o menos. El estudiante del ejemplo siguiente asistió menos del 60% y se le debe un reembolso. Por lo tanto, el cálculo del reembolso procede de la siguiente manera:
                <ul>
                    <li>Costo total del año académico: 10,050.00 dólares (24 créditos)</li>
                    <li>Cargos del periodo de asistencia: 5,160.00 dólares</li>
                    <li>Número de días del año académico: 280 (40 semanas)</li>
                    <li>Número de días del periodo de asistencia: 140 (20 semanas)</li>
                    <li>Número de días asistidos en el periodo: 36</li>
                    <li>Porcentaje del periodo asistido: (36 días 140 días) X 100% = 26%.</li>
                    <li>El periodo asistido es del 26%, es decir, menos del 60%, por lo que se debe un reembolso al estudiante.</li>
                    <li>Importe adeudado al estudiante: (100% - 26%) X $5,160.00 = $3,818.40</li>
                </ul>
            </p>

            <p>
                <b>Baja y Reingreso:</b> Para determinar la cantidad que debe por el tiempo de asistencia, se considerará que se ha dado de baja del curso o programa cuando se produzca cualquiera de los siguientes casos:
            </p>
            <ol>
                <li>Notifica a la escuela la baja o la fecha real de baja.</li>

                <li>La universidad le da de baja o le expulsa.</li>

                <li>El alumno deja de asistir a clase durante 14 días naturales. En este caso, se considerará que la fecha de baja es la última fecha de asistencia registrada. (Las bajas no oficiales se determinarán controlando la asistencia cada 30 días).</li>

                <li>Si se permite un permiso de ausencia (LOA) aprobado y usted comunica a la escuela que no va a regresar, la fecha de baja será la primera entre la fecha prevista de regreso del LOA o la fecha en que el estudiante comunique a la escuela que no va a regresar.</li>
            </ol>

            <p>
                Un estudiante que se retira en buen estado puede ser aceptado para el reingreso en la próxima fecha de inicio de clases en función de la disponibilidad de plazas y que la discreción del oficial de la escuela.
            </p>
            <p>
                <b>DERECHO DE CANCELACIÓN DEL ALUMNO:</b> Tiene derecho a cancelar el acuerdo de inscripción y obtener el reembolso de los cargos pagados hasta la asistencia a la primera sesión de clase, o el séptimo día después de la inscripción, lo que ocurra más tarde. Si la instrucción no se ofrece en tiempo real, el estudiante tendrá derecho a cancelar el acuerdo de inscripción y recibir un reembolso completo antes de recibir la primera lección y los materiales. Esto es aplicable a los cursos en línea e híbridos. La cancelación será efectiva en la fecha en que se envíe la notificación de cancelación por escrito. San Diego Global Knowledge University realizará el reembolso de acuerdo con la sección 71750 del CA Ed. Code Regulations. Si San Diego Global Knowledge University envió la primera lección y los materiales antes de que se recibiera una notificación de cancelación efectiva, San Diego Global Knowledge University hará el reembolso dentro de los 30 días siguientes a la devolución de los materiales por parte del estudiante. Usted puede cancelar este contrato y recibir un reembolso enviando una notificación a: San Diego Global Knowledge University, 1095 K Street, Ste. B, San Diego, California 92101. Los estudiantes que cancelen en un plazo de siete días recibirán el reembolso de todos los cargos, incluida la cuota de inscripción/registro. Los estudiantes que cancelen más de siete días después de la inscripción recibirán el reembolso de todos los cargos menos la cuota de inscripción/registro.
            </p>
            <p>
                <b>Programas federales de ayuda financiera para estudiantes: </b> SDGKU cumple con las regulaciones aplicables de los programas federales de ayuda financiera estudiantil bajo el Title IV de la Ley Federal de Educación Superior de 1965. SDGKU advierte a cada estudiante que una notificación de cancelación deberá ser por escrito, y que un retiro puede ser efectuado por la notificación escrita del estudiante o por la conducta del estudiante, incluyendo, pero no necesariamente limitado a, la falta de asistencia del estudiante. Se hará un reembolso prorrateado de los dineros del programa de ayuda financiera estudiantil no federal por cargos institucionales a los estudiantes que hayan completado el 60 por ciento o menos del período de asistencia. SDGKU reembolsará el 100 por ciento de la cantidad pagada por cargos institucionales, menos un depósito razonable o cuota de solicitud que no exceda de doscientos cincuenta dólares ($250), si la notificación de cancelación se hace a través de la asistencia a la primera sesión de clase, o el séptimo día después de la inscripción, lo que ocurra más tarde.
            </p>

            <div className="position-relative">
                {getInitials('page3')}
            </div>
        </div>
    )
})