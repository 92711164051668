import TitleBar from "../../components/shared/titleBar/titleBar";
import DocumentTemplateViewer from "../../components/documentTemplate/documentTemplateViewer";

const DocumentTemplatePage = () => {
    return (
        <div className="home-page pd-0">
            <TitleBar header="Manage Document Templates" subheader="Presented on student documents" />

            <DocumentTemplateViewer/>
        </div>

    )
}

export default DocumentTemplatePage;