import http from "./http-common";
import { State } from '../models/common/State.model';
import { Country } from "../models/common/Country.model";

class CommonService {

    async getStates(): Promise<State[]> {
        try {
            const response = await http.get("/common/states/");
            return response.data;
        } catch (error) {
            http.fa360.displayError("Error loading state list");
            return [];
        }
    }

    async getCountries(): Promise<Country[]> {
        try {
            const response = await http.get("/common/countries/");
            return response.data;
        } catch (error) {
            http.fa360.displayError("Error loading country list");
            return [];
        }
    }
}

export default new CommonService();