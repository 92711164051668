import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { trackGAPageView, trackAZPageView } from '../app.analytics';

interface TrackPageViewsProps {
  children: ReactNode;
}

const TrackPageViews: React.FC<TrackPageViewsProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    trackGAPageView(location.pathname + location.search);
    trackAZPageView(location.pathname + location.search);
  }, [location]);

  return <>{children}</>;
};

export default TrackPageViews;