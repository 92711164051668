import { School } from "./School.model";
export class Student {
	public id = 0;
	public firstName = "";
	public lastName = "";
	public middleName = "";
	public otherName = "";
	public email = "";
	public ssn = "";
	public phone = "";
	public workPhone = "";
	public educationLevel = "";
	public lastSchool = "";
	public maritalStatus = "";
	public numberChildren = 0;
	public certificateName = "";
	public preferredPronoun = "";
	public preferredPronounOther = "";

	public comment: string = "";
	public startDate? = "";
	public birthDate? = "";

	public status = 0;
	public school: number = 0;
	public schoolData: School | undefined;
	public reviewer: number | undefined = undefined;

	public addressNumber = "";
	public addressStreet = "";
	public addressCity = "";
	public addressState = "";
	public addressStateName = "";
	public addressCountry = "";
	public addressZip = "";

	public age = 0;
	public gender = 0;
	public legalGuardian = "";
	public permanentAddress = "";
	public leftHanded = false;
	public disabilities = "";	
	public convicted = "";

	public created_at?: Date;
	public updated_at?: Date;


	// non persisted fields
	public disabilities_decline = false;

	constructor(data?: any) {
		Object.assign(this, data);
	}

	public fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	public fullAddress(): string {
		return `${this.addressStreet || ""} ${this.addressNumber || ""}, ${this.addressCity || ""}, ${this.addressStateName || this.addressState || ""} ${this.addressZip || ""}`;
	}
}
