import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import globalContext from "../../context/globalContext";
import { LoggedInStatus } from "../../context/IContext";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = useContext(globalContext).isLoggedIn;

  // TODO: Return a loading component
  if (isLoggedIn === LoggedInStatus.NOT_VERIFIED) return null;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isLoggedIn === LoggedInStatus.LOGGED_IN ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/public/login',
              state: { from: props.location },
            }}
          />
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
