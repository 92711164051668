import { PersonalReferenceType } from './Enums';
import { Student } from './Student.model';

export interface ApplicationFormAcademicInfo {
    id?: number;
    ged_date: string;
    highName: string;
    highAddress: string;
    highYear: string;
    highDegree: string;
    highFrom: string;
    highTo: string;
    highGraduated: boolean | number;

    collegeName: string;
    collegeAddress: string;
    collegeDate: string;
    collegeDegree: string;
    collegeFrom: string;
    collegeTo: string;
    collegeGraduated: boolean | number;

    trainingName: string;
    trainingAddress: string;
    trainingDate: string;
    trainingDegree: string;
    trainingFrom: string;
    trainingTo: string;
    trainingGraduated: boolean | number;
}

export interface ApplicationFormMilitaryInfo {
    id?: number;
    militaryRecord: string;
    militaryBranch: string;
    dateFrom: string;
    dateTo: string;
    benefits: boolean | string;
    honorableDischarged: boolean | string;
    chapter?: string;
    // add chapter to model FE, BE, DB, Serializers
}

export interface ApplicationCitizenshipInfo {
    id?: number;
    birthCity: "";
    birthState: "";
    birthCountry: "";
    citizen: boolean | string;
    visa: boolean | string;
    citizenship: boolean | string;
    englishSpeaker: boolean | string;
    alienNumber: boolean | string;
    alienRegistrationNumber?: string;
    englishExam: boolean | string;
    examScore?: string;
    englishProficiency:0;
}

export interface ApplicationFormEmploymentReference {
    id?: number;
    employerName: string;
    employerWebsite: string;
    employerSupervisor: string;
    employerDates: string;
    employerAddress: string;
    employerPhone: string;
    employerEmail: string;
    employerPosition: string;
    employerDuties: string;
    employerField: string;
    employerDateFrom: string;
    employerDateTo: string;
}

export interface ApplicationFormPersonalReference {
    id?: number;
    name: string;
    email: string;
    address: string;
    phone: string;
    relationship: string;
    city: string;
    state: string;
    type: PersonalReferenceType;
}

export interface ApplicationFormComplexHearAboutUs {
    id?: number;
    website?: boolean;
    onlineSearch?: boolean;
    droveBy?: boolean;
    wordOfMouth?: boolean;
    socialMedia?: boolean;
    socialMediaName: string;
    referral?: boolean;
    referralName: string;
    other?: boolean;
    otherName: string;
}

export class ApplicationForm {
    public id = 0;
    public student: Student = new Student();

    public program = "";
    public program_id? : number = undefined;
    public hearAboutUs = "";
    public hearAboutUsOther = "";
    public whyChooseUs = "";
    public whyChooseUsOther = "";
    public whyAttendSchool = "";
    public howEnsureCompletion = "";
    public educationalGoals = "";
    public payFinancialAid = false;
    public payCash = false;
    public payMilitaryBenefits = false;
    public payCreditCard = false;    
    public payCheck = false;    
    public payEmployeeTuition = false;
    public payScholarship = false;
    public payPrimaCounty = false;
    public payVocationalRehab = false;
    public pay_tribal_grant = false;
    public payOther = false;
    public payOtherName = "";
    public signature = "";
    public step = 1;

    public ethnicity = "";
    public race = "";
    public occurrence = "";
    public startDate = "";
    public schedule = "";
    public courseLength = "";
    public guardianAgree = false;

    public criminalRecord = "";

    public currentlyEmployed = "";
    public employedWhere = "";
    public currentlyStudying = "";
    public studentWhere = "";

    public createdAt = "";
    public updatedAt = "";
    public status = 0;

    public academicInfo?: ApplicationFormAcademicInfo;
    public militaryInfo?: ApplicationFormMilitaryInfo;
    public citizenship?: ApplicationCitizenshipInfo;

    public employment?: ApplicationFormEmploymentReference[];
    public references?: ApplicationFormPersonalReference[];
    public complexHearAboutUs?: ApplicationFormComplexHearAboutUs;

    public token?: string;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}

export interface ApplicationFormSDFKUStep2SignatureData {
  signature: string;
  guardianAgree: boolean;
  whyChooseUs: string;
}

export interface ApplicationFormSDFKUStep2ReferemcesData {
  references: ApplicationFormPersonalReference[];
}

export interface ApplicationFormSDGKUStep2Request {
  requestId: number;
  educationLevel: string;
  currentlyEmployed: boolean;
  employmentData: ApplicationFormEmploymentReference;
  referencesData: ApplicationFormPersonalReference[];
  signatureData: ApplicationFormSDFKUStep2SignatureData;
  academicData: ApplicationFormAcademicInfo;
}


export const GetApplicationPaymentMethodsList = (application: ApplicationForm) => {
    let paymentMethods = [];

    if (application.payFinancialAid) {
        paymentMethods.push('Financial Aid');
    }

    if (application.payCash) {
        paymentMethods.push('Cash');
    }

    if (application.payMilitaryBenefits) {
        paymentMethods.push('Military Benefits');
    }

    if (application.payCreditCard) {
        paymentMethods.push('Credit Card');
    }

    if (application.payCheck) {
        paymentMethods.push('Check');
    }

    if (application.payEmployeeTuition) {
        paymentMethods.push('Employee Tuition');
    }

    if (application.payScholarship) {
        paymentMethods.push('Scholarship');
    }

    if (application.payPrimaCounty) {
        paymentMethods.push('Prima County');
    }

    if (application.payVocationalRehab) {
        paymentMethods.push('Vocational Rehab');
    }

    if (application.pay_tribal_grant) {
        paymentMethods.push('Tribal Grant');
    }

    if (application.payOther) {
        paymentMethods.push(application.payOtherName);
    }

    return paymentMethods;
};

export const GetApplicationPaymentMethodsString = (application: ApplicationForm, separator = ", ") => {
    return GetApplicationPaymentMethodsList(application).join(separator);
}