import { FunctionComponent, useContext, useEffect, useState } from 'react';
import './inviteCreateUser.scss';
import { toast } from 'react-toastify';
import globalContext from '../../../context/globalContext';
import authService from '../../../services/auth.service';
import { DepartmentUserRequest } from '../../../models/User.model';
import calendarService from '../../../services/calendar.service';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { useHistory } from 'react-router-dom';

interface InviteCreateUserProps {
  location: any;
}

const InviteCreateUser: FunctionComponent<InviteCreateUserProps> = (props) => {
  const [token, setToken] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [formData, setFormData] = useState<DepartmentUserRequest>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const history = useHistory();

  const { setLoading } = useContext(globalContext);

  useEffect(() => {
    validateToken();
  }, []);


  const showToast = (message: string) => {
    toast.dark(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
};


  const validateToken = async () => {
    // get token from query params
    const token = props.location.search.split('=')[1];
    if (!token) {
      setIsTokenValid(false);
      return;
    }

    setLoading(true, 'Validating token...');
    const response = await authService.validateDepartmentInviteToken(token);
    setLoading(false);

    if (response?.success) {
      setToken(token);
      setIsTokenValid(true);
    } else {
      setIsTokenValid(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();

  //   if(formData.password === formData.confirmPassword) {
  //     let response = await calendarService.acceptInvite(formData, token);
  //     processReponse(response);
  //     history.push('/login')
  //   } else {
  //     toast.error(`❌ Password doesn't  match, try again`, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //     });
  //   } 

  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (formData.password !== formData.confirmPassword) {
      showToast(`❌ Passwords don't match, please try again`);
      return;
    }
  
    if (formData.password.length < 8) {
      showToast(`❌ Password must be at least 8 characters long`);
      return;
    }
  
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if (!regex.test(formData.password)) {
      showToast(`❌ Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character`);
      return;
    }
  
    try {
      let response = await calendarService.acceptInvite(formData, token);
      processReponse(response);
      history.push('/login');
    } catch (error) {
      showToast(`❌ An error occurred while accepting the invitation. Please try again.`);
      console.error(error);
    }
  };


  const processReponse = (response: any) => {
    if (response && response.status === HttpStatusCode.Ok) {
      toast.dark(`✔️ User created`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast.dark("❌ Something went wrong, try again", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const formContent = (
    <>
      <div className="flex">
        <label>
          <input
            required
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="input"
          />
          <span>First Name</span>
        </label>

        <label>
          <input
            required
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="input"
          />
          <span>Last Name</span>
        </label>
      </div>

      <label>
        <input
          required
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="input"
        />
        <span>Email</span>
      </label>

      <label>
        <input
          required
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="input"
        />
        <span>Password</span>
      </label>

      <label>
        <input
          required
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          className="input"
        />
        <span>Confirm Password</span>
      </label>

      <button type="submit" className="submit btn btn-md btn-outline-primary">
        Submit
      </button>
    </>
  );

  const invalidToken = (
    <>
      <div className="alert mg-10 alert-danger" role="alert">
        <h5 className="tx-center">Invalid token</h5>
        The security token is invalid. Please ask the calendar manager for a new
        invite.
      </div>
    </>
  );

  return (
    <div className="accept-invite">
      <form className="form" onSubmit={handleSubmit}>
        <p className="title">Register </p>
        <h1 className="tx-color-01 mg-b-5">FA360</h1>
        <p className="message">
          Signup now to see events of <b>Accounting</b> department!
        </p>
          {isTokenValid ? formContent : invalidToken}
      </form>
    </div>
  );
};

export default InviteCreateUser;
